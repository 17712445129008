<ng-container *transloco="let t; read: 'raccontiRiflessioni.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <p>{{t('alert')}}</p>

    <div fxLayout="column" fxLayoutGap="1em" formArrayName="domande" (window:resize)="onResize($event)">
      <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
        <span *ngIf="isMobile" class="small">{{ t('domanda.' + domanda?.value?.codiceDomanda) }}</span>
        <mat-form-field fxFill appearance="outline" [formGroupName]="i">
          <mat-label *ngIf="!isMobile">{{ t('domanda.' + domanda?.value?.codiceDomanda) }}</mat-label>
          <textarea matInput formControlName="rispostaDomanda"></textarea>
        </mat-form-field>
      </ng-container>
    </div>
  </form>
</ng-container>
