import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { StrumentoDiRegistrazioneArchivio, StrutturaEducativa } from '@gpi/lbl/shared/entity';
import { AutovalutazioneQualitaSintesiStrutturaNidoInfanzia } from 'libs/lbl/shared/entity/src/lib/base/StrumentiRegistrazione/AutovalutazioneQualitaSintesiStrutturaNidoInfanzia';
import { AutovalutazioneQualitaSintesiStrutturaRow } from 'libs/lbl/shared/entity/src/lib/base/StrumentiRegistrazione/AutovalutazioneQualitaSintesiStrutturaRow';
import { Observable } from 'rxjs';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-autovalutazione-qualita-nido-infanzia',
  templateUrl: './scheda-autovalutazione-qualita-sintesi-livello-struttura-nido-infanzia.component.html',
  styleUrls: ['./scheda-autovalutazione-qualita-sintesi-livello-struttura-nido-infanzia.component.scss'],
})
export class SchedaAutovalutazioneQualitaSintesiLivelloStrutturaNidoInfanziaComponent implements OnInit, OnChanges {
  @Input() strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input() strutturaEducativa: StrutturaEducativa;
  @Input() submit$: Observable<void>;
  @Output() updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  form: FormGroup;
  displayedColumns = ['descrittore', 'osservazioniEmpiriche', 'puntiDiForza', 'azioniDiMiglioramento', 'approfindimentiProcedure'];

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): AutovalutazioneQualitaSintesiStrutturaNidoInfanzia {
    let content = this.strumentoDiRegistrazioneArchivio.content as AutovalutazioneQualitaSintesiStrutturaNidoInfanzia;
    if (!content) {
      content = AutovalutazioneQualitaSintesiStrutturaNidoInfanzia.InitContent();
    }

    return content;
  }

  get spaziAccessbilita(): FormArray {
    return this.form.get('spaziAccessbilita') as FormArray;
  }
  get leggibilitaRiconoscibilita(): FormArray {
    return this.form.get('leggibilitaRiconoscibilita') as FormArray;
  }
  get differenziazioneFunzionale(): FormArray {
    return this.form.get('differenziazioneFunzionale') as FormArray;
  }
  get spaziPersonalizzazione(): FormArray {
    return this.form.get('spaziPersonalizzazione') as FormArray;
  }
  get tempiPrevedibilitaRiconoscibilita(): FormArray {
    return this.form.get('tempiPrevedibilitaRiconoscibilita') as FormArray;
  }
  get tempiPersonalizzazione(): FormArray {
    return this.form.get('tempiPersonalizzazione') as FormArray;
  }
  get tempiContinuitaCambiamenti(): FormArray {
    return this.form.get('tempiContinuitaCambiamenti') as FormArray;
  }
  get relazioniBenessere(): FormArray {
    return this.form.get('relazioniBenessere') as FormArray;
  }
  get relazioniPersonalizzazione(): FormArray {
    return this.form.get('relazioniPersonalizzazione') as FormArray;
  }
  get relazioniSocialitaProcessi(): FormArray {
    return this.form.get('relazioniSocialitaProcessi') as FormArray;
  }
  get proposteEducativeIntenzionalita(): FormArray {
    return this.form.get('proposteEducativeIntenzionalita') as FormArray;
  }
  get proposteEducativeSignificativitacontinuita(): FormArray {
    return this.form.get('proposteEducativeSignificativitacontinuita') as FormArray;
  }
  get proposteEducativeVarietaCoerenza(): FormArray {
    return this.form.get('proposteEducativeVarietaCoerenza') as FormArray;
  }
  get relazioniPartecipazioneFamiglie(): FormArray {
    return this.form.get('relazioniPartecipazioneFamiglie') as FormArray;
  }
  get culturaGenitorialita(): FormArray {
    return this.form.get('culturaGenitorialita') as FormArray;
  }
  get rapportoTerritorioAzioniRaccordo(): FormArray {
    return this.form.get('rapportoTerritorioAzioniRaccordo') as FormArray;
  }
  get rapportoTerritorioCulturaComunitaEducante(): FormArray {
    return this.form.get('rapportoTerritorioCulturaComunitaEducante') as FormArray;
  }
  get coordinamentoGruppoLavoroCollegialita(): FormArray {
    return this.form.get('coordinamentoGruppoLavoroCollegialita') as FormArray;
  }
  get coordinamentoGruppoLavoroFormazioneContestualizzata(): FormArray {
    return this.form.get('coordinamentoGruppoLavoroFormazioneContestualizzata') as FormArray;
  }
  get coordinamentoGruppoLavoroCoordinamentoPedagogico(): FormArray {
    return this.form.get('coordinamentoGruppoLavoroCoordinamentoPedagogico') as FormArray;
  }
  get coordinamentoGruppoLavoroProcessiRiflessione(): FormArray {
    return this.form.get('coordinamentoGruppoLavoroProcessiRiflessione') as FormArray;
  }
  get progettazioneIntenzionalitaCoerenza(): FormArray {
    return this.form.get('progettazioneIntenzionalitaCoerenza') as FormArray;
  }
  get progettazioneContestualizzazioneFlessibilita(): FormArray {
    return this.form.get('progettazioneContestualizzazioneFlessibilita') as FormArray;
  }
  get documentazioneSistematicitaCoerenza(): FormArray {
    return this.form.get('documentazioneSistematicitaCoerenza') as FormArray;
  }
  get documentazioneLeggibilita(): FormArray {
    return this.form.get('documentazioneLeggibilita') as FormArray;
  }
  get sistematicita(): FormArray {
    return this.form.get('sistematicita') as FormArray;
  }
  get funzioneFormativa(): FormArray {
    return this.form.get('funzioneFormativa') as FormArray;
  }

  constructor() { }

  ngOnInit(): void {
    this.form = this.buildForm();

    this.form.valueChanges.subscribe(() => {
      this.submit(this.form);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      spaziAccessbilita: this.content?.spaziAccessbilita ? this.buildFormTable(this.content.spaziAccessbilita) : new FormArray([]),
      leggibilitaRiconoscibilita: this.content?.leggibilitaRiconoscibilita ? this.buildFormTable(this.content.leggibilitaRiconoscibilita) : new FormArray([]),
      differenziazioneFunzionale: this.content?.leggibilitaRiconoscibilita ? this.buildFormTable(this.content.differenziazioneFunzionale) : new FormArray([]),
      spaziPersonalizzazione: this.content?.spaziPersonalizzazione ? this.buildFormTable(this.content.spaziPersonalizzazione) : new FormArray([]),
      tempiPrevedibilitaRiconoscibilita: this.content?.tempiPrevedibilitaRiconoscibilita ? this.buildFormTable(this.content.tempiPrevedibilitaRiconoscibilita) : new FormArray([]),
      tempiPersonalizzazione: this.content?.tempiPersonalizzazione ? this.buildFormTable(this.content.tempiPersonalizzazione) : new FormArray([]),
      tempiContinuitaCambiamenti: this.content?.tempiContinuitaCambiamenti ? this.buildFormTable(this.content.tempiContinuitaCambiamenti) : new FormArray([]),
      relazioniBenessere: this.content?.relazioniBenessere ? this.buildFormTable(this.content.relazioniBenessere) : new FormArray([]),
      relazioniPersonalizzazione: this.content?.relazioniPersonalizzazione ? this.buildFormTable(this.content.relazioniPersonalizzazione) : new FormArray([]),
      relazioniSocialitaProcessi: this.content?.relazioniSocialitaProcessi ? this.buildFormTable(this.content.relazioniSocialitaProcessi) : new FormArray([]),
      proposteEducativeIntenzionalita: this.content?.proposteEducativeIntenzionalita ? this.buildFormTable(this.content.proposteEducativeIntenzionalita) : new FormArray([]),
      proposteEducativeSignificativitacontinuita: this.content?.proposteEducativeSignificativitacontinuita ? this.buildFormTable(this.content.proposteEducativeSignificativitacontinuita) : new FormArray([]),
      proposteEducativeVarietaCoerenza: this.content?.proposteEducativeVarietaCoerenza ? this.buildFormTable(this.content.proposteEducativeVarietaCoerenza) : new FormArray([]),
      relazioniPartecipazioneFamiglie: this.content?.relazioniPartecipazioneFamiglie ? this.buildFormTable(this.content.relazioniPartecipazioneFamiglie) : new FormArray([]),
      culturaGenitorialita: this.content?.culturaGenitorialita ? this.buildFormTable(this.content.culturaGenitorialita) : new FormArray([]),
      rapportoTerritorioAzioniRaccordo: this.content?.rapportoTerritorioAzioniRaccordo ? this.buildFormTable(this.content.rapportoTerritorioAzioniRaccordo) : new FormArray([]),
      rapportoTerritorioCulturaComunitaEducante: this.content?.rapportoTerritorioCulturaComunitaEducante ? this.buildFormTable(this.content.rapportoTerritorioCulturaComunitaEducante) : new FormArray([]),
      coordinamentoGruppoLavoroCollegialita: this.content?.coordinamentoGruppoLavoroCollegialita ? this.buildFormTable(this.content.coordinamentoGruppoLavoroCollegialita) : new FormArray([]),
      coordinamentoGruppoLavoroFormazioneContestualizzata: this.content?.coordinamentoGruppoLavoroFormazioneContestualizzata ? this.buildFormTable(this.content.coordinamentoGruppoLavoroFormazioneContestualizzata) : new FormArray([]),
      coordinamentoGruppoLavoroCoordinamentoPedagogico: this.content?.coordinamentoGruppoLavoroCoordinamentoPedagogico ? this.buildFormTable(this.content.coordinamentoGruppoLavoroCoordinamentoPedagogico) : new FormArray([]),
      coordinamentoGruppoLavoroProcessiRiflessione: this.content?.coordinamentoGruppoLavoroProcessiRiflessione ? this.buildFormTable(this.content.coordinamentoGruppoLavoroProcessiRiflessione) : new FormArray([]),
      progettazioneIntenzionalitaCoerenza: this.content?.progettazioneIntenzionalitaCoerenza ? this.buildFormTable(this.content.progettazioneIntenzionalitaCoerenza) : new FormArray([]),
      progettazioneContestualizzazioneFlessibilita: this.content?.progettazioneContestualizzazioneFlessibilita ? this.buildFormTable(this.content.progettazioneContestualizzazioneFlessibilita) : new FormArray([]),
      documentazioneSistematicitaCoerenza: this.content?.documentazioneSistematicitaCoerenza ? this.buildFormTable(this.content.documentazioneSistematicitaCoerenza) : new FormArray([]),
      documentazioneLeggibilita: this.content?.documentazioneLeggibilita ? this.buildFormTable(this.content.documentazioneLeggibilita) : new FormArray([]),
      sistematicita: this.content?.sistematicita ? this.buildFormTable(this.content.sistematicita) : new FormArray([]),
      funzioneFormativa: this.content?.funzioneFormativa ? this.buildFormTable(this.content.funzioneFormativa) : new FormArray([]),
    }, { updateOn: 'blur' });

    return form;
  }

  buildFormTable(rows: AutovalutazioneQualitaSintesiStrutturaRow[]): FormArray {
    const form = new FormArray(rows.map((value) => this.buildFormRow(value)));

    return form;
  }

  buildFormRow(row: AutovalutazioneQualitaSintesiStrutturaRow): FormGroup {
    const form = new FormGroup({
      descrittore: new FormControl(row.descrittore),
      osservazioniEmpiriche: new FormControl(row.osservazioniEmpiriche),
      puntiDiForza: new FormControl(row.puntiDiForza),
      azioniDiMiglioramento: new FormControl(row.azioniDiMiglioramento),
      approfindimentiProcedure: new FormControl(row.approfindimentiProcedure),
    });

    return form;
  }

  updateForm(entity: AutovalutazioneQualitaSintesiStrutturaNidoInfanzia): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const autovalutazioneQualitaSintesiStrutturaNidoInfanzia = new AutovalutazioneQualitaSintesiStrutturaNidoInfanzia(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: autovalutazioneQualitaSintesiStrutturaNidoInfanzia,
      });
      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }

}
