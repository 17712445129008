import { Allegato, IAllegato } from './Allegato';
import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { FunzionalitaLBL, IFunzionalitaLBL } from './FunzionalitaLBL';

export enum EnumModuliLBL {
  Base = 'ModuloBase',
  Gestionale = 'ModuloGestionale',
  Monitoring = 'ModuloMonitoring',
  Valutazione = 'ModuloValutazione',
}

export interface IModuloLBLAttivatoPerStruttura extends IBaseBusinessObject {
  moduloDiRiferimento: EnumModuliLBL;

  /**
   * Relazioni
   */
  tutorialModulo: IAllegato;
  funzionalitaAttivataPerModulo: IFunzionalitaLBL[];
}

export class ModuloLBLAttivatoPerStruttura extends BaseBusinessObject
  implements IModuloLBLAttivatoPerStruttura {
  moduloDiRiferimento: EnumModuliLBL;

  /**
   * Relazioni
   */
  tutorialModulo: Allegato;
  funzionalitaAttivataPerModulo: FunzionalitaLBL[];

  constructor(params?: Partial<IModuloLBLAttivatoPerStruttura>) {
    super(params);

    /**
     * Mapping
     */
    if (params.tutorialModulo) {
      this.tutorialModulo = new Allegato(params.tutorialModulo);
    }
    if (params.funzionalitaAttivataPerModulo) {
      this.funzionalitaAttivataPerModulo = params.funzionalitaAttivataPerModulo.map(
        (value) => new FunzionalitaLBL(value),
      );
    }
  }

  deconstructor(): IModuloLBLAttivatoPerStruttura {
    return Object.assign({ }, this);
  }
}
