import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  CompetenzaInsegnantiRow,
  CompetenzeInsegnanti,
  RuoloApplicativo,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
  TaskEducatore,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-competenze-insegnanti',
  templateUrl: './strumento-registrazione-competenze-insegnanti.component.html',
  styleUrls: ['./strumento-registrazione-competenze-insegnanti.component.scss'],
})
export class StrumentoRegistrazioneCompetenzeInsegnantiComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  ruoloApplicativoCorrente: RuoloApplicativo;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button')
  buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): CompetenzeInsegnanti {
    let content = this.strumentoDiRegistrazioneArchivio.content as CompetenzeInsegnanti;

    if (!content) {
      content = CompetenzeInsegnanti.InitContent();
    }

    return content;
  }

  form: FormGroup;

  get competenze(): FormArray {
    return this.form.get('competenze') as FormArray;
  }

  displayedColumns: string[] = ['competenza', 'autovalutazione', 'valutazione'];

  tasksMin: number = 0;
  tasksMax: number = 25;

  skillsMin: number = 26;

  valutazioni: number[] = [1, 2, 3, 4, 5];

  ngOnInit() {
    this.form = this.buildForm();

    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      competenze: this.content && this.content.competenze ? this.buildFormCompetenze(this.content.competenze) : new FormArray([])
    });

    return form;
  }

  buildFormCompetenze(competenze: CompetenzaInsegnantiRow[]): FormArray {
    const form = new FormArray(competenze.map((value) => this.buildCompetenzaRow(value)));

    return form;
  }

  buildCompetenzaRow(row: CompetenzaInsegnantiRow): FormGroup {
    const form = new FormGroup({
      competenza: new FormControl(row.competenza ? row.competenza : null),
      autovalutazione: new FormControl(row.autovalutazione ? row.autovalutazione : null),
      valutazione: new FormControl(row.valutazione ? row.valutazione : null)
    });

    return form;
  }

  updateForm(entity: CompetenzeInsegnanti): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const competenzeInsegnanti = new CompetenzeInsegnanti(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: competenzeInsegnanti,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
