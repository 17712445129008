import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AnniScolasticiService } from '@gpi/lbl/app/data-access/anni-scolastici';
import { AnnoScolasticoPeriodoFormativo, FunzionalitaLBL, RuoloApplicativo, ServizioEducativo, StrumentoDiRegistrazioneArchivio, StrutturaEducativa, Utente } from '@gpi/lbl/shared/entity';
import { filter } from 'rxjs/operators';
import { FunzionalitaLblService } from '@gpi/lbl/app/data-access/funzionalita-lbl';

@Component({
  selector: 'lbl-app-ui-checklist-monitoraggio-valutazioni',
  templateUrl: './checklist-monitoraggio-valutazioni.component.html',
  styleUrls: ['./checklist-monitoraggio-valutazioni.component.scss']
})
export class ChecklistMonitoraggioValutazioniComponent implements OnInit {
  // @Output() updatedSintesi: EventEmitter<ClusterIndicatori[]> = new EventEmitter();
  // @Output() annoScolasticoPeriodoFormativiSelected: EventEmitter<AnnoScolasticoPeriodoFormativo[]> = new EventEmitter();
  @Input() funzionalitaLblSelezionata: FunzionalitaLBL;
  @Input() strutturaEducativaSelected: StrutturaEducativa;
  @Input() utenteCorrenteParamInput: Utente;
  @Input() ruoloApplicativoAttualmenteUtilizzatoParamInput: RuoloApplicativo;
  @Input() strumentoArchivioInstanceParamInput: StrumentoDiRegistrazioneArchivio;

  clusterIndicatori = []
  anniScolasticoPeriodoFormativiPerStruttura: AnnoScolasticoPeriodoFormativo[] = [];
  anniScolastivi: AnnoScolasticoPeriodoFormativo[] = [];
  servizioEducativo: ServizioEducativo;
  text: string = 'Vedi sintesi per sezione';
  isDisable = false
  open = false;

  constructor(
    private anniScolasticiService: AnniScolasticiService,
    private dio: FunzionalitaLblService,
    // private strumentiRegistrazioneArchivioService: StrumentiRegistrazioneArchivioService,
    private router: Router) {
  }

  ngOnInit(): void {
    this.anniScolasticiService.readMany().subscribe((annoScolastico) => {

      annoScolastico.data.forEach(el => { this.anniScolasticoPeriodoFormativiPerStruttura.push(el) })
      if (localStorage.getItem('anniScolastici')) {
        let anniScolastici = JSON.parse(
          localStorage.getItem('anniScolastici'),
        );
        this.anniScolastivi = anniScolastici
      }
    })
    this.servizioEducativo = null;
  }

  selectAnnoScolastico(anniScolasticiSelezionati: AnnoScolasticoPeriodoFormativo[]) {
    this.anniScolastivi = []
    anniScolasticiSelezionati.forEach((val) => {
      const annoScolastico = this.anniScolasticoPeriodoFormativiPerStruttura.find((elem) => elem.id === val.id);
      if (annoScolastico) {
        this.isDisable = true;
        this.anniScolastivi = this.anniScolastivi.concat(annoScolastico);
        localStorage.setItem('anniScolastici', JSON.stringify(this.anniScolastivi))
      }
    });
    if (this.anniScolastivi.length === 0) {
      this.isDisable = false;

    }
    this.anniScolastivi.length > 1 ? this.text = 'Vedi sintesi per servizio' : this.text = 'Vedi sintesi per sezione'
  }

  compareFnAnniScolastici(option: AnnoScolasticoPeriodoFormativo, value: AnnoScolasticoPeriodoFormativo): boolean {
    return option && value && option.id === value.id;
  }
  openComponent(routerPath: string) {
    this.router.navigate(['/private/backoffice/strumenti-modulo-monitoring/calcolo-lbl-compliance/' + routerPath])

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {

      this.open = true
    });
  }

  submit() {
    this.clusterIndicatori = ['1', '2', '3', '4', '5']
    // this.updatedSintesi.emit(this.strumentiRegistrazioneArchivioService.getSintesiStrumentiDiRegistrazioneArchivio(this.anniScolastivi));
    // this.annoScolasticoPeriodoFormativiSelected.emit(this.anniScolastivi)
  }
}
