<ng-container *transloco="let t; read: 'sipiSezioneAnagrafica.strumentoRegistrazione.dialog'">
  <h3 mat-dialog-title>
    {{ t('title') }}
  </h3>
  <mat-dialog-content>
    <form [formGroup]="form">
      <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start">
        <div fxLayout="row" fxLayoutGap="1em">
          <mat-form-field appearance="outline">
            <mat-label>{{t('form.nome')}}</mat-label>
            <input matInput formControlName="nome" required />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{t('form.cognome')}}</mat-label>
            <input matInput formControlName="cognome" required />
          </mat-form-field>
        </div>
        <mat-form-field appearance="outline">
          <mat-label>{{t('form.funzione')}}</mat-label>
          <input matInput formControlName="funzione" />
        </mat-form-field>
        <div fxLayout="row" fxLayoutGap="1em">
          <mat-form-field appearance="outline">
            <mat-label>{{t('form.telefono')}}</mat-label>
            <input matInput formControlName="telefono" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{t('form.altriRecapiti')}}</mat-label>
            <input matInput formControlName="altriRecapiti" />
          </mat-form-field>
        </div>
      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <button mat-raised-button (click)="cancel()">
      {{ t('action.cancel') }}
    </button>
    <button mat-raised-button color="primary" (click)="continue()" [disabled]="form.invalid">
      {{ t('action.continue') }}
    </button>
  </mat-dialog-actions>
</ng-container>
