<ng-container *transloco="let t; read: 'dicciCosaPensiDiNoi.strumentoDiRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill (window:resize)="onResize($event)"
      *ngIf="!isMobile">

      <h3>{{t('qualitaAmbiente.title')}}</h3>
      <span>{{t('qualitaAmbiente.subtitle')}}</span>
      <div fxLayout="row">
        <div fxFlex="calc(50% - 1em)" fxFlexOffset="calc(50% - 1em)" fxLayoutGap="1em"
          fxLayoutAlign="space-between center">
          <span *ngFor="let giudizio of gradiValutazione">{{t('gradiValutazione.' + giudizio)}}</span>
        </div>
      </div>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between start" class="mb-3"
            *ngIf="i >= qualitaAmbienteMin && i <= qualitaAmbienteMax" [formGroupName]="i">
            <mat-label fxFlex="calc(40% - 1em)">
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
              fxFlex="calc(50% - 1em)" fxFlexOffset="calc(10% - 1em)" formControlName="valutazione">
              <mat-radio-button *ngFor="let voto of votiValutazione" [value]="voto"></mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-container>
      </div>

      <h3>{{t('aspettiOrganizzazione.title')}}</h3>
      <span>{{t('aspettiOrganizzazione.subtitle')}}</span>
      <div fxLayout="row">
        <div fxFlex="calc(50% - 1em)" fxFlexOffset="calc(50% - 1em)" fxLayoutGap="1em"
          fxLayoutAlign="space-between center">
          <span *ngFor="let giudizio of gradiValutazione">{{t('gradiValutazione.' + giudizio)}}</span>
        </div>
      </div>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between start" class="mb-3"
            *ngIf="i >= aspettiOrganizzazioneMin && i <= aspettiOrganizzazioneMax" [formGroupName]="i">
            <mat-label fxFlex="calc(40% - 1em)">
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
              fxFlex="calc(50% - 1em)" fxFlexOffset="calc(10% - 1em)" formControlName="valutazione">
              <mat-radio-button *ngFor="let voto of votiValutazione" [value]="voto"></mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-container>
      </div>

      <h3>{{t('informazione.title')}}</h3>
      <span>{{t('informazione.subtitle')}}</span>
      <div fxLayout="row">
        <div fxFlex="calc(50% - 1em)" fxFlexOffset="calc(50% - 1em)" fxLayoutGap="1em"
          fxLayoutAlign="space-between center">
          <span *ngFor="let giudizio of gradiValutazione">{{t('gradiValutazione.' + giudizio)}}</span>
        </div>
      </div>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between start" class="mb-3"
            *ngIf="i >= informazioneMin && i <= informazioneMax" [formGroupName]="i">
            <mat-label fxFlex="calc(40% - 1em)">
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
              fxFlex="calc(50% - 1em)" fxFlexOffset="calc(10% - 1em)" formControlName="valutazione">
              <mat-radio-button *ngFor="let voto of votiValutazione" [value]="voto"></mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-container>
      </div>

      <h3>{{t('alimentazione.title')}}</h3>
      <span>{{t('alimentazione.subtitle')}}</span>
      <div fxLayout="row">
        <div fxFlex="calc(50% - 1em)" fxFlexOffset="calc(50% - 1em)" fxLayoutGap="1em"
          fxLayoutAlign="space-between center">
          <span *ngFor="let giudizio of gradiValutazione">{{t('gradiValutazione.' + giudizio)}}</span>
        </div>
      </div>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between start" class="mb-3"
            *ngIf="i >= alimentazioneMin && i <= alimentazioneMax" [formGroupName]="i">
            <mat-label fxFlex="calc(40% - 1em)">
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
              fxFlex="calc(50% - 1em)" fxFlexOffset="calc(10% - 1em)" formControlName="valutazione">
              <mat-radio-button *ngFor="let voto of votiValutazione" [value]="voto"></mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-container>
      </div>

      <h3>{{t('partecipazioneFamiglie.title')}}</h3>
      <span>{{t('partecipazioneFamiglie.subtitle')}}</span>
      <div fxLayout="row">
        <div fxFlex="calc(50% - 1em)" fxFlexOffset="calc(50% - 1em)" fxLayoutGap="1em"
          fxLayoutAlign="space-between center">
          <span *ngFor="let giudizio of gradiValutazione">{{t('gradiValutazione.' + giudizio)}}</span>
        </div>
      </div>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between start" class="mb-3"
            *ngIf="i >= partecipazioneMin && i <= partecipazioneMax" [formGroupName]="i">
            <mat-label fxFlex="calc(40% - 1em)">
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
              fxFlex="calc(50% - 1em)" fxFlexOffset="calc(10% - 1em)" formControlName="valutazione">
              <mat-radio-button *ngFor="let voto of votiValutazione" [value]="voto"></mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-container>
      </div>

      <h3>{{t('progettoEducativo.title')}}</h3>
      <span>{{t('progettoEducativo.subtitle')}}</span>
      <div fxLayout="row">
        <div fxFlex="calc(50% - 1em)" fxFlexOffset="calc(50% - 1em)" fxLayoutGap="1em"
          fxLayoutAlign="space-between center">
          <span *ngFor="let giudizio of gradiValutazione">{{t('gradiValutazione.' + giudizio)}}</span>
        </div>
      </div>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between start" class="mb-3"
            *ngIf="i >= progettoMin && i <= progettoMax" [formGroupName]="i">
            <mat-label fxFlex="calc(40% - 1em)">
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
              fxFlex="calc(50% - 1em)" fxFlexOffset="calc(10% - 1em)" formControlName="valutazione">
              <mat-radio-button *ngFor="let voto of votiValutazione" [value]="voto"></mat-radio-button>
            </mat-radio-group>
          </div>
        </ng-container>
      </div>

      <h3>{{t('valutazioni.' + valutazioni.controls[valutazioni.controls.length - 1].value.codice)}}</h3>
      <div fxLayout="row" formArrayName="valutazioni">
        <div fxFlex="80" fxFlexOffset="10" fxLayoutGap="1em" fxLayoutAlign="space-between end"
          [formGroupName]="valutazioni.controls.length - 1">
          <span>{{t('gradiValutazione.noSoddisfatto')}}</span>
          <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
            fxFlex="calc(50% - 1em)" fxFlexOffset="calc(10% - 1em)" formControlName="valutazione">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em" *ngFor="let voto of votiValutazione">
              <span class="pr-2">{{voto}}</span>
              <mat-radio-button [value]="voto"></mat-radio-button>
            </div>
          </mat-radio-group>
          <span>{{t('gradiValutazione.soddisfatto')}}</span>
        </div>
      </div>

      <mat-form-field fxFill appearance="outline" class="mt-5">
        <mat-label>{{ t('cosaPiace') }}</mat-label>
        <textarea matInput formControlName="cosaPiaceDelServizio"></textarea>
      </mat-form-field>

      <mat-form-field fxFill appearance="outline">
        <mat-label>{{ t('cosaNonPiace') }}</mat-label>
        <textarea matInput formControlName="cosaNonPiaceDelServizio"></textarea>
      </mat-form-field>

      <button #button type="submit" class="d-none"></button>
    </div>
    <!-- versione mobile -->
    <div fxLayout="column" (window:resize)="onResize($event)" *ngIf="isMobile" class="mobile">

      <h4>{{t('qualitaAmbiente.title')}}</h4>
      <span>{{t('qualitaAmbiente.subtitle')}}</span>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="column" fxLayoutGap="1em" *ngIf="i >= qualitaAmbienteMin && i <= qualitaAmbienteMax"
            [formGroupName]="i">
            <mat-label fxFlex>
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <div fxLayout="row">
              <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
                formControlName="valutazione">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em"
                  *ngFor="let voto of votiValutazione">
                  <span>{{voto}}</span>
                  <mat-radio-button [value]="voto"></mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
            <br>

          </div>
        </ng-container>
      </div>
      <br>

      <h4>{{t('aspettiOrganizzazione.title')}}</h4>
      <span>{{t('aspettiOrganizzazione.subtitle')}}</span>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="column" fxLayoutGap="1em"
            *ngIf="i >= aspettiOrganizzazioneMin && i <= aspettiOrganizzazioneMax" [formGroupName]="i">
            <mat-label>
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <div fxLayout="row">
              <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
                formControlName="valutazione">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em"
                  *ngFor="let voto of votiValutazione">
                  <span>{{voto}}</span>
                  <mat-radio-button [value]="voto"></mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
            <br>

          </div>
        </ng-container>
      </div>
      <br>

      <h4>{{t('informazione.title')}}</h4>
      <span>{{t('informazione.subtitle')}}</span>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="column" fxLayoutGap="1em" *ngIf="i >= informazioneMin && i <= informazioneMax"
            [formGroupName]="i">
            <mat-label>
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <div fxLayout="row">
              <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
                formControlName="valutazione">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em"
                  *ngFor="let voto of votiValutazione">
                  <span>{{voto}}</span>
                  <mat-radio-button [value]="voto"></mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
            <br>

          </div>
        </ng-container>
      </div>
      <br>

      <h4>{{t('alimentazione.title')}}</h4>
      <span>{{t('alimentazione.subtitle')}}</span>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="column" fxLayoutGap="1em" *ngIf="i >= alimentazioneMin && i <= alimentazioneMax"
            [formGroupName]="i">
            <mat-label>
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <div fxLayout="row">
              <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
                formControlName="valutazione">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em"
                  *ngFor="let voto of votiValutazione">
                  <span>{{voto}}</span>
                  <mat-radio-button [value]="voto"></mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
            <br>

          </div>
        </ng-container>
      </div>
      <br>

      <h4>{{t('partecipazioneFamiglie.title')}}</h4>
      <span>{{t('partecipazioneFamiglie.subtitle')}}</span>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="column" fxLayoutGap="1em" *ngIf="i >= partecipazioneMin && i <= partecipazioneMax"
            [formGroupName]="i">
            <mat-label>
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <div fxLayout="row">
              <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
                formControlName="valutazione">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em"
                  *ngFor="let voto of votiValutazione">
                  <span>{{voto}}</span>
                  <mat-radio-button [value]="voto"></mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
            <br>

          </div>
        </ng-container>
      </div>
      <br>

      <h4>{{t('progettoEducativo.title')}}</h4>
      <span>{{t('progettoEducativo.subtitle')}}</span>
      <div formArrayName="valutazioni">
        <ng-container *ngFor="let valutazione of valutazioni.controls; let i = index">
          <div fxLayout="column" fxLayoutGap="1em" *ngIf="i >= progettoMin && i <= progettoMax" [formGroupName]="i">
            <mat-label>
              {{ t('valutazioni.' + valutazione.value.codice) }}</mat-label>
            <div fxLayout="row">
              <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
                formControlName="valutazione">
                <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em"
                  *ngFor="let voto of votiValutazione">
                  <span>{{voto}}</span>
                  <mat-radio-button [value]="voto"></mat-radio-button>
                </div>
              </mat-radio-group>
            </div>
            <br>
          </div>
        </ng-container>
      </div>
      <br>

      <h4>{{t('valutazioni.' + valutazioni.controls[valutazioni.controls.length - 1].value.codice)}}</h4>
      <div fxLayout="row" formArrayName="valutazioni">
        <div fxFlex fxLayoutGap="1em" fxLayoutAlign="space-between end"
          [formGroupName]="valutazioni.controls.length - 1">
          <!-- <small>{{t('gradiValutazione.noSoddisfatto')}}</small> -->
          <mat-radio-group fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center"
            formControlName="valutazione">
            <div fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="1em" *ngFor="let voto of votiValutazione">
              <span>{{voto}}</span>
              <mat-radio-button [value]="voto"></mat-radio-button>
            </div>
          </mat-radio-group>
          <!-- <small>{{t('gradiValutazione.soddisfatto')}}</small> -->
        </div>
      </div>

      <mat-form-field fxFill appearance="outline" class="mt-5">
        <mat-label>{{ t('cosaPiace') }}</mat-label>
        <textarea matInput formControlName="cosaPiaceDelServizio"></textarea>
      </mat-form-field>

      <mat-form-field fxFill appearance="outline">
        <mat-label>{{ t('cosaNonPiace') }}</mat-label>
        <textarea matInput formControlName="cosaNonPiaceDelServizio"></textarea>
      </mat-form-field>

      <button #button type="submit" class="d-none"></button>
    </div>

  </form>
</ng-container>
