export interface Theme {
  id: string;
  label: string;

  cssClass: string;
}

export function createLblAppUtilTheme(params: Partial<Theme>) {
  return {
    ...params,
  } as Theme;
}
