import { StrutturaEducativa } from '@gpi/lbl/shared/entity';


export interface IGradoSoddisfazioneGenitori {
    cosaPiaceDelServizio: string;
    cosaNonPiaceDelServizio: string;

    /**
     * Relazioni
     */
    valutazioni: IValutazione[];

}

export class GradoSoddisfazioneGenitori implements IGradoSoddisfazioneGenitori {
    cosaPiaceDelServizio: string;
    cosaNonPiaceDelServizio: string;

    /**
     * Relazioni
     */
    valutazioni: Valutazione[];

    constructor(params: Partial<IGradoSoddisfazioneGenitori> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */

        if (params.valutazioni) {
            this.valutazioni = params.valutazioni.map(
                (value) => new Valutazione(value),
            );
        }

    }

    static InitContent(): GradoSoddisfazioneGenitori {
        const content = new GradoSoddisfazioneGenitori();

        const testiDomande = [
            'Le caratteristiche degli spazi interni del servizio (es. arredi, materiali, suddivisione degli spazi)?',
            'Le soft qualities (es. colori, illuminazione, sensazioni olfattive, sonorità...)?',

            'L\'organizzazione dei diversi momenti che la caratterizzano?',
            'L\'accoglienza dei bambini e dei genitori durante la giornata nel servizio educativo?',
            'Orario e calendario adottati dal servizio?',

            'L\'organizzazione del servizio?',
            'Le esperienze quotidiane che il bambino vive nel servizio?',
            'Il progetto pedagogico/curricolo adottato dal servizio?',

            'L\'esperienza alimentare offerta dal servizio (menù proposto, modalità di produzione del pasto)?',
            'I percorsi di educazione alimentare organizzati nel servizio?',

            'Le occasioni di partecipazione delle famiglie offerte durante l\'anno?',

            'La professionalità degli insegnanti?',
            'La qualità delle esperienze/routine proposte ai bambini nella quotidianità?',
            'La quantità delle attività didattiche proposte ai bambini?',
            'La documentazione fornita ai genitori riguardo ai percorsi educativi?',

            'Complessivamente quanto si sente soddisfatto del servizio?'
        ];

        content.valutazioni = testiDomande.map((value, index) => {
            return new Valutazione({
                codice: `ValutazioneGradoSoddisfazioneGenitori_${index}`,
                testo: value,
            });
        });
        
        return content;
    }

    deconstructor(): IGradoSoddisfazioneGenitori {
        return Object.assign({}, this);
    }
}

export interface IValutazione {
    codice: string;
    testo: string;
    valutazione: number;
}

export class Valutazione implements IValutazione {
    codice: string;
    testo: string;
    valutazione: number;

    constructor(params: Partial<IValutazione> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
    }

    deconstructor(): IValutazione {
        return Object.assign({}, this);
    }
}
