<ng-container *transloco="let t; read: 'gestioneOsservazionePrimiGiorniAmbientamento'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="row" fxLayoutGap="1em">
      <mat-form-field appearance="outline">
        <mat-label>{{t('dateOsservazione.inizio')}}</mat-label>
        <input matInput [matDatepicker]="dataInizioOsservazionePicker" formControlName="periodoOsservazioneDal">
        <mat-datepicker-toggle matSuffix [for]="dataInizioOsservazionePicker"></mat-datepicker-toggle>
        <mat-datepicker #dataInizioOsservazionePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{t('dateOsservazione.fine')}}</mat-label>
        <input matInput [matDatepicker]="dataFineOsservazionePicker" formControlName="periodoOsservazioneAl">
        <mat-datepicker-toggle matSuffix [for]="dataFineOsservazionePicker"></mat-datepicker-toggle>
        <mat-datepicker #dataFineOsservazionePicker></mat-datepicker>
      </mat-form-field>
    </div>

    <h3 class="subtitle">
      <span class="status"></span>
      <span>{{t('salutoEAccoglienza.title')}}</span>
    </h3>
    <div fxLayout="column" fxLayoutGap="1em" formArrayName="domande">
      <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
        <mat-form-field *ngIf="i >= domandeSalutoAccoglienzaMin && i < domandeSalutoAccoglienzaMax" fxFill
          appearance="outline" [formGroupName]="i">
          <mat-label>{{ t('domande.' + domanda?.value?.codiceDomanda) }}</mat-label>
          <textarea matInput formControlName="rispostaDomanda"></textarea>
        </mat-form-field>
      </ng-container>
    </div>


    <h3 class="subtitle">
      <span class="status"></span>
      <span>{{t('relazioni.title')}}</span>
    </h3>
    <div fxLayout="column" fxLayoutGap="1em" formArrayName="domande">
      <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
        <mat-form-field *ngIf="i >= domandeRelazioniMin" fxFill appearance="outline" [formGroupName]="i">
          <mat-label>{{ t('domande.' + domanda?.value?.codiceDomanda) }}</mat-label>
          <textarea matInput formControlName="rispostaDomanda"></textarea>
        </mat-form-field>
      </ng-container>
    </div>
  </form>
</ng-container>
