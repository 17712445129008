import { Domanda, IDomanda } from './Domanda';

export interface IVerbaleDiValutazioneEVerifica {
  elencoPresenti: string[];

  /**
   * Relazioni
   */
  domande: IDomanda[];
}

export class VerbaleDiValutazioneEVerifica implements IVerbaleDiValutazioneEVerifica {
  elencoPresenti: string[];

  /**
   * Relazioni
   */
  domande: Domanda[];

  constructor(params: Partial<IVerbaleDiValutazioneEVerifica> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.domande) {
      this.domande = params.domande.map((value) => new Domanda(value));
    }
  }

  static InitContent(): VerbaleDiValutazioneEVerifica {
    const content = new VerbaleDiValutazioneEVerifica();

    const testiDomande = [
      'Riflessione sulla partecipazione delle famiglie e sulla strategie di coinvolgimento?',
      'Punti di forza e di debolezza dell’organizzazione',
      'Riflessioni rispetto alle proposte formative realizzate ed eventuali rilanci',
      'Stato di avanzamento della progettazione in ogni sezione  (v.d. Paragrafo “verifica e valutazioni” delle scritture progettuali)',
    ];

    content.domande = testiDomande.map((value, index) => {
      return new Domanda({
        codiceDomanda: `VerbaleDiValutazioneEVerifica_${index}`,
        rispostaDomanda: '',
        testoDomanda: value,
      });
    });

    return content;
  }

  deconstructor(): IVerbaleDiValutazioneEVerifica {
    return Object.assign({}, this);
  }
}
