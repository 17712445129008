import { GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido, GradoSoddisfazioneGenitori, IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido, IGradoSoddisfazioneGenitori, IStandardOProceduraDiRiferimento, StandardOProceduraDiRiferimento } from '../..';
import { AnnoScolasticoPeriodoFormativo, IAnnoScolasticoPeriodoFormativo } from './AnnoScolasticoPeriodoFormativo';
import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { IPersonaFisica, PersonaFisica } from './PersonaFisica';
import {
  ISezioneAnnoScolasticoPeriodoFormativo,
  SezioneAnnoScolasticoPeriodoFormativo
} from './SezioneAnnoScolasticoPeriodoFormativo';
import {
  AutovalutazioneQualitaSintesiStrutturaNidoInfanzia,
  IAutovalutazioneQualitaSintesiStrutturaNidoInfanzia
} from './StrumentiRegistrazione/AutovalutazioneQualitaSintesiStrutturaNidoInfanzia';
import {
  AutovalutazioneQualitaSintesiStrutturaScuolaInfanzia,
  IAutovalutazioneQualitaSintesiStrutturaScuolaInfanzia
} from './StrumentiRegistrazione/AutovalutazioneQualitaSintesiStrutturaScuolaInfanzia';
import { CompetenzeInsegnanti, ICompetenzeInsegnanti } from './StrumentiRegistrazione/CompetenzeInsegnanti';
import {
  GestioneColloquiInizialiNidoInfanzia,
  IGestioneColloquiInizialiNidoInfanzia
} from './StrumentiRegistrazione/GestioneColloquiInizialiNidoInfanzia';
import {
  GestioneColloquiInizialiScuolaInfanzia,
  IGestioneColloquiInizialiScuolaInfanzia
} from './StrumentiRegistrazione/GestioneColloquiInizialiScuolaInfanzia';
import {
  GestioneOsservazionePrimiGiorniDiAmbientamento,
  IGestioneOsservazionePrimiGiorniDiAmbientamento
} from './StrumentiRegistrazione/GestioneOsservazionePrimiGiorniDiAmbientamento';
import {
  GestioneOsservazioniLinguaggiEspressiviCreativita,
  IGestioneOsservazioniLinguaggiEspressiviCreativita
} from './StrumentiRegistrazione/GestioneOsservazioniLinguaggiEspressiviCreativita';
import { GestionePortfolioCompetenzeBambini, IGestionePortfolioCompetenzeBambini } from './StrumentiRegistrazione/GestionePortfolioCompetenzeBambini';
import {
  GestioneProgettazioniSettimanali,
  IGestioneProgettazioniSettimanali
} from './StrumentiRegistrazione/GestioneProgettazioniSettimanali';
import {
  GestioneSchedaControlloPulizie,
  IGestioneSchedaControlloPulizie
} from './StrumentiRegistrazione/GestioneSchedaControlloPulizie';
import {
  GestioneScrittureProgettuali,
  IGestioneScrittureProgettuali
} from './StrumentiRegistrazione/GestioneScrittureProgettuali';
import {
  GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta,
  IGestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta
} from './StrumentiRegistrazione/GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta';
import {
  GestioneSituazioneGiornalieraNidoInfanziaLattanti,
  IGestioneSituazioneGiornalieraNidoInfanziaLattanti
} from './StrumentiRegistrazione/GestioneSituazioneGiornalieraNidoInfanziaLattanti';
import {
  GestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta,
  IGestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta
} from './StrumentiRegistrazione/GestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta';
import { GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola, IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola } from './StrumentiRegistrazione/GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola';
import {
  IPensieriRiflessioniPrimoPeriodoServizioEducativo,
  PensieriRiflessioniPrimoPeriodoServizioEducativo
} from './StrumentiRegistrazione/PensieriRiflessioniPrimoPeriodoServizioEducativo';
import {
  IRaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate,
  RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate
} from './StrumentiRegistrazione/RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate';
import {
  IRegistrazioniCaduteIncidentiBambini,
  RegistrazioniCaduteIncidentiBambini
} from './StrumentiRegistrazione/RegistrazioniCaduteIncidentiBambini';
import { IRegistrazioniSmarrimentiOggettiBambini, RegistrazioniSmarrimentiOggettiBambini } from './StrumentiRegistrazione/RegistrazioniSmarrimentiOggettiBambini';
import { IRelazioneSintesi, RelazioneSintesi } from './StrumentiRegistrazione/RelazioneSintesi';
import { IRicadutaAttivitaFormativa, RicadutaAttivitaFormativa } from './StrumentiRegistrazione/RicadutaAttivitaFormativa';
import { IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne, RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne } from './StrumentiRegistrazione/rilevazione/RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne';
import { IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi, RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi } from './StrumentiRegistrazione/rilevazione/RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi';
import { IRilevazioneDocumentazioneProgettualeServiziEducativi, RilevazioneDocumentazioneProgettualeServiziEducativi } from './StrumentiRegistrazione/rilevazione/RilevazioneDocumentazioneProgettualeServiziEducativi';
import { IRilevazionePartecipazioneFamiglie, RilevazionePartecipazioneFamiglie } from './StrumentiRegistrazione/rilevazione/RilevazionePartecipazioneFamiglie';
import { IRilevazioneProgettazioniAttivitaSviluppoPensieroEcologico, RilevazioneProgettazioniAttivitaSviluppoPensieroEcologico } from './StrumentiRegistrazione/rilevazione/RilevazioneProgettazioniAttivitaLinguaggiCibo';
import { IRilevazioneProgettazioniAttivitaLinguaggiCibo, RilevazioneProgettazioniAttivitaLinguaggiCibo } from './StrumentiRegistrazione/rilevazione/RilevazioneProgettazioniAttivitaSviluppoPensieroEcologico';
import { IRilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia, RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia } from './StrumentiRegistrazione/rilevazione/RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia';
import { IRilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita, RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita } from './StrumentiRegistrazione/rilevazione/RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita';
import { IRilevazionePromozioneAmbitiSviluppoNido, RilevazionePromozioneAmbitiSviluppoNido } from './StrumentiRegistrazione/rilevazione/RilevazionePromozioneAmbitiSviluppoNido';
import { IInformazioniAnagraficheSipi, InformazioniAnagraficheSipi } from './StrumentiRegistrazione/sipi/InformazioniAnagraficheSipi';
import {
  IRaccoltaInformazioniSipi,
  RaccoltaInformazioniSipi
} from './StrumentiRegistrazione/sipi/RaccoltaInformazioniSipi';
import {
  ISintesiProgettoEducativoSipi,
  SintesiProgettoEducativoSipi
} from './StrumentiRegistrazione/sipi/SintesiProgettoEducativoSipi';
import { ISituazioneInizialeSipi, SituazioneInizialeSipi } from './StrumentiRegistrazione/sipi/SituazioneInizialeSipi';
import {
  IStrumentoProgettualeSipi,
  StrumentoProgettualeSipi
} from './StrumentiRegistrazione/sipi/StrumentoProgettualeSipi';
import { IVerbaliSipi, VerbaliSipi } from './StrumentiRegistrazione/sipi/VerbaliSipi';
import { IVerbaleDiValutazioneEVerifica, VerbaleDiValutazioneEVerifica } from './StrumentiRegistrazione/VerbaleDiValutazioneEVerifica';
import {
  EnumStrumentoDiRegistrazioneTemplateBodyCode,
  IStrumentoDiRegistrazioneTemplateBody,
  StrumentoDiRegistrazioneTemplateBody
} from './StrumentoDiRegistrazioneTemplateBody';


export enum EnumStatoStrumentoRegistrazione {
  NonCompilato = 'NonCompilato',
  Bozza = 'Bozza',
  Pubblicato = 'Pubblicato',
  InApprovazione = 'InApprovazione',
  Rifiutato = 'Rifiutato',
}

export enum EnumTipologiaConsumoPasto {
  Tutto = 'Tutto',
  Meta = 'Meta',
  InParte = 'InParte',
  Assaggiato = 'Assaggiato',
  NonConsumato = 'NonConsumato',
  PastoNonPresente = 'PastoNonPresente',
}

export type IStrumentoDiRegistrazioneArchivioContent =
  | IGestioneColloquiInizialiNidoInfanzia
  | IGestioneColloquiInizialiScuolaInfanzia
  | IGestioneOsservazionePrimiGiorniDiAmbientamento
  | IGestioneProgettazioniSettimanali
  | IGestioneSchedaControlloPulizie
  | IGestioneScrittureProgettuali
  | IGestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta
  | IGestioneSituazioneGiornalieraNidoInfanziaLattanti
  | IGestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta
  | IPensieriRiflessioniPrimoPeriodoServizioEducativo
  | IRaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate
  | IRegistrazioniCaduteIncidentiBambini
  | IVerbaleDiValutazioneEVerifica
  | IVerbaleDiValutazioneEVerifica
  | IInformazioniAnagraficheSipi
  | IVerbaliSipi
  | IRaccoltaInformazioniSipi
  | IStrumentoProgettualeSipi
  | ISituazioneInizialeSipi
  | IGestioneOsservazioniLinguaggiEspressiviCreativita
  | ISintesiProgettoEducativoSipi
  | IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido
  | IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola
  | IRicadutaAttivitaFormativa
  | IGestionePortfolioCompetenzeBambini
  | IAutovalutazioneQualitaSintesiStrutturaNidoInfanzia
  | IAutovalutazioneQualitaSintesiStrutturaScuolaInfanzia
  | IGestionePortfolioCompetenzeBambini
  | ICompetenzeInsegnanti
  | IRegistrazioniSmarrimentiOggettiBambini
  | IGradoSoddisfazioneGenitori
  | IRilevazioneProgettazioniAttivitaLinguaggiCibo
  | IRilevazioneProgettazioniAttivitaSviluppoPensieroEcologico
  | IRilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia
  | IRilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita
  | IRilevazionePromozioneAmbitiSviluppoNido
  | IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi
  | IRilevazioneDocumentazioneProgettualeServiziEducativi
  | IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne
  | IRilevazionePartecipazioneFamiglie
  | IRelazioneSintesi
  | IStandardOProceduraDiRiferimento;

export type StrumentoDiRegistrazioneArchivioContent =
  | GestioneColloquiInizialiNidoInfanzia
  | GestioneColloquiInizialiScuolaInfanzia
  | GestioneOsservazionePrimiGiorniDiAmbientamento
  | GestioneProgettazioniSettimanali
  | GestioneSchedaControlloPulizie
  | GestioneScrittureProgettuali
  | GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta
  | GestioneSituazioneGiornalieraNidoInfanziaLattanti
  | GestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta
  | PensieriRiflessioniPrimoPeriodoServizioEducativo
  | RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate
  | RegistrazioniCaduteIncidentiBambini
  | VerbaleDiValutazioneEVerifica
  | VerbaleDiValutazioneEVerifica
  | InformazioniAnagraficheSipi
  | VerbaliSipi
  | RaccoltaInformazioniSipi
  | StrumentoProgettualeSipi
  | SintesiProgettoEducativoSipi
  | GestioneOsservazioniLinguaggiEspressiviCreativita
  | SintesiProgettoEducativoSipi
  | GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido
  | GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola
  | RicadutaAttivitaFormativa
  | AutovalutazioneQualitaSintesiStrutturaNidoInfanzia
  | AutovalutazioneQualitaSintesiStrutturaScuolaInfanzia
  | GestionePortfolioCompetenzeBambini
  | CompetenzeInsegnanti
  | RegistrazioniSmarrimentiOggettiBambini
  | GradoSoddisfazioneGenitori
  | RilevazioneProgettazioniAttivitaLinguaggiCibo
  | RilevazioneProgettazioniAttivitaSviluppoPensieroEcologico
  | RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia
  | RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita
  | RilevazionePromozioneAmbitiSviluppoNido
  | RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi
  | RilevazioneDocumentazioneProgettualeServiziEducativi
  | RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne
  | RilevazionePartecipazioneFamiglie
  | RelazioneSintesi
  | StandardOProceduraDiRiferimento;

export interface IStrumentoDiRegistrazioneArchivio extends IBaseBusinessObject {
  status: EnumStatoStrumentoRegistrazione;

  /**
   * Relazioni
   */
  anniScolastici: IAnnoScolasticoPeriodoFormativo[];
  personeCoinvolte: IPersonaFisica[];
  content?: IStrumentoDiRegistrazioneArchivioContent;
  sezioni: ISezioneAnnoScolasticoPeriodoFormativo[];
  strumentoRegistrazioneTemaplateBody: IStrumentoDiRegistrazioneTemplateBody;
  urlToFunzionalita: string;
}

export class StrumentoDiRegistrazioneArchivio extends BaseBusinessObject implements IStrumentoDiRegistrazioneArchivio {
  status: EnumStatoStrumentoRegistrazione;

  /**
   * Relazioni
   */
  anniScolastici: AnnoScolasticoPeriodoFormativo[];
  personeCoinvolte: PersonaFisica[];
  content?: StrumentoDiRegistrazioneArchivioContent;
  sezioni: SezioneAnnoScolasticoPeriodoFormativo[];
  strumentoRegistrazioneTemaplateBody: StrumentoDiRegistrazioneTemplateBody;
  urlToFunzionalita: string;

  constructor(params?: Partial<IStrumentoDiRegistrazioneArchivio>) {
    super(params);

    /**
     * Mapping
     */
    if (params.anniScolastici) {
      this.anniScolastici = params.anniScolastici.map((value) => new AnnoScolasticoPeriodoFormativo(value));
    }
    if (params.personeCoinvolte) {
      this.personeCoinvolte = params.personeCoinvolte.map((value) => new PersonaFisica(value));
    }
    if (params.sezioni) {
      this.sezioni = params.sezioni.map((value) => new SezioneAnnoScolasticoPeriodoFormativo(value));
    }
    if (params.strumentoRegistrazioneTemaplateBody && params.content) {
      this.strumentoRegistrazioneTemaplateBody = new StrumentoDiRegistrazioneTemplateBody(
        params.strumentoRegistrazioneTemaplateBody,
      );

      switch (this.strumentoRegistrazioneTemaplateBody.templateBodyCode) {
        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioneColloquiInizialiNidoInfanzia:
          this.content = new GestioneColloquiInizialiNidoInfanzia(
            params.content as GestioneColloquiInizialiNidoInfanzia,
          );
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioneColloquiInizialiScuolaInfanzia:
          this.content = new GestioneColloquiInizialiScuolaInfanzia(
            params.content as GestioneColloquiInizialiScuolaInfanzia,
          );
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioneOsservazionePrimiGiorniDiAmbientamento:
          this.content = new GestioneOsservazionePrimiGiorniDiAmbientamento(params.content as GestioneOsservazionePrimiGiorniDiAmbientamento);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioneSchedaControlloPulizie:
          this.content = new GestioneSchedaControlloPulizie(params.content as GestioneSchedaControlloPulizie);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RelazioneSintesi:
          this.content = new RelazioneSintesi(params.content as RelazioneSintesi);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioneProgettazioniSettimanali:
          this.content = new GestioneProgettazioniSettimanali(params.content as GestioneProgettazioniSettimanali);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta:
          this.content = new GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta(
            params.content as GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta,
          );
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioneSituazioneGiornalieraNidoInfanziaLattanti:
          this.content = new GestioneSituazioneGiornalieraNidoInfanziaLattanti(
            params.content as GestioneSituazioneGiornalieraNidoInfanziaLattanti,
          );
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta:
          this.content = new GestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta(
            params.content as GestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta,
          );
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioneScrittureProgettuali:
          this.content = new GestioneScrittureProgettuali(params.content as GestioneScrittureProgettuali);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.PensieriRiflessioniPrimoPeriodoServizioEducativo:
          this.content = new PensieriRiflessioniPrimoPeriodoServizioEducativo(
            params.content as PensieriRiflessioniPrimoPeriodoServizioEducativo,
          );
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate:
          this.content = new RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate(
            params.content as RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate,
          );
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RegistrazioniCaduteIncidentiBambini:
          this.content = new RegistrazioniCaduteIncidentiBambini(params.content as RegistrazioniCaduteIncidentiBambini);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.VerbaleDiValutazioneEVerifica:
          this.content = new VerbaleDiValutazioneEVerifica(params.content as VerbaleDiValutazioneEVerifica);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.SipiSezioneAnagrafica:
          this.content = new InformazioniAnagraficheSipi(params.content as InformazioniAnagraficheSipi);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.SipiSezioneVerbali:
          this.content = new VerbaliSipi(params.content as VerbaliSipi);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.SipiRaccoltaInformazioni:
          this.content = new RaccoltaInformazioniSipi(params.content as RaccoltaInformazioniSipi);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.SipiSituazioneIniziale:
          this.content = new SituazioneInizialeSipi(params.content as SituazioneInizialeSipi);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.SipiStrumentoProgettuale:
          this.content = new StrumentoProgettualeSipi(params.content as StrumentoProgettualeSipi);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.SipiSintesiProgettoEducativo:
          this.content = new SintesiProgettoEducativoSipi(params.content as SintesiProgettoEducativoSipi);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioneOsservazioniLinguaggiEspressiviCreativita:
          this.content = new GestioneOsservazioniLinguaggiEspressiviCreativita(params.content as GestioneOsservazioniLinguaggiEspressiviCreativita);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RicadutaAttivitaFormativa:
          this.content = new RicadutaAttivitaFormativa(params.content as RicadutaAttivitaFormativa);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.AutovalutazioneQualitaSintesiStrutturaNidoInfanzia:
          this.content = new AutovalutazioneQualitaSintesiStrutturaNidoInfanzia(params.content as AutovalutazioneQualitaSintesiStrutturaNidoInfanzia);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.AutovalutazioneQualitaSintesiStrutturaScuolaInfanzia:
          this.content = new AutovalutazioneQualitaSintesiStrutturaScuolaInfanzia(params.content as AutovalutazioneQualitaSintesiStrutturaScuolaInfanzia);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GestioniPortfolioCompetenzeBambini:
          this.content = new GestionePortfolioCompetenzeBambini(params.content as GestionePortfolioCompetenzeBambini);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.CompetenzeInsegnanti:
          this.content = new CompetenzeInsegnanti(params.content as CompetenzeInsegnanti);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RegistrazioneSmarrimentiOggettiBambini:
          this.content = new RegistrazioniSmarrimentiOggettiBambini(params.content as RegistrazioniSmarrimentiOggettiBambini);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.GradoSoddisfazioneGenitori:
          this.content = new GradoSoddisfazioneGenitori(params.content as GradoSoddisfazioneGenitori);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RilevazioneProgettazioniAttivitaLinguaggiCibo:
          this.content = new RilevazioneProgettazioniAttivitaLinguaggiCibo(params.content as RilevazioneProgettazioniAttivitaLinguaggiCibo);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RilevazioneProgettazioniAttivitaSviluppoPensieroEcologico:
          this.content = new RilevazioneProgettazioniAttivitaSviluppoPensieroEcologico(params.content as RilevazioneProgettazioniAttivitaSviluppoPensieroEcologico);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia:
          this.content = new RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia(params.content as RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita:
          this.content = new RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita(params.content as RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RilevazionePromozioneAmbitiSviluppoNido:
          this.content = new RilevazionePromozioneAmbitiSviluppoNido(params.content as RilevazionePromozioneAmbitiSviluppoNido);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi:
          this.content = new RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi(params.content as RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RilevazioneDocumentazioneProgettualeServiziEducativi:
          this.content = new RilevazioneDocumentazioneProgettualeServiziEducativi(params.content as RilevazioneDocumentazioneProgettualeServiziEducativi);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne:
          this.content = new RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne(params.content as RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.RilevazionePartecipazioneFamiglie:
          this.content = new RilevazionePartecipazioneFamiglie(params.content as RilevazionePartecipazioneFamiglie);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioStandardStrutturaliOrganizzativi:
          this.content = new StandardOProceduraDiRiferimento(params.content as StandardOProceduraDiRiferimento);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioErogazioneServizio:
          this.content = new StandardOProceduraDiRiferimento(params.content as StandardOProceduraDiRiferimento);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioFamilyEngagement:
          this.content = new StandardOProceduraDiRiferimento(params.content as StandardOProceduraDiRiferimento);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioImplementazioneCurricolo:
        case EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioImplementazioneCurricoloNidoInfanzia:
        case EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioImplementazioneCurricoloScuolaInfanzia:
          this.content = new StandardOProceduraDiRiferimento(params.content as StandardOProceduraDiRiferimento);
          break;

        case EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioValutazioneEfficaciaOutcomesServizio:
          this.content = new StandardOProceduraDiRiferimento(params.content as StandardOProceduraDiRiferimento);
          break;
      }
    }
  }

  deconstructor(): IStrumentoDiRegistrazioneArchivio {
    return Object.assign({}, { ...this });
  }
}
