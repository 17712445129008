import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class CustomMatPaginatorIntlService extends MatPaginatorIntl
  implements OnDestroy {
  unsubscribe$: Subject<void> = new Subject();

  constructor(private translocoService: TranslocoService) {
    super();

    this.init();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return this.translocoService.translate('matPaginator.shortRangeLabel', {
        length,
      });
    }
    length = Math.max(length, 0);

    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;

    return this.translocoService.translate('matPaginator.longRangeLabel', {
      startIndex,
      endIndex,
      length,
    });
  }

  init() {
    this.translocoService.langChanges$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.firstPageLabel = this.translocoService.translate(
          'matPaginator.firstPageLabel',
        );
        this.itemsPerPageLabel = this.translocoService.translate(
          'matPaginator.itemsPerPageLabel',
        );
        this.lastPageLabel = this.translocoService.translate(
          'matPaginator.lastPageLabel',
        );
        this.nextPageLabel = this.translocoService.translate(
          'matPaginator.nextPageLabel',
        );
        this.previousPageLabel = this.translocoService.translate(
          'matPaginator.previousPageLabel',
        );
      });
    this.changes.next();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
