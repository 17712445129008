export interface IAutovalutazioneQualitaSintesiStrutturaRow {
  descrittore: IDescrittoreAutovalutazioneQualitaSintesiStrutturaRow;
  osservazioniEmpiriche: string;
  puntiDiForza: string;
  azioniDiMiglioramento: string;
  approfindimentiProcedure: string;
}

export class AutovalutazioneQualitaSintesiStrutturaRow implements IAutovalutazioneQualitaSintesiStrutturaRow {
  descrittore: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow;
  osservazioniEmpiriche: string;
  puntiDiForza: string;
  azioniDiMiglioramento: string;
  approfindimentiProcedure: string;

  constructor(params?: Partial<IAutovalutazioneQualitaSintesiStrutturaRow>) {
    if (!params) {
      return;
    }

    Object.assign(this, params);
    if (params.descrittore) {
      this.descrittore = new DescrittoreAutovalutazioneQualitaSintesiStrutturaRow(params.descrittore);
    }
  }

  deconstructor(): IAutovalutazioneQualitaSintesiStrutturaRow {
    return Object.assign({}, this);
  }
}

export interface IDescrittoreAutovalutazioneQualitaSintesiStrutturaRow {
  id: string;
  descrittore: string;
}

export class DescrittoreAutovalutazioneQualitaSintesiStrutturaRow implements IDescrittoreAutovalutazioneQualitaSintesiStrutturaRow {
  id: string;
  descrittore: string;

  constructor(params?: Partial<IDescrittoreAutovalutazioneQualitaSintesiStrutturaRow>) {
    if (!params) {
      return;
    }

    Object.assign(this, params);
  }

  deconstructor(): IDescrittoreAutovalutazioneQualitaSintesiStrutturaRow {
    return Object.assign({}, this);
  }
}