import { ErrorHandler, Injectable } from '@angular/core';

import { BackendError } from '../models/backend-error.model';
import { ErrorService } from '../state/error.service';

@Injectable()
export class BackendErrorHandler implements ErrorHandler {
  constructor(private errorService: ErrorService) { }

  handleError(error) {
    if (error instanceof BackendError) {
      this.errorService.store.setError(error);
    }
  }
}
