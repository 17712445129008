import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { IAnnoScolasticoPeriodoFormativo } from '@gpi/lbl/shared/entity';

export interface AnniScolasticiState
  extends EntityState<IAnnoScolasticoPeriodoFormativo, number>,
    ActiveState<number> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lbl-app-data-access-anni-scolastici' })
export class AnniScolasticiStore extends EntityStore<AnniScolasticiState> {
  constructor() {
    super();
  }
}
