import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';

export interface FeatureState {
  ui: {
    nav: {
      visible: boolean;
    };
  };
}

export function createInitialState(): FeatureState {
  return {
    ui: {
      nav: {
        visible: false,
      },
    },
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lbl-app-feature-backoffice', producerFn: produce })
export class FeatureStore extends Store<FeatureState> {
  constructor() {
    super(createInitialState());
  }

  setNavVisibility(visible: boolean) {
    this.update((state) => {
      state.ui.nav.visible = visible;
    });
  }
}
