import { Allegato, IAllegato } from './Allegato';
import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { IPersonaFisica, PersonaFisica } from './PersonaFisica';
import { IRuoloApplicativo, RuoloApplicativo } from './RuoloApplicativo';

export enum EnumStatoAccount {
  Attivo = 'Attivo',
  Disabilitato = 'Disabilitato',
}

export enum EnumStatoPassword {
  Attiva = 'Attiva',
  Rigenerata = 'Rigenerata',
  Scaduta = 'Scaduta',
}

export interface IUtente extends IBaseBusinessObject {
  password: string;
  statoAccount: EnumStatoAccount;
  statoPassword: EnumStatoPassword;
  username: string;
  token: string;
  idStrutturaSelected: number;

  /**
   * Relazioni
   */
  immagine: IAllegato;
  personaFisica: IPersonaFisica;
  ruoli: IRuoloApplicativo[];
}

export class Utente extends BaseBusinessObject implements IUtente {
  password: string;
  statoAccount: EnumStatoAccount;
  statoPassword: EnumStatoPassword;
  username: string;
  token: string;
  idStrutturaSelected: number;

  /**
   * Relazioni
   */
  immagine: Allegato;
  personaFisica: PersonaFisica;
  ruoli: RuoloApplicativo[];

  constructor(params?: Partial<IUtente>) {
    super(params);

    /**
     * Mapping
     */
    if (params.immagine) {
      this.immagine = new Allegato(params.immagine);
    }
    if (params.personaFisica) {
      this.personaFisica = new PersonaFisica(params.personaFisica);
    }
    if (params.ruoli) {
      this.ruoli = params.ruoli.map((value) => new RuoloApplicativo(value));
    }
  }

  deconstructor(): IUtente {
    return Object.assign({ }, this);
  }
}
