import {
  ErrorHandler,
  Inject,
  Injectable,
  InjectionToken,
} from '@angular/core';

import { BackendError } from '../models/backend-error.model';
import { FrontendError } from '../models/frontend-error.model';

export const ERROR_HANDLERS: InjectionToken<
  ErrorHandler[]
> = new InjectionToken<ErrorHandler[]>('ERROR_HANDLERS', {
  providedIn: 'root',
  factory: () => [],
});

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(@Inject(ERROR_HANDLERS) private errorHandlers: ErrorHandler[]) { }

  handleError(error) {
    if (!(error instanceof FrontendError || error instanceof BackendError)) {
      error = new FrontendError(error);
    }

    this.errorHandlers.forEach((handler) => handler.handleError(error));
  }
}
