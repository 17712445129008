
export interface IRilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia {
    numeroProgettiRealizzati: number;

    /**
     * Relazioni
     */
    progettiRealizzati: IProgettiRealizzatiSviluppoCampiEsperienzaScuolaInfanziaRow[];
}

export class RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia implements IRilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia {
    numeroProgettiRealizzati: number;

    /**
     * Relazioni
     */
    progettiRealizzati: ProgettiRealizzatiSviluppoCampiEsperienzaScuolaInfanziaRow[];


    constructor(params: Partial<IRilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
        if (params.progettiRealizzati) {
            this.progettiRealizzati = params.progettiRealizzati.map((value) => new ProgettiRealizzatiSviluppoCampiEsperienzaScuolaInfanziaRow(value));
        }
    }

    deconstructor(): IRilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia {
        return Object.assign({}, this);
    }
}

export interface IProgettiRealizzatiSviluppoCampiEsperienzaScuolaInfanziaRow {
    progettazione: string;
    proposte: string;
    documentazione: string;
}

export class ProgettiRealizzatiSviluppoCampiEsperienzaScuolaInfanziaRow implements IProgettiRealizzatiSviluppoCampiEsperienzaScuolaInfanziaRow {
    progettazione: string;
    proposte: string;
    documentazione: string;

    constructor(params: Partial<IProgettiRealizzatiSviluppoCampiEsperienzaScuolaInfanziaRow> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
    }

    deconstructor(): IProgettiRealizzatiSviluppoCampiEsperienzaScuolaInfanziaRow {
        return Object.assign({}, this);
    }
}
