import { EnumTipologiaConsumoPasto } from '../StrumentoDiRegistrazioneArchivio';

export interface IGestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta {
  assente: boolean;
  contorno: EnumTipologiaConsumoPasto;
  feci: number;
  frutta: EnumTipologiaConsumoPasto;
  merenda: EnumTipologiaConsumoPasto;
  note: string;
  primo: EnumTipologiaConsumoPasto;
  secondo: EnumTipologiaConsumoPasto;
  sonnoPomeriggioAlle: string;
  sonnoPomeriggioDalle: string;
}

export class GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta implements IGestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta {
  assente: boolean;
  contorno: EnumTipologiaConsumoPasto;
  feci: number;
  frutta: EnumTipologiaConsumoPasto;
  merenda: EnumTipologiaConsumoPasto;
  note: string;
  primo: EnumTipologiaConsumoPasto;
  secondo: EnumTipologiaConsumoPasto;
  sonnoPomeriggioAlle: string;
  sonnoPomeriggioDalle: string;

  constructor(params?: Partial<IGestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta>) {
    if (!params) {
      return;
    }

    Object.assign(this, params);
  }

  deconstructor(): IGestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta {
    return Object.assign({ }, this);
  }
}
