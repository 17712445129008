import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  EnumPeriodoOsservazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividuali,
  EnumTipoAmbitiDiSviluppoCampiDiEsperienza,
  GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
  ValutazioneAmbitiSviluppoNidoTemplateRow,
} from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-gestione-valutazione-ambiti-sviluppo-competenze-individuali-nido',
  templateUrl: './strumento-registrazione-gestione-valutazione-ambiti-sviluppo-competenze-individuali-nido.component.html',
  styleUrls: ['./strumento-registrazione-gestione-valutazione-ambiti-sviluppo-competenze-individuali-nido.component.scss'],
})
export class StrumentoRegistrazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividualiNidoComponent implements OnInit, OnChanges {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  enum = [
    EnumPeriodoOsservazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividuali.PrimoPeriodo,
    EnumPeriodoOsservazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividuali.SecondoPeriodo,
    EnumPeriodoOsservazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividuali.TerzoPeriodo,
  ]

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido {
    let content = this.strumentoDiRegistrazioneArchivio.content as GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido;

    return content;
  }

  form: FormGroup;

  get formTemplate(): FormArray {
    return this.form.get('template') as FormArray;
  }

  templateDisplayedColumns = ['campoEsperienza', 'esperienze', 'scaffolding', 'traguardiSviluppo', 'note'];

  ngOnInit() {
    this.form = this.buildForm();

    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      dataOsservazioneDal: new FormControl(this.content && this.content.dataOsservazioneDal ? this.content.dataOsservazioneDal : null),
      dataOsservazioneAl: new FormControl(this.content && this.content.dataOsservazioneAl ? this.content.dataOsservazioneAl : null),
      periodoOsservazione: new FormControl(this.content && this.content.periodoOsservazione ? this.content.periodoOsservazione : null),
      allenamenti: new FormControl(this.content && this.content.allenamenti ? this.content.allenamenti : null),
      template: this.buildFormTemplate(this.content && this.content.template ? this.content.template : null)
    });

    return form;
  }

  buildFormTemplate(template: ValutazioneAmbitiSviluppoNidoTemplateRow[]): FormArray {
    const form = template && template.length > 0 ?
      new FormArray(template.map((value) => this.buildFormSingoloTemplate(value))) :
      new FormArray(Object.keys(EnumTipoAmbitiDiSviluppoCampiDiEsperienza).map(value => this.buildFormSingoloTemplate(new ValutazioneAmbitiSviluppoNidoTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienza[value] }))));

    return form;
  }

  buildFormSingoloTemplate(template: ValutazioneAmbitiSviluppoNidoTemplateRow): FormGroup {
    const form = new FormGroup({
      campoEsperienza: new FormControl(template.campoEsperienza ? template.campoEsperienza : null),
      esperienze: new FormControl(template.esperienze ? template.esperienze : null),
      scaffolding: new FormControl(template.scaffolding ? template.scaffolding : null),
      traguardiSviluppo: new FormControl(template.traguardiSviluppo ? template.traguardiSviluppo : null),
      valutazioneTraguardiSviluppo: new FormControl(!isNaN(template.valutazioneTraguardiSviluppo) ? template.valutazioneTraguardiSviluppo : null),
      note: new FormControl(template.note ? template.note : null),
    });

    return form;
  }

  updateForm(entity: GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const gestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido = new GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: gestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;

      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
