import { Domanda, IDomanda } from './Domanda';

export interface IGestioneOsservazioniLinguaggiEspressiviCreativita {
  dataOsservazione: Date;
  valutazioneComplessiva: string;
  luogo: string;
  gruppoBambini: string[];
  strumentiESupporti: string;
  focusOsservativi: string;
  domandeAdulto: string;

  osservazioniList: IGestioneOsservazioniLinguaggiEspressiviCreativitaRow[];
}

export class GestioneOsservazioniLinguaggiEspressiviCreativita implements IGestioneOsservazioniLinguaggiEspressiviCreativita {
  dataOsservazione: Date;
  valutazioneComplessiva: string;
  luogo: string;
  gruppoBambini: string[];
  strumentiESupporti: string;
  focusOsservativi: string;
  domandeAdulto: string;

  osservazioniList: GestioneOsservazioniLinguaggiEspressiviCreativitaRow[];

  constructor(params: Partial<IGestioneOsservazioniLinguaggiEspressiviCreativita> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.dataOsservazione) {
      this.dataOsservazione = new Date(params.dataOsservazione);
    }

    if (params.gruppoBambini) {
      this.gruppoBambini = params.gruppoBambini.map((value) => value);
    }
    if (params.osservazioniList) {
      this.osservazioniList = params.osservazioniList.map((value) => new GestioneOsservazioniLinguaggiEspressiviCreativitaRow(value));
    }
  }

  static InitContent(): GestioneOsservazioniLinguaggiEspressiviCreativita {
    const content = new GestioneOsservazioniLinguaggiEspressiviCreativita();

    content.osservazioniList = new Array<GestioneOsservazioniLinguaggiEspressiviCreativitaRow>();

    return content;
  }

  deconstructor(): IGestioneOsservazioniLinguaggiEspressiviCreativita {
    return Object.assign({}, this);
  }
}

export interface IGestioneOsservazioniLinguaggiEspressiviCreativitaRow {
  approcciESperimentazioni: string;
  alfabeti: string;
  relazioneConGruppo: boolean;
  linguaggi: string;
  note: string;
  relazioneConGruppoText: string;
}

export class GestioneOsservazioniLinguaggiEspressiviCreativitaRow implements IGestioneOsservazioniLinguaggiEspressiviCreativitaRow {
  approcciESperimentazioni: string;
  alfabeti: string;
  relazioneConGruppo: boolean;
  linguaggi: string;
  note: string;
  relazioneConGruppoText: string;

  constructor(params: Partial<IGestioneOsservazioniLinguaggiEspressiviCreativitaRow> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
  }

  deconstructor(): IGestioneOsservazioniLinguaggiEspressiviCreativitaRow {
    return Object.assign({}, this);
  }
}
