import { Component, Input } from '@angular/core';
import { ContenutoChioscoLBLArchivio } from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-contenuto-chiosco-viewer',
  templateUrl: 'contenuto-chiosco-viewer.component.html',
  styleUrls: ['contenuto-chiosco-viewer.component.scss'],
})
export class ContenutoChioscoViewerComponent {
  @Input()
  item: ContenutoChioscoLBLArchivio;
}
