import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeIt from '@angular/common/locales/it';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LblAppUtilSharedModule } from '@gpi/lbl/app/util/shared';

import { LblAppCoreModule } from './core/lbl-app-core.module';
import { LblAppRoutingModule } from './lbl-app-routing.module';
import { RootComponent } from './root/root.component';

// Registering locales
registerLocaleData(localeEn);
registerLocaleData(localeIt);

@NgModule({
  declarations: [RootComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LblAppCoreModule,
    LblAppUtilSharedModule,
    LblAppRoutingModule,
  ],
  bootstrap: [RootComponent],
})
export class LblAppModule { }
