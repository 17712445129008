import { EnumTipologiaRuoloApplicativo } from '@gpi/lbl/shared/entity';

export interface AuthorizationGuardOptions {
  authorized: boolean;
  permissions: EnumTipologiaRuoloApplicativo[];
  redirectTo: string;
}

export const AUTHORIZATION_GUARD_OPTIONS_DEFAULT = {
  authorized: true,
  permissions: [],
  redirectTo: '/',
};
