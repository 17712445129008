import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { SharedUiMaterialModule } from '@gpi/shared/ui/material';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ChecklistMonitoraggioValutazioniComponent } from './checklist-monitoraggio-valutazioni/checklist-monitoraggio-valutazioni.component';
import { ContenutiChioscoViewerComponent } from './contenuti-chiosco-viewer/contenuti-chiosco-viewer.component';
import { ContenutiChioscoComponent } from './contenuti-chiosco/contenuti-chiosco.component';
import { ContenutoChioscoViewerComponent } from './contenuto-chiosco-viewer/contenuto-chiosco-viewer.component';
import { ContenutoChioscoComponent } from './contenuto-chiosco/contenuto-chiosco.component';
import { LayoutSharedAvatarComponent } from './layout/shared/avatar/layout-shared-avatar.component';
import {
  StrumentoRegistrazioneCaduteincidentiBambiniComponent
} from './strumento-registrazione/cadute-incidenti-bambini/strumento-registrazione-cadute-incidenti-bambini.component';
import { StrumentoRegistrazioneCompetenzeInsegnantiComponent } from './strumento-registrazione/competenze-insegnanti/strumento-registrazione-competenze-insegnanti.component';
import { StrumentoRegistrazioneDicciCosaPensiDiNoiComponent } from './strumento-registrazione/dicci-cosa-pensi-di-noi/strumento-registrazione-dicci-cosa-pensi-di-noicomponent';
import { ErogazioneServizioComponent } from './strumento-registrazione/erogazione-servizio/erogazione-servizio.component';
import { FamilyEngagementComponent } from './strumento-registrazione/family-engagement/family-engagement.component';
import {
  StrumentoRegistrazioneGestioneColloquiInizialiNidoInfanziaComponent
} from './strumento-registrazione/gestione-colloqui-iniziali/nido-infanzia/strumento-registrazione-gestione-colloqui-iniziali-nido-infanzia.component';
import {
  StrumentoRegistrazioneGestioneColloquiInizialiScuolaInfanziaComponent
} from './strumento-registrazione/gestione-colloqui-iniziali/scuola-infanzia/strumento-registrazione-gestione-colloqui-iniziali-scuola-infanzia.component';
import {
  StrumentoRegistrazioneGestioneOsservazionePrimiGiorniAmbientamentoComponent
} from './strumento-registrazione/gestione-osservazione-primi-giorni-ambientamento/strumento-registrazione-gestione-osservazione-primi-giorni-ambientamento.component';
import {
  GestioneOsservazioniLinguaggiEspressiviCreativitaComponent
} from './strumento-registrazione/gestione-osservazioni-linguaggi-espressivi-creativita/gestione-osservazioni-linguaggi-espressivi-creativita.component';
import { DialogLineeGuidaPortfolioCompetenzeBambiniComponent } from './strumento-registrazione/gestione-portfolio-competenze-bambini/dialog/dialog-linee-guida-portfolio-competenze-bambini.component';
import { StrumentoRegistrazioneGestionePortfolioCompetenzeBambini } from './strumento-registrazione/gestione-portfolio-competenze-bambini/strumento-registrazione-gestione-portfolio-competenze-bambini.component';
import {
  StrumentoRegistrazioneGestioneProgettazioniSettimanaliComponent
} from './strumento-registrazione/gestione-progettazioni-settimanali/strumento-registrazione-gestione-progettazioni-settimanali.component';
import {
  StrumentoRegistrazioneGestioneSchedaControlloPulizieComponent
} from './strumento-registrazione/gestione-scheda-controllo-pulizie/strumento-registrazione-gestione-scheda-controllo-pulizie.component';
import {
  StrumentoRegistrazioneGestioneScrittureProgettualiComponent
} from './strumento-registrazione/gestione-scritture-progettuali/strumento-registrazione-gestione-scritture-progettuali.component';
import {
  StrumentoRegistrazioneGestioneSituazioneGiornalieraNidoInfanziaAltreFasceEtaComponent
} from './strumento-registrazione/gestione-situazione-giornaliera/nido-infanzia/altre-fasce-eta/strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-altre-fasce-eta.component';
import {
  StrumentoRegistrazioneGestioneSituazioneGiornalieraNidoInfanziaLattantiComponent
} from './strumento-registrazione/gestione-situazione-giornaliera/nido-infanzia/lattanti/strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-lattanti.component';
import {
  StrumentoRegistrazioneGestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEtaComponent
} from './strumento-registrazione/gestione-situazione-giornaliera/scuola-infanzia/altre-fasce-eta/strumento-registrazione-gestione-situazione-giornaliera-scuola-infanzia-altre-fasce-eta.component';
import {
  StrumentoRegistrazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividualiNidoComponent
} from './strumento-registrazione/gestione-valutazione-ambiti-sviluppo-competenze-individuali/nido/strumento-registrazione-gestione-valutazione-ambiti-sviluppo-competenze-individuali-nido.component';
import {
  StrumentoRegistrazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividualiScuolaComponent
} from './strumento-registrazione/gestione-valutazione-ambiti-sviluppo-competenze-individuali/scuola/strumento-registrazione-gestione-valutazione-ambiti-sviluppo-competenze-individuali-scuola.component';
import { ImplementazioneCurricoloComponent } from './strumento-registrazione/implementazione-curricolo/implementazione-curricolo.component';
import {
  StrumentoRegistrazionePensieriRiflessioniComponent
} from './strumento-registrazione/pensieri-riflessioni/strumento-registrazione-pensieri-riflessioni.component';
import {
  StrumentoRegistrazioneRaccontiRiflessioniComponent
} from './strumento-registrazione/racconti-riflessioni/strumento-registrazione-racconti-riflessioni.component';
import { RicadutaAttivitaFormativaComponent } from './strumento-registrazione/ricaduta-attivita-formativa/ricaduta-attivita-formativa.component';
import { RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneComponent } from './strumento-registrazione/rilevazione-allestimento-spazi-ricerche-materiali-aree-esterne/rilevazione-allestimento-spazi-ricerche-materiali-aree-esterne.component';
import { RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiComponent } from './strumento-registrazione/rilevazione-allestimento-spazi-ricerche-materiali-servizi-educativi/rilevazione-allestimento-spazi-ricerche-materiali-servizi-educativi.component';
import { RilevazioneDocumentazioneProgettualeServiziEducativiComponent } from './strumento-registrazione/rilevazione-documentazione-progettuale-servizi-educativi/rilevazione-documentazione-progettuale-servizi-educativi.component';
import { RilevazionePartecipazioneFamiglieComponent } from './strumento-registrazione/rilevazione-partecipazione-famiglie/rilevazione-partecipazione-famiglie.component';
import { RilevazioneProgettazioniAttivitaLinguaggiCiboComponent } from './strumento-registrazione/rilevazione-progettazioni-attivita-linguaggi-cibo/rilevazione-progettazioni-attivita-linguaggi-cibo.component';
import { RilevazioneProgettazioniAttivitaSviluppoPensieroEcologicoComponent } from './strumento-registrazione/rilevazione-progettazioni-attivita-sviluppo-pensiero-ecologico/rilevazione-progettazioni-attivita-sviluppo-pensiero-ecologico.component';
import { RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanziaComponent } from './strumento-registrazione/rilevazione-progettazioni-sviluppo-campi-esperienza-scuola-infanzia/rilevazione-progettazioni-sviluppo-campi-esperienza-scuola-infanzia.component';
import { RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativitaComponent } from './strumento-registrazione/rilevazione-progettualita-sviluppo-linguaggi-espressivi-creativita/rilevazione-progettualita-sviluppo-linguaggi-espressivi-creativita.component';
import { RilevazionePromozioneAmbitiSviluppoNidoComponent } from './strumento-registrazione/rilevazione-promozione-ambiti-sviluppo-nido/rilevazione-promozione-ambiti-sviluppo-nido.component';
import { SchedaAutovalutazioneQualitaSintesiLivelloStrutturaNidoInfanziaComponent } from './strumento-registrazione/scheda-autovalutazione-qualita-sintesi-livello-struttura/nido-infanzia/scheda-autovalutazione-qualita-sintesi-livello-struttura-nido-infanzia.component';
import { SchedaAutovalutazioneQualitaSintesiLivelloStrutturaScuolaInfanziaComponent } from './strumento-registrazione/scheda-autovalutazione-qualita-sintesi-livello-struttura/scuola-infanzia/scheda-autovalutazione-qualita-sintesi-livello-struttura-scuola-infanzia.component';
import {
  DialogFormFiguraProfessionaleComponent
} from './strumento-registrazione/sipi-sezione-anagrafica/dialog/dialog-form-figura-professionale.component';
import {
  SipiSezioneAnagraficaComponent
} from './strumento-registrazione/sipi-sezione-anagrafica/sipi-sezione-anagrafica.component';
import {
  DialogFormProfessionistaComponent
} from './strumento-registrazione/sipi-sezione-raccolta-informazioni/dialog/dialog-form-professionista.component';
import {
  SipiSezioneRaccoltaInformazioniComponent
} from './strumento-registrazione/sipi-sezione-raccolta-informazioni/sipi-sezione-raccolta-informazioni.component';
import {
  DialogLineeGuidaSintesiProgettoComponent
} from './strumento-registrazione/sipi-sezione-sintesi-progetto/dialog/dialog-linee-guida-sintesi-progetto.component';
import {
  SipiSezioneSintesiProgettoComponent
} from './strumento-registrazione/sipi-sezione-sintesi-progetto/sipi-sezione-sintesi-progetto.component';
import {
  SipiSezioneSituazioneInizialeComponent
} from './strumento-registrazione/sipi-sezione-situazione-iniziale/sipi-sezione-situazione-iniziale.component';
import {
  SipiSezioneStrumentoProgettualeComponent
} from './strumento-registrazione/sipi-sezione-strumento-progettuale/sipi-sezione-strumento-progettuale.component';
import { SipiSezioneVerbaliComponent } from './strumento-registrazione/sipi-sezione-verbali/sipi-sezione-verbali.component';
import { StrumentoRegistrazioneSmarrimentiOggettiBambiniComponent } from './strumento-registrazione/smarrimenti-oggetti-bambini/strumento-registrazione-smarrimenti-oggetti-bambini.component';
import { StandardStrutturaliOrganizzativiComponent } from './strumento-registrazione/standard-strutturali-organizzativi/standard-strutturali-organizzativi.component';
import { StrumentoRegistrazioneComponent } from './strumento-registrazione/strumento-registrazione.component';
import { ValutazioneEfficaciaOutcomesServizioComponent } from './strumento-registrazione/valutazione-efficacia-outcomes-servizio/valutazione-efficacia-outcomes-servizio.component';
import { RelazioneSintesiComponent } from './strumento-registrazione/relazione-sintesi/relazione-sintesi.component';

import { StrumentoRegistrazioneVerbaleValutazioneVerificaComponent } from './strumento-registrazione/verbale-valutazione-verifica/strumento-registrazione-verbale-valutazione-verifica.component';

const modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  SharedUiMaterialModule,
  TranslocoModule,
  TranslocoLocaleModule,
  PdfViewerModule,
  CKEditorModule,
];

const components = [
  LayoutSharedAvatarComponent,
  ContenutiChioscoComponent,
  ContenutiChioscoViewerComponent,
  ContenutoChioscoComponent,
  ContenutoChioscoViewerComponent,

  StrumentoRegistrazioneComponent,

  StrumentoRegistrazioneCaduteincidentiBambiniComponent,
  StrumentoRegistrazioneGestioneColloquiInizialiNidoInfanziaComponent,
  StrumentoRegistrazioneGestioneColloquiInizialiScuolaInfanziaComponent,
  StrumentoRegistrazioneGestioneProgettazioniSettimanaliComponent,
  StrumentoRegistrazioneGestioneOsservazionePrimiGiorniAmbientamentoComponent,
  StrumentoRegistrazioneGestioneSchedaControlloPulizieComponent,
  StrumentoRegistrazioneGestioneScrittureProgettualiComponent,
  StrumentoRegistrazioneGestioneSituazioneGiornalieraNidoInfanziaAltreFasceEtaComponent,
  StrumentoRegistrazioneGestioneSituazioneGiornalieraNidoInfanziaLattantiComponent,
  StrumentoRegistrazioneGestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEtaComponent,
  StrumentoRegistrazionePensieriRiflessioniComponent,
  StrumentoRegistrazioneRaccontiRiflessioniComponent,
  StrumentoRegistrazioneVerbaleValutazioneVerificaComponent,
  SipiSezioneVerbaliComponent,
  SipiSezioneAnagraficaComponent,
  SipiSezioneSituazioneInizialeComponent,
  SipiSezioneRaccoltaInformazioniComponent,
  SipiSezioneStrumentoProgettualeComponent,
  SipiSezioneSintesiProgettoComponent,
  GestioneOsservazioniLinguaggiEspressiviCreativitaComponent,
  StrumentoRegistrazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividualiNidoComponent,
  StrumentoRegistrazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividualiScuolaComponent,
  StrumentoRegistrazioneGestionePortfolioCompetenzeBambini,
  SchedaAutovalutazioneQualitaSintesiLivelloStrutturaNidoInfanziaComponent,
  SchedaAutovalutazioneQualitaSintesiLivelloStrutturaScuolaInfanziaComponent,
  RicadutaAttivitaFormativaComponent,
  StrumentoRegistrazioneCompetenzeInsegnantiComponent,
  StrumentoRegistrazioneSmarrimentiOggettiBambiniComponent,
  StrumentoRegistrazioneDicciCosaPensiDiNoiComponent,
  RilevazioneProgettazioniAttivitaLinguaggiCiboComponent,
  RilevazioneProgettazioniAttivitaSviluppoPensieroEcologicoComponent,
  RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativitaComponent,
  RilevazionePromozioneAmbitiSviluppoNidoComponent,
  RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanziaComponent,
  RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiComponent,
  RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneComponent,
  RilevazionePartecipazioneFamiglieComponent,
  RilevazioneDocumentazioneProgettualeServiziEducativiComponent,
  ChecklistMonitoraggioValutazioniComponent,
  StandardStrutturaliOrganizzativiComponent,
  ErogazioneServizioComponent,
  FamilyEngagementComponent,
  ImplementazioneCurricoloComponent,
  ValutazioneEfficaciaOutcomesServizioComponent,
  RelazioneSintesiComponent
];

const entryComponents = [
  DialogFormFiguraProfessionaleComponent,
  DialogFormProfessionistaComponent,
  DialogLineeGuidaSintesiProgettoComponent,
  DialogLineeGuidaPortfolioCompetenzeBambiniComponent,

];

@NgModule({
  declarations: [...components, ...entryComponents],
  imports: [...modules],
  exports: [...components],
  entryComponents: [...entryComponents],
})
export class LblAppUiModule { }
