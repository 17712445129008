import {
  AnnoScolasticoPeriodoFormativo,
  IAnnoScolasticoPeriodoFormativo,
} from './AnnoScolasticoPeriodoFormativo';
import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { IPersonaFisica, PersonaFisica } from './PersonaFisica';
import { IUtente, Utente } from './Utente';

export enum EnumFasciaEta {
  Lattanti = 'Lattanti',
  AltreFasceEta = 'AltreFasceEta',
}

export interface ISezioneAnnoScolasticoPeriodoFormativo
  extends IBaseBusinessObject {
  fasciaEtaDiRiferimento: EnumFasciaEta;
  nome: string;

  /**
   * Relazioni
   */
  bambini: IPersonaFisica[];
  insegnanti: IUtente[];
  annoScolasticoPeriodoFormativo: IAnnoScolasticoPeriodoFormativo;
}

export class SezioneAnnoScolasticoPeriodoFormativo extends BaseBusinessObject
  implements ISezioneAnnoScolasticoPeriodoFormativo {
  fasciaEtaDiRiferimento: EnumFasciaEta;
  nome: string;

  /**
   * Relazioni
   */
  bambini: PersonaFisica[];
  insegnanti: Utente[];
  annoScolasticoPeriodoFormativo: AnnoScolasticoPeriodoFormativo;

  constructor(params?: Partial<ISezioneAnnoScolasticoPeriodoFormativo>) {
    super(params);

    /**
     * Mapping
     */
    if (params.bambini) {
      this.bambini = params.bambini.map((value) => new PersonaFisica(value));
    }
    if (params.insegnanti) {
      this.insegnanti = params.insegnanti.map((value) => new Utente(value));
    }
    if (params.annoScolasticoPeriodoFormativo) {
      this.annoScolasticoPeriodoFormativo = new AnnoScolasticoPeriodoFormativo(
        params.annoScolasticoPeriodoFormativo,
      );
    }
  }

  deconstructor(): ISezioneAnnoScolasticoPeriodoFormativo {
    return Object.assign({ }, this);
  }
}
