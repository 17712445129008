
export enum EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi {
  Costruttivita = 'Costruttivita',
  Atelier = 'Atelier',
  GiocoSimbolico = 'GiocoSimbolico',
  Lettura = 'Lettura',
  SpazioMotorio = 'SpazioMotorio',
  SpazioLogicoMatematico = 'SpazioLogicoMatematico',
  SpazioScoperteScientifiche = 'SpazioScoperteScientifiche',
  SpazioComunicazione = 'SpazioComunicazione',
  Altro = 'Altro'
}

export interface IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi {
  totAllestimentiRicerche: number;
  totMaterialiSupporto: number;

  /**
   * Relazioni
   */
  rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList: IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow[];
}

export class RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi implements IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi {
  totAllestimentiRicerche: number;
  totMaterialiSupporto: number;
  /**
   * Relazioni
   */
  rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList: RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow[];


  constructor(params: Partial<IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList) {
      this.rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList = params.rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList.map((value) => new RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow(value));
    }
  }

  deconstructor(): IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi {
    return Object.assign({}, this);
  }
}

export interface IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow {
  centroInteresse: EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi;
  approfondimenti: string;
  materiali: string;
  numApprofondimenti: number;
  numMateriali: number;
}

export class RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow implements IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow {
  centroInteresse: EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi;
  approfondimenti: string;
  materiali: string;
  numApprofondimenti: number;
  numMateriali: number;

  constructor(params: Partial<IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
  }

  deconstructor(): IRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow {
    return Object.assign({}, this);
  }
}
