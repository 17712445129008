import { IDomanda, Domanda } from '@gpi/lbl/shared/entity';

export interface ISituazioneInizialeSipi {

  /**
   * Relazioni
   */
  domande: IDomanda[];
}

export class SituazioneInizialeSipi implements ISituazioneInizialeSipi {

  /**
   * Relazioni
   */
  domande: Domanda[];

  constructor(params: Partial<ISituazioneInizialeSipi> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.domande) {
      this.domande = params.domande.map((value) => new Domanda(value));
    }
  }

  static InitContent(): SituazioneInizialeSipi {
    const content = new SituazioneInizialeSipi();

    const testiDomande = [
      // Sezione "Progettazione e svolgimento dell'osservazione sistematica"
      'Obiettivo dell\'osservazione',
      'Focus osservativo',
      'In quale/i momento/i della giornate osservare',
      'In quale/i luogo/luoghi osservare',
      'Cosa osservare (indicatori comportamentali)',
      'Modalità di campionamento delle osservazioni (temporale / a evento)',
      'Strumento/i impiegato/i per la raccolta delle informazioni (resoconti / griglie osservative / schemi di codifica)',
      'Trascrizione di alcune osservazioni raccolte (resoconti / griglie osservative / schemi di codifica)',
      'Sintesi / interpretazione delle informazioni raccolte durante l\'osservazione sistematica',

      // Sezione "Situazione iniziale bambino"
      'Punti di forza',
      'Punti di debolezza',
      'Elementi che sembrano capaci di facilitare o ostacolare lo sviluppo del/la bambino/a',
      'Sintesi della situazione iniziale (indicare le aree di bisogno prevalenti sulle quali si intende lavorare a breve e medio termine)',
      'Definizione degli strumenti osservativi e documentativi in sintesi (si veda allegato C)',
      'Elenco del materiale osservativo e documentativo raccolti a fini progettuali (si veda allegato C)'
    ];

    content.domande = testiDomande.map((value, index) => {
      return new Domanda({
        codiceDomanda: `SituazioneInizialeSipi_${index}`,
        rispostaDomanda: '',
        testoDomanda: value,
      });
    });

    return content;
  }

  deconstructor(): ISituazioneInizialeSipi {
    return Object.assign({}, this);
  }
}
