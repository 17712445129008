import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  RelazioneSintesi,
  StrumentoDiRegistrazioneArchivio,
} from '@gpi/lbl/shared/entity';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';



@Component({
  selector: 'lbl-app-ui-relazione-sintesi',
  templateUrl: './relazione-sintesi.component.html',
  styleUrls: ['./relazione-sintesi.component.scss']
})
export class RelazioneSintesiComponent implements OnInit {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  editor = ClassicEditor;


  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): RelazioneSintesi {
    let content = this.strumentoDiRegistrazioneArchivio.content as RelazioneSintesi;

    if (!content) {
      content = RelazioneSintesi.InitContent();
    }

    return content;
  }

  form: FormGroup;


  ngOnInit() {
    this.form = this.buildForm();

    this.form.valueChanges.subscribe(() => {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = this.updateForm(this.content);
      }
    }
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      testo: new FormControl(this.content && this.content.testo ? this.content.testo : null),
    });

    return form;
  }

  updateForm(entity: RelazioneSintesi): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);
    return form;
  }

  buildStrumentoForm() {
    const form = this.form.getRawValue();
    this.strumentiDiRegistrazioneArchivio[0].content = form;

    return this.strumentiDiRegistrazioneArchivio;
  }

  submit(form: FormGroup) {
    if (form.valid) {
    }
  }

}
