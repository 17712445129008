import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import {
  EnumTipologiaRuoloApplicativo,
  RuoloApplicativo,
  SezioneAnnoScolasticoPeriodoFormativo,
  StrutturaEducativa,
  Utente,
} from '@gpi/lbl/shared/entity';
import { combineLatest } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthState, AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthQuery extends Query<AuthState> {
  constructor(protected store: AuthStore) {
    super(store);
  }

  isAuthenticated() {
    const utenteCorrente$ = this.selectUtenteCorrente();
    const isAuthenticated$ = utenteCorrente$.pipe(map((utente) => !!utente));

    return isAuthenticated$;
  }

  isAuthorized(permissions: EnumTipologiaRuoloApplicativo[]) {
    const ruoloApplicativoCorrente$ = this.selectRuoloApplicativoCorrente();
    const isAuthorized$ = ruoloApplicativoCorrente$.pipe(
      map((ruoloApplicativo) => {
        let isAuthorized = false;

        if (ruoloApplicativo) {
          const hasPermission = permissions.some(
            (permission) => permission === ruoloApplicativo.tipo,
          );

          if (hasPermission) {
            isAuthorized = true;
          }
        }

        return isAuthorized;
      }),
    );

    return isAuthorized$;
  }

  isImpersonating() {
    const utenteImpersonificato$ = this.selectUtenteImpersonificato();
    const isImpersonating$ = utenteImpersonificato$.pipe(
      map((utente) => !!utente),
    );

    return isImpersonating$;
  }

  isParente() {
    const utenteParente$ = this.selectUtenteParente();
    const isParente$ = utenteParente$.pipe(map((utente) => !!utente));

    return isParente$;
  }

  selectUtente() {
    return this.select('utente').pipe(
      map((entity) => (entity ? new Utente(entity) : null)),
    );
  }
  selectRuoloApplicativo() {
    return this.select('ruoloApplicativo').pipe(
      map((entity) => (entity ? new RuoloApplicativo(entity) : null)),
    );
  }
  selectStrutturaEducativa() {
    return this.select('strutturaEducativa').pipe(
      map((entity) => (entity ? new StrutturaEducativa(entity) : null)),
    );
  }
  selectSezioneAnnoScolasticoPeriodoFormativo() {
    return this.select('sezioneAnnoScolasticoPeriodoFormativo').pipe(
      map((entity) =>
        entity ? new SezioneAnnoScolasticoPeriodoFormativo(entity) : null,
      ),
    );
  }

  selectUtenteImpersonificato() {
    return this.select('utenteImpersonificato').pipe(
      map((entity) => (entity ? new Utente(entity) : null)),
    );
  }
  selectRuoloApplicativoImpersonificato() {
    return this.select('ruoloApplicativoImpersonificato').pipe(
      map((entity) => (entity ? new RuoloApplicativo(entity) : null)),
    );
  }
  selectStrutturaEducativaImpersonificato() {
    return this.select('strutturaEducativaImpersonificato').pipe(
      map((entity) => (entity ? new StrutturaEducativa(entity) : null)),
    );
  }
  selectSezioneAnnoScolasticoPeriodoFormativoImpersonificato() {
    return this.select(
      'sezioneAnnoScolasticoPeriodoFormativoImpersonificato',
    ).pipe(
      map((entity) =>
        {
        //console.log('sezioneAnnoScolasticoPeriodoFormativoImpersonificato', entity);
        return entity ? new SezioneAnnoScolasticoPeriodoFormativo(entity) : null;
        }
      ),
    );
  }

  selectUtenteParente() {
    return this.select('utenteParente').pipe(
      map((entity) => (entity ? new Utente(entity) : null)),
    );
  }
  selectRuoloApplicativoParente() {
    return this.select('ruoloApplicativoParente').pipe(
      map((entity) => (entity ? new RuoloApplicativo(entity) : null)),
    );
  }
  selectStrutturaEducativaParente() {
    return this.select('strutturaEducativaParente').pipe(
      map((entity) => (entity ? new StrutturaEducativa(entity) : null)),
    );
  }
  selectSezioneAnnoScolasticoPeriodoFormativoParente() {
    return this.select('sezioneAnnoScolasticoPeriodoFormativoParente').pipe(
      map((entity) =>
        entity ? new SezioneAnnoScolasticoPeriodoFormativo(entity) : null,
      ),
    );
  }

  selectUtenteCorrente() {
    const isImpersonating$ = this.isImpersonating();

    const utente$ = this.selectUtente();
    const utenteImpersonificato$ = this.selectUtenteImpersonificato();

    const utenteCorrente$ = isImpersonating$.pipe(
      switchMap((isImpersonating) => !isImpersonating ? utente$ : utenteImpersonificato$),
    );

    return utenteCorrente$;
  }
  selectRuoloApplicativoCorrente() {
    const isImpersonating$ = this.isImpersonating();

    const ruoloApplicativo$ = this.selectRuoloApplicativo();
    const ruoloApplicativoImpersonificato$ = this.selectRuoloApplicativoImpersonificato();

    const ruoloApplicativoCorrente$ = isImpersonating$.pipe(
      switchMap((isImpersonating) => !isImpersonating ? ruoloApplicativo$ : ruoloApplicativoImpersonificato$),
    );

    return ruoloApplicativoCorrente$;
  }
  selectStrutturaEducativaCorrente() {
    const isImpersonating$ = this.isImpersonating();

    const strutturaEducativa$ = this.selectStrutturaEducativa();
    const strutturaEducativaImpersonificato$ = this.selectStrutturaEducativaImpersonificato();

    const strutturaEducativaCorrente$ = isImpersonating$.pipe(
      switchMap((isImpersonating) => !isImpersonating ? strutturaEducativa$ : strutturaEducativaImpersonificato$),
    );

    return strutturaEducativaCorrente$;
  }
  selectSezioneAnnoScolasticoPeriodoFormativoCorrente() {
    const isImpersonating$ = this.isImpersonating();

    const sezioneAnnoScolasticoPeriodoFormativo$ = this.selectSezioneAnnoScolasticoPeriodoFormativo();
    const sezioneAnnoScolasticoPeriodoFormativoImpersonificato$ = this.selectSezioneAnnoScolasticoPeriodoFormativoImpersonificato();

    const strutturaEducativaCorrente$ = isImpersonating$.pipe(
      switchMap((isImpersonating) => !isImpersonating ? sezioneAnnoScolasticoPeriodoFormativo$ : sezioneAnnoScolasticoPeriodoFormativoImpersonificato$),
    );
    //console.log('selectSezioneAnnoScolasticoPeriodoFormativoCorrente: ', strutturaEducativaCorrente$ );
    return strutturaEducativaCorrente$;
  }
}
