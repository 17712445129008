import { Allegato, IAllegato } from '../../..';


export interface IGestionePortfolioCompetenzeBambini {

  /**
   * Relazioni
   */
  portfolio: IAllegato;

}

export class GestionePortfolioCompetenzeBambini implements IGestionePortfolioCompetenzeBambini {

  /**
   * Relazioni
   */
  portfolio: Allegato;


  constructor(params: Partial<IGestionePortfolioCompetenzeBambini> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.portfolio) {
      this.portfolio = new Allegato(params.portfolio);
    }
  }

  deconstructor(): IGestionePortfolioCompetenzeBambini {
    return Object.assign({}, this);
  }
}
