import { Domanda, IDomanda } from './Domanda';

export interface IRegistrazioniCaduteIncidentiBambini {
  data: Date;
  ora: string;
  testimoni: string[];

  /**
   * Relazioni
   */
  domande: IDomanda[];
}

export class RegistrazioniCaduteIncidentiBambini implements IRegistrazioniCaduteIncidentiBambini {
  data: Date;
  ora: string;
  testimoni: string[];

  /**
   *
   * Relazioni
   */
  domande: Domanda[];

  constructor(params: Partial<IRegistrazioniCaduteIncidentiBambini> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.data) {
      this.data = new Date(params.data);
    }

    if (params.domande) {
      this.domande = params.domande.map((value) => new Domanda(value));
    }

    if (params.testimoni) {
      this.testimoni = params.testimoni.map((value) => value);
    }
  }

  static InitContent(): RegistrazioniCaduteIncidentiBambini {
    const content = new RegistrazioniCaduteIncidentiBambini();

    const testiDomande = [
      'Luogo',
      'Descrizione dell\'accaduto',
      'Motivo (compresi eventuali ostacoli ambientali)',
      'Eventuali conseguenze riportate',
      'Azioni Svolte',
    ];

    content.domande = testiDomande.map((value, index) => {
      return new Domanda({
        codiceDomanda: `RegistrazioniCaduteIncidentiBambini_${index}`,
        rispostaDomanda: '',
        testoDomanda: value,
      });
    });

    return content;
  }

  deconstructor(): IRegistrazioniCaduteIncidentiBambini {
    return Object.assign({}, this);
  }
}
