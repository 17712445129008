import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { GestioneProgettazioniSettimanali, GestioneProgettazioniSettimanaliRow, StrumentoDiRegistrazioneArchivio, StrutturaEducativa } from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-gestione-progettazioni-settimanali',
  templateUrl: './strumento-registrazione-gestione-progettazioni-settimanali.component.html',
  styleUrls: ['./strumento-registrazione-gestione-progettazioni-settimanali.component.scss'],
})
export class StrumentoRegistrazioneGestioneProgettazioniSettimanaliComponent implements OnInit, OnChanges {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): GestioneProgettazioniSettimanali {
    let content = this.strumentoDiRegistrazioneArchivio.content as GestioneProgettazioniSettimanali;

    if (!content) {
      content = GestioneProgettazioniSettimanali.InitContent(this.strutturaEducativa);
    }

    return content;
  }

  form: FormGroup;

  get formProgettazioniPerGiorno(): FormArray {
    return this.form.get('progettazioniPerGiorno') as FormArray;
  }
  progettazioniPerGiornoDisplayedColumns = ['giorni', 'zoneInteresse', 'esperienzaGrandeGruppo', 'esperienzaPiccoliGruppi', 'incontriFamiglie', 'formazioneEAggiornamento'];

  ngOnInit() {

    if (this.strumentoDiRegistrazioneArchivio.status === 'Bozza') {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    }

    this.form.valueChanges.subscribe((el) => {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = this.updateForm(this.content);
      }
    }
    if (changes) {
      if (changes.strutturaEducativa && changes.strutturaEducativa.currentValue) {
        this.form = this.updateForm(this.content);
      }
    }
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      progettazioniPerGiorno: this.buildFormProgettazioniPerGiorno(this.content.progettazioniPerGiorno),
    });

    return form;
  }

  buildFormProgettazioniPerGiorno(progettazioniPerGiorno: GestioneProgettazioniSettimanaliRow[]): FormArray {
    const form = new FormArray(progettazioniPerGiorno.map((value) => this.buildFormProgettazionePerGiorno(value)));

    return form;
  }

  buildFormProgettazionePerGiorno(progettazionePerGiorno: GestioneProgettazioniSettimanaliRow): FormGroup {
    const form = new FormGroup({
      esperienza_a_grande_gruppo: new FormControl(),
      esprerienza_nei_piccoli_gruppi: new FormControl(),
      formazione_e_aggiornamento: new FormControl(),
      incontri_con_le_famiglie_ed_il_territorio: new FormControl(),
      nomeGiorno: new FormControl(progettazionePerGiorno.nomeGiorno),
      zone_di_interesse_osservazine_e_rapprendimento: new FormControl(),
    });

    return form;
  }

  updateForm(entity: GestioneProgettazioniSettimanali): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  buildStrumentoForm() {

    const form = this.form.getRawValue();

    this.strumentiDiRegistrazioneArchivio[0].content = form;

    return this.strumentiDiRegistrazioneArchivio;
  }

  submit(form: FormGroup) {
    if (form.valid) {
    }
  }
}
