<ng-container *transloco="let t; read: 'gestioneScrittureProgettuali.strumentoRegistrazione'">
  <form  [formGroup]="form" (ngSubmit)="submit()">
    <div fxLayout="column" fxLayoutGap="1em" formArrayName="domande">
      <ng-container *ngFor="let domanda of formDomande.controls; let i = index" [formGroupName]="i">
      <mat-label>{{ t('domanda.' + domanda?.value?.codiceDomanda) }}</mat-label>
        <ckeditor formControlName="rispostaDomanda" [editor]="editor"
        [config]="{ toolbar: [ 'heading','fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'indent', 'outdent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'] }">
      </ckeditor>
        <!--<mat-form-field fxFill appearance="outline">
          <mat-label>{{ t('domanda.' + domanda?.value?.codiceDomanda) }}</mat-label>
          <textarea matInput formControlName="rispostaDomanda"></textarea>
        </mat-form-field>-->
      </ng-container>
    </div>

    <ng-container *ngIf="ruoloApplicativoCorrente.tipo !== 'RuoloEducatore_Insegnante'">
      <mat-form-field fxFill appearance="outline">
        <mat-label>{{ t('noteApprovazione') }}</mat-label>
        <textarea matInput formControlName="noteApprovazione"></textarea>
      </mat-form-field>
    </ng-container>
  </form>
</ng-container>
