<ng-container *transloco="let t; read: 'gestioneSituazioniGiornaliere.scuolaInfanzia.altreFasceEta'">
  <form [formGroup]="formGruop" (ngSubmit)="buildStrumentoForm()">
    <div class="mat-elevation-z1">
      <table formArrayName="rows" mat-table [dataSource]="strumentiDiRegistrazioneArchivio">

        <ng-container matColumnDef="bambino">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.bambino') }}
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field class="example-full-width">
              <input matInput formControlName="bambino">
            </mat-form-field>

          </td>
        </ng-container>

        <ng-container matColumnDef="assente">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.assente') }}
          </th>
          <td mat-cell *matCellDef="let row; let index = index" class="testo-centrato">
            <mat-checkbox (change)="changeCheckBox($event, index)" [formGroupName]="index"
              [checked]="row.content?.assente">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="primo">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.primo') }}
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-select formControlName="primo" class="testo-centrato">
              <mat-option *ngFor="let food of arrayConsumoPasto" value="{{food}}">
                {{'type.' + food | transloco}}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="secondo">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.secondo') }}
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-select formControlName="secondo" class="testo-centrato">
              <mat-option *ngFor="let food of arrayConsumoPasto" value="{{food}}">
                {{'type.' + food | transloco}}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="contorno">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.contorno') }}
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-select formControlName="contorno" class="testo-centrato">
              <mat-option *ngFor="let food of arrayConsumoPasto" value="{{food}}">
                {{'type.' + food | transloco}}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="frutta">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.frutta') }}
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-select formControlName="frutta" class="testo-centrato">
              <mat-option *ngFor="let food of arrayConsumoPasto" value="{{food}}">
                {{'type.' + food | transloco}}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="merenda">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.merenda') }}
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-select formControlName="merenda" class="testo-centrato">
              <mat-option *ngFor="let food of arrayConsumoPasto" value="{{food}}">
                {{'type.' + food | transloco}}
              </mat-option>
            </mat-select>
          </td>
        </ng-container>

        <!-- <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.note') }}
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field class="example-full-width">
              <input matInput formControlName="note">
            </mat-form-field>
          </td>
        </ng-container> -->

        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.note') }}
          </th>
          <td mat-cell *matCellDef="let row; let index = index" [formGroupName]="index">
            <mat-form-field class="example-full-width">
              <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="5"
                formControlName="note">
              </textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>
  </form>
</ng-container>
