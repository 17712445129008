import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import {
  GiornoSettimanaStruttura,
  IGiornoSettimanaStruttura,
} from './GiornoSettimanaStruttura';
import { IMenuPastoPortata, MenuPastoPortata } from './MenuPastoPortata';
import {
  INumeroSettimanaMenuStruttura,
  NumeroSettimanaMenuStruttura,
} from './NumeroSettimanaMenuStruttura';
import { IPastoMenuStruttura, PastoMenuStruttura } from './PastoMenuStruttura';

export interface IMenuPasto extends IBaseBusinessObject {
  /**
   * Relazioni
   */
  portate: IMenuPastoPortata[];
  relativoAGiornoSettimana: IGiornoSettimanaStruttura;
  relativoANumeroSettimana: INumeroSettimanaMenuStruttura;
  tipologiaPasto: IPastoMenuStruttura;
}

export class MenuPasto extends BaseBusinessObject implements IMenuPasto {
  /**
   * Relazioni
   */
  portate: MenuPastoPortata[];
  relativoAGiornoSettimana: GiornoSettimanaStruttura;
  relativoANumeroSettimana: NumeroSettimanaMenuStruttura;
  tipologiaPasto: PastoMenuStruttura;

  constructor(params?: Partial<IMenuPasto>) {
    super(params);

    /**
     * Mapping
     */
    if (params.portate) {
      this.portate = params.portate.map((value) => new MenuPastoPortata(value));
    }
    if (params.relativoAGiornoSettimana) {
      this.relativoAGiornoSettimana = new GiornoSettimanaStruttura(
        params.relativoAGiornoSettimana,
      );
    }
    if (params.relativoANumeroSettimana) {
      this.relativoANumeroSettimana = new NumeroSettimanaMenuStruttura(
        params.relativoANumeroSettimana,
      );
    }
    if (params.tipologiaPasto) {
      this.tipologiaPasto = new PastoMenuStruttura(params.tipologiaPasto);
    }
  }

  deconstructor(): IMenuPasto {
    return Object.assign({ }, this);
  }
}
