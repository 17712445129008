export class FrontendError extends Error {
  code: string;

  constructor(message: string, code?: string) {
    super(message);
    this.name = 'FrontendError';
    this.code = `error.frontend.${code}`;

    Object.setPrototypeOf(this, FrontendError.prototype);
  }
}
