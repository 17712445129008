<ng-container *transloco="let t; read: 'competenzeInsegnanti.strumentoDiRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <h3>{{t('titleTask')}}</h3>
      <div class="mat-elevation-z1" *ngIf="competenze?.controls?.length > 0">

        <table mat-table [dataSource]="competenze.controls.slice(tasksMin, tasksMax + 1)" formArrayName="competenze">

          <!-- Competenze -->
          <ng-container matColumnDef="competenza">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              {{ t('table.competenze.' + element?.value?.competenza?.id) }}
            </td>
          </ng-container>

          <!-- Autovalutazione -->
          <ng-container matColumnDef="autovalutazione">
            <th mat-header-cell *matHeaderCellDef> {{t('table.column.autovalutazione')}} </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="pr-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="autovalutazione"
                  [disabled]="ruoloApplicativoCorrente?.tipo === 'RuoloCoordinatorePedagogico'">
                  <mat-option *ngFor="let valutazione of valutazioni" [value]="valutazione">
                    {{valutazione}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Valutazione -->
          <ng-container matColumnDef="valutazione">
            <th mat-header-cell *matHeaderCellDef> {{t('table.column.valutazione')}} </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field appearance="outline">
                <mat-select formControlName="valutazione"
                  [disabled]="ruoloApplicativoCorrente?.tipo === 'RuoloEducatore_Insegnante'">
                  <mat-option *ngFor="let valutazione of valutazioni" [value]="valutazione">
                    {{valutazione}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <h3>{{t('titleSkills')}}</h3>
      <div class="mat-elevation-z1" *ngIf="competenze?.controls?.length > 0">

        <table mat-table [dataSource]="competenze.controls.slice(skillsMin, competenze.controls.length)"
          formArrayName="competenze">

          <!-- Competenze -->
          <ng-container matColumnDef="competenza">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              {{ t('table.competenze.' + element?.value?.competenza?.id) }}
            </td>
          </ng-container>

          <!-- Autovalutazione -->
          <ng-container matColumnDef="autovalutazione">
            <th mat-header-cell *matHeaderCellDef> {{t('table.column.autovalutazione')}} </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i + skillsMin" class="pr-3">
              <mat-form-field appearance="outline">
                <mat-select formControlName="autovalutazione"
                  [disabled]="ruoloApplicativoCorrente?.tipo === 'RuoloCoordinatorePedagogico'">
                  <mat-option *ngFor="let valutazione of valutazioni" [value]="valutazione">
                    {{valutazione}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Valutazione -->
          <ng-container matColumnDef="valutazione">
            <th mat-header-cell *matHeaderCellDef> {{t('table.column.valutazione')}} </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i + skillsMin">
              <mat-form-field appearance="outline">
                <mat-select formControlName="valutazione"
                  [disabled]="ruoloApplicativoCorrente?.tipo === 'RuoloEducatore_Insegnante'">
                  <mat-option *ngFor="let valutazione of valutazioni" [value]="valutazione">
                    {{valutazione}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>
    <button #button type="submit" class="d-none"></button>
  </form>
</ng-container>