import { EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi } from './RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi';

export interface IAreaEsterna {
  totAllestimentiRicerche: number;
  totMaterialiSupporto: number;

  rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList: IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow[];
}

export class AreaEsterna implements IAreaEsterna {
  totAllestimentiRicerche: number;
  totMaterialiSupporto: number;


  rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList: RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow[];


  constructor(params: Partial<IAreaEsterna> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList) {
      this.rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList = params.rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList.map((value) => new RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow(value));
    }
  }

  deconstructor(): IAreaEsterna {
    return Object.assign({}, this);
  }
}

export interface IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow {
  centroInteresse: EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi;
  approfondimenti: string;
  materiali: string;
  numApprofondimenti: number;
  numMateriali: number;
}

export class RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow implements IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow {
  centroInteresse: EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi;
  approfondimenti: string;
  materiali: string;
  numApprofondimenti: number;
  numMateriali: number;

  constructor(params: Partial<IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
  }

  deconstructor(): IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow {
    return Object.assign({}, this);
  }


}
export interface IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne {
  areeEsterne: IAreaEsterna[]
  sumAllestimentiRicerche: number;
  sumMaterialiSupporto: number;
}
export class RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne implements IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne {
  areeEsterne: AreaEsterna[]
  sumAllestimentiRicerche: number;
  sumMaterialiSupporto: number;

  constructor(params: Partial<IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne> = {}) {
    if (params.areeEsterne) {
      this.areeEsterne = params.areeEsterne.map((value) => new AreaEsterna(value));
    }
  }

  deconstructor(): IRilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne {
    return Object.assign({}, this);
  }

  static InitContent() {
    const content = new RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne();
    content.areeEsterne = new Array<AreaEsterna>(new AreaEsterna());

    return content
  }

}


