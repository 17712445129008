<ng-container *transloco="let t; read: 'gestioneValutazioneAmbitiSviluppoCompetenzeIndividuali.scuola'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <h2>{{ t('title')}}</h2>
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start">
      <h3>{{ t('periodoOsservazione.title') }}</h3>
      <div fxLayout="row" fxLayoutGap="1em">
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>{{ t('periodoOsservazione.inizio') }}</mat-label>
          <input matInput [matDatepicker]="pickerDal" formControlName="dataOsservazioneDal"
            [max]="form.get('dataOsservazioneAl').value" />
          <mat-datepicker-toggle matSuffix [for]="pickerDal"></mat-datepicker-toggle>
          <mat-datepicker #pickerDal></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>{{ t('periodoOsservazione.fine') }}</mat-label>
          <input matInput [matDatepicker]="pickerAl" formControlName="dataOsservazioneAl"
            [min]="form.get('dataOsservazioneDal').value" />
          <mat-datepicker-toggle matSuffix [for]="pickerAl"></mat-datepicker-toggle>
          <mat-datepicker #pickerAl></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>{{ t('periodoOsservazione.title') }}</mat-label>
          <mat-select formControlName="periodoOsservazione">
            <mat-option *ngFor="let periodo of enum" [value]="periodo">
              {{periodo}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <label style="padding-top:20px">Allenamenti</label>
        <mat-form-field fxFill appearance="outline" fxFlex="30">
          <textarea matInput formControlName="allenamenti"></textarea>
        </mat-form-field>
      </div>
      <div class="mat-elevation-z1" formArrayName="template">
        <table mat-table [dataSource]="formTemplate.controls">

          <!-- Campi esperienza -->
          <ng-container matColumnDef="campoEsperienza">
            <th mat-header-cell *matHeaderCellDef> {{t('template.table.column.campiEsperienza')}} </th>
            <td mat-cell *matCellDef="let element">
              {{ 'type.scuola.' + element.value.campoEsperienza | transloco }}
            </td>
          </ng-container>

          <!-- Esperienze -->
          <ng-container matColumnDef="esperienze">
            <th mat-header-cell *matHeaderCellDef> {{t('template.table.column.esperienze')}} </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-form-field appearance="outline" [formGroupName]="i">
                <textarea matInput formControlName="esperienze"></textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Scaffolding -->
          <ng-container matColumnDef="scaffolding">
            <th mat-header-cell *matHeaderCellDef> {{t('template.table.column.scaffolding')}} </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-form-field appearance="outline" [formGroupName]="i">
                <textarea matInput formControlName="scaffolding"></textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Traguardi sviluppo -->
          <ng-container matColumnDef="traguardiSviluppo">
            <th mat-header-cell *matHeaderCellDef> {{t('template.table.column.traguardiSviluppo')}} </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <div fxLayout="column" [formGroupName]="i">
                <mat-form-field appearance="outline">
                  <mat-label>Valutazione</mat-label>
                  <mat-select formControlName="valutazioneTraguardiSviluppo">
                    <mat-option *ngFor="let valutazione of [0,1,2,3,4,5]" [value]="valutazione">
                      {{valutazione}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <textarea matInput formControlName="traguardiSviluppo"></textarea>
                </mat-form-field>
              </div>
            </td>
          </ng-container>

          <!-- Meta cognizione -->
          <ng-container matColumnDef="metaCognizione">
            <th mat-header-cell *matHeaderCellDef> {{t('template.table.column.metaCognizione')}} </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-form-field appearance="outline" [formGroupName]="i">
                <textarea matInput formControlName="metaCognizione"></textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Traguardi sviluppo meta cognizione-->
          <ng-container matColumnDef="traguardiSviluppoMetaCognizione">
            <th mat-header-cell *matHeaderCellDef> {{t('template.table.column.traguardiSviluppometacognizione')}} </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <div fxLayout="column" [formGroupName]="i">
                <mat-form-field appearance="outline" fxFlex="30">
                  <mat-label>Valutazione</mat-label>
                  <mat-select formControlName="valutazioneTraguardiSviluppoMetaCognizione">
                    <mat-option *ngFor="let valutazione of [0,1,2,3,4,5]" [value]="valutazione">
                      {{valutazione}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <textarea matInput formControlName="traguardiSviluppoMetaCognizione"></textarea>
                </mat-form-field>
              </div>
            </td>
          </ng-container>

          <!-- Note -->
          <ng-container matColumnDef="note">
            <th mat-header-cell *matHeaderCellDef> {{t('template.table.column.note')}} </th>
            <td mat-cell *matCellDef="let element; let i = index">
              <mat-form-field appearance="outline" [formGroupName]="i">
                <textarea matInput formControlName="note"></textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="templateDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: templateDisplayedColumns"></tr>
        </table>
      </div>
    </div>
    <button #button type="submit" class="d-none"></button>
  </form>
</ng-container>
