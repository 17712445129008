import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { SintesiProgettoEducativoSipi, StrumentoDiRegistrazioneArchivio, StrutturaEducativa } from '@gpi/lbl/shared/entity';
import { Observable, Subject, config } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { DialogLineeGuidaSintesiProgettoComponent } from './dialog/dialog-linee-guida-sintesi-progetto.component';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'lbl-app-ui-strumento-registrazione-sipi-sezione-sintesi-progetto',
  templateUrl: './sipi-sezione-sintesi-progetto.component.html',
  styleUrls: ['./sipi-sezione-sintesi-progetto.component.scss'],
})
export class SipiSezioneSintesiProgettoComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button')
  buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): SintesiProgettoEducativoSipi {
    let content = this.strumentoDiRegistrazioneArchivio.content as SintesiProgettoEducativoSipi;

    return content;
  }

  form: FormGroup;

  editor = ClassicEditor;

  constructor(private matDialog: MatDialog) {}

  ngOnInit() {
    this.form = this.buildForm();
    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  openDialog() {
    this.matDialog.open(DialogLineeGuidaSintesiProgettoComponent);
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      sintesi: new FormControl(this.content && this.content.sintesi ? this.content.sintesi : null)
    });

    return form;
  }

  updateForm(entity: SintesiProgettoEducativoSipi): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const sintesiProgettoEducativoSipi = new SintesiProgettoEducativoSipi(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: sintesiProgettoEducativoSipi,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
