
export interface IRilevazionePartecipazioneFamiglieRow {
  data: Date;
  descrizione: String;
  numeroFamiglie: number;
}

export class RilevazionePartecipazioneFamiglieRow implements IRilevazionePartecipazioneFamiglieRow {
  data: Date;
  descrizione: String;
  numeroFamiglie: number;
  constructor(params: Partial<IRilevazionePartecipazioneFamiglieRow> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
  }

  deconstructor(): IRilevazionePartecipazioneFamiglieRow {
    return Object.assign({}, this);
  }
}

export interface IRilevazionePartecipazioneFamiglie {
  incontriSezione: IRilevazionePartecipazioneFamiglieRow[];
  totRowincontriSezione: number;
  totFamiglieincontriSezione: number;

  laboratori: IRilevazionePartecipazioneFamiglieRow[];
  totRowlaboratori: number;
  totFamiglielaboratori: number;

  incontriConsiglio: IRilevazionePartecipazioneFamiglieRow[];
  totRowincontriConsiglio: number;
  totFamiglieincontriConsiglio: number;

  feste: IRilevazionePartecipazioneFamiglieRow[];
  totRowfeste: number;
  totFamigliefeste: number;

  serateATema: IRilevazionePartecipazioneFamiglieRow[];
  totRowserateATema: number;
  totFamiglieserateATema: number;

  altro: IRilevazionePartecipazioneFamiglieRow[];
  totRowaltro: number;
  totFamigliealtro: number;

  totFamigliePresentiIncontriSezione: number;
  totIniziativeFamigliePresenti: number;


  /**
   * Relazioni
   */
}

export class RilevazionePartecipazioneFamiglie implements IRilevazionePartecipazioneFamiglie {
  incontriSezione: RilevazionePartecipazioneFamiglieRow[];
  totRowincontriSezione: number;
  totFamiglieincontriSezione: number;

  laboratori: RilevazionePartecipazioneFamiglieRow[];
  totRowlaboratori: number;
  totFamiglielaboratori: number;

  incontriConsiglio: RilevazionePartecipazioneFamiglieRow[];
  totRowincontriConsiglio: number;
  totFamiglieincontriConsiglio: number;

  feste: RilevazionePartecipazioneFamiglieRow[];
  totRowfeste: number;
  totFamigliefeste: number;

  serateATema: RilevazionePartecipazioneFamiglieRow[];
  totRowserateATema: number;
  totFamiglieserateATema: number;

  altro: RilevazionePartecipazioneFamiglieRow[];
  totRowaltro: number;
  totFamigliealtro: number;

  totFamigliePresentiIncontriSezione: number;
  partecipazioneMediaIncontriSezione: number;

  totIniziativeFamigliePresenti: number;
  partecipazioneMediaIniziativeFamiglie: number;

  /**
   * Relazioni
   */

  constructor(params: Partial<IRilevazionePartecipazioneFamiglie> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */

    if (params?.incontriSezione) {
      this.incontriSezione = params.incontriSezione.map((value) => new RilevazionePartecipazioneFamiglieRow(value));
    }

    if (params?.laboratori) {
      this.laboratori = params.laboratori.map((value) => new RilevazionePartecipazioneFamiglieRow(value));
    }
    if (params?.incontriConsiglio) {
      this.incontriConsiglio = params.incontriConsiglio.map((value) => new RilevazionePartecipazioneFamiglieRow(value));

    }
    if (params?.feste) {
      this.feste = params.feste.map((value) => new RilevazionePartecipazioneFamiglieRow(value));
    }
    if (params?.serateATema) {
      this.serateATema = params.serateATema.map((value) => new RilevazionePartecipazioneFamiglieRow(value));
    }
    if (params?.altro) {
      this.altro = params.altro.map((value) => new RilevazionePartecipazioneFamiglieRow(value));
    }
  }
  static InitContent(): RilevazionePartecipazioneFamiglie {
    const content = new RilevazionePartecipazioneFamiglie();

    content.incontriSezione = new Array<RilevazionePartecipazioneFamiglieRow>();
    content.laboratori = new Array<RilevazionePartecipazioneFamiglieRow>();
    content.incontriConsiglio = new Array<RilevazionePartecipazioneFamiglieRow>();
    content.feste = new Array<RilevazionePartecipazioneFamiglieRow>();
    content.serateATema = new Array<RilevazionePartecipazioneFamiglieRow>();
    content.altro = new Array<RilevazionePartecipazioneFamiglieRow>();

    return content;
  }

  deconstructor(): IRilevazionePartecipazioneFamiglie {
    return Object.assign({}, this);
  }
}
