import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';

import { BackendErrorHandler } from './error-handlers/backend.error-handler';
import { FrontendErrorHandler } from './error-handlers/frontend.error-handler';
import {
  ERROR_HANDLERS,
  GlobalErrorHandler,
} from './error-handlers/global.error-handlers';
import { BackendErrorInterceptor } from './interceptors/backend-error.interceptor';
import { Configuration } from './models/configuration.model';

@NgModule({ })
export class LblAppUtilErrorModule {
  static forRoot(configuration: Configuration): ModuleWithProviders {
    return {
      ngModule: LblAppUtilErrorModule,
      providers: configuration.enabled
        ? [
            {
              provide: ErrorHandler,
              useClass: GlobalErrorHandler,
            },
            {
              provide: ERROR_HANDLERS,
              useClass: FrontendErrorHandler,
              multi: true,
            },
            {
              provide: ERROR_HANDLERS,
              useClass: BackendErrorHandler,
              multi: true,
            },
            {
              provide: HTTP_INTERCEPTORS,
              useClass: BackendErrorInterceptor,
              multi: true,
            },
          ]
        : [],
    };
  }
}
