import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { IPersonaFisica, PersonaFisica } from './PersonaFisica';

export enum EnumTipologiaDettagliPersonaFisicaRelazioni {
  ViveCon = 'ViveCon',
  ReferentePrincipale = 'ReferentePrincipale',
}

export enum EnumPersonaFisicaTipologiaParentela {
  Padre = 'Padre',
  Madre = 'Madre',
  Nonno = 'Nonno',
  Nonna = 'Nonna',
  Zio = 'Zio',
  Zia = 'Zia',
  Sorella = 'Sorella',
  Fratello = 'Fratello',
  TutoreLegale = 'TutoreLegale',
  Figlio = 'Figlio',
}

export interface IPersonaFisicaRelazioni extends IBaseBusinessObject {
  dettagliRelazione?: EnumTipologiaDettagliPersonaFisicaRelazioni;
  parentelaBiologica: boolean;
  tipologiaParentela: EnumPersonaFisicaTipologiaParentela;

  /**
   * Relazioni
   */
  inRelazioneCon: IPersonaFisica;
}

export class PersonaFisicaRelazioni extends BaseBusinessObject
  implements IPersonaFisicaRelazioni {
  dettagliRelazione?: EnumTipologiaDettagliPersonaFisicaRelazioni;
  parentelaBiologica: boolean;
  tipologiaParentela: EnumPersonaFisicaTipologiaParentela;

  /**
   * Relazioni
   */
  inRelazioneCon: PersonaFisica;

  constructor(params?: Partial<IPersonaFisicaRelazioni>) {
    super(params);

    /**
     * Mapping
     */
    if (params.inRelazioneCon) {
      this.inRelazioneCon = new PersonaFisica(params.inRelazioneCon);
    }
  }

  deconstructor(): IPersonaFisicaRelazioni {
    return Object.assign({ }, this);
  }
}
