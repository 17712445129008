import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { FeatureQuery } from './feature.query';
import { FeatureStore } from './feature.store';

@Injectable({ providedIn: 'root' })
export class FeatureService {
  constructor(public query: FeatureQuery, public store: FeatureStore) { }

  private contatore: number;

  resetContatoreWrapper() {
    return this.contatore = 0;
  }

  getContatoreWrapper() {
    return this.contatore;
  }

  setContatoreWrapper(cont: number) {
    this.contatore = cont;
  }

  addContatoreWrapper() {
    return this.contatore += 1;
  }

}
