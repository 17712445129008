import { EnumGiorniSettimana, GiornoSettimanaStruttura } from '../GiornoSettimanaStruttura';
import { StrutturaEducativa } from '../StrutturaEducativa';

export interface IGestioneProgettazioniSettimanali {
  /**
   * Relazioni
   */
  progettazioniPerGiorno: IGestioneProgettazioniSettimanaliRow[];
}

export class GestioneProgettazioniSettimanali implements IGestioneProgettazioniSettimanali {
  /**
   * Relazioni
   */
  progettazioniPerGiorno: GestioneProgettazioniSettimanaliRow[];

  constructor(params: Partial<IGestioneProgettazioniSettimanali> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params?.progettazioniPerGiorno) {
      this.progettazioniPerGiorno = params.progettazioniPerGiorno.map((value) => new GestioneProgettazioniSettimanaliRow(value));
    }
  }

  static InitContent(strutturaEducativa: StrutturaEducativa): GestioneProgettazioniSettimanali {
    const content = new GestioneProgettazioniSettimanali();

    content.progettazioniPerGiorno = strutturaEducativa.orarioApertura.map((value) => {
      return new GestioneProgettazioniSettimanaliRow({
        nomeGiorno: value.nomeGiorno,
      });
    });

    return content;
  }
}

export interface IGestioneProgettazioniSettimanaliRow {
  esperienza_a_grande_gruppo: string;
  esprerienza_nei_piccoli_gruppi: string;
  formazione_e_aggiornamento: string;
  incontri_con_le_famiglie_ed_il_territorio: string;
  nomeGiorno: EnumGiorniSettimana;
  zone_di_interesse_osservazine_e_rapprendimento: string;
}

export class GestioneProgettazioniSettimanaliRow implements IGestioneProgettazioniSettimanaliRow {
  esperienza_a_grande_gruppo: string;
  esprerienza_nei_piccoli_gruppi: string;
  formazione_e_aggiornamento: string;
  incontri_con_le_famiglie_ed_il_territorio: string;
  nomeGiorno: EnumGiorniSettimana;
  zone_di_interesse_osservazine_e_rapprendimento: string;

  constructor(params: Partial<IGestioneProgettazioniSettimanaliRow> = {}) {
    Object.assign(this, params);
  }

  deconstructor(): IGestioneProgettazioniSettimanaliRow {
    return Object.assign({}, this);
  }
}
