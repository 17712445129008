<ng-container
  *transloco="let t; read: 'rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <ng-container *ngFor="let strumento of strumentiDiRegistrazioneArchivio; let indexSezioni = index"
        formArrayName="sezioniForm">
        <strong>{{strumento.sezioni[0].nome}}</strong>
        <span>{{t('totAllestimentiRicerche')}}:
          {{sezioniForm.at(indexSezioni).get('totAllestimentiRicerche').value}}</span>
        <span>{{t('totMaterialiSupporto')}}: {{sezioniForm.at(indexSezioni).get('totMaterialiSupporto').value}}</span>
        <span>{{t('totCentriInteresse')}}:
          {{sezioniForm.at(indexSezioni).get('rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList').value.length}}</span>
        <span>{{t('fasciaEta')}}: {{strumento.sezioni[0].fasciaEtaDiRiferimento | transloco}}</span>

        <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="end ">
          <button mat-raised-button color="primary" type="button" (click)="addRilevazione(indexSezioni)">
            <mat-icon svgIcon="add"></mat-icon>
            {{t('table.add')}}
          </button>
        </div>

        <div class="mat-elevation-z1" [formGroupName]="indexSezioni" class="mb-5">
          <table mat-table #table [dataSource]="getFormArrayRilevazione(indexSezioni).controls"
            formArrayName="rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList">
            <!-- centroInteresse -->
            <ng-container matColumnDef="centroInteresse">
              <th mat-header-cell *matHeaderCellDef>{{t('table.column.centroInteresse')}}</th>
              <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                <mat-form-field>
                  <mat-label>{{t('table.selectCentroInteresse')}}</mat-label>
                  <mat-select formControlName="centroInteresse">
                    <mat-option *ngFor="let centro of centriInteresseList" [value]="centro">{{'type.' + centro |
                      transloco}}</mat-option>
                  </mat-select>
                </mat-form-field>
              </td>
            </ng-container>

            <!-- approfondimenti -->
            <ng-container matColumnDef="approfondimenti">
              <th mat-header-cell *matHeaderCellDef>{{t('table.column.approfondimenti')}}</th>
              <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                <mat-form-field appearance="outline" class="format-data">
                  <input type="number" (focusout)="calcoloTot(indexSezioni)" matInput
                    formControlName="numApprofondimenti">
                </mat-form-field>
                <mat-form-field>
                  <textarea matInput formControlName="approfondimenti"> </textarea>
                </mat-form-field>
              </td>
            </ng-container>

            <!-- materiali -->
            <ng-container matColumnDef="materiali">
              <th mat-header-cell *matHeaderCellDef>{{t('table.column.materiali')}}</th>
              <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                <mat-form-field appearance="outline" class="format-data">
                  <input type="number" (focusout)="calcoloTot(indexSezioni)" matInput formControlName="numMateriali">
                </mat-form-field>
                <mat-form-field>
                  <textarea matInput formControlName="materiali"> </textarea>
                </mat-form-field>
              </td>
            </ng-container>

            <!-- Azioni Column -->
            <ng-container matColumnDef="azioni">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row; let i = index;">
                <button mat-icon-button color="primary" (click)="deleteRilevazione(indexSezioni, i)"
                  matTooltip="{{t('table.delete')}}">
                  <mat-icon svgIcon="delete"></mat-icon>
                </button>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="progettiRealizzatiDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: progettiRealizzatiDisplayedColumns"></tr>
          </table>
        </div>
      </ng-container>

    </div>

    <button #button type="submit" class="d-none"></button>

  </form>
</ng-container>
