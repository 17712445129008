<ng-container *transloco="let t; read: 'relazioneSintesi.strumentoDiRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">

    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>
      <h3>{{t('title')}}</h3>
      <mat-label>{{t('note')}}</mat-label>
      <ckeditor formControlName="testo" [editor]="editor"
        [config]="{ toolbar: [ 'heading','fontColor', 'fontBackgroundColor', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'indent', 'outdent', '|', 'blockQuote', 'insertTable', 'undo', 'redo'] }">
      </ckeditor>
      <!-- <mat-form-field fxFill appearance="outline">
        <mat-label>{{t('note')}}</mat-label>
        <textarea matInput formControlName="testo"></textarea>
      </mat-form-field> -->
    </div>
  </form>
</ng-container>
