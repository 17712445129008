<ng-container *transloco="let t; read: 'sipiSezioneSituazioneIniziale.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">

    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <h3 class="subtitle">
        <span class="status"></span>
        <span>{{t('schedaOsservazione.title')}}</span>
      </h3>

      <div [innerHTML]=" t('schedaOsservazione.ambitiOsservativi') "></div>

      <div formArrayName="domande">
        <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
          <mat-form-field *ngIf="i >= domandeOsservazioniMin && i < domandeOsservazioniMax" fxFill appearance="outline"
            [formGroupName]="i">
            <mat-label>{{ t('domande.' + domanda.value.codiceDomanda) }}</mat-label>
            <textarea matInput formControlName="rispostaDomanda"></textarea>
          </mat-form-field>
        </ng-container>
      </div>

      <h3 class="subtitle">
        <span class="status"></span>
        <span>{{t('situazioneInizialeBambino.title')}}</span>
      </h3>
      <div formArrayName="domande">
        <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
          <mat-form-field *ngIf="i >= domandeSituazioneInizialeBambinoMin" fxFill appearance="outline"
            [formGroupName]="i">
            <mat-label>{{ t('domande.' + domanda.value.codiceDomanda) }}</mat-label>
            <textarea matInput formControlName="rispostaDomanda"></textarea>
          </mat-form-field>
        </ng-container>
      </div>

    </div>
    <button #button type="submit" class="d-none"></button>
  </form>
</ng-container>
