import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableAkitaProdMode, persistState } from '@datorama/akita';
import { debounceTime } from 'rxjs/operators';

import { LblAppModule } from './app/lbl-app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

persistState({
  storage: localStorage,
  include: [
    'lbl-app-data-access-auth',
    'lbl-app-data-access-tipologie',
    'lbl-app-data-access-ruoli-applicativi',
    'lbl-app-data-access-funzionalita-lbl',
  ],
  preStorageUpdate(storeName, state) {
    if (storeName === 'lbl-app-data-access-auth') {
      return {
        utenteId: state.utenteId,
        ruoloApplicativoId: state.ruoloApplicativoId,
        strutturaEducativaId: state.strutturaEducativaId,
        sezioneAnnoScolasticoPeriodoFormativoId: state.sezioneAnnoScolasticoPeriodoFormativoId,

        utenteImpersonificatoId: state.utenteImpersonificatoId,
        ruoloApplicativoImpersonificatoId: state.ruoloApplicativoImpersonificatoId,
        strutturaEducativaImpersonificatoId: state.strutturaEducativaImpersonificatoId,
        sezioneAnnoScolasticoPeriodoFormativoImpersonificatoId: state.sezioneAnnoScolasticoPeriodoFormativoImpersonificatoId,

        utenteToken: state.utenteToken,
        utenteImpersonificatoToken: state.utenteImpersonificatoToken,
      };
    }

    return state;
  },
  preStorageUpdateOperator: () => debounceTime(1000),
});

platformBrowserDynamic()
  .bootstrapModule(LblAppModule)
  .catch((err) => console.error(err));
