import { GiornoSettimanaStruttura, IGiornoSettimanaStruttura } from '../GiornoSettimanaStruttura';
import { StrutturaEducativa } from '../StrutturaEducativa';

import { Domanda, IDomanda } from './Domanda';

export interface IGestioneColloquiInizialiScuolaInfanzia {
  inizioAmbientamento: Date;
  noteAnagrafica: string;
  notePostColloquio: string;
  noteRiflessioni: string;
  partecipantiColloquio: string[];

  /**
   * Relazioni
   */
  domande: IDomanda[];
  orarioFrequenzaRichiesto: IGiornoSettimanaStruttura[];
}

export class GestioneColloquiInizialiScuolaInfanzia implements IGestioneColloquiInizialiScuolaInfanzia {
  inizioAmbientamento: Date;
  noteAnagrafica: string;
  notePostColloquio: string;
  noteRiflessioni: string;
  partecipantiColloquio: string[];

  /**
   * Relazioni
   */
  domande: Domanda[];
  orarioFrequenzaRichiesto: GiornoSettimanaStruttura[];

  constructor(params: Partial<IGestioneColloquiInizialiScuolaInfanzia> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.inizioAmbientamento) {
      this.inizioAmbientamento = new Date(params.inizioAmbientamento);
    }

    if (params.domande) {
      this.domande = params.domande.map((value) => new Domanda(value));
    }
    if (params.orarioFrequenzaRichiesto) {
      this.orarioFrequenzaRichiesto = params.orarioFrequenzaRichiesto.map(
        (value) => new GiornoSettimanaStruttura(value),
      );
    }
  }

  static InitContent(strutturaEducativaSelezionataInContesto: StrutturaEducativa): GestioneColloquiInizialiScuolaInfanzia {
    const content = new GestioneColloquiInizialiScuolaInfanzia();

    const testiDomande = [
      // Sezione "Dati anagrafici"
      'Per favorire la partecipazione delle famiglie agli incontri/iniziative che il servizio educativo propone, quale fascia oraria preferite (prima/dopo cena)?',

      // Sezione "Domande famiglia"
      'Volete raccontarci del/la vostro/a bambino/a?',
      'Come si relaziona con le persone che frequenta abitualmente? (fratelli, nonni,ecc.)',
      'Come trascorre la giornata il/la vostro/a bambino/a? Con chi gioca abitualmente? (adulti, bambini, ecc…)',
      'Quali relazioni ha con i coetanei?',
      'Come si relaziona con le persone che non conosce?',
      'Quali modalità possono favorire il coinvolgimento e la relazione con il/la vostro/a bambino/a?',
      'Ci sono dei gesti abituali che usate durante il saluto? ',
      'Ci sono oggetti ai quali è particolarmente affezionato/a che possono favorire il suo star bene a scuola?',
      'Quali spazi privilegia per giocare e quali sono i suoi giochi preferiti?',
      'È interessato/a a qualcosa in particolare? (ad esempio oggetti insoliti, musica, ecc…)',
      'Come gioca di solito: in modo autonomo o preferisce la presenza di adulti o bambini?',
      'Che cosa mangia abitualmente il bambino/a? Ci sono cibi che ama particolarmente o che rifiuta? Ha intolleranze alimentari o allergie?',
      'Quali autonomie possiede a tavola?',
      'Quali sono le vostre abitudini familiari durante colazione/pranzo/cena?',
      'Dove dorme di solito il/la bambino/a e che abitudini/rituali ha al momento del sonno?',
      'Come comunica verbalmente? (di solito usa le parole da sole o insieme ai gesti? Usa parole-frasi o costruisce discorsi?) ',
      'Quali aspettative o timori avete rispetto all’esperienza alla scuola dell’infanzia?',
      'Avete dei suggerimenti da darci per favorire l’inizio di questa esperienza?',
      'Ci sono altre informazioni che volete aggiungere?',
    ];

    content.domande = testiDomande.map((value, index) => {
      return new Domanda({
        codiceDomanda: `GestioneColloquiInizialiScuolaInfanzia_${index}`,
        rispostaDomanda: '',
        testoDomanda: value,
      });
    });
    content.orarioFrequenzaRichiesto = strutturaEducativaSelezionataInContesto.orarioApertura.map((giornoConfiguratoPerStruttura) => {
      return new GiornoSettimanaStruttura(giornoConfiguratoPerStruttura);
    });

    return content;
  }

  deconstructor(): IGestioneColloquiInizialiScuolaInfanzia {
    return Object.assign({}, this);
  }
}
