import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChildren } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { SezioneAnnoScolasticoPeriodoFormativo, StrumentoDiRegistrazioneArchivio, StrutturaEducativa } from '@gpi/lbl/shared/entity';
import { RilevazioneDocumentazioneProgettualeServiziEducativi, RilevazioneGenericRow } from 'libs/lbl/shared/entity/src/lib/base/StrumentiRegistrazione/rilevazione/RilevazioneDocumentazioneProgettualeServiziEducativi';
import { Observable } from 'rxjs';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-rilevazione-documentazione-progettuale-servizi-educativi',
  templateUrl: './rilevazione-documentazione-progettuale-servizi-educativi.component.html',
  styleUrls: ['./rilevazione-documentazione-progettuale-servizi-educativi.component.scss']
})
export class RilevazioneDocumentazioneProgettualeServiziEducativiComponent implements OnInit {
  @Input() strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input() strutturaEducativa: StrutturaEducativa;
  @Input() submit$: Observable<void>;
  @Output() updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();
  @ViewChildren(MatTable) _matTables;

  sum = 0;
  form: FormGroup;
  ROWS = ['DocumentazioniProgettuali', 'DocumentazioneQuotidiana', 'IncontriDiSezione', 'DocumantazioneFamiglie'];
  sezioniArray: SezioneAnnoScolasticoPeriodoFormativo[] = [];
  displayedColumns = ['DocumentazioniProgettuali', 'DocumentazioneQuotidiana', 'IncontriDiSezione', 'DocumantazioneFamiglie'];

  getDocForm(sezione: string, titolo: string) {
    return this.form.get([sezione, titolo]) as FormArray;
  }

  get sezioniForm() {
    return this.form.get('sezioni') as FormArray;
  }

  getContent(index: number): RilevazioneDocumentazioneProgettualeServiziEducativi {
    let content = this.strumentiDiRegistrazioneArchivio[index].content as RilevazioneDocumentazioneProgettualeServiziEducativi;
    if (!content) {
      content = RilevazioneDocumentazioneProgettualeServiziEducativi.InitContent();
    }

    return content;
  }

  constructor() { }

  ngOnInit(): void {
    // this.strumentiDiRegistrazioneArchivio = this.strumentiDiRegistrazioneArchivio.slice(1)
    this.strumentiDiRegistrazioneArchivio.forEach((el) => el.sezioni.forEach((sezione) => this.sezioniArray.push(sezione)))

    this.form = this.buildForm();

    this.form.valueChanges.subscribe(() => {
      this.submit(this.form);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      changes.strumentiDiRegistrazioneArchivio.currentValue.forEach((element, i) => {
        if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.getContent(i)) {
          this.form = this.updateForm(this.getContent(i));
        }
      });
    }
  }

  buildForm(): FormGroup {
    const formGroup: FormGroup = new FormGroup({});

    this.sezioniArray.forEach((el, i) => {
      const form = new FormGroup({
        DocumentazioniProgettuali: this.buildFormRilevazioneDocumentazioneProgettualeServiziEducativi(this.getContent(i).DocumentazioniProgettuali),
        NumTotDocumentazioniProgettuali: new FormControl(this.getContent(i).NumTotDocumentazioniProgettuali || 0),
        DocumentazioneQuotidiana: this.buildFormRilevazioneDocumentazioneProgettualeServiziEducativi(this.getContent(i).DocumentazioneQuotidiana),
        NumTotDocumentazioneQuotidiana: new FormControl(this.getContent(i).NumTotDocumentazioneQuotidiana || 0),
        IncontriDiSezione: this.buildFormRilevazioneDocumentazioneProgettualeServiziEducativi(this.getContent(i).IncontriDiSezione),
        NumTotIncontriDiSezione: new FormControl(this.getContent(i).NumTotIncontriDiSezione || 0),
        DocumantazioneFamiglie: this.buildFormRilevazioneDocumentazioneProgettualeServiziEducativi(this.getContent(i).DocumantazioneFamiglie),
        NumTotDocumantazioneFamiglie: new FormControl(this.getContent(i).NumTotDocumantazioneFamiglie || 0)
      }, { updateOn: 'blur' });

      const formControlFields = [];
      formControlFields.push({ name: el.nome })

      formControlFields.forEach(f => formGroup.addControl(f.name, form));
      this.form = new FormGroup({ sezioni: formGroup });

    })

    return formGroup;
  }



  buildFormRilevazioneDocumentazioneProgettualeServiziEducativi(rilevazioneGenericRow: RilevazioneGenericRow[]): FormArray {
    const form = new FormArray(rilevazioneGenericRow.map((value) => this.buildFormRilevazioneGenericRow(value)));

    return form;
  }

  buildFormRilevazioneGenericRow(rilevazioneGenericRow: RilevazioneGenericRow): FormGroup {
    const form = new FormGroup({
      numeroAttivitaEffettaute: new FormControl(rilevazioneGenericRow.numeroAttivitaEffettaute),
      descrizioneAttivita: new FormControl(rilevazioneGenericRow.descrizioneAttivita)
    });

    return form;
  }

  updateForm(entity: RilevazioneDocumentazioneProgettualeServiziEducativi): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  addrow(sezione: string, titolo: string) {
    let newCell = this.form.get([sezione, titolo]) as FormArray
    newCell.push(this.buildFormRilevazioneGenericRow(new RilevazioneGenericRow()))
  }

  removeRow(sezione: string, row: string, index: number) {
    let removeCell = this.form.get([sezione, row]) as FormArray
    removeCell.removeAt(index);

    this.calcolaTotaleNumAttivita(sezione, row)
  }

  calcolaTotaleNumAttivita(sezione: string, row: string) {
    let newCell = this.form.get([sezione, row]).value
    let arrayNum = []

    if (newCell.length) {
      newCell.forEach((el: RilevazioneGenericRow) => {
        arrayNum.push(el.numeroAttivitaEffettaute)
      })
      this.sum = arrayNum.reduce((acc, cur) => acc + cur, 0);
      this.form.get([sezione, 'NumTot' + row]).setValue(this.sum)
    } else {
      this.form.get([sezione, 'NumTot' + row]).setValue(null)
    }

  }

  submit(form: FormGroup) {
    if (form.valid) {

      this.sezioniArray.forEach((sezione, i) => {
        this.strumentiDiRegistrazioneArchivio[i].content = form.controls[sezione.nome].value

      });
      // console.log(this.strumentiDiRegistrazioneArchivio)
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.strumentiDiRegistrazioneArchivio);

    }
  }
}
