<ng-container *transloco="let t; read: 'verbaleValutazioneVerifica.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <mat-form-field fxFill appearance="outline">
      <mat-chip-list #chipList formControlName="elencoPresenti">
        <mat-chip color="primary" *ngFor="let presente of formElencoPresenti.value; let i = index" [selectable]="true"
          [removable]="true" (removed)="removeElencoPresenti(i)">
          {{ presente }}
          <mat-icon svgIcon="delete" matChipRemove></mat-icon>
        </mat-chip>

        <input placeholder="{{t('elencoPresenti')}}" [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="elencoPresentiSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addElencoPresenti($event)" />
      </mat-chip-list>
    </mat-form-field>

    <div fxLayout="column" fxLayoutGap="1em" formArrayName="domande">
      <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
        <mat-form-field fxFill appearance="outline" [formGroupName]="i">
          <mat-label>{{ t('domanda.' + domanda?.value?.codiceDomanda) }}</mat-label>
          <textarea matInput formControlName="rispostaDomanda"></textarea>
        </mat-form-field>
      </ng-container>
    </div>
  </form>
</ng-container>
