<ng-container *transloco="let t; read: 'sipiSezioneStrumentoProgettuale.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>
      <!-- tabella obiettivi -->
      <div class="mat-elevation-z1">
        <p>{{t('tableObiettivi.titolo')}}</p>

        <table mat-table [dataSource]="obiettivi.controls" formArrayName="obiettivi">

          <ng-container matColumnDef="areaDiLavoro">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableObiettivi.column.areaLavoro')}}
            </th>
            <td mat-cell *matCellDef="let element;">
              {{ t('tableObiettivi.areeDiLavoro.'+ element?.value?.areaDiLavoro) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="obiettiviGenerali">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableObiettivi.column.obiettiviGenerali')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field>
                <textarea matInput formControlName="obiettivoGenerale" [readonly]="!isEnabledBody"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="obiettiviSpecifici">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableObiettivi.column.obiettiviSpecifici')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field>
                <textarea matInput formControlName="obiettivoSpecifico" [readonly]="!isEnabledBody"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="descrittoriVerifica">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableObiettivi.column.descrittoriVerifica')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field>
                <textarea matInput formControlName="descrittoriVerifica" [readonly]="!isEnabledBody"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="obiettiviDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: obiettiviDisplayedColumns"></tr>
        </table>
      </div>
      <!-- strategia generale -->
      <mat-form-field appearance="outline" fxFill>
        <mat-label>{{t('strategieGenerali')}}</mat-label>
        <textarea matInput formControlName="noteStrategie" [readonly]="!isEnabledBody"></textarea>
      </mat-form-field>

      <!-- area Di lavoro attività -->





      <mat-accordion [multi]="true">
        {{t('titlecollapse')}}

        <!-- area di lavoro autonomie -->
        <mat-expansion-panel [expanded]="isExpand('areaDiLavoroAutonomie')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p>{{t('tableAttivita.title')}}</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxFlex class="mat-elevation-z1">
            <button mat-raised-button (click)="addrow('areaDiLavoroAutonomie',1)">{{t('aggiungiRiga')}}</button>
            <table mat-table [dataSource]="getAreaDiLavoro('areaDiLavoroAutonomie').controls"
              formArrayName="areaDiLavoroAutonomie">
              <ng-container matColumnDef="tempo">
                <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tableAttivita.column.tempo')"></th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="tempo" [readonly]="!isEnabledBody"></textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tableAttivita.column.nome')"></th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="nome" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="descrizione">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.descrizione')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="descrizione" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="strategie">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.strategie')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="strategie" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="stimaDurata">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.stimaDurata')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="stimaDurata" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="materiali">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.materiali')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="materiali" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="spazio">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.spazio')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="spazio" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="adultiPropositori">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.adultiPropositori')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="adultiPropositori" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="AttivitaDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: AttivitaDisplayedColumns"></tr>
            </table>
          </div>
        </mat-expansion-panel>

        <!-- area Di Lavoro Linguaggi e Comunicazioni -->
        <mat-expansion-panel [expanded]="isExpand('areaDiLavoroLinguaggiComunicazioni')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p>{{t('collapse2')}}</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mat-elevation-z1">
            <button mat-raised-button
              (click)="addrow('areaDiLavoroLinguaggiComunicazioni',2)">{{t('aggiungiRiga')}}</button>
            <table mat-table [dataSource]="getAreaDiLavoro('areaDiLavoroLinguaggiComunicazioni').controls"
              formArrayName="areaDiLavoroLinguaggiComunicazioni">
              <ng-container matColumnDef="tempo">
                <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tableAttivita.column.tempo')"></th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="tempo" [readonly]="!isEnabledBody"></textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tableAttivita.column.nome')"></th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="nome" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="descrizione">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.descrizione')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="descrizione" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="strategie">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.strategie')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="strategie" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="stimaDurata">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.stimaDurata')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="stimaDurata" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="materiali">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.materiali')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="materiali" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="spazio">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.spazio')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="spazio" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="adultiPropositori">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.adultiPropositori')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="adultiPropositori" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="AttivitaDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: AttivitaDisplayedColumns"></tr>
            </table>
          </div>
        </mat-expansion-panel>

        <!-- area Di Lavoro Relazioni -->
        <mat-expansion-panel [expanded]="isExpand('areaDiLavoroRelazioni')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p>{{t('collapse3')}}</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mat-elevation-z1">
            <button mat-raised-button (click)="addrow('areaDiLavoroRelazioni',3)">{{t('aggiungiRiga')}}</button>
            <table mat-table [dataSource]="getAreaDiLavoro('areaDiLavoroRelazioni').controls"
              formArrayName="areaDiLavoroRelazioni">
              <ng-container matColumnDef="tempo">
                <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tableAttivita.column.tempo')"></th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="tempo" [readonly]="!isEnabledBody"></textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tableAttivita.column.nome')"></th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="nome" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="descrizione">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.descrizione')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="descrizione" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="strategie">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.strategie')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="strategie" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="stimaDurata">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.stimaDurata')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="stimaDurata" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="materiali">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.materiali')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="materiali" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="spazio">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.spazio')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="spazio" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="adultiPropositori">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.adultiPropositori')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="adultiPropositori" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="AttivitaDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: AttivitaDisplayedColumns"></tr>
            </table>
          </div>
        </mat-expansion-panel>

        <!-- area Di Lavoro Sapere e Far Sapere -->
        <mat-expansion-panel [expanded]="isExpand('areaDiLavoroSapereFarSapere')">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <p>{{t('collapse4')}}</p>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="mat-elevation-z1">
            <button mat-raised-button (click)="addrow('areaDiLavoroSapereFarSapere',4)">{{t('aggiungiRiga')}}</button>
            <table mat-table [dataSource]="getAreaDiLavoro('areaDiLavoroSapereFarSapere').controls"
              formArrayName="areaDiLavoroSapereFarSapere">
              <ng-container matColumnDef="tempo">
                <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tableAttivita.column.tempo')"></th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="tempo" [readonly]="!isEnabledBody"></textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="nome">
                <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tableAttivita.column.nome')"></th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="nome" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="descrizione">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.descrizione')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="descrizione" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="strategie">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.strategie')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="strategie" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="stimaDurata">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.stimaDurata')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="stimaDurata" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="materiali">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.materiali')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="materiali" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="spazio">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.spazio')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="spazio" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <ng-container matColumnDef="adultiPropositori">
                <th mat-header-cell *matHeaderCellDef>{{t('tableAttivita.column.adultiPropositori')}}</th>
                <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
                  <mat-form-field>
                    <textarea matInput formControlName="adultiPropositori" [readonly]="!isEnabledBody"> </textarea>
                  </mat-form-field>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="AttivitaDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: AttivitaDisplayedColumns"></tr>
            </table>
          </div>
        </mat-expansion-panel>

      </mat-accordion>


      <div class="mat-elevation-z1">
        <p>{{t('tableObiettivi.titolo')}}</p>

        <table mat-table [dataSource]="verifiche.controls" formArrayName="verifiche">

          <ng-container matColumnDef="areaDiLavoro">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableObiettivi.column.areaLavoro')}}
            </th>
            <td mat-cell *matCellDef="let element;">
              {{ t('tableObiettivi.areeDiLavoro.'+ element?.value?.areaDiLavoro) }}
            </td>
          </ng-container>

          <ng-container matColumnDef="obiettiviGenerali">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableObiettivi.column.obiettiviGenerali')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field>
                <textarea matInput formControlName="obiettivoGenerale" [readonly]="!isEnabledBody"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="obiettiviSpecifici">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableObiettivi.column.obiettiviSpecifici')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field>
                <textarea matInput formControlName="obiettivoSpecifico" [readonly]="!isEnabledBody"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="descrittoriVerifica">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableObiettivi.column.descrittoriVerifica')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field>
                <textarea matInput formControlName="descrittoriVerifica" [readonly]="!isEnabledBody"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="verifica">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableObiettivi.column.verifica')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field>
                <textarea matInput formControlName="verifica" [readonly]="!isEnabledBody"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="verificaDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: verificaDisplayedColumns"></tr>
        </table>
      </div>

      <!-- Principali cambiamenti -->

      <mat-form-field appearance="outline" fxFill>
        <mat-label>{{t('principaliCambiamenti')}}</mat-label>
        <textarea matInput formControlName="noteCambiamenti" [readonly]="!isEnabledBody"></textarea>
      </mat-form-field>
    </div>
  </form>
</ng-container>
