import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AllegatiService } from '@gpi/lbl/app/data-access/allegati';
import { AuthService } from '@gpi/lbl/app/data-access/auth';
import {
  Allegato,
  EnumAllegatiTabellaDiProvenienza,
  EnumTipologiaAllegato,
  PersonaFisica,
  Utente,
} from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-layout-shared-avatar',
  templateUrl: 'layout-shared-avatar.component.html',
  styleUrls: ['layout-shared-avatar.component.scss'],
})
export class LayoutSharedAvatarComponent {
  @Input()
  person: PersonaFisica;
  @Input()
  size: 'small' | 'medium' = 'small';
  @Input()
  editable = false;

  @Output()
  changed: EventEmitter<Allegato> = new EventEmitter();

  newAvatar: Allegato;

  get avatar(): Allegato {
    return (
      this.person &&
      this.person.allegati &&
      this.person.allegati.find(
        (allegato) =>
          allegato.tipologia === EnumTipologiaAllegato.ImmaginePersonaFisica,
      )
    );
  }
  set avatar(allegato: Allegato) {
    this.changed.emit(allegato);
  }

  get avatarStyle(): string {
    const width = `width: ${this.size === 'small' ? '35' : '100'}px;`;
    const height = `height: ${this.size === 'small' ? '35' : '100'}px;`;
    const backgroundImageUrl = this.newAvatar ? `url(${this.newAvatar?.link})` : `url(${this.avatar?.link})`;
    const backgroundImage =
      (this.avatar && this.avatar?.link) || (this.newAvatar && this.newAvatar?.link)
        ? `background-image: ${backgroundImageUrl}`
        : '';

    return `${width} ${height} ${backgroundImage}`;
  }

  get textClass(): string {
    return `${this.size === 'small' ? 'mat-subtitle' : 'mat-title'}`;
  }

  constructor(private allegatiService: AllegatiService) { }

  intialsPerson(): string {
    if (this.person && this.person.nome && this.person.cognome) {
      return this.person.nome.charAt(0) + '' + this.person.cognome.charAt(0);
    }
  }

  upload(fileList: FileList) {
    for (const key in fileList) {
      if (fileList.hasOwnProperty(key)) {
        const file = fileList[key];
        const allegato = new Allegato({
          tableRowId: this.person.rowId,
          tabellaDiProvenienza: EnumAllegatiTabellaDiProvenienza.PersoneFisiche,
          tipologia: EnumTipologiaAllegato.ImmaginePersonaFisica,
        });

        this.allegatiService.upload(allegato, file).subscribe((entity) => {
          this.newAvatar = entity;
        });
      }
    }
  }
}
