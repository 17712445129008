import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import {
  IRuoloApplicativo,
  ISezioneAnnoScolasticoPeriodoFormativo,
  IStrutturaEducativa,
  IUtente,
  StrutturaEducativa,
  EnumTipologiaRuoloApplicativo,
} from '@gpi/lbl/shared/entity';

export interface AuthState {
  utente: IUtente;
  utenteId: number;
  ruoloApplicativo: IRuoloApplicativo;
  ruoloApplicativoId: number;
  strutturaEducativa: IStrutturaEducativa;
  strutturaEducativaId: number;
  sezioneAnnoScolasticoPeriodoFormativo: ISezioneAnnoScolasticoPeriodoFormativo;
  sezioneAnnoScolasticoPeriodoFormativoId: number;

  utenteImpersonificato: IUtente;
  utenteImpersonificatoId: number;
  ruoloApplicativoImpersonificato: IRuoloApplicativo;
  ruoloApplicativoImpersonificatoId: number;
  strutturaEducativaImpersonificato: IStrutturaEducativa;
  strutturaEducativaImpersonificatoId: number;
  sezioneAnnoScolasticoPeriodoFormativoImpersonificato: ISezioneAnnoScolasticoPeriodoFormativo;
  sezioneAnnoScolasticoPeriodoFormativoImpersonificatoId: number;

  utenteParente: IUtente;
  utenteParenteId: number;
  ruoloApplicativoParente: IRuoloApplicativo;
  ruoloApplicativoParenteId: number;
  strutturaEducativaParente: IStrutturaEducativa;
  strutturaEducativaParenteId: number;
  sezioneAnnoScolasticoPeriodoFormativoParente: ISezioneAnnoScolasticoPeriodoFormativo;
  sezioneAnnoScolasticoPeriodoFormativoParenteId: number;

  utenteToken: string;
  utenteImpersonificatoToken: string;
  utenteParenteToken: string;
}

export function createInitialState(): AuthState {
  return {
    utente: null,
    utenteId: null,
    ruoloApplicativo: null,
    ruoloApplicativoId: null,
    strutturaEducativa: null,
    strutturaEducativaId: null,
    sezioneAnnoScolasticoPeriodoFormativo: null,
    sezioneAnnoScolasticoPeriodoFormativoId: null,

    utenteImpersonificato: null,
    utenteImpersonificatoId: null,
    ruoloApplicativoImpersonificato: null,
    ruoloApplicativoImpersonificatoId: null,
    strutturaEducativaImpersonificato: null,
    strutturaEducativaImpersonificatoId: null,
    sezioneAnnoScolasticoPeriodoFormativoImpersonificato: null,
    sezioneAnnoScolasticoPeriodoFormativoImpersonificatoId: null,

    utenteParente: null,
    utenteParenteId: null,
    ruoloApplicativoParente: null,
    ruoloApplicativoParenteId: null,
    strutturaEducativaParente: null,
    strutturaEducativaParenteId: null,
    sezioneAnnoScolasticoPeriodoFormativoParente: null,
    sezioneAnnoScolasticoPeriodoFormativoParenteId: null,

    utenteToken: null,
    utenteImpersonificatoToken: null,
    utenteParenteToken: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lbl-app-data-access-auth', resettable: true })
export class AuthStore extends Store<AuthState> {
  constructor() {
    super(createInitialState());
  }

  setUtente(utente: IUtente) {
    const state = this.getValue();
    const ruoloApplicativoId = state.ruoloApplicativoId;

    this.update({
      utente,

      utenteId: utente?.id,
      utenteToken: utente?.token,
    });

    let ruoloApplicativo: IRuoloApplicativo;

    if (utente?.ruoli) {
      if (utente.ruoli.length === 1) {
        ruoloApplicativo = utente.ruoli[0];
      } else if (utente.ruoli.length > 1) {
        ruoloApplicativo = utente.ruoli.find((value) => value.id === ruoloApplicativoId);
      }

      if (ruoloApplicativo) {
        this.setRuoloApplicativo(ruoloApplicativo);
      }
    }
  }
  setRuoloApplicativo(ruoloApplicativo: IRuoloApplicativo) {
    const state = this.getValue();
    const strutturaEducativaId = state.strutturaEducativaId;
    const sezioneAnnoScolasticoPeriodoFormativoId = state.sezioneAnnoScolasticoPeriodoFormativoId;

    this.update({
      ruoloApplicativo,

      ruoloApplicativoId: ruoloApplicativo?.id,
    });

    let strutturaEducativa: IStrutturaEducativa;
    let sezioneAnnoScolasticoPeriodoFormativo: ISezioneAnnoScolasticoPeriodoFormativo;

    if (ruoloApplicativo?.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso) {
      strutturaEducativa = strutturaEducativaId
        ? ruoloApplicativo.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso.find((value) => value.id === strutturaEducativaId)
        : ruoloApplicativo.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso[0];

      if (strutturaEducativa) {
        this.setStrutturaEducativa(strutturaEducativa);
      }
    }

    if (ruoloApplicativo?.coinvoltoInSezioniPerAnnoScolasticoInCorso) {
      sezioneAnnoScolasticoPeriodoFormativo = sezioneAnnoScolasticoPeriodoFormativoId
        ? ruoloApplicativo.coinvoltoInSezioniPerAnnoScolasticoInCorso.find((value) => value.id === sezioneAnnoScolasticoPeriodoFormativoId)
        : this._getSezioneAnnoScolasticoInCorso(ruoloApplicativo);

      if (sezioneAnnoScolasticoPeriodoFormativo) {
        this.setSezioneAnnoScolasticoPeriodoFormativo(sezioneAnnoScolasticoPeriodoFormativo);
      }
    }
  }
  setStrutturaEducativa(strutturaEducativa: IStrutturaEducativa) {
    this.update({
      strutturaEducativa,

      strutturaEducativaId: strutturaEducativa?.id,
    });
  }
  setSezioneAnnoScolasticoPeriodoFormativo(sezioneAnnoScolasticoPeriodoFormativo: ISezioneAnnoScolasticoPeriodoFormativo) {
    this.update({
      sezioneAnnoScolasticoPeriodoFormativo,

      sezioneAnnoScolasticoPeriodoFormativoId: sezioneAnnoScolasticoPeriodoFormativo?.id,
    });
  }

  setUtenteImpersonificato(utente: IUtente) {
    const state = this.getValue();
    const ruoloApplicativoId = state.ruoloApplicativoImpersonificatoId;

    this.update({
      utenteImpersonificato: utente,

      utenteImpersonificatoId: utente?.id,
      utenteImpersonificatoToken: utente?.token,
    });

    let ruoloApplicativo: IRuoloApplicativo;

    if (utente?.ruoli) {
      if (utente.ruoli.length === 1) {
        ruoloApplicativo = utente.ruoli[0];
      } else if (utente.ruoli.length > 1) {
        ruoloApplicativo = utente.ruoli.find((value) => value.id === ruoloApplicativoId);
      }

      if (ruoloApplicativo) {
        this.setRuoloApplicativoImpersonificato(ruoloApplicativo);
      }
    }
  }
  setRuoloApplicativoImpersonificato(ruoloApplicativo: IRuoloApplicativo) {
    const state = this.getValue();
    const strutturaEducativaId = state.strutturaEducativaImpersonificatoId;
    const sezioneAnnoScolasticoPeriodoFormativoId = state.sezioneAnnoScolasticoPeriodoFormativoImpersonificatoId;

    this.update({
      ruoloApplicativoImpersonificato: ruoloApplicativo,

      ruoloApplicativoImpersonificatoId: ruoloApplicativo?.id,
    });

    let strutturaEducativa: IStrutturaEducativa;
    let sezioneAnnoScolasticoPeriodoFormativo: ISezioneAnnoScolasticoPeriodoFormativo;

    if (ruoloApplicativo?.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso) {
      strutturaEducativa = strutturaEducativaId
        ? ruoloApplicativo.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso.find((value) => value.id === strutturaEducativaId)
        : ruoloApplicativo.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso[0];

      if (strutturaEducativa) {
        this.setStrutturaEducativaImpersonificato(strutturaEducativa);
      }
    }

    if (ruoloApplicativo?.coinvoltoInSezioniPerAnnoScolasticoInCorso) {
      sezioneAnnoScolasticoPeriodoFormativo = sezioneAnnoScolasticoPeriodoFormativoId
        ? ruoloApplicativo.coinvoltoInSezioniPerAnnoScolasticoInCorso.find((value) => value.id === sezioneAnnoScolasticoPeriodoFormativoId)
        : this._getSezioneAnnoScolasticoInCorso(ruoloApplicativo);

      if (sezioneAnnoScolasticoPeriodoFormativo) {
        this.setSezioneAnnoScolasticoPeriodoFormativoImpersonificato(sezioneAnnoScolasticoPeriodoFormativo);
      }
    }
  }

  _getSezioneAnnoScolasticoInCorso(ruoloApplicativo:IRuoloApplicativo){
    if(ruoloApplicativo.tipo != "RuoloParentiBambino"){
      //come prima
      return ruoloApplicativo.coinvoltoInSezioniPerAnnoScolasticoInCorso[0];
    }else{
      const now = new Date();
      // è un parente bambino, ci sono uno o più anni scolastici
      let sezioniAttive = 
        ruoloApplicativo.coinvoltoInSezioniPerAnnoScolasticoInCorso.filter((sezione)=>{
          return new Date(sezione.annoScolasticoPeriodoFormativo.dataInizio).getTime() <= now.getTime() && 
          new Date(sezione.annoScolasticoPeriodoFormativo.dataFine).getTime()>= now.getTime();
        });
      if(sezioniAttive == undefined || sezioniAttive.length == 0){
        // ritorno la prima, lascio il comportamento invariato
        return ruoloApplicativo.coinvoltoInSezioniPerAnnoScolasticoInCorso[0];
      }else{
        return sezioniAttive[0];
      }

    }

  }

  setStrutturaEducativaImpersonificato(strutturaEducativa: IStrutturaEducativa) {
    this.update({
      strutturaEducativaImpersonificato: strutturaEducativa,

      strutturaEducativaImpersonificatoId: strutturaEducativa?.id,
    });
  }

  setSezioneAnnoScolasticoPeriodoFormativoImpersonificato(sezioneAnnoScolasticoPeriodoFormativo: ISezioneAnnoScolasticoPeriodoFormativo) {
    this.update({
      sezioneAnnoScolasticoPeriodoFormativoImpersonificato: sezioneAnnoScolasticoPeriodoFormativo,

      sezioneAnnoScolasticoPeriodoFormativoImpersonificatoId: sezioneAnnoScolasticoPeriodoFormativo?.id,
    });
  }

  setUtenteParente(utente: IUtente) {
    const state = this.getValue();
    const ruoloApplicativoId = state.ruoloApplicativoParenteId;

    this.update({
      utenteParente: utente,

      utenteParenteId: utente?.id,
      utenteParenteToken: utente?.token,
    });

    let ruoloApplicativo: IRuoloApplicativo;

    if (utente?.ruoli) {
      if (utente.ruoli.length === 1) {
        ruoloApplicativo = utente.ruoli[0];
      } else if (utente.ruoli.length > 1) {
        ruoloApplicativo = utente.ruoli.find((value) => value.id === ruoloApplicativoId);
      } else {
        ruoloApplicativo = utente.ruoli.find((value) => value.tipo === EnumTipologiaRuoloApplicativo.ParentiBambino)
      }

      if (ruoloApplicativo) {
        this.setRuoloApplicativoParente(ruoloApplicativo);
      }
    }
  }
  setRuoloApplicativoParente(ruoloApplicativo: IRuoloApplicativo) {
    const state = this.getValue();
    const strutturaEducativaId = state.strutturaEducativaParenteId;
    const sezioneAnnoScolasticoPeriodoFormativoId = state.sezioneAnnoScolasticoPeriodoFormativoParenteId;

    this.update({
      ruoloApplicativoParente: ruoloApplicativo,

      ruoloApplicativoParenteId: ruoloApplicativo?.id,
    });

    let strutturaEducativa: IStrutturaEducativa;
    let sezioneAnnoScolasticoPeriodoFormativo: ISezioneAnnoScolasticoPeriodoFormativo;

    if (ruoloApplicativo?.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso) {
      strutturaEducativa = strutturaEducativaId
        ? ruoloApplicativo.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso.find((value) => value.id === strutturaEducativaId)
        : ruoloApplicativo.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso[0];

      if (strutturaEducativa) {
        this.setStrutturaEducativaParente(strutturaEducativa);
      }
    }

    if (ruoloApplicativo?.coinvoltoInSezioniPerAnnoScolasticoInCorso) {
      sezioneAnnoScolasticoPeriodoFormativo = sezioneAnnoScolasticoPeriodoFormativoId
        ? ruoloApplicativo.coinvoltoInSezioniPerAnnoScolasticoInCorso.find((value) => value.id === sezioneAnnoScolasticoPeriodoFormativoId)
        : ruoloApplicativo.coinvoltoInSezioniPerAnnoScolasticoInCorso[0];

      if (sezioneAnnoScolasticoPeriodoFormativo) {
        this.setSezioneAnnoScolasticoPeriodoFormativoParente(sezioneAnnoScolasticoPeriodoFormativo);
      }
    }
  }
  setStrutturaEducativaParente(strutturaEducativa: IStrutturaEducativa) {
    this.update({
      strutturaEducativaParente: strutturaEducativa,

      strutturaEducativaParenteId: strutturaEducativa?.id,
    });
  }
  setSezioneAnnoScolasticoPeriodoFormativoParente(sezioneAnnoScolasticoPeriodoFormativo: ISezioneAnnoScolasticoPeriodoFormativo) {
    this.update({
      sezioneAnnoScolasticoPeriodoFormativoParente: sezioneAnnoScolasticoPeriodoFormativo,

      sezioneAnnoScolasticoPeriodoFormativoParenteId: sezioneAnnoScolasticoPeriodoFormativo?.id,
    });
  }

  setUtenteCorrente(utente: IUtente) {
    const state = this.getValue();
    const isImpersonating = !!state.utenteImpersonificato;
    const setUtenteCorrenteFn = !isImpersonating ? this.setUtente : this.setUtenteImpersonificato;

    setUtenteCorrenteFn(utente);
  }
  setRuoloApplicativoCorrente(ruoloApplicativo: IRuoloApplicativo) {
    const state = this.getValue();
    const isImpersonating = !!state.utenteImpersonificato;

    !isImpersonating ? this.setRuoloApplicativo(ruoloApplicativo) : this.setRuoloApplicativoImpersonificato(ruoloApplicativo);
  }
  setStrutturaEducativaCorrente(strutturaEducativa: IStrutturaEducativa) {
    const state = this.getValue();
    const isImpersonating = !!state.utenteImpersonificato;

    !isImpersonating ? this.setStrutturaEducativa(strutturaEducativa) : this.setStrutturaEducativaImpersonificato(strutturaEducativa);
  }
  setSezioneAnnoScolasticoPeriodoFormativoCorrente(sezioneAnnoScolasticoPeriodoFormativo: ISezioneAnnoScolasticoPeriodoFormativo) {
    const state = this.getValue();
    const isImpersonating = !!state.utenteImpersonificato;

    !isImpersonating ? this.setSezioneAnnoScolasticoPeriodoFormativo(sezioneAnnoScolasticoPeriodoFormativo) : this.setSezioneAnnoScolasticoPeriodoFormativoImpersonificato(sezioneAnnoScolasticoPeriodoFormativo);
  }
}
