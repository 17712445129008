import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@gpi/lbl/app/data-access/auth';
import { ENVIRONMENT, IEnvironment } from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';

@Injectable()
export class StrutturaEducativaHeaderInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    @Inject(ENVIRONMENT)
    private environment: IEnvironment,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const apiUrl = this.environment.urls.api;
    const strutturaEducativa = this.authService.query.getValue()
      .strutturaEducativa;
    const strutturaEducativaImpersonificato = this.authService.query.getValue()
      .strutturaEducativaImpersonificato;

    const strutturaEducativaId = strutturaEducativaImpersonificato
      ? strutturaEducativaImpersonificato.id.toString()
      : strutturaEducativa
      ? strutturaEducativa.id.toString()
      : null;

    if (req.url.startsWith(apiUrl) && strutturaEducativaId) {
      req = req.clone({
        setHeaders: {
          strutturaEducativaId,
        },
      });
    }

    return next.handle(req);
  }
}
