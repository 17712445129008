import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { IStrumentoDiRegistrazioneArchivio } from '@gpi/lbl/shared/entity';

export interface StrumentiRegistrazioneArchivioState
  extends EntityState<IStrumentoDiRegistrazioneArchivio, number>,
  ActiveState<number> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lbl-app-data-access-strumenti-registrazione-archivio' })
export class StrumentiRegistrazioneArchivioStore extends EntityStore<StrumentiRegistrazioneArchivioState> {
  constructor() {
    super();
  }
}
