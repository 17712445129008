import { EnumStrumentoDiRegistrazioneTemplateBodyCode } from "../StrumentoDiRegistrazioneTemplateBody";

export enum EnumConformitaCriterioDimensione {
    Conforme,
    NonConforme
}

export enum EnumGradoValutazioneRequisito {
    NON_SODDISFATTO_GROSSE_CRITICITA_NECESSITA_INTERVENTO_IMMEDIATO = 0,
    NON_SODDISFATTO__CRITICITA_NECESSITA_AZIONI_CORRETTIVE = 1,
    SODDISFATTO_SUGGERISCONO_AZIONI_DI_MIGLIORAMENTO = 2,
    SODDISFATTO_E_MIGLIORATO = 3,
    SODDISFATTO_E_AMPIAMENTE_MIGLIORATO = 4
}

export interface IItemMonitoraggioValutazione {
    titoloItemMonitoraggioValutazione: string;
    dimensioneDiRiferimento: IDimensione;
    pesoItemMonitoraggioValutazionePercentuale: number;
    conformita: EnumConformitaCriterioDimensione;
    gradoSoddisfazioneRequisiti: EnumGradoValutazioneRequisito;
}

export class ItemMonitoraggioValutazione implements IItemMonitoraggioValutazione {
    titoloItemMonitoraggioValutazione: string;
    dimensioneDiRiferimento: Dimensione;
    pesoItemMonitoraggioValutazionePercentuale: number;
    conformita: EnumConformitaCriterioDimensione;
    gradoSoddisfazioneRequisiti: number;

    constructor(params: Partial<IItemMonitoraggioValutazione> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
        */
        if (params.dimensioneDiRiferimento) {
            this.dimensioneDiRiferimento = new Dimensione(params.dimensioneDiRiferimento);
        }
    }

    get pesoItemMonitoraggioValutazioneCifra(): number {
        return this.dimensioneDiRiferimento.puntiDimensione * this.pesoItemMonitoraggioValutazionePercentuale / 100;
    }

    get punteggio(): number {
        return this.conformita === EnumConformitaCriterioDimensione.Conforme ? this.pesoItemMonitoraggioValutazioneCifra : 0;
    }

    get valutazioneRequisito(): number {
        return this.dimensioneDiRiferimento.puntiDimensione * this.gradoSoddisfazioneRequisiti;
    }

    deconstructor(): IItemMonitoraggioValutazione {
        return Object.assign({}, this);
    }
}

export interface IDimensione {
    titoloDimensione: string;
    puntiDimensione: number;
    itemsMonitoraggioValutazione: IItemMonitoraggioValutazione[];
}

export class Dimensione {
    titoloDimensione: string;
    puntiDimensione: number;
    itemsMonitoraggioValutazione: ItemMonitoraggioValutazione[];

    constructor(params: Partial<IDimensione> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
        */
        if (params.itemsMonitoraggioValutazione) {
            this.itemsMonitoraggioValutazione = params.itemsMonitoraggioValutazione.map((value) => new ItemMonitoraggioValutazione(value));
        }
    }

    get totalePunteggioItemMonitoraggio(): number {
        return this.itemsMonitoraggioValutazione.reduce((a, { punteggio }) => a + punteggio, 0)
    }

    get totaleValutazioneItemMonitoraggio(): number {
        var sommaValutazioneiPerItem: number;
        sommaValutazioneiPerItem = this.itemsMonitoraggioValutazione.reduce((a, { valutazioneRequisito }) => a + valutazioneRequisito, 0);
        return (sommaValutazioneiPerItem / this.itemsMonitoraggioValutazione.length);
    }

    get conformitaDimensione(): EnumConformitaCriterioDimensione {
        return this.itemsMonitoraggioValutazione.some(
            element =>
                element.conformita.toString() === EnumConformitaCriterioDimensione[EnumConformitaCriterioDimensione.NonConforme]) ? EnumConformitaCriterioDimensione.NonConforme : EnumConformitaCriterioDimensione.Conforme;
    }

    deconstructor(): IDimensione {
        return Object.assign({}, this);
    }
}

export interface IStandardOProceduraDiRiferimento {
    titoloStandardOProcedura: string;
    dimensioni: IDimensione[];
    noteESuggerimenti: string;
}

export class StandardOProceduraDiRiferimento {
    titoloStandardOProcedura: string;
    dimensioni: Dimensione[];
    noteESuggerimenti: string;

    constructor(params: Partial<IStandardOProceduraDiRiferimento> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
        */
        if (params.dimensioni) {
            this.dimensioni = params.dimensioni.map((value) => new Dimensione(value));
        }
    }

    get maxValutazioneStandardOProceduraDiRiferimento(): number {
        const requisiti = Object.keys(EnumGradoValutazioneRequisito).map(key => EnumGradoValutazioneRequisito[key]).filter(el => !isNaN(el));
        const numRequisiti = requisiti.length;
        const maxPunteggioRequisito = requisiti[numRequisiti - 1];
        return this.sumPuntiDimensioniStandardOProceduraDiRiferimento * maxPunteggioRequisito;
        // const punteggioMaxDimensione = Math.max.apply(Math, this.dimensioni.map(d => d.puntiDimensione));


        //return punteggioMaxDimensione * numRequisiti * maxPunteggioRequisito;
    }

    get sumPuntiDimensioniStandardOProceduraDiRiferimento(): number {
        return this.dimensioni.reduce((a, { puntiDimensione }) => a + puntiDimensione, 0)
    }

    get totalePunteggioStandardOProceduraDiRiferimento(): number {
        return this.dimensioni.reduce((a, { totalePunteggioItemMonitoraggio }) => a + totalePunteggioItemMonitoraggio, 0)
    }

    get totaleValutazioneStandardOProceduraDiRiferimento(): number {
        return this.dimensioni.reduce((a, { totaleValutazioneItemMonitoraggio }) => a + totaleValutazioneItemMonitoraggio, 0)
    }

    get complianceStandardOProceduraDiRiferimento(): number {
        return this.totalePunteggioStandardOProceduraDiRiferimento + this.totaleValutazioneStandardOProceduraDiRiferimento;
    }

    get complianceMaxStandardOProceduraDiRiferimento(): number {
        return this.maxValutazioneStandardOProceduraDiRiferimento + this.sumPuntiDimensioniStandardOProceduraDiRiferimento;
    }

    get compliancePercentualeStandardOProceduraDiRiferimento(): number {
        return (this.complianceStandardOProceduraDiRiferimento / this.complianceMaxStandardOProceduraDiRiferimento) * 100;
    }

    static InitContent(strumentoRegistrazioneDiRiferimento: EnumStrumentoDiRegistrazioneTemplateBodyCode): StandardOProceduraDiRiferimento {
        const content = new StandardOProceduraDiRiferimento();
        if (strumentoRegistrazioneDiRiferimento === EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioStandardStrutturaliOrganizzativi) {
            content.titoloStandardOProcedura = 'Standard strutturali e organizzativi';
            content.dimensioni = [
                new Dimensione({
                    titoloDimensione: 'Area/edificio',
                    puntiDimensione: 0.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "Area/edificio",
                            pesoItemMonitoraggioValutazionePercentuale: 100,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Area/edificio", puntiDimensione: 0.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Spazi interni',
                    puntiDimensione: 0.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: 'spazi per il sonno',
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Spazi interni", puntiDimensione: 0.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: 'spazi per l\'igiene',
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Spazi interni", puntiDimensione: 0.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: 'spazi per il pranzo',
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Spazi interni", puntiDimensione: 0.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: 'cucina',
                            pesoItemMonitoraggioValutazionePercentuale: 5,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Spazi interni", puntiDimensione: 0.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: 'atelier',
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Spazi interni", puntiDimensione: 0.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: 'miniatelier',
                            pesoItemMonitoraggioValutazionePercentuale: 5,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Spazi interni", puntiDimensione: 0.5 })
                        }),
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Spazi esterni',
                    puntiDimensione: 0.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "atelier all'aperto",
                            pesoItemMonitoraggioValutazionePercentuale: 40,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Spazi esterni", puntiDimensione: 0.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "percorsi sensoriali",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Spazi esterni", puntiDimensione: 0.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "percorsi motori",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Spazi esterni", puntiDimensione: 0.5 })
                        }),
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Organizzazione dei bambini in sezioni/classi',
                    puntiDimensione: 1,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "suddivisione dei bambini per età",
                            pesoItemMonitoraggioValutazionePercentuale: 50,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Organizzazione dei bambini in sezioni/classi", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "numero max di bambini per gruppo",
                            pesoItemMonitoraggioValutazionePercentuale: 50,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Organizzazione dei bambini in sezioni/classi", puntiDimensione: 1 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Rapporto numerico insegnante/bambino',
                    puntiDimensione: 1,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "Rapporto numerico insegnante/bambino",
                            pesoItemMonitoraggioValutazionePercentuale: 100,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Rapporto numerico insegnante/bambino", puntiDimensione: 1 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Figure professionali del gruppo di lavoro',
                    puntiDimensione: 0.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "Figure professionali del gruppo di lavoro",
                            pesoItemMonitoraggioValutazionePercentuale: 100,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Figure professionali del gruppo di lavoro", puntiDimensione: 0.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Metodologia del gruppo di lavoro',
                    puntiDimensione: 1,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontri settimanali di progettazione",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Metodologia del gruppo di lavoro", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontri mensili di progettazione",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Metodologia del gruppo di lavoro", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontri con le famiglie",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Metodologia del gruppo di lavoro", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compresenza delle insegnanti",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Metodologia del gruppo di lavoro", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "ore non frontali, 250 ore annuali per incontri progettuali, documentazione, incontri con le famiglie",
                            pesoItemMonitoraggioValutazionePercentuale: 25,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Metodologia del gruppo di lavoro", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "formazione 100 ore annuali",
                            pesoItemMonitoraggioValutazionePercentuale: 25,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Metodologia del gruppo di lavoro", puntiDimensione: 1 })
                        })
                    ]
                })
            ];
        } else if (strumentoRegistrazioneDiRiferimento === EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioErogazioneServizio) {
            content.titoloStandardOProcedura = 'Erogazione del servizio';
            content.dimensioni = [
                new Dimensione({
                    titoloDimensione: 'La realizzazione del servizio educativo',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "elaborazione della scansione del piano pedagogico e dell’organizzazione del servizio educativo",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione del servizio educativo", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "redazione scritture progettuali e relativa verifica",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione del servizio educativo", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "progettazione settimanale",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione del servizio educativo", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "redazione della valutazione delle competenze individuali dei bambini",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione del servizio educativo", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "autovalutazione della qualità del servizio",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione del servizio educativo", puntiDimensione: 5 })
                        }),

                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'L’allestimento degli spazi e dei materiali nelle strutture',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "spazi",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’allestimento degli spazi e dei materiali nelle strutture", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "centri di interesse/allestimenti",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’allestimento degli spazi e dei materiali nelle strutture", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "materiali",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’allestimento degli spazi e dei materiali nelle strutture", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "approfondimento progettuale sui materiali",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’allestimento degli spazi e dei materiali nelle strutture", puntiDimensione: 5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'L’organizzazione della giornata tipo al nido e alla scuola dell’infanzia',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "accoglienza",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’organizzazione della giornata tipo al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "assemblea",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’organizzazione della giornata tipo al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "merenda",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’organizzazione della giornata tipo al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "attività a piccolo/grande gruppo",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’organizzazione della giornata tipo al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "preparazione al pranzo",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’organizzazione della giornata tipo al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "pranzo",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’organizzazione della giornata tipo al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "sonno/risveglio",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’organizzazione della giornata tipo al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "attività pomeridiane",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’organizzazione della giornata tipo al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La realizzazione della documentazione nelle strutture educative',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "documentazioni progettuali",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione della documentazione nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "documentazioni ad altezza bambino",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione della documentazione nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "documentazione quotidiana e settimanale",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione della documentazione nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "documentazione per gli incontri di sezione con le famiglie",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione della documentazione nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "documentazione da consegnare alle famiglie alla fine dell'anno scolastico",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione della documentazione nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "documentazione sui progetti di ricerca e/o approfondimenti tematici",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione della documentazione nelle strutture educative", puntiDimensione: 5 })
                        }),
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La personalizzazione dell’intervento educativo al nido',
                    puntiDimensione: 2,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "spazi individuali",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La personalizzazione dell’intervento educativo al nido", puntiDimensione: 2 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "cartella digitale per bambino per raccogliere tutti i suoi documenti  relativi all’ambientamento",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La personalizzazione dell’intervento educativo al nido", puntiDimensione: 2 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "cartella digitale per bambino per raccogliere il suo percorso sulla valutazione delle competenze",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La personalizzazione dell’intervento educativo al nido", puntiDimensione: 2 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "cartella individuale per raccogliere le fotografie",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La personalizzazione dell’intervento educativo al nido", puntiDimensione: 2 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "cartella digitale per raccogliere le grafiche",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La personalizzazione dell’intervento educativo al nido", puntiDimensione: 2 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La gestione degli oggetti dei bambini al nido e alla scuola dell’infanzia',
                    puntiDimensione: 1,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "La gestione degli oggetti dei bambini al nido e alla scuola dell’infanzia",
                            pesoItemMonitoraggioValutazionePercentuale: 100,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La gestione degli oggetti dei bambini al nido e alla scuola dell’infanzia", puntiDimensione: 1 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La gestione dell’emergenze cliniche ed organizzative nelle strutture educative',
                    puntiDimensione: 1,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "La gestione dell’emergenze cliniche ed organizzative nelle strutture educative",
                            pesoItemMonitoraggioValutazionePercentuale: 100,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La gestione dell’emergenze cliniche ed organizzative nelle strutture educative", puntiDimensione: 1 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La pulizia dei locali nelle strutture educative',
                    puntiDimensione: 1,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "interventi di pulizia prima dell’apertura dell’anno scolastico",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La pulizia dei locali nelle strutture educative", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "interventi di pulizia da effettuarsi durante l’anno scolastico",
                            pesoItemMonitoraggioValutazionePercentuale: 40,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La pulizia dei locali nelle strutture educative", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "interventi di pulizia da effettuarsi a fine anno scolastico",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La pulizia dei locali nelle strutture educative", puntiDimensione: 1 })
                        })
                    ]
                })
            ];
        } else if (strumentoRegistrazioneDiRiferimento === EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioFamilyEngagement) {
            content.titoloStandardOProcedura = 'Family engagement';
            content.dimensioni = [
                new Dimensione({
                    titoloDimensione: 'Lo sviluppo delle attività per la promozione e la comunicazione del centro educativo',
                    puntiDimensione: 1,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "piano di comunicazione",
                            pesoItemMonitoraggioValutazionePercentuale: 25,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo delle attività per la promozione e la comunicazione del centro educativo", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione del sito web",
                            pesoItemMonitoraggioValutazionePercentuale: 25,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo delle attività per la promozione e la comunicazione del centro educativo", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione carta dei servizi",
                            pesoItemMonitoraggioValutazionePercentuale: 25,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo delle attività per la promozione e la comunicazione del centro educativo", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione open day, visite programmate, eventi, incontri tematici, atelier tematici",
                            pesoItemMonitoraggioValutazionePercentuale: 25,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo delle attività per la promozione e la comunicazione del centro educativo", puntiDimensione: 1 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Il coinvolgimento delle famiglie nel processo educativo al nido e alla scuola',
                    puntiDimensione: 4,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "colloqui individuali con le famiglie",
                            pesoItemMonitoraggioValutazionePercentuale: 25,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Il coinvolgimento delle famiglie nel processo educativo al nido e alla scuola", puntiDimensione: 4 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "dialogo quotidiano con i genitori",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Il coinvolgimento delle famiglie nel processo educativo al nido e alla scuola", puntiDimensione: 4 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontri di sezione",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Il coinvolgimento delle famiglie nel processo educativo al nido e alla scuola", puntiDimensione: 4 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "serate a tema",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Il coinvolgimento delle famiglie nel processo educativo al nido e alla scuola", puntiDimensione: 4 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "serate lavorative",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Il coinvolgimento delle famiglie nel processo educativo al nido e alla scuola", puntiDimensione: 4 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "feste",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Il coinvolgimento delle famiglie nel processo educativo al nido e alla scuola", puntiDimensione: 4 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "giornata del genitore nei servizi educativi",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Il coinvolgimento delle famiglie nel processo educativo al nido e alla scuola", puntiDimensione: 4 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'L’ambientamento e il riambientamento al nido e alla scuola dell’infanzia',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "colloquio individuale con le famiglie",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’ambientamento e il riambientamento al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontro/merenda con i bambini e le famiglie già frequentanti",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’ambientamento e il riambientamento al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "serata di benvenuto per tutti i genitori nuovi",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’ambientamento e il riambientamento al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione dell'ambientamento/riambientamento",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’ambientamento e il riambientamento al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "l'incontro di sezione",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’ambientamento e il riambientamento al nido e alla scuola dell’infanzia", puntiDimensione: 5 })
                        })
                    ]
                })
            ];
        } else if ( strumentoRegistrazioneDiRiferimento === EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioImplementazioneCurricolo ) {
            content.titoloStandardOProcedura = 'Implementazione del curriculo';
            content.dimensioni = [
                new Dimensione({
                    titoloDimensione: 'La promozione degli ambiti di sviluppo al nido',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione dei centri di interesse in base all’approfondimento annuale progettuale sugli ambiti di sviluppo",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La promozione degli ambiti di sviluppo al nido", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione compilazione delle scritture progettuali “zone esplorabili e campi di esperienza; contesti, proposte e linguaggi”",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La promozione degli ambiti di sviluppo al nido", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione dello ”strumento di valutazione degli ambiti di sviluppo delle competenze individuali al nido”, tre volte all’anno",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La promozione degli ambiti di sviluppo al nido", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione settimanale di almeno tre esperienze, per bambino, in relazione alle attività educative-didattiche declinandole nello strumento “Progettazione settimanale”",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La promozione degli ambiti di sviluppo al nido", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "individuazione di 1 approfondimento annuale progettuale relativo ad un ambito di sviluppo",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La promozione degli ambiti di sviluppo al nido", puntiDimensione: 5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Lo sviluppo dei campi di esperienza nella scuola dell’infanzia',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione dei centri di interesse in base all’approfondimento annuale progettuale dei campi di esperienza",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei campi di esperienza nella scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione delle scritture progettuali “zone esplorabili e campi di esperienza; contesti, proposte e linguaggi”",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei campi di esperienza nella scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione dello ”strumento di valutazione dei traguardi delle competenze individuali alla scuola dell’infanzia”, tre volte all’anno",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei campi di esperienza nella scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione settimanale di almeno tre esperienze, per bambino, in relazione alle attività educative-didattiche declinandole nello strumento “Progettazione settimanale”",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei campi di esperienza nella scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "individuazione di 1 approfondimento annuale progettuale relativo ad un campo di esperienza",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei campi di esperienza nella scuola dell’infanzia", puntiDimensione: 5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione di spazi di atelier e mini atelier",
                            pesoItemMonitoraggioValutazionePercentuale: 40,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione delle scritture progettuali “contesti, proposte e linguaggi” per definire le attività didattiche in relazione ai diversi linguaggi espressivi",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione dello ”strumento di osservazione sullo sviluppo dei linguaggi espressivi e della creatività”, tre volte all’anno",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative", puntiDimensione: 5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La definizione di strategie per garantire la continuità al nido d’infanzia',
                    puntiDimensione: 1.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "colloqui tra insegnanti dell’anno precedente e di quello successivo",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità al nido d’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione di un progetto di continuità orizzontale",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità al nido d’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione di un progetto di continuità verticale",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità al nido d’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "condivisione con le famiglie dei progetti realizzati",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità al nido d’infanzia", puntiDimensione: 1.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La definizione di strategie per garantire la continuità alla scuola dell’infanzia',
                    puntiDimensione: 1.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "colloqui tra insegnanti dell’anno precedente e di quello successivo",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità alla scuola dell’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione di un progetto di continuità orizzontale",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità alla scuola dell’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione di un progetto di continuità verticale",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità alla scuola dell’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "condivisione con le famiglie dei progetti realizzati",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità alla scuola dell’infanzia", puntiDimensione: 1.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo',
                    puntiDimensione: 3.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre contesti per il pranzo",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "creare occasioni per apparecchiature speciali",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre con cura il possibile rinfresco per incontri con le famiglie",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzare attività chiamate “atelier del gusto”",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Lo sviluppo del pensiero ecologico nel servizio educativo',
                    puntiDimensione: 3.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "promuovere azioni quotidiane per lo sviluppo di una cultura ecologica",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre contesti e spazi per realizzare ricerche sul tema",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "proporre esperienze didattiche sul tema",
                            pesoItemMonitoraggioValutazionePercentuale: 40,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre laboratori, serate tematiche con le famiglie sul tema",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La progettazione individualizzata per i bambini portatori di diritti speciali',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "raccolta dei dati del bambino",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontri con i servizi coinvolti",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "colloqui con la famiglia",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "equipe organizzative e progettuali tra tutto il team",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "osservazione sistematica del bambino",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "sintesi del progetto educativo",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontro finale con la famiglia e con i servizi coinvolti",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        })
                    ]
                })
            ];
        } else if (strumentoRegistrazioneDiRiferimento === EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioImplementazioneCurricoloNidoInfanzia){
            content.titoloStandardOProcedura = 'Implementazione del curriculo';
            content.dimensioni = [
                new Dimensione({
                    titoloDimensione: 'La promozione degli ambiti di sviluppo al nido',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione dei centri di interesse in base all’approfondimento annuale progettuale sugli ambiti di sviluppo",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La promozione degli ambiti di sviluppo al nido", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione compilazione delle scritture progettuali “zone esplorabili e campi di esperienza; contesti, proposte e linguaggi”",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La promozione degli ambiti di sviluppo al nido", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione dello ”strumento di valutazione degli ambiti di sviluppo delle competenze individuali al nido”, tre volte all’anno",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La promozione degli ambiti di sviluppo al nido", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione settimanale di almeno tre esperienze, per bambino, in relazione alle attività educative-didattiche declinandole nello strumento “Progettazione settimanale”",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La promozione degli ambiti di sviluppo al nido", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "individuazione di 1 approfondimento annuale progettuale relativo ad un ambito di sviluppo",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La promozione degli ambiti di sviluppo al nido", puntiDimensione: 5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione di spazi di atelier e mini atelier",
                            pesoItemMonitoraggioValutazionePercentuale: 40,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione delle scritture progettuali “contesti, proposte e linguaggi” per definire le attività didattiche in relazione ai diversi linguaggi espressivi",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione dello ”strumento di osservazione sullo sviluppo dei linguaggi espressivi e della creatività”, tre volte all’anno",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative", puntiDimensione: 5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La definizione di strategie per garantire la continuità al nido d’infanzia',
                    puntiDimensione: 1.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "colloqui tra insegnanti dell’anno precedente e di quello successivo",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità al nido d’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione di un progetto di continuità orizzontale",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità al nido d’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione di un progetto di continuità verticale",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità al nido d’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "condivisione con le famiglie dei progetti realizzati",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità al nido d’infanzia", puntiDimensione: 1.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo',
                    puntiDimensione: 3.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre contesti per il pranzo",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "creare occasioni per apparecchiature speciali",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre con cura il possibile rinfresco per incontri con le famiglie",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzare attività chiamate “atelier del gusto”",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Lo sviluppo del pensiero ecologico nel servizio educativo',
                    puntiDimensione: 3.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "promuovere azioni quotidiane per lo sviluppo di una cultura ecologica",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre contesti e spazi per realizzare ricerche sul tema",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "proporre esperienze didattiche sul tema",
                            pesoItemMonitoraggioValutazionePercentuale: 40,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre laboratori, serate tematiche con le famiglie sul tema",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La progettazione individualizzata per i bambini portatori di diritti speciali',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "raccolta dei dati del bambino",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontri con i servizi coinvolti",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "colloqui con la famiglia",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "equipe organizzative e progettuali tra tutto il team",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "osservazione sistematica del bambino",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "sintesi del progetto educativo",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontro finale con la famiglia e con i servizi coinvolti",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        })
                    ]
                })
            ];
            console.log('dati curricolo nido infanzia');
            console.log(content);
        } else if (strumentoRegistrazioneDiRiferimento === EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioImplementazioneCurricoloScuolaInfanzia){
            content.titoloStandardOProcedura = 'Implementazione del curriculo';
            content.dimensioni = [
                new Dimensione({
                    titoloDimensione: 'Lo sviluppo dei campi di esperienza nella scuola dell’infanzia',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione dei centri di interesse in base all’approfondimento annuale progettuale dei campi di esperienza",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei campi di esperienza nella scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione delle scritture progettuali “zone esplorabili e campi di esperienza; contesti, proposte e linguaggi”",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei campi di esperienza nella scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione dello ”strumento di valutazione dei traguardi delle competenze individuali alla scuola dell’infanzia”, tre volte all’anno",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei campi di esperienza nella scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione settimanale di almeno tre esperienze, per bambino, in relazione alle attività educative-didattiche declinandole nello strumento “Progettazione settimanale”",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei campi di esperienza nella scuola dell’infanzia", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "individuazione di 1 approfondimento annuale progettuale relativo ad un campo di esperienza",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei campi di esperienza nella scuola dell’infanzia", puntiDimensione: 5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione di spazi di atelier e mini atelier",
                            pesoItemMonitoraggioValutazionePercentuale: 40,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione delle scritture progettuali “contesti, proposte e linguaggi” per definire le attività didattiche in relazione ai diversi linguaggi espressivi",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione dello ”strumento di osservazione sullo sviluppo dei linguaggi espressivi e della creatività”, tre volte all’anno",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo dei linguaggi espressivi e della creatività nelle strutture educative", puntiDimensione: 5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La definizione di strategie per garantire la continuità alla scuola dell’infanzia',
                    puntiDimensione: 1.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "colloqui tra insegnanti dell’anno precedente e di quello successivo",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità alla scuola dell’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione di un progetto di continuità orizzontale",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità alla scuola dell’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione di un progetto di continuità verticale",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità alla scuola dell’infanzia", puntiDimensione: 1.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "condivisione con le famiglie dei progetti realizzati",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La definizione di strategie per garantire la continuità alla scuola dell’infanzia", puntiDimensione: 1.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo',
                    puntiDimensione: 3.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre contesti per il pranzo",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "creare occasioni per apparecchiature speciali",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre con cura il possibile rinfresco per incontri con le famiglie",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzare attività chiamate “atelier del gusto”",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La realizzazione di contesti educativi per l’alimentazione: i linguaggi del cibo", puntiDimensione: 3.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Lo sviluppo del pensiero ecologico nel servizio educativo',
                    puntiDimensione: 3.5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "promuovere azioni quotidiane per lo sviluppo di una cultura ecologica",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre contesti e spazi per realizzare ricerche sul tema",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "proporre esperienze didattiche sul tema",
                            pesoItemMonitoraggioValutazionePercentuale: 40,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "predisporre laboratori, serate tematiche con le famiglie sul tema",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Lo sviluppo del pensiero ecologico nel servizio educativo", puntiDimensione: 3.5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La progettazione individualizzata per i bambini portatori di diritti speciali',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "raccolta dei dati del bambino",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontri con i servizi coinvolti",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "colloqui con la famiglia",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "equipe organizzative e progettuali tra tutto il team",
                            pesoItemMonitoraggioValutazionePercentuale: 15,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "osservazione sistematica del bambino",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "sintesi del progetto educativo",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "incontro finale con la famiglia e con i servizi coinvolti",
                            pesoItemMonitoraggioValutazionePercentuale: 10,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La progettazione individualizzata per i bambini portatori di diritti speciali", puntiDimensione: 5 })
                        })
                    ]
                })
            ];
            console.log('dati curricolo scuola infanzia');
            console.log(content);
        }
        else if (strumentoRegistrazioneDiRiferimento === EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioValutazioneEfficaciaOutcomesServizio) {
            content.titoloStandardOProcedura = 'Valutazione dell’efficacia degli outcomes del servizio';
            content.dimensioni = [
                new Dimensione({
                    titoloDimensione: 'L’autovalutazione della qualità del servizio',
                    puntiDimensione: 1,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "organizzazione del contesto educativo",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’autovalutazione della qualità del servizio", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "famiglie e territorio",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’autovalutazione della qualità del servizio", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "funzionamento del gruppo di lavoro",
                            pesoItemMonitoraggioValutazionePercentuale: 20,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’autovalutazione della qualità del servizio", puntiDimensione: 1 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "valutazione",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "L’autovalutazione della qualità del servizio", puntiDimensione: 1 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La valutazione delle competenze dei bambini',
                    puntiDimensione: 10,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione tre volte all’anno degli strumenti di valutazione degli ambiti di sviluppo delle competenze al nido e degli strumenti di valutazione dei traguardi di sviluppo delle competenze individuali alla scuola dell’infanzia",
                            pesoItemMonitoraggioValutazionePercentuale: 35,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La valutazione delle competenze dei bambini", puntiDimensione: 10 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "compilazione dello strumento di osservazione sullo sviluppo dei linguaggi espressivi e della creatività, tre volte all’anno",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La valutazione delle competenze dei bambini", puntiDimensione: 10 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione a fine anno del Portfolio individuale per ogni bambino",
                            pesoItemMonitoraggioValutazionePercentuale: 35,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La valutazione delle competenze dei bambini", puntiDimensione: 10 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La valutazione delle competenze dello staff',
                    puntiDimensione: 6,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "valutazione tasks dell’educatore",
                            pesoItemMonitoraggioValutazionePercentuale: 50,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La valutazione delle competenze dello staff", puntiDimensione: 6 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "valutazione skills dell’educatore",
                            pesoItemMonitoraggioValutazionePercentuale: 50,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La valutazione delle competenze dello staff", puntiDimensione: 6 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'La valutazione della ricaduta dell’attività di formazione annuale del personale',
                    puntiDimensione: 5,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione di progettualità coerenti con la formazione",
                            pesoItemMonitoraggioValutazionePercentuale: 35,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La valutazione della ricaduta dell’attività di formazione annuale del personale", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "realizzazione di documentazioni inerenti",
                            pesoItemMonitoraggioValutazionePercentuale: 30,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La valutazione della ricaduta dell’attività di formazione annuale del personale", puntiDimensione: 5 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "modifiche di spazi ed allestimenti inerenti alla formazione",
                            pesoItemMonitoraggioValutazionePercentuale: 35,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "La valutazione della ricaduta dell’attività di formazione annuale del personale", puntiDimensione: 5 })
                        })
                    ]
                }),
                new Dimensione({
                    titoloDimensione: 'Customer satisfacion',
                    puntiDimensione: 8,
                    itemsMonitoraggioValutazione: [
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "eseguita l'analisi dei dati",
                            pesoItemMonitoraggioValutazionePercentuale: 50,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Customer satisfacion", puntiDimensione: 8 })
                        }),
                        new ItemMonitoraggioValutazione({
                            titoloItemMonitoraggioValutazione: "avviato il follow up",
                            pesoItemMonitoraggioValutazionePercentuale: 50,
                            dimensioneDiRiferimento: new Dimensione({ titoloDimensione: "Customer satisfacion", puntiDimensione: 8 })
                        })
                    ]
                })
            ];
        }
        return content;
    }

    deconstructor(): IStandardOProceduraDiRiferimento {
        return Object.assign({}, this);
    }
}