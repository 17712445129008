import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ENVIRONMENT,
  IEnvironment, ILoginBody, IStrumentoDiRegistrazioneArchivio
} from '@gpi/lbl/shared/entity';
import { IClusterIndicatori } from 'libs/lbl/shared/entity/src/lib/base/ClusterIndicatori';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class StrumentiDiRegistrazioneArchivioBackend {
  constructor(
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT)
    private environment: IEnvironment,
  ) { }

  postStrumentoDiRegistrazioneArchivio(body): Observable<IStrumentoDiRegistrazioneArchivio[]> {
    return this.httpClient.post<IStrumentoDiRegistrazioneArchivio[]>(
      `${this.environment.urls.api}/strumenti-di-registrazione/get/`,
      body,
    );
  }

  postSintesiStrumentiDiRegistrazioneArchivio(body): Observable<IClusterIndicatori[]> {
    return this.httpClient.post<IClusterIndicatori[]>(
      `${this.environment.urls.api}/strumenti-di-registrazione/get-sintesi/`,
      body,
    );
  }


}
