import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ContenutoChioscoLBLArchivio } from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-contenuti-chiosco',
  templateUrl: 'contenuti-chiosco.component.html',
  styleUrls: ['contenuti-chiosco.component.scss'],
})
export class ContenutiChioscoComponent implements OnInit, OnChanges {
  @Input()
  list: ContenutoChioscoLBLArchivio[] = [];
  @Input()
  itemSelected: ContenutoChioscoLBLArchivio;
  @Input()
  showType = false;

  @Input()
  isMobile: boolean;

  @Output()
  selectedItem: EventEmitter<ContenutoChioscoLBLArchivio> = new EventEmitter();

  autoselectFirstItem() {
    //ordinamento lista eventi/newsletter per dataInizioPubllicazione
    this.list = this.list.sort((a, b) => {
      let dateA = new Date(a.dataInizioPubblicazione).getTime();
      let dateB = new Date(b.dataInizioPubblicazione).getTime();
      return dateB - dateA;
    });
    //console.log('list', this.list);

    if (!this.isMobile) {
      const item = this.list[0];

      if (this.list && item) {
        this.selectedItem.emit(item);
      }
    }
  }

  ngOnInit() {
    this.autoselectFirstItem();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.list) {
      this.autoselectFirstItem();
    }
  }
}
