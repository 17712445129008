

export interface IInformazioniAnagraficheSipi {
    auslDiRiferimento: string;
    neuropsichiatraDiRiferimento: string;
    altriContattiAusl: string;
    altroReferente: string;
    numeroTelefono: string;
    email: string;
    altreInformazioni: string;

    /**
     * Relazioni
     */
    figureProfessionaliDiRiferimento: IFiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow[];
}

export class InformazioniAnagraficheSipi implements IInformazioniAnagraficheSipi {
    auslDiRiferimento: string;
    neuropsichiatraDiRiferimento: string;
    altriContattiAusl: string;
    altroReferente: string;
    numeroTelefono: string;
    email: string;
    altreInformazioni: string;

    /**
     * Relazioni
     */
    figureProfessionaliDiRiferimento: FiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow[];


    constructor(params: Partial<IInformazioniAnagraficheSipi> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
        if (params.figureProfessionaliDiRiferimento) {
            this.figureProfessionaliDiRiferimento = params.figureProfessionaliDiRiferimento.map((value) => new FiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow(value));
        }
    }

    deconstructor(): IInformazioniAnagraficheSipi {
        return Object.assign({}, this);
    }
}

export interface IFiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow {
    nome: string;
    cognome: string;
    funzione: string;
    telefono: string;
    altriRecapiti: string;
}

export class FiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow implements IFiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow {
    nome: string;
    cognome: string;
    funzione: string;
    telefono: string;
    altriRecapiti: string;

    constructor(params: Partial<IFiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
    }

    deconstructor(): IFiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow {
        return Object.assign({}, this);
    }
}
