import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';
import { FunzionalitaLBL } from '@gpi/lbl/shared/entity';

export interface FunzionalitaLblState
  extends EntityState<FunzionalitaLBL, number>,
    ActiveState<number> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lbl-app-data-access-funzionalita-lbl' })
export class FunzionalitaLblStore extends EntityStore<FunzionalitaLblState> {
  constructor() {
    super();
  }
}
