import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { Theme } from './theme.model';

export interface ThemesState
  extends EntityState<Theme, string>,
    ActiveState<string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lbl-app-util-themes' })
export class ThemesStore extends EntityStore<ThemesState> {
  constructor() {
    super();
  }
}
