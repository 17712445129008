import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewChildren } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { AttivitaStrumentoProgettualeSipiRow, ObiettivoStrumentoProgettualeSipiRow, StrumentoDiRegistrazioneArchivio, StrumentoProgettualeSipi, StrutturaEducativa } from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-sezione-strumento-progettuale',
  templateUrl: './sipi-sezione-strumento-progettuale.component.html',
  styleUrls: ['./sipi-sezione-strumento-progettuale.component.scss'],
})
export class SipiSezioneStrumentoProgettualeComponent implements OnInit, OnChanges {
  @Input() strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input() strutturaEducativa: StrutturaEducativa;
  @Input() submit$: Observable<void>;
  @Output() updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();
  @Input() isEnabledBody: boolean;
  @ViewChildren(MatTable) _matTables;

  form: FormGroup;
  areeDiLavoro = ['autonomie', 'linguaggioEComunicazione', 'relazioni', 'saperi'];
  obiettiviDisplayedColumns = ['areaDiLavoro', 'obiettiviGenerali', 'obiettiviSpecifici', 'descrittoriVerifica'];
  verificaDisplayedColumns = ['areaDiLavoro', 'obiettiviGenerali', 'obiettiviSpecifici', 'descrittoriVerifica', 'verifica'];
  AttivitaDisplayedColumns = ['tempo', 'nome', 'descrizione', 'strategie', 'stimaDurata', 'materiali', 'spazio', 'adultiPropositori'];

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    //console.log('getting strumento di registrazione');
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): StrumentoProgettualeSipi {
    //console.log('getting content');
    let content = this.strumentoDiRegistrazioneArchivio.content as StrumentoProgettualeSipi;
    if (!content) {
      content = StrumentoProgettualeSipi.InitContent(this.areeDiLavoro);
    }

    return content;
  }

  get obiettivi(): FormArray {
    return this.form.get('obiettivi') as FormArray;
  }

  get verifiche(): FormArray {
    return this.form.get('verifiche') as FormArray;
  }

  constructor() { }

  ngOnInit() {
    this.form = this.buildForm();
    //se modifico la tabella obiettici compilo anche la tabella verifica con in campi comuni
    this.obiettivi.valueChanges.subscribe((el: ObiettivoStrumentoProgettualeSipiRow[]) => {
      //console.log('patching values');
      this.verifiche.patchValue(el);
    });

    this.form.valueChanges.subscribe(() => {
      //console.log('Values changed, submitting form');
      this.submit(this.form);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      //console.log('values changed');
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      obiettivi: this.content && this.content.obiettivi ? this.buildFormObiettivi(this.content.obiettivi) : new FormArray([]),
      noteStrategie: new FormControl(this.content.noteStrategie),
      areaDiLavoroAutonomie: this.content && this.content.areaDiLavoroAutonomie ? this.buildFormAttivitas(this.content.areaDiLavoroAutonomie) : new FormArray([]),
      areaDiLavoroLinguaggiComunicazioni: this.content && this.content.areaDiLavoroLinguaggiComunicazioni ? this.buildFormAttivitas(this.content.areaDiLavoroLinguaggiComunicazioni) : new FormArray([]),
      areaDiLavoroRelazioni: this.content && this.content.areaDiLavoroRelazioni ? this.buildFormAttivitas(this.content.areaDiLavoroRelazioni) : new FormArray([]),
      areaDiLavoroSapereFarSapere: this.content && this.content.areaDiLavoroSapereFarSapere ? this.buildFormAttivitas(this.content.areaDiLavoroSapereFarSapere) : new FormArray([]),
      verifiche: this.content && this.content.obiettivi ? this.buildFormVerifiche(this.content.verifiche) : new FormArray([]),
      noteCambiamenti: new FormControl(this.content.noteCambiamenti),
    }, { updateOn: 'blur' });

    return form;
  }

  buildFormObiettivi(obiettivi: ObiettivoStrumentoProgettualeSipiRow[]): FormArray {
    const form = new FormArray(obiettivi.map((value) => this.buildFormObiettivo(value)));

    return form;
  }

  buildFormObiettivo(obiettivi: ObiettivoStrumentoProgettualeSipiRow): FormGroup {
    const form = new FormGroup({
      areaDiLavoro: new FormControl(obiettivi.areaDiLavoro),
      obiettivoGenerale: new FormControl(obiettivi.obiettivoGenerale),
      obiettivoSpecifico: new FormControl(obiettivi.obiettivoSpecifico),
      descrittoriVerifica: new FormControl(obiettivi.descrittoriVerifica),
    });

    return form;
  }

  buildFormAttivitas(attivita: AttivitaStrumentoProgettualeSipiRow[]): FormArray {
    const form = new FormArray(attivita.map((value) => this.buildFormAttivita(value)));

    return form;
  }

  buildFormAttivita(attivita: AttivitaStrumentoProgettualeSipiRow): FormGroup {
    const form = new FormGroup({
      tempo: new FormControl(attivita.tempo),
      nome: new FormControl(attivita.nome),
      descrizione: new FormControl(attivita.descrizione),
      strategie: new FormControl(attivita.strategie),
      stimaDurata: new FormControl(attivita.stimaDurata),
      materiali: new FormControl(attivita.materiali),
      spazio: new FormControl(attivita.spazio),
      adultiPropositori: new FormControl(attivita.adultiPropositori),
    });

    return form;
  }

  buildFormVerifiche(obiettivi: ObiettivoStrumentoProgettualeSipiRow[]): FormArray {
    const form = new FormArray(obiettivi.map((value) => this.buildFormVerifica(value)));

    return form;
  }

  buildFormVerifica(obiettivi: ObiettivoStrumentoProgettualeSipiRow): FormGroup {
    const form = new FormGroup({
      areaDiLavoro: new FormControl(obiettivi.areaDiLavoro),
      obiettivoGenerale: new FormControl(obiettivi.obiettivoGenerale),
      obiettivoSpecifico: new FormControl(obiettivi.obiettivoSpecifico),
      descrittoriVerifica: new FormControl(obiettivi.descrittoriVerifica),
      verifica: new FormControl(obiettivi.verifica),
    });

    return form;
  }

  getAreaDiLavoro(titolo: string): FormArray {
    return this.form.get(titolo) as FormArray;
  }

  addrow(titolo: string, id: number) {

    this.getAreaDiLavoro(titolo).push(this.buildFormAttivita(new AttivitaStrumentoProgettualeSipiRow()));

    this._matTables['_results'][id].renderRows();

  }

  isExpand(titolo: string) {
    if (this.getAreaDiLavoro(titolo).value.length > 0) {

      return true;
    } else {
      return false;
    }
  }

  updateForm(entity: StrumentoProgettualeSipi): FormGroup {
    //console.log('updating form');
    const form = this.buildForm();
    form.patchValue(entity);
    this.submit(form);
    return form;
  }

  buildStrumentoForm() {

    const form = this.form.getRawValue();

    this.strumentiDiRegistrazioneArchivio[0].content = form;

    return this.strumentiDiRegistrazioneArchivio;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const informazioniAnagraficheSipi = new StrumentoProgettualeSipi(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: informazioniAnagraficheSipi,
      });
      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
