
export interface IRilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita {
    numeroProgettiRealizzati: number;

    /**
     * Relazioni
     */
    progettiRealizzati: IProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow[];
}

export class RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita implements IRilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita {
    numeroProgettiRealizzati: number;

    /**
     * Relazioni
     */
    progettiRealizzati: ProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow[];


    constructor(params: Partial<IRilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
        if (params.progettiRealizzati) {
            this.progettiRealizzati = params.progettiRealizzati.map((value) => new ProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow(value));
        }
    }

    deconstructor(): IRilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita {
        return Object.assign({}, this);
    }
}

export interface IProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow {
    progettazione: string;
    proposte: string;
    documentazione: string;
}

export class ProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow implements IProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow {
    progettazione: string;
    proposte: string;
    documentazione: string;

    constructor(params: Partial<IProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
    }

    deconstructor(): IProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow {
        return Object.assign({}, this);
    }
}
