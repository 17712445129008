<ng-container
  *transloco="let t; read: 'rilevazioneDocumentazioneProgettualeRealizzataiServiziEducativi.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">

    <div>
      <ng-container *ngFor="let sezione of sezioniArray; let index = index">

        <div class="card w-100 mat-elevation-z1 p-3" [formGroupName]="sezione.nome">
          <div class="card-body">
            <h2>{{sezione.nome}}</h2>

            <div class="d-flex" style="margin-bottom: 23px;">
              <ng-container *ngFor="let row of ROWS">

                <div class="container" formArrayName="{{row}}">
                  <div>
                    <p [innerHTML]="t('table.column.'+row)"></p>
                    <p style="margin-left: 10px;">{{t('tot')}}{{form.get([sezione.nome, 'NumTot' + row]).value || 0}}
                    </p>
                  </div>
                  <div fxLayout="row" *ngFor="let item of getDocForm(sezione.nome, row).controls; let i = index"
                    [formGroupName]="i" >
                    <mat-form-field appearance="outline" style="width: 60px;">
                      <input type="number" (focusout)="calcolaTotaleNumAttivita(sezione.nome, row)" matInput
                        formControlName="numeroAttivitaEffettaute">
                    </mat-form-field>
                    <mat-form-field appearance="outline">
                      <input matInput formControlName="descrizioneAttivita">
                    </mat-form-field>

                    <button mat-icon-button color="primary" style="margin-top: 9px"
                      (click)="removeRow(sezioniArray[index].nome,row,i)">
                      <mat-icon svgIcon="delete"></mat-icon>
                    </button>

                  </div>
                  <button mat-stroked-button (click)="addrow(sezioniArray[index].nome,row)">+</button>
                </div>

              </ng-container>
            </div>
          </div>
        </div>

      </ng-container>
    </div>
  </form>
</ng-container>
