

export enum EnumPeriodoOsservazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividuali {
    PrimoPeriodo = "Primo periodo",
    SecondoPeriodo = "Secondo periodo",
    TerzoPeriodo = "Terzo periodo",
}

export enum EnumTipoAmbitiDiSviluppoCampiDiEsperienza {
    Sviluppo_corporeo_Corpo_in_movimento = "Sviluppo corporeo (corpo in movimento)",
    Sviluppo_del_linguaggio_Discorsi_e_parole = "Sviluppo del linguaggio (discorsi e parole)",
    Lo_sviluppo_sociale_se_e_altro = "Sviluppo sociale (se e l'altro)",
    Sviluppo_logico_e_matematico_conoscenza_del_mondo = "Sviluppo logico e matematico (conoscenza del mondo)",
    Sviluppo_linguaggio_grafico_immagini_e_Colori = "Sviluppo linguaggio grafico (immagini, suoni e colori)"
}

export interface IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido {

    periodoOsservazione: EnumPeriodoOsservazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividuali,
    dataOsservazioneDal: Date;
    dataOsservazioneAl: Date;

    /**
     * Relazioni
     */
    template: ValutazioneAmbitiSviluppoNidoTemplateRow[];

}

export class GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido implements IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido {

    periodoOsservazione: EnumPeriodoOsservazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividuali
    dataOsservazioneDal: Date;
    dataOsservazioneAl: Date;
    allenamenti?: string;

    /**
     * Relazioni
     */
    template: ValutazioneAmbitiSviluppoNidoTemplateRow[];

    constructor(params: Partial<IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */

        if (params.template) {
            this.template = params.template.map(
                (value) => new ValutazioneAmbitiSviluppoNidoTemplateRow(value),
            );
        }
    }

    static InitContent(): GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido {
        const content = new GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido();
        content.template = [];
        content.template.push(new ValutazioneAmbitiSviluppoNidoTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienza.Sviluppo_corporeo_Corpo_in_movimento }));
        content.template.push(new ValutazioneAmbitiSviluppoNidoTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienza.Sviluppo_del_linguaggio_Discorsi_e_parole }));
        content.template.push(new ValutazioneAmbitiSviluppoNidoTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienza.Lo_sviluppo_sociale_se_e_altro }));
        content.template.push(new ValutazioneAmbitiSviluppoNidoTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienza.Sviluppo_logico_e_matematico_conoscenza_del_mondo }));
        content.template.push(new ValutazioneAmbitiSviluppoNidoTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienza.Sviluppo_linguaggio_grafico_immagini_e_Colori }));
        return content;
    }

    deconstructor(): IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido {
        return Object.assign({}, this);
    }
}

export interface IValutazioneAmbitiSviluppoNidoTemplateRow {
    campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienza;
    esperienze: string;
    scaffolding: string;
    traguardiSviluppo: string;
    valutazioneTraguardiSviluppo: number;
    note: string;
}

export class ValutazioneAmbitiSviluppoNidoTemplateRow implements IValutazioneAmbitiSviluppoNidoTemplateRow {
    campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienza;
    esperienze: string;
    scaffolding: string;
    traguardiSviluppo: string;
    valutazioneTraguardiSviluppo: number;
    note: string;

    constructor(params: Partial<IValutazioneAmbitiSviluppoNidoTemplateRow> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
    }

    deconstructor(): IValutazioneAmbitiSviluppoNidoTemplateRow {
        return Object.assign({}, this);
    }
}

