import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ENVIRONMENT,
  IEnvironment,
  IResetPasswordBody,
  ISendMailResetPasswordBody,
  IUpdatePasswordBody,
  IUserInfoBody,
  IUserInfoResponse,
} from '@gpi/lbl/shared/entity';
import {
  IImpersonateBody,
  IImpersonateResponse,
  ILoginBody,
  ILoginResponse,
} from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthBackend {
  constructor(
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT)
    private environment: IEnvironment,
  ) { }

  login(body: ILoginBody): Observable<ILoginResponse> {
    return this.httpClient.post<ILoginResponse>(
      `${this.environment.urls.api}/login`,
      body,
    );
  }

  impersonate(body: IImpersonateBody): Observable<IImpersonateResponse> {
    return this.httpClient.post<IImpersonateResponse>(
      `${this.environment.urls.api}/auth/impersonate`,
      body,
    );
  }

  userInfo(body: IUserInfoBody): Observable<IUserInfoResponse> {
    return this.httpClient.post<IUserInfoResponse>(
      `${this.environment.urls.api}/auth/user-info`,
      body,
    );
  }

  sendMailResetPassword(body: ISendMailResetPasswordBody) {
    return this.httpClient.post<void>(
      `${this.environment.urls.api}/password/sendMailResetPassword`,
      body,
    );
  }

  resetPassword(body: IResetPasswordBody) {
    return this.httpClient.post<void>(
      `${this.environment.urls.api}/password/reset`,
      body,
    );
  }

  updatePassword(utenteId: number, body: IUpdatePasswordBody) {
    return this.httpClient.post<void>(
      `${this.environment.urls.api}/utente/${utenteId}/updatePassword`,
      body,
    );
  }
}
