<mat-card class="no-header" fxLayout="column" fxLayoutGap="0.5rem" [ngClass]="{'active': item?.id === itemSelected?.id}"
  (click)="selectedItem.emit(item)">
  <mat-card-header fxLayout="row" fxLayoutAlign="space-between">
    <span class="mat-caption" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="0.5rem">
      <ng-container *ngIf="showType">
        <mat-icon [svgIcon]="item?.tipo"></mat-icon>
        <span>{{'type.' + item?.tipo | transloco}}</span>
      </ng-container>
    </span>
    <span class="mat-caption" fxFlexAlign="center">
      {{item.dataInizioPubblicazione | translocoDate: 'shortDate'}}
    </span>
  </mat-card-header>
  <div fxLayout="row" fxLayoutGap="0.5rem" class="wrapper">
    <div fxFlex="none">
      <img [src]="item?.immaginePreview?.link" width="100" height="100">
    </div>
    <div fxLayout="column" fxLayoutGap="1.25rem" fxFlex="auto" class="content-wrapper">
      <h3>{{item.titolo}}</h3>
      <p>
        {{item.descrizione}}
      </p>
    </div>
  </div>
</mat-card>
