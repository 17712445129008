import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  filterStore,
  NgEntityService,
  NgEntityServiceConfig,
  NgEntityServiceLoader,
  NgEntityServiceNotifier,
} from '@datorama/akita-ng-entity-service';
import { FunzionalitaLBL, IFunzionalitaLBL, ReadManyParams, ReadManyResponse } from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { FunzionalitaLblQuery } from './funzionalita-lbl.query';
import {
  FunzionalitaLblState,
  FunzionalitaLblStore,
} from './funzionalita-lbl.store';

@NgEntityServiceConfig({
  resourceName: 'funzionalitalbl',
})
@Injectable({ providedIn: 'root' })
export class FunzionalitaLblService extends NgEntityService<
FunzionalitaLblState
> {
  loaders = this.loaderService.loadersFor(
    'lbl-app-data-access-funzionalita-lbl',
  );
  notifiers = this.notifierService.action$.pipe(
    filterStore('lbl-app-data-access-funzionalita-lbl'),
  );

  constructor(
    public query: FunzionalitaLblQuery,
    public store: FunzionalitaLblStore,
    protected loaderService: NgEntityServiceLoader,
    protected notifierService: NgEntityServiceNotifier,
  ) {
    super(store);
  }

  readAll(): Observable<FunzionalitaLBL[]> {
    return this.get<IFunzionalitaLBL[]>({
      mapResponseFn: (response) => response.data,
    }).pipe(
      map((entities) => entities.map((entity) => new FunzionalitaLBL(entity))),
    );
  }

  readMany(
    params?: ReadManyParams,
  ): Observable<ReadManyResponse<FunzionalitaLBL>> {
    const httpParams = new HttpParams({ fromObject: params as any });

    return this.get<ReadManyResponse<IFunzionalitaLBL>>({
      params: httpParams,
    }).pipe(
      map((response) => {
        const mappedResponse: ReadManyResponse<FunzionalitaLBL> = {
          ...response,
          data: (response && response.data) ? response.data.map((entity) => new FunzionalitaLBL(entity)) : []
        };

        return mappedResponse;
      }),
    );
  }

}
