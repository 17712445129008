<ng-container *transloco="let t; read: 'schedaAutovalutazioneQualitaSintesiLivelloStruttura.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <h3>{{t('section1.title1')}}</h3>

      <div class="mat-elevation-z1">
        <span>{{t('section1.tableAccessibilita.title')}}</span>

        <table mat-table [dataSource]="spaziAccessbilita.controls" formArrayName="spaziAccessbilita">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableAccessibilita.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableLeggibilita.title')}}</span>

        <table mat-table [dataSource]="leggibilitaRiconoscibilita.controls" formArrayName="leggibilitaRiconoscibilita">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableLeggibilita.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tabledifferenziazioneFunzionale.title')}}</span>

        <table mat-table [dataSource]="differenziazioneFunzionale.controls" formArrayName="differenziazioneFunzionale">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tabledifferenziazioneFunzionale.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableSpaziPersonalizzazione.title')}}</span>

        <table mat-table [dataSource]="spaziPersonalizzazione.controls" formArrayName="spaziPersonalizzazione">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableSpaziPersonalizzazione.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableTempiPrevedibilitaRiconoscibilita.title')}}</span>

        <table mat-table [dataSource]="tempiPrevedibilitaRiconoscibilita.controls"
          formArrayName="tempiPrevedibilitaRiconoscibilita">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableTempiPrevedibilitaRiconoscibilita.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableTempiPersonalizzazione.title')}}</span>

        <table mat-table [dataSource]="tempiPersonalizzazione.controls" formArrayName="tempiPersonalizzazione">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableTempiPersonalizzazione.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableTempiContinuitaCambiamenti.title')}}</span>

        <table mat-table [dataSource]="tempiContinuitaCambiamenti.controls" formArrayName="tempiContinuitaCambiamenti">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableTempiContinuitaCambiamenti.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableRelazioniBenessere.title')}}</span>

        <table mat-table [dataSource]="relazioniBenessere.controls" formArrayName="relazioniBenessere">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableRelazioniBenessere.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableRelazioniPersonalizzazione.title')}}</span>

        <table mat-table [dataSource]="relazioniPersonalizzazione.controls" formArrayName="relazioniPersonalizzazione">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableRelazioniPersonalizzazione.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableRelazioniSocialitaProcessi.title')}}</span>

        <table mat-table [dataSource]="relazioniSocialitaProcessi.controls" formArrayName="relazioniSocialitaProcessi">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableRelazioniSocialitaProcessi.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableProposteEducativeIntenzionalita.title')}}</span>

        <table mat-table [dataSource]="proposteEducativeIntenzionalita.controls"
          formArrayName="proposteEducativeIntenzionalita">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableProposteEducativeIntenzionalita.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableProposteEducativeSignificativitacontinuita.title')}}</span>

        <table mat-table [dataSource]="proposteEducativeSignificativitacontinuita.controls"
          formArrayName="proposteEducativeSignificativitacontinuita">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableProposteEducativeSignificativitacontinuita.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section1.tableProposteEducativeVarietaCoerenza.title')}}</span>

        <table mat-table [dataSource]="proposteEducativeVarietaCoerenza.controls"
          formArrayName="proposteEducativeVarietaCoerenza">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section1.tableProposteEducativeVarietaCoerenza.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

    </div>

    <div fxLayout="column" style="margin-top: 20px;" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <h3>{{t('section2.title2')}}</h3>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section2.tableRelazioniPartecipazioneFamiglie.title')}}</span>

        <table mat-table [dataSource]="relazioniPartecipazioneFamiglie.controls"
          formArrayName="relazioniPartecipazioneFamiglie">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section2.tableRelazioniPartecipazioneFamiglie.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section2.tableCulturaGenitorialita.title')}}</span>

        <table mat-table [dataSource]="culturaGenitorialita.controls" formArrayName="culturaGenitorialita">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section2.tableCulturaGenitorialita.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section2.tableRapportoTerritorioAzioniRaccordo.title')}}</span>

        <table mat-table [dataSource]="rapportoTerritorioAzioniRaccordo.controls"
          formArrayName="rapportoTerritorioAzioniRaccordo">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section2.tableRapportoTerritorioAzioniRaccordo.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section2.tableRapportoTerritorioCulturaComunitaEducante.title')}}</span>

        <table mat-table [dataSource]="rapportoTerritorioCulturaComunitaEducante.controls"
          formArrayName="rapportoTerritorioCulturaComunitaEducante">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section2.tableRapportoTerritorioCulturaComunitaEducante.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>
    </div>

    <div fxLayout="column" style="margin-top: 20px;" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <h3>{{t('section3.title3')}}</h3>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section3.tableCoordinamentoGruppoLavoroCollegialita.title')}}</span>

        <table mat-table [dataSource]="coordinamentoGruppoLavoroCollegialita.controls"
          formArrayName="coordinamentoGruppoLavoroCollegialita">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section3.tableCoordinamentoGruppoLavoroCollegialita.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section3.tableCoordinamentoGruppoLavoroFormazioneContestualizzata.title')}}</span>

        <table mat-table [dataSource]="coordinamentoGruppoLavoroFormazioneContestualizzata.controls"
          formArrayName="coordinamentoGruppoLavoroFormazioneContestualizzata">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section3.tableCoordinamentoGruppoLavoroFormazioneContestualizzata.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section3.tableCoordinamentoGruppoLavoroCoordinamentoPedagogico.title')}}</span>

        <table mat-table [dataSource]="coordinamentoGruppoLavoroCoordinamentoPedagogico.controls"
          formArrayName="coordinamentoGruppoLavoroCoordinamentoPedagogico">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section3.tableCoordinamentoGruppoLavoroCoordinamentoPedagogico.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section3.tableCoordinamentoGruppoLavoroProcessiRiflessione.title')}}</span>

        <table mat-table [dataSource]="coordinamentoGruppoLavoroProcessiRiflessione.controls"
          formArrayName="coordinamentoGruppoLavoroProcessiRiflessione">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section3.tableCoordinamentoGruppoLavoroProcessiRiflessione.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section3.tableProgettazioneIntenzionalitaCoerenza.title')}}</span>

        <table mat-table [dataSource]="progettazioneIntenzionalitaCoerenza.controls" formArrayName="progettazioneIntenzionalitaCoerenza">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section3.tableProgettazioneIntenzionalitaCoerenza.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section3.tableProgettazioneContestualizzazioneFlessibilita.title')}}</span>

        <table mat-table [dataSource]="progettazioneContestualizzazioneFlessibilita.controls" formArrayName="progettazioneContestualizzazioneFlessibilita">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section3.tableProgettazioneContestualizzazioneFlessibilita.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section3.tableDocumentazioneSistematicitaCoerenza.title')}}</span>

        <table mat-table [dataSource]="documentazioneSistematicitaCoerenza.controls" formArrayName="documentazioneSistematicitaCoerenza">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section3.tableDocumentazioneSistematicitaCoerenza.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section3.tableDocumentazioneLeggibilita.title')}}</span>

        <table mat-table [dataSource]="documentazioneLeggibilita.controls" formArrayName="documentazioneLeggibilita">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section3.tableDocumentazioneLeggibilita.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

    </div>

    <div fxLayout="column" style="margin-top: 20px;" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <h3>{{t('section4.title4')}}</h3>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section4.tableSistematicita.title')}}</span>

        <table mat-table [dataSource]="sistematicita.controls"
          formArrayName="sistematicita">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section4.tableSistematicita.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

      <div class="mat-elevation-z1 mt-4">
        <span>{{t('section4.tableFunzioneFormativa.title')}}</span>

        <table mat-table [dataSource]="funzioneFormativa.controls" formArrayName="funzioneFormativa">

          <ng-container matColumnDef="descrittore">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.descrittore')}}
            </th>
            <td mat-cell *matCellDef="let element;" class="p-2">
              {{t( 'section4.tableFunzioneFormativa.descrittori.' + element?.value?.descrittore?.id)}}
            </td>
          </ng-container>

          <ng-container matColumnDef="osservazioniEmpiriche">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.osservazioni')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="osservazioniEmpiriche"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="puntiDiForza">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.puntiForza')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="puntiDiForza"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="azioniDiMiglioramento">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.azioniMiglioramento')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="azioniDiMiglioramento"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <ng-container matColumnDef="approfindimentiProcedure">
            <th mat-header-cell *matHeaderCellDef>
              {{t('tableGeneric.column.approfondimenti')}}
            </th>
            <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i" class="p-2">
              <mat-form-field style="width: auto;">
                <textarea matInput formControlName="approfindimentiProcedure"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>
      </div>

    </div>
    <button #button type="submit" class="d-none"></button>
  </form>
</ng-container>
