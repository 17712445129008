import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  filterStore,
  NgEntityService,
  NgEntityServiceConfig,
  NgEntityServiceLoader,
  NgEntityServiceNotifier,
} from '@datorama/akita-ng-entity-service';
import {
  AnnoScolasticoPeriodoFormativo,
  IAnnoScolasticoPeriodoFormativo,
  ReadManyParams,
  ReadManyResponse,
} from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AnniScolasticiQuery } from './anni-scolastici.query';
import {
  AnniScolasticiState,
  AnniScolasticiStore,
} from './anni-scolastici.store';

@NgEntityServiceConfig({
  resourceName: 'anniscolastici',
})
@Injectable({ providedIn: 'root' })
export class AnniScolasticiService extends NgEntityService<
AnniScolasticiState
> {
  loaders = this.loaderService.loadersFor(
    'lbl-app-data-access-anni-scolastici',
  );
  notifiers$ = this.notifierService.action$.pipe(
    filterStore('lbl-app-data-access-anni-scolastici'),
  );

  constructor(
    public query: AnniScolasticiQuery,
    public store: AnniScolasticiStore,
    protected loaderService: NgEntityServiceLoader,
    protected notifierService: NgEntityServiceNotifier,
  ) {
    super(store);
  }

  readMany(
    params?: ReadManyParams,
  ): Observable<ReadManyResponse<AnnoScolasticoPeriodoFormativo>> {
    const httpParams = new HttpParams({ fromObject: params as any });

    return this.get<ReadManyResponse<IAnnoScolasticoPeriodoFormativo>>({
      params: httpParams,
    }).pipe(
      map((response) => {
        const mappedResponse: ReadManyResponse<AnnoScolasticoPeriodoFormativo> = {
          ...response,
          data: (response && response.data) ? response.data.map((entity) => new AnnoScolasticoPeriodoFormativo(entity)) : [],
        };

        return mappedResponse;
      }),
    );
  }

  readById(id: number): Observable<AnnoScolasticoPeriodoFormativo> {
    return this.get<IAnnoScolasticoPeriodoFormativo>(id).pipe(
      map((entity) => new AnnoScolasticoPeriodoFormativo(entity)),
    );
  }
}
