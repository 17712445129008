<ng-container *transloco="let t; read: 'gestioneColloquiIniziali.nidoInfanzia'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>
      <!-- Dati anagrafici -->
      <h3 class="subtitle">
        <span class="status"></span>
        <span>{{t('datiAnagrafici.title')}}</span>
      </h3>
      <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start">
        <mat-form-field fxFill appearance="outline">
          <mat-chip-list #chipList formControlName="partecipantiColloquio">
            <mat-chip color="primary" *ngFor="let partecipante of formPartecipantiColloquio.value; let i = index"
              [selectable]="true" [removable]="true" (removed)="removePartecipanteColloquio(i)">
              {{ partecipante }}
              <mat-icon svgIcon="delete" matChipRemove></mat-icon>
            </mat-chip>

            <input placeholder="{{t('datiAnagrafici.partecipantiAlColloquio')}}" [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="partecipantiColloquioSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
              (matChipInputTokenEnd)="addPartecipanteColloquio($event)" />
          </mat-chip-list>
        </mat-form-field>

        <div formArrayName="domande">
          <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
            <mat-form-field *ngIf="i >= domandeDatiAnagraficiMin && i < domandeDatiAnagraficiMax" fxFill
              appearance="outline" [formGroupName]="i">
              <mat-label>{{ t('domande.' + domanda.value.codiceDomanda) }}</mat-label>
              <textarea matInput formControlName="rispostaDomanda"></textarea>
            </mat-form-field>
          </ng-container>
        </div>

        <div fxLayout="row">
          <mat-form-field appearance="outline" fxFlex="30">
            <mat-label>{{ t('datiAnagrafici.inizioAmbientamento') }}</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="inizioAmbientamento" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>

        <h3>{{ t('datiAnagrafici.orarioFrequenza') }}</h3>
        <div class="mat-elevation-z1" formArrayName="orarioFrequenzaRichiesto">
          <table mat-table [dataSource]="orarioFrequenzaRows">
            <!-- Fascia -->
            <ng-container matColumnDef="fascia">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                {{ 'type.' + element | transloco }}
              </td>
            </ng-container>

            <!-- Giorni -->
            <ng-container *ngFor="let column of orarioFrequenzaColumns">
              <ng-container [matColumnDef]="column">
                <th mat-header-cell *matHeaderCellDef>{{ 'type.' + column | transloco }}</th>
                <td mat-cell *matCellDef="let element"
                  [formGroupName]="getOrarioFrequenzaRichiestoFormGroupName(column)">
                  <input style="width: auto;" type="time" matInput
                    [formControlName]="getOrarioFrequenzaRichiestoFormControlName(element)" />
                </td>
              </ng-container>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="orarioFrequenzaDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: orarioFrequenzaDisplayedColumns"></tr>
          </table>
        </div>

        <mat-form-field appearance="outline" fxFill>
          <mat-label>{{ t('datiAnagrafici.note') }}</mat-label>
          <textarea matInput formControlName="noteAnagrafica"></textarea>
        </mat-form-field>
      </div>

      <!-- Domande alla famiglia -->
      <h3 class="subtitle">
        <span class="status"></span>
        <span>{{t('domandeFamiglia.title')}}</span>
      </h3>
      <div fxLayout="column" fxLayoutGap="1em" formArrayName="domande">
        <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
          <mat-form-field *ngIf="i >= domandeAllaFamigliaMin" fxFill appearance="outline" [formGroupName]="i">
            <mat-label>{{ t('domande.' + domanda.value.codiceDomanda) }}</mat-label>
            <textarea matInput formControlName="rispostaDomanda"></textarea>
          </mat-form-field>
        </ng-container>
      </div>

      <!-- Riflessioni sul colloquio e prime impressioni rispetto la famiglia -->
      <h3 class="subtitle">
        <span class="status"></span>
        <span>{{t('riflessioniColloquio.title')}}</span>
      </h3>
      <mat-form-field appearance="outline" fxFill>
        <mat-label>{{t('riflessioniColloquio.note')}}</mat-label>
        <textarea matInput formControlName="noteRiflessioni"></textarea>
      </mat-form-field>

      <!-- Informazioni significative emerse dopo il colloquio -->
      <h3 class="subtitle">
        <span class="status"></span>
        <span>{{t('notePostColloquio.title')}}</span>
      </h3>
      <mat-form-field appearance="outline" fxFill>
        <mat-label>{{t('notePostColloquio.note')}}</mat-label>
        <textarea matInput formControlName="notePostColloquio"></textarea>
      </mat-form-field>
    </div>
    <button #button type="submit" class="d-none"></button>
  </form>
</ng-container>
