<ng-container *transloco="let t; read: 'rilevazioneProgettazioniAttivitaSviluppoPensieroEcologico.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <strong>
        {{t('numeroProgettiRealizzati')}}
        {{form.get('numeroProgettiRealizzati').value}}
      </strong>

      <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="end ">
        <button mat-raised-button color="primary" type="button" (click)="addProgettoRealizzato()">
          <mat-icon svgIcon="add"></mat-icon>
          {{t('table.add')}}
        </button>
      </div>

      <div class="mat-elevation-z1">
        <table mat-table #table [dataSource]="formProgettiRealizzati.controls" formArrayName="progettiRealizzati">
          <!-- Progettazione -->
          <ng-container matColumnDef="progettazione">
            <th mat-header-cell *matHeaderCellDef>{{t('table.column.progettazione')}}</th>
            <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
              <mat-form-field>
                <textarea matInput formControlName="progettazione"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Proposta -->
          <ng-container matColumnDef="proposte">
            <th mat-header-cell *matHeaderCellDef>{{t('table.column.proposte')}}</th>
            <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
              <mat-form-field>
                <textarea matInput formControlName="proposte"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Documentazione -->
          <ng-container matColumnDef="documentazione">
            <th mat-header-cell *matHeaderCellDef>{{t('table.column.documentazione')}}</th>
            <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
              <mat-form-field>
                <textarea matInput formControlName="documentazione"> </textarea>
              </mat-form-field>
            </td>
          </ng-container>

          <!-- Azioni Column -->
          <ng-container matColumnDef="azioni">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row; let i = index;">
              <button mat-icon-button color="primary" (click)="deleteProgettoRealizzato(i)"
                matTooltip="{{t('table.delete')}}">
                <mat-icon svgIcon="delete"></mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="progettiRealizzatiDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: progettiRealizzatiDisplayedColumns"></tr>
        </table>
      </div>

    </div>

    <button #button type="submit" class="d-none"></button>

  </form>
</ng-container>