import { Injectable } from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface ErrorState {
  error: any;
}

export function createInitialState(): ErrorState {
  return {
    error: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lbl-app-util-error' })
export class ErrorStore extends Store<ErrorState> {
  constructor() {
    super(createInitialState());
  }
}
