import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { FunzionalitaLBL, IFunzionalitaLBL } from './FunzionalitaLBL';
import {
  ISezioneAnnoScolasticoPeriodoFormativo,
  SezioneAnnoScolasticoPeriodoFormativo,
} from './SezioneAnnoScolasticoPeriodoFormativo';
import { IStrutturaEducativa, StrutturaEducativa } from './StrutturaEducativa';

export enum EnumTipologiaRuoloApplicativo {
  AmministratoreLBL = 'RuoloAmministratoreLBL',
  Auditor = 'RuoloAuditor',
  CoordinatorePedagogico = 'RuoloCoordinatorePedagogico',
  Cuoco = 'RuoloCuoco',
  EducatoreInsegnante = 'RuoloEducatore_Insegnante',
  ParentiBambino = 'RuoloParentiBambino',
  UtenteChiosco = 'RuoloUtenteChiosco',
}

export interface IRuoloApplicativo extends IBaseBusinessObject {
  tipo: EnumTipologiaRuoloApplicativo;

  /**
   * Relazioni
   */
  coinvoltoInSezioniPerAnnoScolasticoInCorso: ISezioneAnnoScolasticoPeriodoFormativo[];
  coinvoltoInStruttureEducativePerAnnoScolasticoInCorso: IStrutturaEducativa[];
  funzionalitaMenu: IFunzionalitaLBL[];
}

export class RuoloApplicativo extends BaseBusinessObject
  implements IRuoloApplicativo {
  tipo: EnumTipologiaRuoloApplicativo;

  /**
   * Relazioni
   */
  coinvoltoInSezioniPerAnnoScolasticoInCorso: SezioneAnnoScolasticoPeriodoFormativo[];
  coinvoltoInStruttureEducativePerAnnoScolasticoInCorso: StrutturaEducativa[];
  funzionalitaMenu: FunzionalitaLBL[];

  constructor(params?: Partial<IRuoloApplicativo>) {
    super(params);

    /**
     * Mapping
     */
    if (params.coinvoltoInSezioniPerAnnoScolasticoInCorso) {
      this.coinvoltoInSezioniPerAnnoScolasticoInCorso = params.coinvoltoInSezioniPerAnnoScolasticoInCorso.map(
        (value) => new SezioneAnnoScolasticoPeriodoFormativo(value),
      );
    }
    if (params.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso) {
      this.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso = params.coinvoltoInStruttureEducativePerAnnoScolasticoInCorso.map(
        (value) => new StrutturaEducativa(value),
      );
    }
    if (params.funzionalitaMenu) {
      this.funzionalitaMenu = params.funzionalitaMenu.map(
        (value) => new FunzionalitaLBL(value),
      );
    }
  }

  deconstructor(): IRuoloApplicativo {
    return Object.assign({ }, this);
  }
}
