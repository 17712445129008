import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AllegatiService } from '@gpi/lbl/app/data-access/allegati';
import {
  Allegato,
  EnumAllegatiTabellaDiProvenienza,
  EnumTipologiaAllegato,
  StrumentoDiRegistrazioneArchivio,
} from '@gpi/lbl/shared/entity';
import {
  RicadutaAttivitaFormativa,
  RicadutaAttivitaFormativaRow,
} from 'libs/lbl/shared/entity/src/lib/base/StrumentiRegistrazione/RicadutaAttivitaFormativa';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-ricaduta-attivita-formativa',
  templateUrl: './ricaduta-attivita-formativa.component.html',
  styleUrls: ['./ricaduta-attivita-formativa.component.scss'],
})
export class RicadutaAttivitaFormativaComponent implements OnInit, OnChanges {

  @Input() strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Output() updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();
  @ViewChildren('inputContenuto') inputContenuto: QueryList<ElementRef>;
  displayedColumns = ['attivitaDaValutare', 'inizioAnno', 'fineAnno', 'allegatiCompilati'];

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): RicadutaAttivitaFormativa {
    let content = this.strumentoDiRegistrazioneArchivio.content as RicadutaAttivitaFormativa;

    if (!content) {
      content = RicadutaAttivitaFormativa.InitContent();
    }

    return content;
  }

  get osservazioniList(): FormArray {
    return this.form.get('osservazioniList') as FormArray;
  }

  form: FormGroup;
  tempAllegato: Array<Allegato> = [];
  tempFile: Array<File> = [];

  constructor(
    private allegatiService: AllegatiService,
  ) { }

  ngOnInit(): void {
    this.form = this.buildForm();

    this.form.valueChanges.subscribe(() => {
      this.submit(this.form);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      dataValutazione: new FormControl(this.content.dataValutazione),
      percorsoFormativo: new FormControl(this.content.percorsoFormativo),

      osservazioniList: this.buildFormDomande(this.content.osservazioniList),
    }, { updateOn: 'blur' });

    return form;
  }

  buildFormDomande(osservazioniList: RicadutaAttivitaFormativaRow[]): FormArray {
    const form = new FormArray(osservazioniList.map((value) => this.buildFormDomanda(value)));

    return form;
  }

  buildFormDomanda(osservazioneList: RicadutaAttivitaFormativaRow): FormGroup {
    const form = new FormGroup({
      attivitaDaValutare: new FormControl(osservazioneList.attivitaDaValutare),
      inizioAnno: new FormControl(osservazioneList.inizioAnno || false),
      fineAnno: new FormControl(osservazioneList.fineAnno || false),
      allegatiCompilati: new FormControl(osservazioneList.allegatiCompilati || null),
    });

    return form;
  }

  updateForm(entity: RicadutaAttivitaFormativa): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  upload(fileList: FileList, index) {
    for (const key in fileList) {
      if (fileList.hasOwnProperty(key)) {
        const file = fileList[key];
        const allegato = new Allegato({
          tableRowId: this.strumentiDiRegistrazioneArchivio[0].rowId,
          tabellaDiProvenienza: EnumAllegatiTabellaDiProvenienza.StrumentiRegistrazioneArchivio,
          tipologia: EnumTipologiaAllegato.ValutazioneRicadutaFormativa,
        });
        this.allegatiService.upload(allegato, file).subscribe((res) => {
          this.form.get(['osservazioniList', index, 'allegatiCompilati']).setValue(res);

          this.tempAllegato[index] = res;
          this.tempFile[index] = file;
        });
      }
    }
  }

  deleteFile(id: number, index) {
    this.allegatiService.deleteById(id).subscribe(() => {
      this.tempAllegato[index] = null;
      this.tempFile[index] = null;

      if (this.content?.osservazioniList[index]?.allegatiCompilati) {
        this.content.osservazioniList[index].allegatiCompilati = null;
        this.form.get(['osservazioniList', index, 'allegatiCompilati']).setValue(null);

      }
      this.form.get(['osservazioniList', index, 'allegatiCompilati']).setValue(null);
      this.inputContenuto['_results'][index].nativeElement.value = null;
    });
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const ricadutaAttivitaFormativaRow = new RicadutaAttivitaFormativa(form.getRawValue());
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: ricadutaAttivitaFormativaRow,
      });
      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      //console.log(strumentiDiRegistrazioneArchivio)
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }

}
