export interface Language {
  id: string;
  label: string;
}

export function createLanguage(params: Partial<Language>) {
  return {
    ...params,
  } as Language;
}
