import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Domanda, RegistrazioniCaduteIncidentiBambini, StrumentoDiRegistrazioneArchivio } from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-cadute-incidenti-bambini',
  templateUrl: './strumento-registrazione-cadute-incidenti-bambini.component.html',
  styleUrls: ['./strumento-registrazione-cadute-incidenti-bambini.component.scss'],
})
export class StrumentoRegistrazioneCaduteincidentiBambiniComponent implements OnInit, OnChanges {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): RegistrazioniCaduteIncidentiBambini {
    let content = this.strumentoDiRegistrazioneArchivio.content as RegistrazioniCaduteIncidentiBambini;

    if (!content) {
      content = RegistrazioniCaduteIncidentiBambini.InitContent();
    }

    return content;
  }

  form: FormGroup;

  get formTestimoni(): FormControl {
    return this.form.get('testimoni') as FormControl;
  }
  testimoniSeparatorKeysCodes: number[] = [ENTER, COMMA];

  get formDomande(): FormArray {
    return this.form.get('domande') as FormArray;
  }

  ngOnInit() {
    this.form = this.buildForm();

    if (this.strumentoDiRegistrazioneArchivio.status === 'Bozza') {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    }

    this.form.valueChanges.subscribe((el) => {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = this.updateForm(this.content);
      }
    }
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      data: new FormControl(this.content.data),
      ora: new FormControl(this.content.ora),
      testimoni: new FormControl(this.content.testimoni || []),

      domande: this.buildFormDomande(this.content.domande),
    });

    return form;
  }

  buildFormDomande(domande: Domanda[]): FormArray {
    const form = new FormArray(domande.map((value) => this.buildFormDomanda(value)));

    return form;
  }

  buildFormDomanda(domanda: Domanda): FormGroup {
    const form = new FormGroup({
      codiceDomanda: new FormControl(domanda.codiceDomanda),
      testoDomanda: new FormControl(domanda.testoDomanda),
      rispostaDomanda: new FormControl(domanda.rispostaDomanda),
    });

    return form;
  }

  updateForm(entity: RegistrazioniCaduteIncidentiBambini): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  addTestimoni(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {

      const values: string[] = Object.values(this.formTestimoni.value);
      values.push(value.trim());

      this.formTestimoni.setValue(values);
    }

    if (input) {
      input.value = '';
    }

    this.form.updateValueAndValidity();
  }

  removeTestimoni(index: number): void {
    const values: string[] = Object.values(this.formTestimoni.value);
    values.splice(index, 1);
    this.formTestimoni.setValue(values);
    this.form.updateValueAndValidity();
  }

  buildStrumentoForm() {
    const form = this.form.getRawValue();

    const content = new RegistrazioniCaduteIncidentiBambini(form);

    this.strumentiDiRegistrazioneArchivio[0].content = content;

    return this.strumentiDiRegistrazioneArchivio;
  }

  submit(form: FormGroup) {
    if (form.valid) {

    }
  }
}
