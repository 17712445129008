<div fxLayout="row" fxLayoutGap="1em">
  <div [fxFlex]="isMobile ? 'calc(100% - 1em)' : 'calc(40% - 1em)'">
    <lbl-app-ui-contenuti-chiosco [list]="list" [itemSelected]="itemSelected" [showType]="showType"
      [isMobile]="isMobile" (selectedItem)="selectedItem($event)">
    </lbl-app-ui-contenuti-chiosco>
  </div>
  <div fxFlex="calc(60% - 1em)" [fxHide.xs]="isMobile" [fxHide.sm]="isMobile" *ngIf="itemSelected">
    <lbl-app-ui-contenuto-chiosco-viewer [item]="itemSelected">
    </lbl-app-ui-contenuto-chiosco-viewer>
  </div>
</div>
