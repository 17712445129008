
export interface IRilevazionePromozioneAmbitiSviluppoNido {
    numeroProgettiRealizzati: number;

    /**
     * Relazioni
     */
    progettiRealizzati: IProgettiRealizzatiPromozioneSviluppoNidoRow[];
}

export class RilevazionePromozioneAmbitiSviluppoNido implements IRilevazionePromozioneAmbitiSviluppoNido {
    numeroProgettiRealizzati: number;

    /**
     * Relazioni
     */
    progettiRealizzati: ProgettiRealizzatiPromozioneSviluppoNidoRow[];


    constructor(params: Partial<IRilevazionePromozioneAmbitiSviluppoNido> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
        if (params.progettiRealizzati) {
            this.progettiRealizzati = params.progettiRealizzati.map((value) => new ProgettiRealizzatiPromozioneSviluppoNidoRow(value));
        }
    }

    deconstructor(): IRilevazionePromozioneAmbitiSviluppoNido {
        return Object.assign({}, this);
    }
}

export interface IProgettiRealizzatiPromozioneSviluppoNidoRow {
    progettazione: string;
    proposte: string;
    documentazione: string;
}

export class ProgettiRealizzatiPromozioneSviluppoNidoRow implements IProgettiRealizzatiPromozioneSviluppoNidoRow {
    progettazione: string;
    proposte: string;
    documentazione: string;

    constructor(params: Partial<IProgettiRealizzatiPromozioneSviluppoNidoRow> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
    }

    deconstructor(): IProgettiRealizzatiPromozioneSviluppoNidoRow {
        return Object.assign({}, this);
    }
}
