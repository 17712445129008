import { Domanda, IDomanda } from '../Domanda';


export interface IVerbaliSipi {
    numeroVerbale: string;
    dataIncontro: Date;
    luogoIncontro: string;
    partecipanti: string[];

    /**
     * Relazioni
     */
    domande: IDomanda[];

}

export class VerbaliSipi implements IVerbaliSipi {
    numeroVerbale: string;
    dataIncontro: Date;
    luogoIncontro: string;
    partecipanti: string[];

    /**
     * Relazioni
     */
    domande: Domanda[];

    constructor(params: Partial<IVerbaliSipi> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */

        if (params.domande) {
            this.domande = params.domande.map((value) => new Domanda(value));
        }
    }

    static InitContent(): VerbaliSipi {
        const content = new VerbaliSipi();
    
        const testiDomande = [
          'Principali contenuti e decisioni prese',
          'Temi affrontati',
          'Sintesi di quanto emerso',
          'Decisioni prese e proposte avanzate',
          'Modalità di verifica',
          'Altre informazioni'
        ];
    
        content.domande = testiDomande.map((value, index) => {
          return new Domanda({
            codiceDomanda: `VerbaliSipi_${index}`,
            rispostaDomanda: '',
            testoDomanda: value,
          });
        });

        return content;
      }

    deconstructor(): IVerbaliSipi {
        return Object.assign({}, this);
    }
}
