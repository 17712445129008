import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  Domanda,
  GiornoSettimanaStruttura,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
  VerbaliSipi,
  InformazioniAnagraficheSipi,
  FiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DialogFormFiguraProfessionaleComponent } from './dialog/dialog-form-figura-professionale.component';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-sipi-sezione-anagrafica',
  templateUrl: './sipi-sezione-anagrafica.component.html',
  styleUrls: ['./sipi-sezione-anagrafica.component.scss'],
})
export class SipiSezioneAnagraficaComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button')
  buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  figureProfessionaliDisplayedColumns = ['nome', 'cognome', 'funzione', 'telefono', 'altriRecapiti', 'azioni']

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): InformazioniAnagraficheSipi {
    let content = this.strumentoDiRegistrazioneArchivio.content as InformazioniAnagraficheSipi;

    return content;
  }

  form: FormGroup;

  get formFigureProfessionali(): FormArray {
    return this.form.get('figureProfessionaliDiRiferimento') as FormArray;
  }

  constructor(private matDialog: MatDialog) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      auslDiRiferimento: new FormControl(this.content && this.content.auslDiRiferimento ? this.content.auslDiRiferimento : null),
      neuropsichiatraDiRiferimento: new FormControl(this.content && this.content.neuropsichiatraDiRiferimento ? this.content.neuropsichiatraDiRiferimento : null),
      altriContattiAusl: new FormControl(this.content && this.content.altriContattiAusl ? this.content.altriContattiAusl : null),
      altroReferente: new FormControl(this.content && this.content.altroReferente ? this.content.altroReferente : null),
      numeroTelefono: new FormControl(this.content && this.content.numeroTelefono ? this.content.numeroTelefono : null),
      email: new FormControl(this.content && this.content.email ? this.content.email : null),
      altreInformazioni: new FormControl(this.content && this.content.altreInformazioni ? this.content.altreInformazioni : null),

      figureProfessionaliDiRiferimento: this.content && this.content.figureProfessionaliDiRiferimento ? this.buildFormFigureProfessionaliDiRiferimento(this.content.figureProfessionaliDiRiferimento) : new FormArray([]),
    });

    return form;
  }

  buildFormFigureProfessionaliDiRiferimento(figureProfessionali: FiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow[]): FormArray {
    const form = new FormArray(figureProfessionali.map((value) => this.buildFormFiguraProfessionaleDiRiferimento(value)));

    return form;
  }

  buildFormFiguraProfessionaleDiRiferimento(figuraDiRiferimento: FiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow): FormGroup {
    const form = new FormGroup({

      nome: new FormControl(figuraDiRiferimento.nome ? figuraDiRiferimento.nome : null, Validators.required),
      cognome: new FormControl(figuraDiRiferimento.cognome ? figuraDiRiferimento.cognome : null, Validators.required),
      funzione: new FormControl(figuraDiRiferimento.funzione ? figuraDiRiferimento.funzione : null),
      telefono: new FormControl(figuraDiRiferimento.telefono ? figuraDiRiferimento.telefono : null),
      altriRecapiti: new FormControl(figuraDiRiferimento.altriRecapiti ? figuraDiRiferimento.altriRecapiti : null),

    });

    return form;
  }

  updateForm(entity: InformazioniAnagraficheSipi): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  addFiguraProfessionale() {
    const dialogRef = this.matDialog.open(DialogFormFiguraProfessionaleComponent, { data: this.buildFormFiguraProfessionaleDiRiferimento(new FiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow()) });
    dialogRef.afterClosed().subscribe((entity: FormGroup) => {
      if (entity) {
        this.formFigureProfessionali.push(entity);
      }
    });
  }

  editFiguraProfessionale(index: number) {
    const dialogRef = this.matDialog.open(DialogFormFiguraProfessionaleComponent, { data: this.buildFormFiguraProfessionaleDiRiferimento(this.formFigureProfessionali.at(index).value) });
    dialogRef.afterClosed().subscribe((entity: FormGroup) => {
      if (entity) {
        this.formFigureProfessionali.at(index).patchValue(entity.value);
      }
    });
  }

  deleteFiguraProfessionale(index: number) {
    this.formFigureProfessionali.removeAt(index);
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const informazioniAnagraficheSipi = new InformazioniAnagraficheSipi(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: informazioniAnagraficheSipi,
      });
      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
