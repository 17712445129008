import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core'; import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi, StrumentoDiRegistrazioneArchivio, StrutturaEducativa } from '@gpi/lbl/shared/entity';
import { AreaEsterna, RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne, RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow } from 'libs/lbl/shared/entity/src/lib/base/StrumentiRegistrazione/rilevazione/RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'lbl-app-ui-rilevazione-allestimento-spazi-ricerche-materiali-aree-esterne',
  templateUrl: './rilevazione-allestimento-spazi-ricerche-materiali-aree-esterne.component.html',
  styleUrls: ['./rilevazione-allestimento-spazi-ricerche-materiali-aree-esterne.component.scss']
})
export class RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneComponent implements OnInit {

  @Input() strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input() strutturaEducativa: StrutturaEducativa;
  @Input() submit$: Observable<void>;
  @Output() updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button') buttonElementRef: ElementRef;

  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  unsubscribe$: Subject<void> = new Subject();

  progettiRealizzatiDisplayedColumns = ['centroInteresse', 'approfondimenti', 'materiali'];

  centriInteresseList: EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi[] = Object.values(EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi);
  areeEsterneArray = []
  form: FormGroup;
  indexA = 0
  sum = 0;

  constructor() { }

  getDocForm(areaEsterna: string, titolo: string) {
    return this.form.get(['AreeEsterne', areaEsterna, titolo]) as FormArray;
  }
  get areeEsterne() {
    return this.form.get('AreeEsterne') as FormArray
  }
  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne {
    let content = this.strumentoDiRegistrazioneArchivio.content as RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne;
    if (!content) {
      content = RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne.InitContent();
    }

    return content;
  }

  ngOnInit() {
    this.content.areeEsterne.forEach((el, i) => {

      this.areeEsterneArray.push(el)
    })

    this.form = new FormGroup({ AreeEsterne: this.buildForm() });

    this.form.valueChanges.subscribe(() => {
      this.submit(this.form);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = new FormGroup({
          AreeEsterne: this.buildForm()
        });
      }
    }
  }


  buildForm() {
    const formArray = new FormArray([]);
    this.areeEsterneArray.forEach((el, i) => {

      const form = new FormGroup({
        totAllestimentiRicerche: new FormControl(this.content.areeEsterne[i]?.totAllestimentiRicerche || 0),
        totMaterialiSupporto: new FormControl(this.content.areeEsterne[i]?.totMaterialiSupporto || 0),

        rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList: this.buildFormRilevazioni(this.content.areeEsterne[i]?.rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList || []),
      }, { updateOn: 'blur' });

      const formControlFields = [];
      formControlFields.push(i)
      this.indexA = i
      formControlFields.forEach(f => formArray.push(form));
      // formGroup = new FormGroup({ AreeEsterne: formArray });

    })
    return formArray;
  }

  buildFormRilevazioni(list: RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow[]): FormArray {
    const form = new FormArray(list.map((value) => this.buildFormRilevazione(value)));

    return form;
  }

  buildFormRilevazione(row: RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow): FormGroup {
    const form = new FormGroup({
      centroInteresse: new FormControl(row?.centroInteresse || null),
      approfondimenti: new FormControl(row?.approfondimenti || null),
      numApprofondimenti: new FormControl(row?.numApprofondimenti || null),
      materiali: new FormControl(row?.materiali || null),
      numMateriali: new FormControl(row?.numMateriali || null)
    }, { updateOn: 'blur' });

    return form;
  }

  addAreaEsterna() {
    this.indexA += 1

    this.areeEsterneArray.push(new AreaEsterna());

    this.form = new FormGroup({ AreeEsterne: this.buildForm() });

    this.form.valueChanges.subscribe(() => {
      this.submit(this.form);
    });
  }

  removeAreaEsterna(areaEsterna) {

    let removeAreaEsterna = this.form.get('AreeEsterne') as FormArray
    removeAreaEsterna.removeAt(areaEsterna)

    this.areeEsterneArray.splice(areaEsterna, 1)
    // this.content.areeEsterne.splice(areaEsterna, 1)

    // this.form = new FormGroup({ AreeEsterne: this.buildForm() });

    // let row = 'rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList'
    // this.calcolaTotaleNumAttivita(areaEsterna, row)

    // this.form.valueChanges.subscribe(() => {
    //   this.submit(this.form);
    // });

  }

  addrow(areaEsterna: string, row: string) {
    let newRow = this.form.get(['AreeEsterne', areaEsterna, row]) as FormArray
    newRow.push(this.buildFormRilevazione(new RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow()))

  }

  removeRow(areaEsterna: string, row: string, indexRow: number) {
    let removeRow = this.form.get(['AreeEsterne', areaEsterna, row]) as FormArray
    removeRow.removeAt(indexRow);

    this.calcoloTot(areaEsterna, row)
  }

  calcolaTotaleNumAttivita(areaEsterna: string, row: string) {

    let arrayRivelazioni = this.form.get(['AreeEsterne', areaEsterna, row]).value

    this.sum = arrayRivelazioni.length
    // this.form.get(['AreeEsterne', areaEsterna, 'totRow']).setValue(this.sum)

  }

  calcoloTot(areaEsterna: string, row: string) {
    let arrayRivelazioni: RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneRow[] = this.form.get(['AreeEsterne', areaEsterna, row]).value
    let arrayNumApprofondimenti = []
    let arrayNumMateriali = []
    arrayRivelazioni.forEach(el => {
      arrayNumApprofondimenti.push(el.numApprofondimenti)
      arrayNumMateriali.push(el.numMateriali)
    })
    this.form.get(['AreeEsterne', areaEsterna, 'totAllestimentiRicerche']).setValue(this.calcoloSum(arrayNumApprofondimenti));

    this.form.get(['AreeEsterne', areaEsterna, 'totMaterialiSupporto']).setValue(this.calcoloSum(arrayNumMateriali));

    let areeEsterne: AreaEsterna[] = this.areeEsterne.value
    let arraySumAllestimentiRicerche = []
    let arraySumMaterialiSupporto = []
    areeEsterne.forEach(area => {
      arraySumAllestimentiRicerche.push(area.totAllestimentiRicerche)
      arraySumMaterialiSupporto.push(area.totMaterialiSupporto)
    })

    this.content.sumAllestimentiRicerche = this.calcoloSum(arraySumAllestimentiRicerche)
    this.content.sumMaterialiSupporto = this.calcoloSum(arraySumMaterialiSupporto)
  }


  calcoloSum(array: number[]) {
    return array.reduce((acc, cur) => acc + cur, 0);
  }

  submit(form: FormGroup) {
    if (form.valid) {

      let areeEsterne: AreaEsterna[] = this.areeEsterne.value
      // this.content.areeEsterne = areeEsterne
      this.areeEsterne.controls.forEach((el, i) => {
        this.content.areeEsterne[i] = form.get(['AreeEsterne', i]).value
        this.strumentiDiRegistrazioneArchivio[0].content = this.content
      })

      // console.log(this.strumentiDiRegistrazioneArchivio)

      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.strumentiDiRegistrazioneArchivio);
    }
  }
}

