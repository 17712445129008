import { EnumPeriodoOsservazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividuali } from './GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido';

export enum EnumTipoAmbitiDiSviluppoCampiDiEsperienzaScuola {
    Corpo_in_movimento = "Corpo in movimento",
    Discorsi_e_parole = "Discorsi e parole",
    se_e_altro = "Sè e l\'altro",
    conoscenza_del_mondo = "Conoscenza del mondo",
    immagini_e_Colori = "Immagini e colori"
}

export interface IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola {

    periodoOsservazione: EnumPeriodoOsservazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividuali;
    dataOsservazioneDal: Date;
    dataOsservazioneAl: Date;
    allenamenti?: string;

    /**
     * Relazioni
     */
    template: ValutazioneAmbitiSviluppoScuolaTemplateRow[];

}

export class GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola implements IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola {

    periodoOsservazione: EnumPeriodoOsservazioneGestioneValutazioneAmbitiSviluppoCompetenzeIndividuali
    dataOsservazioneDal: Date;
    dataOsservazioneAl: Date;
    allenamenti?: string;

    /**
     * Relazioni
     */
    template: ValutazioneAmbitiSviluppoScuolaTemplateRow[];

    constructor(params: Partial<IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */

        if (params.template) {
            this.template = params.template.map(
                (value) => new ValutazioneAmbitiSviluppoScuolaTemplateRow(value),
            );
        }
    }

    static InitContent(): GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola {
        const content = new GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola();
        content.template = [];
        content.template.push(new ValutazioneAmbitiSviluppoScuolaTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienzaScuola.Corpo_in_movimento }));
        content.template.push(new ValutazioneAmbitiSviluppoScuolaTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienzaScuola.Discorsi_e_parole }));
        content.template.push(new ValutazioneAmbitiSviluppoScuolaTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienzaScuola.se_e_altro }));
        content.template.push(new ValutazioneAmbitiSviluppoScuolaTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienzaScuola.conoscenza_del_mondo }));
        content.template.push(new ValutazioneAmbitiSviluppoScuolaTemplateRow({ campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienzaScuola.immagini_e_Colori }));
        return content;
    }

    deconstructor(): IGestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola {
        return Object.assign({}, this);
    }
}

export interface IValutazioneAmbitiSviluppoScuolaTemplateRow {
    campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienzaScuola;
    esperienze: string;
    scaffolding: string;
    traguardiSviluppo: string;
    valutazioneTraguardiSviluppo: number;
    metaCognizione: string;
    traguardiSviluppoMetaCognizione: string;
    valutazioneTraguardiSviluppoMetaCognizione: number;
    note: string;
}

export class ValutazioneAmbitiSviluppoScuolaTemplateRow implements IValutazioneAmbitiSviluppoScuolaTemplateRow {
    campoEsperienza: EnumTipoAmbitiDiSviluppoCampiDiEsperienzaScuola;
    esperienze: string;
    scaffolding: string;
    traguardiSviluppo: string;
    valutazioneTraguardiSviluppo: number;
    metaCognizione: string;
    traguardiSviluppoMetaCognizione: string;
    valutazioneTraguardiSviluppoMetaCognizione: number;
    note: string;

    constructor(params: Partial<IValutazioneAmbitiSviluppoScuolaTemplateRow> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
    }

    deconstructor(): IValutazioneAmbitiSviluppoScuolaTemplateRow {
        return Object.assign({}, this);
    }
}

