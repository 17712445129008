import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { AuthService } from '@gpi/lbl/app/data-access/auth';
import { Domanda, EnumStatoStrumentoRegistrazione, GestioneScrittureProgettuali, RuoloApplicativo, StrumentoDiRegistrazioneArchivio } from '@gpi/lbl/shared/entity';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditorComponent } from '@ckeditor/ckeditor5-angular';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-gestione-scritture-progettuali',
  templateUrl: './strumento-registrazione-gestione-scritture-progettuali.component.html',
  styleUrls: ['./strumento-registrazione-gestione-scritture-progettuali.component.scss'],
})
export class StrumentoRegistrazioneGestioneScrittureProgettualiComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  ruoloApplicativoCorrente: RuoloApplicativo;
  unsubscribe$: Subject<void> = new Subject();

  editor: CKEditorComponent = ClassicEditor;

  constructor(private authService: AuthService) {

  }
  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): GestioneScrittureProgettuali {
    let content = this.strumentoDiRegistrazioneArchivio.content as GestioneScrittureProgettuali;

    if (!content) {
      content = GestioneScrittureProgettuali.InitContent();
    }

    return content;
  }

  form: FormGroup;

  get formDomande(): FormArray {
    return this.form.get('domande') as FormArray;
  }

  enumStatoStrumentoRegistrazione = EnumStatoStrumentoRegistrazione;

  ngOnInit() {
    this.authService.query.selectRuoloApplicativoCorrente().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((el) => this.ruoloApplicativoCorrente = el);

    this.form = this.buildForm();

    if (this.strumentoDiRegistrazioneArchivio.status === 'Bozza') {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    }

    this.form.valueChanges.subscribe((el) => {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = this.updateForm(this.content);
      }
    }
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      noteApprovazione: new FormControl(),

      domande: this.buildFormDomande(this.content.domande),
    });

    return form;
  }

  buildFormDomande(domande: Domanda[]): FormArray {
    const form = new FormArray(domande.map((value) => this.buildFormDomanda(value)));

    return form;
  }

  buildFormDomanda(domanda: Domanda): FormGroup {
    const form = new FormGroup({
      codiceDomanda: new FormControl(domanda.codiceDomanda),
      testoDomanda: new FormControl(domanda.testoDomanda),
      rispostaDomanda: new FormControl(domanda.rispostaDomanda),
    });

    return form;
  }

  updateForm(entity: GestioneScrittureProgettuali): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  buildStrumentoForm() {
    const form = this.form.getRawValue();
    this.strumentiDiRegistrazioneArchivio[0].content = form;

    return this.strumentiDiRegistrazioneArchivio;
  }

  submit(form: FormGroup) {
    if (form.valid) {

    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
