<ng-container *transloco="let t; read: 'gestioneSchedaControlloPulizie.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="row" fxLayoutGap="1em">
      <mat-form-field appearance="outline">
        <mat-label>{{t('data')}}</mat-label>
        <input matInput [matDatepicker]="dataInizioOsservazionePicker" formControlName="data">
        <mat-datepicker-toggle matSuffix [for]="dataInizioOsservazionePicker"></mat-datepicker-toggle>
        <mat-datepicker #dataInizioOsservazionePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{t('ora')}}</mat-label>
        <input matInput type="time" formControlName="ora">
      </mat-form-field>
    </div>

    <div class="mat-elevation-z1">
      <table mat-table [dataSource]="formListaControlli.controls" formArrayName="listaControlli">
        <!-- Descrizione controllo -->
        <ng-container matColumnDef="descrizioneControllo">
          <th mat-header-cell *matHeaderCellDef>{{t('table.column.descrizioneControllo')}}</th>
          <td mat-cell *matCellDef="let element">
            {{ t('table.controlli.' + element?.value?.descrizioneControllo) }}
          </td>
        </ng-container>

        <!-- Conforme -->
        <ng-container matColumnDef="conforme">
          <th mat-header-cell *matHeaderCellDef>{{t('table.column.conforme')}}</th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-checkbox formControlName="conforme"></mat-checkbox>
          </td>
        </ng-container>

        <!-- Osservazioni -->
        <ng-container matColumnDef="osservazioni">
          <th mat-header-cell *matHeaderCellDef>{{t('table.column.osservazioni')}}</th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="osservazioni"></textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Provvedimento-->
        <ng-container matColumnDef="provvedimento">
          <th mat-header-cell *matHeaderCellDef>{{t('table.column.provvedimento')}}</th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="provvedimento"></textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Data provvedimento -->
        <ng-container matColumnDef="dataProvvedimento">
          <th mat-header-cell *matHeaderCellDef>{{t('table.column.dataProvvedimento')}}</th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <input matInput [matDatepicker]="dataInizioOsservazionePicker" formControlName="dataProvvedimento">
              <mat-datepicker-toggle matSuffix [for]="dataInizioOsservazionePicker"></mat-datepicker-toggle>
              <mat-datepicker #dataInizioOsservazionePicker></mat-datepicker>
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="listaControlliDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: listaControlliDisplayedColumns"></tr>
      </table>
    </div>
  </form>
</ng-container>
