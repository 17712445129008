<ng-container *transloco="let t; read: 'sipiSezioneSintesiProgetto.strumentoRegistrazione.dialog'">
  <h3 mat-dialog-title>
    {{ t('title') }}
  </h3>
  <span>{{t('subtitle')}}</span>
  <mat-dialog-content>
    <div [innerHTML]="t('content')"></div>
  </mat-dialog-content>
  <mat-dialog-actions class="justify-content-end">
    <button mat-raised-button color="primary" (click)="cancel()">
      {{ t('cancel') }}
    </button>
  </mat-dialog-actions>
</ng-container>