import { IEnvironment } from '@gpi/lbl/shared/entity';

export const environment: IEnvironment = {
  title: 'Learning by Languages Staging',
  version: '0.0.1',

  develop: false,
  staging: true,
  production: false,

  urls: {
    api: 'https://lbyl-test.netico.it/lblonweb/rest',   //test
    //api: 'https://lbylweb.com/lblonweb/rest',         //prod
  },
};
