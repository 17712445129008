
export interface IStrumentoProgettualeSipi {
  noteStrategie: string;
  noteCambiamenti: string;

  /**
   * Relazioni
   */
  obiettivi: IObiettivoStrumentoProgettualeSipiRow[];
  areaDiLavoroAutonomie: AttivitaStrumentoProgettualeSipiRow[];
  areaDiLavoroLinguaggiComunicazioni: AttivitaStrumentoProgettualeSipiRow[];
  areaDiLavoroRelazioni: AttivitaStrumentoProgettualeSipiRow[];
  areaDiLavoroSapereFarSapere: AttivitaStrumentoProgettualeSipiRow[];
  verifiche: IObiettivoStrumentoProgettualeSipiRow[];
}

export class StrumentoProgettualeSipi implements IStrumentoProgettualeSipi {
  noteStrategie: string;
  noteCambiamenti: string;

  /**
   * Relazioni
   */
  obiettivi: ObiettivoStrumentoProgettualeSipiRow[];
  areaDiLavoroAutonomie: AttivitaStrumentoProgettualeSipiRow[];
  areaDiLavoroLinguaggiComunicazioni: AttivitaStrumentoProgettualeSipiRow[];
  areaDiLavoroRelazioni: AttivitaStrumentoProgettualeSipiRow[];
  areaDiLavoroSapereFarSapere: AttivitaStrumentoProgettualeSipiRow[];
  verifiche: ObiettivoStrumentoProgettualeSipiRow[];

  constructor(params: Partial<IStrumentoProgettualeSipi> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.obiettivi) {
      this.obiettivi = params.obiettivi.map((value) => new ObiettivoStrumentoProgettualeSipiRow(value));
    }
    if (params.areaDiLavoroAutonomie) {
      this.areaDiLavoroAutonomie = params.areaDiLavoroAutonomie.map((value) => new AttivitaStrumentoProgettualeSipiRow(value));
    }
    if (params.areaDiLavoroLinguaggiComunicazioni) {
      this.areaDiLavoroLinguaggiComunicazioni = params.areaDiLavoroLinguaggiComunicazioni.map((value) => new AttivitaStrumentoProgettualeSipiRow(value));
    }
    if (params.areaDiLavoroRelazioni) {
      this.areaDiLavoroRelazioni = params.areaDiLavoroRelazioni.map((value) => new AttivitaStrumentoProgettualeSipiRow(value));
    }
    if (params.areaDiLavoroSapereFarSapere) {
      this.areaDiLavoroSapereFarSapere = params.areaDiLavoroSapereFarSapere.map((value) => new AttivitaStrumentoProgettualeSipiRow(value));
    }
  }

  static InitContent(areeDiLavoro: string[]) {
    const content = new StrumentoProgettualeSipi();

    content.obiettivi = areeDiLavoro.map((value) => {
      return new ObiettivoStrumentoProgettualeSipiRow({
        areaDiLavoro: value,
        obiettivoGenerale: '',
        obiettivoSpecifico: '',
        descrittoriVerifica: '',
      });
    });
    content.verifiche = areeDiLavoro.map((value) => {
      return new ObiettivoStrumentoProgettualeSipiRow({
        areaDiLavoro: value,
        obiettivoGenerale: '',
        obiettivoSpecifico: '',
        descrittoriVerifica: '',
        verifica: '',
      });
    });
    content.areaDiLavoroAutonomie = new Array<AttivitaStrumentoProgettualeSipiRow>();
    content.areaDiLavoroLinguaggiComunicazioni = new Array<AttivitaStrumentoProgettualeSipiRow>();
    content.areaDiLavoroRelazioni = new Array<AttivitaStrumentoProgettualeSipiRow>();
    content.areaDiLavoroSapereFarSapere = new Array<AttivitaStrumentoProgettualeSipiRow>();

    content.noteStrategie = '';
    content.noteCambiamenti = '';

    return content;
  }

  deconstructor(): IStrumentoProgettualeSipi {
    return Object.assign({}, this);
  }
}

export interface IObiettivoStrumentoProgettualeSipiRow {
  areaDiLavoro: string;
  obiettivoGenerale: string;
  obiettivoSpecifico: string;
  descrittoriVerifica: string;
  verifica?: string;
}

export class ObiettivoStrumentoProgettualeSipiRow implements IObiettivoStrumentoProgettualeSipiRow {
  areaDiLavoro: string;
  obiettivoGenerale: string;
  obiettivoSpecifico: string;
  descrittoriVerifica: string;
  verifica?: string;

  constructor(params: Partial<IObiettivoStrumentoProgettualeSipiRow> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
  }

  deconstructor(): IObiettivoStrumentoProgettualeSipiRow {
    return Object.assign({}, this);
  }
}

export interface IAttivitaStrumentoProgettualeSipiRow {
  tempo: string;
  nome: string;
  descrizione: string;
  strategie: string;
  stimaDurata: string;
  materiali: string;
  spazio: string;
  adultiPropositori: string;
}

export class AttivitaStrumentoProgettualeSipiRow implements IAttivitaStrumentoProgettualeSipiRow {
  tempo: string;
  nome: string;
  descrizione: string;
  strategie: string;
  stimaDurata: string;
  materiali: string;
  spazio: string;
  adultiPropositori: string;

  constructor(params: Partial<IAttivitaStrumentoProgettualeSipiRow> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
  }

  deconstructor(): IAttivitaStrumentoProgettualeSipiRow {
    return Object.assign({}, this);
  }
}
