import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContenutoChioscoLBLArchivio } from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-contenuto-chiosco',
  templateUrl: 'contenuto-chiosco.component.html',
  styleUrls: ['contenuto-chiosco.component.scss'],
})
export class ContenutoChioscoComponent {
  @Input()
  item: ContenutoChioscoLBLArchivio;
  @Input()
  itemSelected: ContenutoChioscoLBLArchivio;

  @Input()
  showType = false;

  @Output()
  selectedItem: EventEmitter<ContenutoChioscoLBLArchivio> = new EventEmitter();
}
