import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { AuthorizationHeaderInterceptor } from './interceptors/authorization-header.interceptor';
import { RuoloApplicativoHeaderInterceptor } from './interceptors/ruolo-applicativo-header.interceptor';
import { SezioneAnnoScolasticoPeriodoFormativoHeaderInterceptor } from './interceptors/sezione-anno-scolastico-periodo-formativo-header.interceptor';
import { StrutturaEducativaHeaderInterceptor } from './interceptors/struttura-educativa-header.interceptor';
import { UtentiProvider } from './providers/utenti.provider';

export function preloadUtenti(provider: UtentiProvider) {
  return () => provider.load();
}

@NgModule()
export class LblAppUtilAuthModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LblAppUtilAuthModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthorizationHeaderInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: RuoloApplicativoHeaderInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: StrutturaEducativaHeaderInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: SezioneAnnoScolasticoPeriodoFormativoHeaderInterceptor,
          multi: true,
        },

        UtentiProvider,
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: preloadUtenti,
          deps: [UtentiProvider],
        },
      ],
    };
  }
}
