import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { BackendError } from '../models/backend-error.model';

export class BackendErrorInterceptor implements HttpInterceptor {
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      // retry(1),
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            err = new BackendError(err.message, err.error?.codiceErrore);
          }
        }

        return throwError(err);
      }),
    );
  }
}
