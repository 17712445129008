import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { EnumModuliLBL } from './ModuloLBLAttivatoPerStruttura';
import {
  IStrumentoDiRegistrazioneTemplate,
  StrumentoDiRegistrazioneTemplate,
} from './StrumentoDiRegistrazioneTemplate';

export interface IFunzionalitaLBL extends IBaseBusinessObject {
  cssClasses: string;
  isSimpleContainer: boolean;
  moduloDiRiferimento?: EnumModuliLBL;
  nome: string;
  orderIndex: number;
  parentId: string;
  routePath: string;
  translateKeyPlaceHolder: string;

  /**
   * Relazioni
   */
  strumentoDiRegistrazioneTemplate: IStrumentoDiRegistrazioneTemplate;
}

export class FunzionalitaLBL extends BaseBusinessObject
  implements IFunzionalitaLBL {
  cssClasses: string;
  isSimpleContainer: boolean;
  moduloDiRiferimento?: EnumModuliLBL;
  nome: string;
  orderIndex: number;
  parentId: string;
  routePath: string;
  translateKeyPlaceHolder: string;

  /**
   * Relazioni
   */
  strumentoDiRegistrazioneTemplate: StrumentoDiRegistrazioneTemplate;

  constructor(params?: Partial<IFunzionalitaLBL>) {
    super(params);

    /**
     * Mapping
     */
    if (params.strumentoDiRegistrazioneTemplate) {
      this.strumentoDiRegistrazioneTemplate = new StrumentoDiRegistrazioneTemplate(
        params.strumentoDiRegistrazioneTemplate,
      );
    }
  }

  deconstructor(): IFunzionalitaLBL {
    return Object.assign({ }, this);
  }
}
