<div fxLayout="column" fxLayoutGap="1em">
  <!-- Header -->
  <div class="align-self-start w-100">
    <!-- Title -->
    <h1 class="title">
      <span class="logo" [class]="funzionalitaLblSelezionata?.routePath"></span>
      {{ 'functionality.' + funzionalitaLblSelezionata?.translateKeyPlaceHolder | transloco }}
    </h1>

    <!-- Dati di contesto -->
    <div fxLayout="column" fxLayoutGap="1em">
      <div class="row">
        <!-- Struttura educativa rimossa per presenza in header -->
        <div class="col-auto">
          <span class="mat-caption">{{'strumentoRegistrazioneComponent.struttura' | transloco}}</span><br />
          <span>{{ strutturaEducativaSelected?.ragioneSociale }}</span>
        </div>
        <div class="col-auto">
          <span class="mat-caption">{{'strumentoRegistrazioneComponent.annoScolastico' | transloco}}</span><br />
          <span>{{ labelValueAnnoScolastico }}</span>
        </div>
        <div class="col-auto">
          <span class="mat-caption">{{'strumentoRegistrazioneComponent.periodo' | transloco}}</span><br />
          <span>{{ labelValuePeriodoRiferimentoCompilazione }}</span>
        </div>

        <div class="col-auto ml-auto"
          *ngIf="!isArchivio && ruoloApplicativoAttualmenteUtilizzatoParamInput?.tipo != 'RuoloParentiBambino'">
          <button mat-raised-button
            (click)="redirectToArchivio()">{{'strumentoRegistrazioneComponent.goToArchivio' | transloco}}</button>
        </div>

      </div>

      <!-- <div class="row">
        <div class="col-auto"
          *ngIf="strumentoDiRegistrazioneTemplateSelected?.enableIsRelativoAServizioEducativoSelector">
          <mat-form-field appearance="outline">
            <mat-label>Servizio educativo</mat-label>
            <mat-select (selectionChange)="changeSelectServiziEducativi($event)">
              <mat-option *ngFor="let servizio of serviziEducativiStrutturaSelezionata" [value]="servizio.id">
                {{ 'type.' + servizio?.tipologiaServizioOfferto | transloco}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-auto" *ngIf="strumentoDiRegistrazioneTemplateSelected?.enableIsRelativoASezioneSelector">
          <mat-form-field appearance="outline">
            <mat-label>Sezioni</mat-label>
            <mat-select (selectionChange)="changeSelectSezioni($event)">
              <mat-option *ngFor="let sezione of sezioniAnnoScolasticoPeriodoFormativoPerServizio" [value]="sezione.id">
                {{ sezione.nome }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-auto" *ngIf="strumentoDiRegistrazioneTemplateSelected?.enableIsRelativoABambinoSelector">
          <mat-form-field appearance="outline">
            <mat-label>Bambini</mat-label>
            <mat-select (selectionChange)="changeSelectBambini($event)">
              <mat-option *ngFor="let bambino of bambini" [value]="bambino.id" >
                {{ bambino.fullName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div> -->
      <div class="row">
        <div class="col-auto"
          *ngIf="strumentoDiRegistrazioneTemplateSelected?.enableIsRelativoAServizioEducativoSelector">
          <strong>{{'strumentoRegistrazioneComponent.servizio' | transloco}} </strong>:
          <select name="serviziEducativiStrutturaSelezionata" (change)="changeSelectServiziEducativi($event)"
            [disabled]="disableTemplateHeaderEditing">
            <option *ngFor="let servizio of serviziEducativiStrutturaSelezionata; let i = index"
              value="{{ servizio.id }}" [selected]="servizio.id === servizioEducativoSelectedId">
              {{ servizio.tipologiaServizioOfferto }}</option>
          </select>
        </div>

        <div class="col-auto" *ngIf="strumentoDiRegistrazioneTemplateSelected?.enableIsRelativoASezioneSelector">
          <strong>{{'strumentoRegistrazioneComponent.sezioni' | transloco}}</strong>:
          <select name="sezioniAnnoScolasticoPeriodoFormativoPerServizio" (change)="changeSelectSezioni($event)"
            [disabled]="disableTemplateHeaderEditing">
            <option *ngFor="let sezione of sezioniAnnoScolasticoPeriodoFormativoPerServizio; let i = index"
              value="{{ sezione.id }}" id="{{ i }}" [selected]="sezione.id === sezioneId">
              {{ sezione.nome }}
            </option>
          </select>
        </div>

        <div class="col-auto" *ngIf="strumentoDiRegistrazioneTemplateSelected?.enableIsRelativoAPersonaSelector">
          <strong
            *ngIf="strumentoDiRegistrazioneTemplateSelected?.isRelativoAPersona">{{'strumentoRegistrazioneComponent.bambini' | transloco}}:
          </strong>
          <strong
            *ngIf="strumentoDiRegistrazioneTemplateSelected?.isRelativoAInsegnante">{{'strumentoRegistrazioneComponent.insegnanti' | transloco}}:
          </strong>
          <select name="bambini" (change)="changeSelectBambini($event)" [disabled]="disableTemplateHeaderEditing">
            <option *ngFor="let bambino of personeCoinvolte; let i = index" id="{{ i }}" value="{{ bambino.id }}"
              [selected]="bambino.id === bambinoSelectId">
              @{{ bambino.fullName }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>

  <!-- Body -->
  <!-- <pre>
    {{istanzeStrumentoDiRegistrazioneArchivio | async | json}}
  </pre> -->
  <ng-container *ngIf="istanzeStrumentoDiRegistrazioneArchivio | async as strumentiDiRegistrazioneArchivio">
    <mat-card *ngIf="!isError && strumentiDiRegistrazioneArchivio.length > 0">
      <div class="mat-card-header" fxLayout="row" *ngIf="
        strumentoDiRegistrazioneTemplateBodySelected?.templateBodyViewMode === enumTemplateBodyViewMode.Full ||
        strumentoDiRegistrazioneTemplateBodySelected.templateBodyViewMode === enumTemplateBodyViewMode.ListItemCustom
      ">
        <!-- <span *ngIf="strumentiDiRegistrazioneArchivio[0]?.id"> -->
        <span *ngIf="strumentiDiRegistrazioneArchivio[0]">
          <ng-container
            *ngIf="strumentiDiRegistrazioneArchivio[0]?.dtInsert && !strumentiDiRegistrazioneArchivio[0]?.dtUpdate">
            {{'strumentoRegistrazioneComponent.creato' | transloco}} {{strumentiDiRegistrazioneArchivio[0]?.dtInsert}} -
            {{strumentiDiRegistrazioneArchivio[0]?.insertedBy}}
            {{strumentiDiRegistrazioneArchivio[0]?.updatedBy?.personaFisica?.cognome}}
          </ng-container>
          <ng-container
            *ngIf="!strumentiDiRegistrazioneArchivio[0]?.dtInsert && strumentiDiRegistrazioneArchivio[0]?.dtUpdate">
            {{'strumentoRegistrazioneComponent.modificato' | transloco}}
            {{strumentiDiRegistrazioneArchivio[0]?.dtInsert}} -
            {{strumentiDiRegistrazioneArchivio[0]?.updatedBy?.personaFisica?.nome}}
            {{strumentiDiRegistrazioneArchivio[0]?.updatedBy?.personaFisica?.cognome}}
          </ng-container>
        </span>
        <span fxFlex></span>
        <span class="ml-3 status" [class]="strumentiDiRegistrazioneArchivio[0]?.status">
          {{ 'type.' + strumentiDiRegistrazioneArchivio[0]?.status | transloco }}
        </span>
      </div>
      <!-- Content -->
      <mat-card-content>
        <fieldset [disabled]="disableTemplateBodyEditing">
          <ng-container [ngSwitch]="strumentoDiRegistrazioneTemplateBodySelected?.templateBodyViewMode">
            <!-- Full -->
            <ng-container *ngSwitchCase="enumTemplateBodyViewMode.Full"
              [ngSwitch]="strumentoDiRegistrazioneTemplateBodySelected?.templateBodyCode">
              <lbl-app-ui-strumento-registrazione-gestione-colloqui-iniziali-nido-infanzia
                *ngSwitchCase="enumTemplateBodyCode.GestioneColloquiInizialiNidoInfanzia"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                [strutturaEducativa]="strutturaEducativaSelected"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-gestione-colloqui-iniziali-nido-infanzia>

              <lbl-app-ui-strumento-registrazione-gestione-colloqui-iniziali-scuola-infanzia
                *ngSwitchCase="enumTemplateBodyCode.GestioneColloquiInizialiScuolaInfanzia"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                [strutturaEducativa]="strutturaEducativaSelected"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-gestione-colloqui-iniziali-scuola-infanzia>

              <lbl-app-ui-strumento-registrazione-gestione-valutazione-ambiti-sviluppo-competenze-individuali-nido
                *ngSwitchCase="enumTemplateBodyCode.GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                [strutturaEducativa]="strutturaEducativaSelected"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-gestione-valutazione-ambiti-sviluppo-competenze-individuali-nido>

              <lbl-app-ui-strumento-registrazione-gestione-valutazione-ambiti-sviluppo-competenze-individuali-scuola
                *ngSwitchCase="enumTemplateBodyCode.GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                [strutturaEducativa]="strutturaEducativaSelected"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-gestione-valutazione-ambiti-sviluppo-competenze-individuali-scuola>

              <lbl-app-ui-strumento-registrazione-gestione-osservazione-primi-giorni-ambientamento
                *ngSwitchCase="enumTemplateBodyCode.GestioneOsservazionePrimiGiorniDiAmbientamento"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-gestione-osservazione-primi-giorni-ambientamento>

              <lbl-app-ui-strumento-registrazione-gestione-progettazioni-settimanali
                *ngSwitchCase="enumTemplateBodyCode.GestioneProgettazioniSettimanali"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                [strutturaEducativa]="strutturaEducativaSelected"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-gestione-progettazioni-settimanali>

              <lbl-app-ui-strumento-registrazione-gestione-scheda-controllo-pulizie
                *ngSwitchCase="enumTemplateBodyCode.GestioneSchedaControlloPulizie"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-gestione-scheda-controllo-pulizie>

              <lbl-app-ui-relazione-sintesi *ngSwitchCase="enumTemplateBodyCode.RelazioneSintesi"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-relazione-sintesi>

              <lbl-app-ui-strumento-registrazione-gestione-scritture-progettuali
                *ngSwitchCase="enumTemplateBodyCode.GestioneScrittureProgettuali"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-gestione-scritture-progettuali>

              <lbl-app-ui-strumento-registrazione-pensieri-riflessioni
                *ngSwitchCase="enumTemplateBodyCode.PensieriRiflessioniPrimoPeriodoServizioEducativo"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-pensieri-riflessioni>

              <lbl-app-ui-strumento-registrazione-racconti-riflessioni
                *ngSwitchCase="enumTemplateBodyCode.RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-racconti-riflessioni>

              <lbl-app-ui-strumento-registrazione-cadute-incidenti-bambini
                *ngSwitchCase="enumTemplateBodyCode.RegistrazioniCaduteIncidentiBambini"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-cadute-incidenti-bambini>

              <lbl-app-ui-strumento-registrazione-verbale-valutazione-verifica
                *ngSwitchCase="enumTemplateBodyCode.VerbaleDiValutazioneEVerifica"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-verbale-valutazione-verifica>

              <lbl-app-ui-strumento-registrazione-sipi-sezione-verbali
                *ngSwitchCase="enumTemplateBodyCode.SipiSezioneVerbali"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-sipi-sezione-verbali>

              <lbl-app-ui-strumento-registrazione-sipi-sezione-anagrafica
                *ngSwitchCase="enumTemplateBodyCode.SipiSezioneAnagrafica"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-sipi-sezione-anagrafica>

              <lbl-app-ui-strumento-registrazione-sipi-sezione-raccolta-informazioni
                *ngSwitchCase="enumTemplateBodyCode.SipiRaccoltaInformazioni"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [strutturaEducativa]="strutturaEducativaSelected">
              </lbl-app-ui-strumento-registrazione-sipi-sezione-raccolta-informazioni>

              <lbl-app-ui-strumento-registrazione-sipi-sezione-situazione-iniziale
                *ngSwitchCase="enumTemplateBodyCode.SipiSituazioneIniziale"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-sipi-sezione-situazione-iniziale>

              <lbl-app-ui-strumento-registrazione-sipi-sezione-raccolta-informazioni
                *ngSwitchCase="enumTemplateBodyCode.SipiRaccoltaInformazioni"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [strutturaEducativa]="strutturaEducativaSelected">
              </lbl-app-ui-strumento-registrazione-sipi-sezione-raccolta-informazioni>


              <lbl-app-ui-strumento-registrazione-sezione-strumento-progettuale
                *ngSwitchCase="enumTemplateBodyCode.SipiStrumentoProgettuale"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                [isEnabledBody]="true"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [strutturaEducativa]="strutturaEducativaSelected">
              </lbl-app-ui-strumento-registrazione-sezione-strumento-progettuale>

              <lbl-app-ui-strumento-registrazione-sipi-sezione-sintesi-progetto
                *ngSwitchCase="enumTemplateBodyCode.SipiSintesiProgettoEducativo"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [strutturaEducativa]="strutturaEducativaSelected">
              </lbl-app-ui-strumento-registrazione-sipi-sezione-sintesi-progetto>

              <lbl-app-ui-strumento-registrazione-gestione-portfolio-competenze-bambini
                *ngSwitchCase="enumTemplateBodyCode.GestioniPortfolioCompetenzeBambini"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [strutturaEducativa]="strutturaEducativaSelected">
              </lbl-app-ui-strumento-registrazione-gestione-portfolio-competenze-bambini>

              <lbl-app-ui-strumento-registrazione-gestione-osservazioni-linguaggi-espressivi-creativita
                *ngSwitchCase="enumTemplateBodyCode.GestioneOsservazioniLinguaggiEspressiviCreativita"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-gestione-osservazioni-linguaggi-espressivi-creativita>

              <lbl-app-ui-strumento-registrazione-ricaduta-attivita-formativa
                *ngSwitchCase="enumTemplateBodyCode.RicadutaAttivitaFormativa"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-ricaduta-attivita-formativa>

              <lbl-app-ui-strumento-registrazione-autovalutazione-qualita-nido-infanzia
                *ngSwitchCase="enumTemplateBodyCode.AutovalutazioneQualitaSintesiStrutturaNidoInfanzia"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [strutturaEducativa]="strutturaEducativaSelected">
              </lbl-app-ui-strumento-registrazione-autovalutazione-qualita-nido-infanzia>

              <lbl-app-ui-strumento-registrazione-autovalutazione-qualita-scuola-infanzia
                *ngSwitchCase="enumTemplateBodyCode.AutovalutazioneQualitaSintesiStrutturaScuolaInfanzia"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [strutturaEducativa]="strutturaEducativaSelected">
              </lbl-app-ui-strumento-registrazione-autovalutazione-qualita-scuola-infanzia>

              <lbl-app-ui-strumento-registrazione-competenze-insegnanti
                *ngSwitchCase="enumTemplateBodyCode.CompetenzeInsegnanti"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [ruoloApplicativoCorrente]="ruoloApplicativoAttualmenteUtilizzatoParamInput">
              </lbl-app-ui-strumento-registrazione-competenze-insegnanti>

              <lbl-app-ui-strumento-registrazione-dicci-cosa-pensi-di-noi
                *ngSwitchCase="enumTemplateBodyCode.GradoSoddisfazioneGenitori"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-dicci-cosa-pensi-di-noi>

              <lbl-app-ui-strumento-registrazione-rilevazione-progettazioni-attivita-linguaggi-cibo
                *ngSwitchCase="enumTemplateBodyCode.RilevazioneProgettazioniAttivitaLinguaggiCibo"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-rilevazione-progettazioni-attivita-linguaggi-cibo>

              <lbl-app-ui-strumento-registrazione-rilevazione-progettazioni-attivita-sviluppo-pensiero-ecologico
                *ngSwitchCase="enumTemplateBodyCode.RilevazioneProgettazioniAttivitaSviluppoPensieroEcologico"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-rilevazione-progettazioni-attivita-sviluppo-pensiero-ecologico>

              <lbl-app-ui-strumento-registrazione-rilevazione-progettualita-sviluppo-linguaggi-espressivi-creativita
                *ngSwitchCase="enumTemplateBodyCode.RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-strumento-registrazione-rilevazione-progettualita-sviluppo-linguaggi-espressivi-creativita>

              <lbl-app-ui-strumento-registrazione-rilevazione-promozione-ambiti-sviluppo-nido
                *ngSwitchCase="enumTemplateBodyCode.RilevazionePromozioneAmbitiSviluppoNido"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [strutturaEducativa]="strutturaEducativaSelected">
              </lbl-app-ui-strumento-registrazione-rilevazione-promozione-ambiti-sviluppo-nido>

              <lbl-app-ui-strumento-registrazione-rilevazione-progettazioni-sviluppo-campi-esperienza-scuola-infanzia
                *ngSwitchCase="enumTemplateBodyCode.RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [strutturaEducativa]="strutturaEducativaSelected">
              </lbl-app-ui-strumento-registrazione-rilevazione-progettazioni-sviluppo-campi-esperienza-scuola-infanzia>

              <lbl-app-ui-rilevazione-allestimento-spazi-ricerche-materiali-aree-esterne
                *ngSwitchCase="enumTemplateBodyCode.RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)"
                [strutturaEducativa]="strutturaEducativaSelected">
              </lbl-app-ui-rilevazione-allestimento-spazi-ricerche-materiali-aree-esterne>
              <!-- relativo ai bambini recuparati dall archivio -->

              <ng-container *ngIf="strumentoDiRegistrazioneTemplateSelected?.isRelativoAPersona "
                [ngSwitch]="strumentoDiRegistrazioneTemplateBodySelected?.templateBodyCode">
                <lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-lattanti
                  *ngSwitchCase="enumTemplateBodyCode.GestioneSituazioneGiornalieraNidoInfanziaLattanti"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-lattanti>

                <lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-altre-fasce-eta
                  *ngSwitchCase="enumTemplateBodyCode.GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-altre-fasce-eta>

                <lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-scuola-infanzia-altre-fasce-eta
                  *ngSwitchCase="enumTemplateBodyCode.GestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-scuola-infanzia-altre-fasce-eta>

                <lbl-app-ui-strumento-registrazione-smarrimenti-oggetti-bambini
                  *ngSwitchCase="enumTemplateBodyCode.RegistrazioneSmarrimentiOggettiBambini"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-strumento-registrazione-smarrimenti-oggetti-bambini>

              </ng-container>

              <!-- relativo alle sezioni recuparate dall archivio -->
              <ng-container *ngIf="strumentoDiRegistrazioneTemplateSelected?.isRelativoASezione"
                [ngSwitch]="strumentoDiRegistrazioneTemplateBodySelected?.templateBodyCode">
                <lbl-app-ui-strumento-registrazione-rilevazione-allestimento-spazi-ricerche-materiali-servizi-educativi
                  *ngSwitchCase="enumTemplateBodyCode.RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-strumento-registrazione-rilevazione-allestimento-spazi-ricerche-materiali-servizi-educativi>

                <lbl-app-ui-strumento-registrazione-rilevazione-documentazione-progettuale-servizi-educativi
                  *ngSwitchCase="enumTemplateBodyCode.RilevazioneDocumentazioneProgettualeServiziEducativi"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-strumento-registrazione-rilevazione-documentazione-progettuale-servizi-educativi>

                <lbl-app-ui-strumento-registrazione-rilevazione-partecipazione-famiglie
                  *ngSwitchCase="enumTemplateBodyCode.RilevazionePartecipazioneFamiglie"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-strumento-registrazione-rilevazione-partecipazione-famiglie>

              </ng-container>

              <ng-container *ngIf="strumentoDiRegistrazioneTemplateSelected?.isRelativoAServizioEducativo"
                [ngSwitch]="strumentoDiRegistrazioneTemplateBodySelected?.templateBodyCode">
                <lbl-app-ui-standard-strutturali-organizzativi
                  *ngSwitchCase="enumTemplateBodyCode.ChecklistMonitoraggioStandardStrutturaliOrganizzativi"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-standard-strutturali-organizzativi>
                <lbl-app-ui-erogazione-servizio
                  *ngSwitchCase="enumTemplateBodyCode.ChecklistMonitoraggioErogazioneServizio"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-erogazione-servizio>
                <lbl-app-ui-family-engagement *ngSwitchCase="enumTemplateBodyCode.ChecklistMonitoraggioFamilyEngagement"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-family-engagement>
                <lbl-app-ui-implementazione-curricolo
                  *ngSwitchCase="enumTemplateBodyCode.ChecklistMonitoraggioImplementazioneCurricolo"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-implementazione-curricolo>
                <lbl-app-ui-implementazione-curricolo
                *ngSwitchCase="enumTemplateBodyCode.ChecklistMonitoraggioImplementazioneCurricoloNidoInfanzia"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-implementazione-curricolo>
              <lbl-app-ui-implementazione-curricolo
                *ngSwitchCase="enumTemplateBodyCode.ChecklistMonitoraggioImplementazioneCurricoloScuolaInfanzia"
                [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
              </lbl-app-ui-implementazione-curricolo>
                <lbl-app-ui-valutazione-efficacia-outcomes-servizio
                  *ngSwitchCase="enumTemplateBodyCode.ChecklistMonitoraggioValutazioneEfficaciaOutcomesServizio"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-valutazione-efficacia-outcomes-servizio>

              </ng-container>

            </ng-container>

            <!-- List item standard -->
            <ng-container *ngSwitchCase="enumTemplateBodyViewMode.ListItemStandard">
              <!--
            Significa che siamo in presenza di un body costruito a partire da una lista dei bambini in base alla sezione selezionata.
            Per ogni bambino avrò la possibilità di aprire in modale lo strumento di registrazione in viewmode full
          -->
              <ng-container *ngIf="strumentoDiRegistrazioneTemplateSelected?.isRelativoAPersona">
                <ng-container *ngFor="let strumento of strumentiDiRegistrazioneArchivio; let last = last">
                  <div class="row justify-content-between bambino">
                    <!-- Info -->
                    <div class="col-auto">
                      <div class="row">
                        <div class="col-auto">
                          <img *ngIf="(strumento?.personeCoinvolte)[0]?.avatar" class="avatar mat-elevation-z1"
                            [src]="(strumento?.personeCoinvolte)[0]?.avatar?.link" />
                          <div *ngIf="!(strumento?.personeCoinvolte)[0]?.avatar" class="avatar mat-elevation-z1">
                            <b>{{ (strumento?.personeCoinvolte)[0]?.initials }}</b>
                          </div>
                        </div>
                        <div class="col-auto" fxLayout="column">
                          <div class="bamino-info">
                            <b>{{ (strumento?.personeCoinvolte)[0]?.fullName }}</b><br />
                            <span>{{ (strumento?.personeCoinvolte)[0]?.ageInMonths }} mesi</span>
                          </div>
                          <span fxFlex></span>
                          <span class="status" [class]="strumento?.status">
                            {{ 'type.' + strumento?.status | transloco }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- Actions -->
                    <div class="col-auto">
                      <button mat-icon-button (click)="onClickCompilaStrumentoListItem(strumento)">
                        <mat-icon svgIcon="edit" matTooltip="{{'strumentoRegistrazioneComponent.compila' | transloco}}">
                        </mat-icon>
                      </button>
                    </div>
                  </div>

                  <mat-divider *ngIf="!last"></mat-divider>
                </ng-container>
              </ng-container>

              <!--
            Significa che siamo in presenza di un body costruito a partire da una lista di sezioni in base al servizo educativo selezionato.
            Per ogni sezione avro la possibilità di aprire in modale lo strumento di strumento di registrazione in viewmode full
          -->
              <ng-container *ngIf="strumentoDiRegistrazioneTemplateSelected?.isRelativoASezione">
                <ng-container
                  *ngFor="let strumentoDiRegistrazioneArchivio of strumentiDiRegistrazioneArchivio; let last = last">
                  <div class="row justify-content-between sezione">
                    <!-- Info -->
                    <div class="col-auto" fxLayout="column">
                      <b>{{ (strumentoDiRegistrazioneArchivio?.sezioni)[0]?.nome }}</b>
                      <span fxFlex></span>
                      <span class="status" [class]="strumentoDiRegistrazioneArchivio?.status">
                        {{ 'type.' + strumentoDiRegistrazioneArchivio?.status | transloco }}
                      </span>
                    </div>
                    <!-- Actions -->
                    <div class="col-auto">
                      <button mat-icon-button
                        (click)="onClickCompilaStrumentoListItem(strumentoDiRegistrazioneArchivio)">
                        <mat-icon svgIcon="edit" matTooltip="{{'strumentoRegistrazioneComponent.compila' | transloco}}">
                        </mat-icon>
                      </button>
                    </div>
                  </div>

                  <mat-divider *ngIf="last"></mat-divider>
                </ng-container>
              </ng-container>

              <!--
            Significa che siamo in presenza di un body costruito a partire da una lista di insegnanti in base alla sezione selezionata.
            Per ogni bambino avrò la possibilità di aprire in modale lo strumento di registrazione in viewmode full
          -->
              <ng-container *ngIf="strumentoDiRegistrazioneTemplateSelected?.isRelativoAInsegnante">
                <ng-container *ngFor="let strumento of strumentiDiRegistrazioneArchivio; let last = last">
                  <div class="row justify-content-between bambino">
                    <!-- Info -->
                    <div class="col-auto">
                      <div class="row">
                        <div class="col-auto">
                          <img *ngIf="(strumento?.personeCoinvolte)[0]?.avatar" class="avatar mat-elevation-z1"
                            [src]="(strumento?.personeCoinvolte)[0]?.avatar?.backgroundImageUrl" />
                          <div *ngIf="!(strumento?.personeCoinvolte)[0]?.avatar" class="avatar mat-elevation-z1">
                            <b>{{ (strumento?.personeCoinvolte)[0]?.initials }}</b>
                          </div>
                        </div>
                        <div class="col-auto" fxLayout="column">
                          <div class="bamino-info">
                            <b>{{ (strumento?.personeCoinvolte)[0]?.fullName }}</b><br />
                            <span>{{ (+(strumento?.personeCoinvolte)[0]?.ageInMonths) / 12 | number: '2.0-0' }}
                              {{'strumentoRegistrazioneComponent.anni' | transloco}}</span>
                          </div>
                          <span fxFlex></span>
                          <span class="status" [class]="strumento?.status">
                            {{ 'type.' + strumento?.status | transloco }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <!-- Actions -->
                    <div class="col-auto">
                      <button mat-icon-button (click)="onClickCompilaStrumentoListItem(strumento)">
                        <mat-icon svgIcon="edit" matTooltip="{{'strumentoRegistrazioneComponent.compila' | transloco}}">
                        </mat-icon>
                      </button>
                    </div>
                  </div>

                  <mat-divider *ngIf="!last"></mat-divider>
                </ng-container>
              </ng-container>
            </ng-container>

            <!-- List item custom -->
            <ng-container *ngSwitchCase="enumTemplateBodyViewMode.ListItemCustom">
              <!--
            Significa che siamo in presenza di un body costruito a partire da una lista dei bambini in base alla sezione selezionata.
            Lato template mi aspetto quidni di ciclare per ogni bambino e mostrare un html personalizzato
          -->

              <ng-container *ngIf="isLoading" class="container-loader">
                <mat-spinner mode="indeterminate" color="primary"></mat-spinner>
              </ng-container>
              <ng-container *ngIf="!isLoading">
                <ng-container *ngIf="strumentoDiRegistrazioneTemplateSelected?.isRelativoAPersona"
                  [ngSwitch]="strumentoDiRegistrazioneTemplateBodySelected?.templateBodyCode">
                  <lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-lattanti
                    *ngSwitchCase="enumTemplateBodyCode.GestioneSituazioneGiornalieraNidoInfanziaLattanti"
                    [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                    (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                  </lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-lattanti>

                  <lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-altre-fasce-eta
                    *ngSwitchCase="enumTemplateBodyCode.GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta"
                    [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                    (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                  </lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-nido-infanzia-altre-fasce-eta>

                  <lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-scuola-infanzia-altre-fasce-eta
                    *ngSwitchCase="enumTemplateBodyCode.GestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta"
                    [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                    (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                  </lbl-app-ui-strumento-registrazione-gestione-situazione-giornaliera-scuola-infanzia-altre-fasce-eta>
                </ng-container>
              </ng-container>

              <ng-container *ngIf="strumentoDiRegistrazioneTemplateSelected?.isRelativoASezione"
                [ngSwitch]="strumentoDiRegistrazioneTemplateBodySelected?.templateBodyCode">
                <lbl-app-ui-strumento-registrazione-rilevazione-allestimento-spazi-ricerche-materiali-servizi-educativi
                  *ngSwitchCase="enumTemplateBodyCode.RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-strumento-registrazione-rilevazione-allestimento-spazi-ricerche-materiali-servizi-educativi>

                <lbl-app-ui-strumento-registrazione-rilevazione-documentazione-progettuale-servizi-educativi
                  *ngSwitchCase="enumTemplateBodyCode.RilevazioneDocumentazioneProgettualeServiziEducativi"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-strumento-registrazione-rilevazione-documentazione-progettuale-servizi-educativi>

                <lbl-app-ui-strumento-registrazione-rilevazione-partecipazione-famiglie
                  *ngSwitchCase="enumTemplateBodyCode.RilevazionePartecipazioneFamiglie"
                  [strumentiDiRegistrazioneArchivio]="strumentiDiRegistrazioneArchivio"
                  (updatedStrumentiDiRegistrazioneArchivio)="getStrumentiRegistrazione($event)">
                </lbl-app-ui-strumento-registrazione-rilevazione-partecipazione-famiglie>

              </ng-container>

            </ng-container>
          </ng-container>
        </fieldset>
      </mat-card-content>

      <!-- Actions -->
      <mat-card-actions align="end" fxLayoutGap="1em">
        <!--
        Se in approvazione si può rifiutare o approvare e pubblicare azioni dell Coordinatore
      -->
        <ng-container
          *ngIf="strumentiDiRegistrazioneArchivio[0]?.status === 'InApprovazione'  && ruoloApplicativoAttualmenteUtilizzatoParamInput.tipo === 'RuoloCoordinatorePedagogico'">
          <button mat-stroked-button color="primary" (click)="action('Rifiutato')">
            {{'strumentoRegistrazioneComponent.actions.rifiuta' | transloco}}
          </button>

          <button mat-flat-button color="primary" (click)="action('Pubblicato')">
            {{'strumentoRegistrazioneComponent.actions.approva' | transloco}}
          </button>
        </ng-container>

        <!--
        azioni del Coordinatore su strumenti gia publicati
      -->
        <ng-container
          *ngIf="strumentiDiRegistrazioneArchivio[0]?.status !== 'InApprovazione' && ruoloApplicativoAttualmenteUtilizzatoParamInput?.tipo === 'RuoloCoordinatorePedagogico'">
          <button mat-stroked-button color="primary" (click)="action('Bozza')" [disabled]="disableTemplateBodyEditing">
            {{'strumentoRegistrazioneComponent.actions.bozza' | transloco}}
          </button>

          <button mat-flat-button color="primary" (click)="action('Pubblicato')"
            [disabled]="disableTemplateBodyEditing">
            {{'strumentoRegistrazioneComponent.actions.pubblica' | transloco}}
          </button>
        </ng-container>

        <!--
        azioni su strumenti
      -->
        <ng-container>

          <button *ngIf="ruoloApplicativoAttualmenteUtilizzatoParamInput?.tipo === 'RuoloEducatore_Insegnante'"
            mat-stroked-button color="primary" (click)="action('Bozza')" [disabled]="disableTemplateBodyEditing">
            {{'strumentoRegistrazioneComponent.actions.bozza' | transloco}}
          </button>
          <button
            *ngIf="strumentoDiRegistrazioneTemplateSelected?.prevedeApprovazione && ruoloApplicativoAttualmenteUtilizzatoParamInput?.tipo === 'RuoloEducatore_Insegnante'"
            mat-flat-button color="primary" (click)="action('InApprovazione')" [disabled]="disableTemplateBodyEditing">
            {{'strumentoRegistrazioneComponent.actions.richiediApprovazione' | transloco}}
          </button>
          <button
            *ngIf="!strumentoDiRegistrazioneTemplateSelected?.prevedeApprovazione && (ruoloApplicativoAttualmenteUtilizzatoParamInput?.tipo === 'RuoloEducatore_Insegnante' || ruoloApplicativoAttualmenteUtilizzatoParamInput?.tipo === 'RuoloParentiBambino') || ruoloApplicativoAttualmenteUtilizzatoParamInput?.tipo === 'RuoloAuditor'"
            mat-flat-button color="primary" (click)="action('Pubblicato')" [disabled]="disableTemplateBodyEditing">
            {{'strumentoRegistrazioneComponent.actions.pubblica' | transloco}}
          </button>
        </ng-container>

      </mat-card-actions>
    </mat-card>

    <mat-card class="align-self-center" *ngIf="isError || strumentiDiRegistrazioneArchivio.length === 0">
      <div fxLayout="column" fxLayoutGap="1em">
        <div *ngIf="isError; else maxCompilazioni">
          <p>{{'strumentoRegistrazioneComponent.' + errorText | transloco}}</p>
        </div>
        <ng-template #maxCompilazioni>
          <p>{{'strumentoRegistrazioneComponent.numMaxCompilazioni' | transloco}}</p>
        </ng-template>
        <button *ngIf="!isError " mat-raised-button
          (click)="redirectToArchivio()">{{'strumentoRegistrazioneComponent.goToArchivio' | transloco}}</button>
      </div>
    </mat-card>
  </ng-container>
</div>
