import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import {
  IStrumentoDiRegistrazioneArchivio,
  StrumentoDiRegistrazioneArchivio,
} from './StrumentoDiRegistrazioneArchivio';

export interface IStrumentoDiRegistrazioneIndicatori
  extends IBaseBusinessObject {
  entitaProvenienza: string;
  nomeIndicatore: string;
  rowIdEntita: string;

  /**
   * Relazioni
   */
  strumentoDiRegistrazioneArchivio: IStrumentoDiRegistrazioneArchivio;
}

export class StrumentoDiRegistrazioneIndicatori extends BaseBusinessObject
  implements IStrumentoDiRegistrazioneIndicatori {
  entitaProvenienza: string;
  nomeIndicatore: string;
  rowIdEntita: string;

  /**
   * Relazioni
   */
  strumentoDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio;

  constructor(params?: Partial<IStrumentoDiRegistrazioneIndicatori>) {
    super(params);

    /**
     * Mapping
     */
    if (params.strumentoDiRegistrazioneArchivio) {
      this.strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio(
        params.strumentoDiRegistrazioneArchivio,
      );
    }
  }

  deconstructor(): IStrumentoDiRegistrazioneIndicatori {
    return Object.assign({ }, this);
  }
}
