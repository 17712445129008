import { Domanda, IDomanda } from './Domanda';

export interface IRaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate {
  /**
   * Relazioni
   */
  domande: IDomanda[];
}

export class RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate
  implements IRaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate {

  constructor(params: Partial<IRaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params?.domande) {
      this.domande = params.domande.map((value) => new Domanda(value));
    }
  }
  /**
   * Relazioni
   */
  domande: Domanda[] = [];

  static InitContent(): RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate {
    const content = new RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate();

    const testiDomande = [
      'Avete osservato dei cambiamenti nel/la vostro/a bambino/a durante l’estate rispetto all’alimentazione, al sonno o altre abitudini?',
      'Ci sono delle autonomie e competenze che il vostro/a bambino/a ha acquisito ultimamente e che avete voglia di raccontarci (ad es. nei movimenti, nelle autonomie al momento del pranzo, nel controllo sfinterico, ecc…)',
      'Avete colto dei cambiamenti rispetto alla capacità di relazionarsi del/la vostro/a bambino/a con gli altri bambini e/o con gli adulti?',
      'Secondo voi si è modificata la sua capacità di esprimersi e in che modo? (ad es. attraverso i gesti, gli sguardi, le parole, ecc…)',
      'Ci sono stati durante l’estate eventi o episodi significativi rispetto alla crescita del vostro bambino che volete raccontarci?',
    ];

    content.domande = testiDomande.map((value, index) => {
      return new Domanda({
        codiceDomanda: `RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate_${index}`,
        rispostaDomanda: '',
        testoDomanda: value,
      });
    });

    return content;
  }

  deconstructor(): IRaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate {
    return Object.assign({}, this);
  }
}
