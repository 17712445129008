import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  CompetenzaInsegnantiRow,
  CompetenzeInsegnanti,
  GradoSoddisfazioneGenitori,
  RuoloApplicativo,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
  TaskEducatore,
  Valutazione,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-dicci-cosa-pensi-di-noi',
  templateUrl: './strumento-registrazione-dicci-cosa-pensi-di-noi.component.html',
  styleUrls: ['./strumento-registrazione-dicci-cosa-pensi-di-noi.component.scss'],
})
export class StrumentoRegistrazioneDicciCosaPensiDiNoiComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  ruoloApplicativoCorrente: RuoloApplicativo;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button')
  buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): GradoSoddisfazioneGenitori {
    let content = this.strumentoDiRegistrazioneArchivio.content as GradoSoddisfazioneGenitori;

    if (!content) {
      content = GradoSoddisfazioneGenitori.InitContent();
    }

    return content;
  }

  form: FormGroup;

  get valutazioni(): FormArray {
    return this.form.get('valutazioni') as FormArray;
  }

  qualitaAmbienteMin: number = 0;
  qualitaAmbienteMax: number = 1;

  aspettiOrganizzazioneMin: number = 2;
  aspettiOrganizzazioneMax: number = 4;

  informazioneMin: number = 5;
  informazioneMax: number = 7;

  alimentazioneMin: number = 8;
  alimentazioneMax: number = 9;

  partecipazioneMin: number = 10;
  partecipazioneMax: number = 10;

  progettoMin: number = 11;
  progettoMax: number = 14;

  giudizioComplessivo: number = 15;

  votiValutazione: number[] = [1, 2, 3, 4, 5];
  gradiValutazione: string[] = ['scarso', 'sufficiente', 'discreto', 'buono', 'ottimo'];

  isMobile: boolean = false;

  ngOnInit() {
    if (window.innerWidth < 1280) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.form = this.buildForm();

    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onResize(event) {
    if (event.target.innerWidth < 1280) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      valutazioni: this.content && this.content.valutazioni ? this.buildFormValutazioni(this.content.valutazioni) : new FormArray([]),
      cosaPiaceDelServizio: new FormControl(this.content && this.content.cosaPiaceDelServizio ? this.content.cosaPiaceDelServizio : null),
      cosaNonPiaceDelServizio: new FormControl(this.content && this.content.cosaNonPiaceDelServizio ? this.content.cosaNonPiaceDelServizio : null)
    });

    return form;
  }

  buildFormValutazioni(valutazioni: Valutazione[]): FormArray {
    const form = new FormArray(valutazioni.map((value) => this.buildValutazione(value)));

    return form;
  }

  buildValutazione(row: Valutazione): FormGroup {
    const form = new FormGroup({
      codice: new FormControl(row.codice ? row.codice : null),
      testo: new FormControl(row.testo ? row.testo : null),
      valutazione: new FormControl(row.valutazione ? row.valutazione : null)
    });

    return form;
  }

  updateForm(entity: GradoSoddisfazioneGenitori): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const gradoSoddisfazioneGenitori = new GradoSoddisfazioneGenitori(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: gradoSoddisfazioneGenitori,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
