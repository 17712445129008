<ng-container *transloco="let t; read: 'registrazioniCaduteIncidentiBambini.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div fxLayout="row" fxLayoutGap="1em">
      <mat-form-field appearance="outline">
        <mat-label>{{t('data')}}</mat-label>
        <input matInput [matDatepicker]="dataInizioOsservazionePicker" formControlName="data">
        <mat-datepicker-toggle matSuffix [for]="dataInizioOsservazionePicker"></mat-datepicker-toggle>
        <mat-datepicker #dataInizioOsservazionePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{t('ora')}}</mat-label>
        <input matInput type="time" formControlName="ora">
      </mat-form-field>
    </div>

    <mat-form-field fxFill appearance="outline" readonly="!isEnabledBody">
      <mat-chip-list #chipList formControlName="testimoni">
        <mat-chip color="primary" *ngFor="let presente of formTestimoni.value; let i = index" [selectable]="true"
          [removable]="true" (removed)="removeTestimoni(i)">
          {{ presente }}
          <mat-icon svgIcon="delete" matChipRemove></mat-icon>
        </mat-chip>

        <input placeholder="{{t('testimoni')}}" [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="testimoniSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
          (matChipInputTokenEnd)="addTestimoni($event)" />
      </mat-chip-list>
    </mat-form-field>

    <div fxLayout="column" fxLayoutGap="1em" formArrayName="domande">
      <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
        <mat-form-field fxFill appearance="outline" [formGroupName]="i">
          <mat-label>{{ t('domanda.' + domanda?.value?.codiceDomanda) }}</mat-label>
          <textarea matInput formControlName="rispostaDomanda"></textarea>
        </mat-form-field>
      </ng-container>
    </div>
  </form>
</ng-container>
