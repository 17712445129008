import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChildren } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatTable } from '@angular/material/table';
import { Domanda, StrumentoDiRegistrazioneArchivio } from '@gpi/lbl/shared/entity';
import { GestioneOsservazioniLinguaggiEspressiviCreativita, GestioneOsservazioniLinguaggiEspressiviCreativitaRow } from 'libs/lbl/shared/entity/src/lib/base/StrumentiRegistrazione/GestioneOsservazioniLinguaggiEspressiviCreativita';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-gestione-osservazioni-linguaggi-espressivi-creativita',
  templateUrl: './gestione-osservazioni-linguaggi-espressivi-creativita.component.html',
  styleUrls: ['./gestione-osservazioni-linguaggi-espressivi-creativita.component.scss'],
})
export class GestioneOsservazioniLinguaggiEspressiviCreativitaComponent implements OnInit, OnChanges {
  @Input() strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[];
  @Output() updatedStrumentiDiRegistrazioneArchivio = new EventEmitter();
  @ViewChildren(MatTable) _matTables;

  gruppoBambiniSeparatorKeysCodes: number[] = [ENTER, COMMA];
  TabellaDisplayedColumns = ['approcci', 'alfabeti', 'relazioni', 'linguaggi', 'note'];
  form: FormGroup;
  checkboxs = [{ nome: 'si', value: true, id: 0 }, { nome: 'no', value: false, id: 1 }];
  selectCheckbox: boolean;
  valueCheckbox: AbstractControl;
  constructor() { }

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get formGruppoBambini(): FormControl {
    return this.form.get('gruppoBambini') as FormControl;
  }

  get formTabella(): FormArray {
    return this.form.get('osservazioniList') as FormArray;
  }

  get content(): GestioneOsservazioniLinguaggiEspressiviCreativita {
    let content = this.strumentoDiRegistrazioneArchivio.content as GestioneOsservazioniLinguaggiEspressiviCreativita;
    if (!content) {
      content = GestioneOsservazioniLinguaggiEspressiviCreativita.InitContent();
    }

    return content;
  }

  ngOnInit() {
    //console.log(this.strumentiDiRegistrazioneArchivio);

    this.form = this.buildForm();
    //se modifico la tabella obiettici compilo anche la tabella verifica con in campi comuni

    this.form.valueChanges.subscribe(() => {
      this.submit(this.form);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

  }

  updateForm(entity: GestioneOsservazioniLinguaggiEspressiviCreativita): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      dataOsservazione: new FormControl(this.content.dataOsservazione),
      valutazioneComplessiva: new FormControl(this.content.valutazioneComplessiva),
      luogo: new FormControl(this.content.luogo),
      gruppoBambini: new FormControl(this.content.gruppoBambini || []),
      strumentiESupporti: new FormControl(this.content.strumentiESupporti),
      focusOsservativi: new FormControl(this.content.focusOsservativi),
      domandeAdulto: new FormControl(this.content.domandeAdulto),
      // domande: this.buildFormDomande(this.content.domande),
      osservazioniList: this.content && this.content.osservazioniList ? this.buildFormRigheTabella(this.content.osservazioniList) : new FormArray([]),
    }, { updateOn: 'blur' });

    return form;
  }

  buildFormRigheTabella(osservazioniList: GestioneOsservazioniLinguaggiEspressiviCreativitaRow[]): FormArray {
    const form = new FormArray(osservazioniList.map((value) => this.buildFormRigaTabella(value)));

    return form;
  }

  buildFormRigaTabella(osservazioniList: GestioneOsservazioniLinguaggiEspressiviCreativitaRow): FormGroup {
    const form = new FormGroup({
      approcciESperimentazioni: new FormControl(osservazioniList.approcciESperimentazioni),
      alfabeti: new FormControl(osservazioniList.alfabeti),
      relazioneConGruppoText: new FormControl(osservazioniList.relazioneConGruppoText),
      linguaggi: new FormControl(osservazioniList.linguaggi),
      note: new FormControl(osservazioniList.note),
    });

    return form;
  }

  addBambini(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {

      const values: string[] = Object.values(this.formGruppoBambini.value);
      values.push(value.trim());

      this.formGruppoBambini.setValue(values);
    }

    if (input) {
      input.value = '';
    }

    this.form.updateValueAndValidity();
  }

  removeBambini(index: number): void {
    const values: string[] = Object.values(this.formGruppoBambini.value);
    values.splice(index, 1);
    this.formGruppoBambini.setValue(values);
    this.form.updateValueAndValidity();
  }

  addrow() {
    this.formTabella.push(this.buildFormRigaTabella(new GestioneOsservazioniLinguaggiEspressiviCreativitaRow()));

    this._matTables['_results'][0].renderRows();
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const gestioneOsservazioniLinguaggiEspressiviCreativita = new GestioneOsservazioniLinguaggiEspressiviCreativita(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: gestioneOsservazioniLinguaggiEspressiviCreativita,
      });
      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
