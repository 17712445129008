<ng-container *transloco="let t; read: 'rilevazionePartecipazioneFamiglie.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">

    <div>
      <ng-container *ngFor="let sezione of sezioniArray; let index = index">

        <div class="card w-100 mat-elevation-z1 p-3" [formGroupName]="sezione.nome">
          <div class="card-body">
            <h2>{{sezione.nome}}</h2>
            <p fxLayout="column">
              <span>
                {{t('totSezioni')}}{{form.get([sezione.nome,'totFamigliePresentiIncontriSezione']).value || 0}}
              </span>
              <span>
                {{t('partecipazioneMedia')}}{{form.get([sezione.nome,'partecipazioneMediaIncontriSezione']).value || 0 }}%
              </span>
            </p>

            <p fxLayout="column">
              <span>
                {{t('totIniziative')}}{{form.get([sezione.nome,'totIniziativeFamigliePresenti']).value || 0}}
              </span>
              <!-- <span>
                {{t('partecipazioneMedia')}}{{form.get([sezione.nome,'partecipazioneMediaIniziativeFamiglie']).value || 0 }}%
              </span> -->
            </p>
            <div fxLayout="column" style="margin-bottom: 23px;">

              <div fxLayout="row" class="p-3">
                <ng-container *ngFor="let col of COLUMNS1">

                  <div class="container col-4" formArrayName="{{col}}" style="padding: 0;">
                    <div style="height: 122px;">
                      <h4 [innerHTML]="t('table.column.'+col)"></h4>
                      <div fxLayout="row">
                        <p style="margin-left: 10px;">
                          {{t('totRow')}}
                          {{form.get([sezione.nome, 'totRow'+col ]).value || 0}}
                        </p>
                        <p>
                          {{t('totFamiglie')}}
                          {{form.get([sezione.nome,'totFamiglie'+col]).value || 0}}
                        </p>
                      </div>
                    </div>

                    <div fxLayout="row" *ngFor="let item of getDocForm(sezione.nome, col).controls; let i = index"
                      [formGroupName]="i">
                      <div style="display: flex; flex-direction: column; width: 108px;">
                        <mat-form-field class="format-data" appearance="outline" style="width: 108px;">
                          <input matInput [type]="getData(sezione.nome,col,i)" onfocus="(this.type='date')"
                            onblur="(this.value == '' ? this.type='text' : this.type='date')" formControlName="data"
                            placeholder="{{t('placeholderData')}}">

                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 108px;">
                          <input type="number" (focusout)="calcolatotFamiglie(sezione.nome, col)" matInput
                            formControlName="numeroFamiglie" placeholder="{{t('placeholderNFam')}}">
                        </mat-form-field>
                      </div>

                      <mat-form-field appearance="outline" style="flex: 0.9;">
                        <textarea style="height: 83px;" matInput formControlName="descrizione"
                          placeholder="Descrizione"></textarea>
                      </mat-form-field>

                      <button mat-icon-button color="primary" style="margin-top: 43px;"
                        (click)="removeRow(sezioniArray[index].nome,col,i)">
                        <mat-icon svgIcon="delete"></mat-icon>
                      </button>

                    </div>
                    <button mat-stroked-button (click)="addrow(sezioniArray[index].nome,col)">+</button>
                  </div>

                </ng-container>
              </div>

              <div fxLayout="row" class="p-3">
                <ng-container *ngFor="let col of COLUMNS2">

                  <div class="container col-4" formArrayName="{{col}}" style="padding: 0;">
                    <div style="height: 122px;">
                      <h4 [innerHTML]="t('table.column.'+col)"></h4>
                      <div fxLayout="row">
                        <p style="margin-left: 10px;">
                          {{t('totRow')}}
                          {{form.get([sezione.nome, 'totRow'+col ]).value || 0}}
                        </p>
                        <p>
                          {{t('totFamiglie')}}
                          {{form.get([sezione.nome,'totFamiglie'+col]).value || 0}}
                        </p>
                      </div>
                    </div>

                    <div fxLayout="row" *ngFor="let item of getDocForm(sezione.nome, col).controls; let i = index"
                      [formGroupName]="i">
                      <div style="display: flex; flex-direction: column; width: 108px;">
                        <mat-form-field class="format-data" appearance="outline" style="width: 108px;">
                          <input matInput type="text" onfocus="(this.type='date')"
                            onblur="(this.value == '' ? this.type='text' : this.type='date')" formControlName="data"
                            placeholder="Data">
                        </mat-form-field>

                        <mat-form-field appearance="outline" style="width: 108px;">
                          <input matInput type="number" (focusout)="calcolatotFamiglie(sezione.nome, col)" matInput
                            formControlName="numeroFamiglie" placeholder="{{t('placeholderNFam')}}">
                        </mat-form-field>
                      </div>

                      <mat-form-field appearance="outline" style="flex: 0.9;">
                        <textarea style="height: 83px;" matInput formControlName="descrizione"
                          placeholder="Descrizione"></textarea>
                      </mat-form-field>

                      <button mat-icon-button color="primary" style="margin-top: 43px;"
                        (click)="removeRow(sezioniArray[index].nome,col,i)">
                        <mat-icon svgIcon="delete"></mat-icon>
                      </button>

                    </div>
                    <button mat-stroked-button (click)="addrow(sezioniArray[index].nome,col)">+</button>
                  </div>

                </ng-container>
              </div>
            </div>
          </div>
        </div>

      </ng-container>
    </div>
  </form>
</ng-container>
