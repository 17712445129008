<ng-container *transloco="let t; read: 'sipiSezioneRaccoltaInformazioni.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">

    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <h3>{{t('titleOrarioFrequenzaTable')}}</h3>
      <div class="mat-elevation-z1">
        <table mat-table [dataSource]="orarioFrequenzaRows" formArrayName="orarioFrequenzaBambino">
          <!-- Fascia oraria -->
          <ng-container matColumnDef="fasciaOraria">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element">
              {{ 'type.' + element  | transloco }}
            </td>
          </ng-container>

          <!-- Giorni -->
          <ng-container
            *ngFor="let column of orarioFrequenzaBaminoDisplayedColumns.slice(1,orarioFrequenzaBaminoDisplayedColumns.length)">
            <ng-container [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef>{{ 'type.' + column | transloco }}</th>
              <td mat-cell *matCellDef="let element; let i = index;"
                [formGroupName]="getOrarioFrequenzaBambinoFormGroupName(column, formOrarioFrequenzaBambino)">
                <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start">
                  <div fxLayout="row" fxLayoutGap="1em">
                    <span fxFlex="5">{{t('time.da')}}</span> <input style="width: auto;" type="time" matInput
                      [formControlName]="getOrarioFrequenzaBambinoFormControlName(element, true)" />
                  </div>
                  <div fxLayout="row" fxLayoutGap="1em">
                    <span fxFlex="5">{{t('time.a')}}</span> <input style="width: auto;" type="time" matInput
                      [formControlName]="getOrarioFrequenzaBambinoFormControlName(element, false)" />
                  </div>
                </div>
              </td>
            </ng-container>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="orarioFrequenzaBaminoDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: orarioFrequenzaBaminoDisplayedColumns"></tr>
        </table>
      </div>
      <mat-form-field fxFill appearance="outline">
        <mat-label>{{ t('note') }}</mat-label>
        <textarea matInput formControlName="noteOrarioFrequenzaBambino"></textarea>
      </mat-form-field>


      <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center">
        <h3>{{t('organizzazioneOrariaAdulti.title')}}</h3>
        <button mat-raised-button color="primary" type="button" (click)="addProfessionista()">
          <mat-icon svgIcon="add"></mat-icon>
          {{t('organizzazioneOrariaAdulti.add')}}
        </button>
      </div>
      <div class="mat-elevation-z1">
        <table mat-table formArrayName="organizzazioneOrariaAdulti"
          *ngIf="formOrganizzazioneOrariaAdulti && formOrganizzazioneOrariaAdulti.value && formOrganizzazioneOrariaAdulti.value.length > 0"
          [dataSource]="formOrganizzazioneOrariaAdulti.controls">

          <!-- Nome e Cognome -->
          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef>{{ t('organizzazioneOrariaAdulti.table.column.nomeCognome') }}</th>
            <td mat-cell *matCellDef="let element">
              {{element.value.nome}} {{element.value.cognome}}
            </td>
          </ng-container>

          <!-- Ruolo -->
          <ng-container matColumnDef="ruolo">
            <th mat-header-cell *matHeaderCellDef>{{ t('organizzazioneOrariaAdulti.table.column.ruolo') }}</th>
            <td mat-cell *matCellDef="let element">
              {{element.value.funzione}}
            </td>
          </ng-container>

          <!-- Giorni -->
          <ng-container
            *ngFor="let column of orarioOrganizzazioneOrariaAdultiDisplayedColumns.slice(2,orarioOrganizzazioneOrariaAdultiDisplayedColumns.length - 1)">
            <ng-container [matColumnDef]="column">
              <th mat-header-cell *matHeaderCellDef>{{ 'type.' + column | transloco }}</th>
              <td mat-cell *matCellDef="let element; let i = index;" [formGroupName]="i">
                <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" [formArrayName]="'orarioFrequenza'">
                  <div [formGroupName]="getOrganizzazioneOrariaGiorniFormGroupName(column, i)">
                    <div fxLayout="row" fxLayoutGap="1em">
                      <span fxFlex="5">{{t('time.da')}}</span> <input style="width: auto;" type="time" matInput
                        formControlName="aperturaDal" />
                    </div>
                    <div fxLayout="row" fxLayoutGap="1em">
                      <span fxFlex="5">{{t('time.a')}}</span> <input style="width: auto;" type="time" matInput
                        formControlName="aperturaAl" />
                    </div>
                  </div>
                </div>
              </td>
            </ng-container>
          </ng-container>

          <!-- Azioni Column -->
          <ng-container matColumnDef="azioni">
            <th mat-header-cell *matHeaderCellDef>
              {{ t('organizzazioneOrariaAdulti.table.column.azioni.title') }}
            </th>
            <td mat-cell *matCellDef="let row; let i = index;">
              <button mat-icon-button color="primary" type="button" [matMenuTriggerFor]="rowActions">
                <mat-icon svgIcon="more_vert"></mat-icon>
              </button>

              <mat-menu #rowActions="matMenu" xPosition="before" yPosition="below">
                <button mat-menu-item type="button" (click)="editProfessionista(i)">
                  {{ t('organizzazioneOrariaAdulti.table.column.azioni.button.edit') }}
                </button>
                <button mat-menu-item type="button" (click)="deleteProfessionista(i)">
                  {{ t('organizzazioneOrariaAdulti.table.column.azioni.button.delete') }}
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="orarioOrganizzazioneOrariaAdultiDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: orarioOrganizzazioneOrariaAdultiDisplayedColumns"></tr>
        </table>
      </div>
      <mat-form-field fxFill appearance="outline">
        <mat-label>{{ t('note') }}</mat-label>
        <textarea matInput formControlName="noteOrarioFrequenzaAdulto"></textarea>
      </mat-form-field>

      <div
        *ngIf="formOrganizzazioneOrariaAdulti && formOrganizzazioneOrariaAdulti.value && formOrganizzazioneOrariaAdulti.value.length > 0">
        <h3>{{t('organizzazioneLavoroAdulti.title')}}</h3>
        <div class="mat-elevation-z1">
          <table mat-table formArrayName="organizzazioneOrariaAdulti"
            [dataSource]="formOrganizzazioneOrariaAdulti.controls">

            <!-- Ruolo -->
            <ng-container matColumnDef="nome">
              <th mat-header-cell *matHeaderCellDef>{{ t('organizzazioneLavoroAdulti.table.column.nome') }}</th>
              <td mat-cell *matCellDef="let element">
                {{element.value.funzione}}
              </td>
            </ng-container>

            <!-- azione -->
            <ng-container matColumnDef="azione">
              <th mat-header-cell *matHeaderCellDef>{{ t('organizzazioneLavoroAdulti.table.column.azione') }}</th>
              <td mat-cell *matCellDef="let element; let i = index">
                <mat-form-field fxFill appearance="outline" [formGroupName]="i">
                  <textarea matInput formControlName="ruoloOAzione"></textarea>
                </mat-form-field>
              </td>
            </ng-container>

            <!-- momento azione -->
            <ng-container matColumnDef="momentoAzione">
              <th mat-header-cell *matHeaderCellDef>{{ t('organizzazioneLavoroAdulti.table.column.momentoAzione') }}
              </th>
              <td mat-cell *matCellDef="let element; let i = index">
                <mat-form-field fxFill appearance="outline" [formGroupName]="i">
                  <textarea matInput formControlName="momentoRuoloOAzione"></textarea>
                </mat-form-field>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="orarioOrganizzazioneLavoroAdultiDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: orarioOrganizzazioneLavoroAdultiDisplayedColumns"></tr>
          </table>
        </div>
      </div>

      <div formArrayName="domande">
        <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
          <mat-form-field fxFill appearance="outline" [formGroupName]="i">
            <mat-label>{{ t('domande.' + domanda.value.codiceDomanda) }}</mat-label>
            <textarea matInput formControlName="rispostaDomanda"></textarea>
          </mat-form-field>
        </ng-container>
      </div>

    </div>
    <button #button type="submit" class="d-none"></button>
  </form>
</ng-container>
