import { EnumTipologiaConsumoPasto } from '../StrumentoDiRegistrazioneArchivio';

export interface IGestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta {
  assente: boolean;
  contorno: EnumTipologiaConsumoPasto;
  note: string;
  primo: EnumTipologiaConsumoPasto;
  secondo: EnumTipologiaConsumoPasto;
}

export class GestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta implements IGestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta {
  assente: boolean;
  contorno: EnumTipologiaConsumoPasto;
  note: string;
  primo: EnumTipologiaConsumoPasto;
  secondo: EnumTipologiaConsumoPasto;
  frutta: EnumTipologiaConsumoPasto;
  merenda: EnumTipologiaConsumoPasto;

  constructor(params?: Partial<IGestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta>) {
    if (!params) {
      return;
    }

    Object.assign(this, params);
  }

  deconstructor(): IGestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta {
    return Object.assign({}, this);
  }
}
