import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { EnumTipologiaOffertaServizioEducativo } from './ServizioEducativo';
import { EnumFasciaEta } from './SezioneAnnoScolasticoPeriodoFormativo';
import {
  IStrumentoDiRegistrazioneTemplate,
  StrumentoDiRegistrazioneTemplate
} from './StrumentoDiRegistrazioneTemplate';

export enum EnumStrumentoDiRegistrazioneTemplateBodyViewMode {
  Full = 'full',
  ListItemCustom = 'listItemCustom',
  ListItemStandard = 'listItemStandard',
}

export enum EnumStrumentoDiRegistrazioneTemplateBodyCode {
  GestioneColloquiInizialiNidoInfanzia = 'gestione_colloqui_iniziali_NidoInfanzia',
  GestioneColloquiInizialiScuolaInfanzia = 'gestione_colloqui_iniziali_ScuolaInfanzia',
  GestioneOsservazionePrimiGiorniDiAmbientamento = 'gestione_osservazione_primi_giorni_di_ambientamento',
  GestioneProgettazioniSettimanali = 'gestione_progettazioni_settimanali',
  GestioneSchedaControlloPulizie = 'gestione_scheda_controllo_pulizie',
  GestioneScrittureProgettuali = 'gestione_scritture_progettuali',
  GestioneSituazioneGiornalieraNidoInfanziaLattanti = 'gestione_situazione_giornaliera_NidoInfanzia_Lattanti',
  GestioneSituazioneGiornalieraNidoInfanziaAltreFasceEta = 'gestione_situazione_giornaliera_NidoInfanzia_AltreFasceEta',
  GestioneSituazioneGiornalieraScuolaInfanziaAltreFasceEta = 'gestione_situazione_giornaliera_ScuolaInfanzia_AltreFasceEta',
  GestioneOsservazioniLinguaggiEspressiviCreativita = 'gestione_osservazioni_linguaggi_espressivi_e_creatività',
  PensieriRiflessioniPrimoPeriodoServizioEducativo = 'pensieri_e_riflessioni_dopo_un_primo_periodo_passato_nel_servizio_educativo',
  RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate = 'racconti_e_riflessioni_delle_autonomie_e_competenze_acquisite_dai_bambini_durante_estate',
  RegistrazioniCaduteIncidentiBambini = 'registrazioni_caduteincidenti_dei_bambini',
  VerbaleDiValutazioneEVerifica = 'verbale_di_valutazione_e_verifica',
  SipiSezioneAnagrafica = 'gestione_sipi_sezione1_anagrafica',
  SipiSezioneVerbali = 'gestione_sipi_sezione2_verbali',
  SipiRaccoltaInformazioni = 'gestione_sipi_sezione3_raccolta_informazioni_carattere_organizzativo',
  SipiSituazioneIniziale = 'gestione_sipi_sezione4_situazione_iniziale',
  SipiStrumentoProgettuale = 'gestione_sipi_sezione5_strumento_progettuale',
  SipiSintesiProgettoEducativo = 'gestione_sipi_sezione6_sintesi_progetto_educativo',
  RicadutaAttivitaFormativa = 'ricaduta_attivita_formativa',
  AutovalutazioneQualitaSintesiStrutturaNidoInfanzia = 'autovalutazione_qualita_sintesi_struttura_NidoInfanzia',
  AutovalutazioneQualitaSintesiStrutturaScuolaInfanzia = 'autovalutazione_qualita_sintesi_struttura_ScuolaInfanzia',
  GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiNido = 'gestione_valutazione_ambiti_sviluppo_competenze_individuali_NidoInfanzia',
  GestioneStrumentoValutazioneAmbitiSviluppoCompetenzeIndividualiScuola = 'gestione_valutazione_ambiti_sviluppo_competenze_individuali_ScuolaInfanzia',
  GestioniPortfolioCompetenzeBambini = 'gestioni_portfolio_competenze_bambini',
  CompetenzeInsegnanti = 'competenze_insegnanti',
  RegistrazioneSmarrimentiOggettiBambini = 'registrazioni_smarrimento_oggetti_bambino',
  GradoSoddisfazioneGenitori = 'questionario_grado_soddisfazione_genitori',
  RilevazioneProgettazioniAttivitaLinguaggiCibo = 'rilevazione_progettazioni_attivita_linguaggi_cibo',
  RilevazioneProgettazioniAttivitaSviluppoPensieroEcologico = 'rilevazione_progettazioni_attivita__sviluppo_pensiero_ecologico',
  RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita = 'rilevazione_progettualita_sviluppo_linguaggi_espressivi_creativita',
  RilevazionePromozioneAmbitiSviluppoNido = 'rilevazione_promozione_ambiti_sviluppo_nido',
  RilevazioneProgettazioniSviluppoCampiEsperienzaScuolaInfanzia = 'rilevazione_progettazioni_sviluppo_campi_esperienza_scuola_infanzia',
  RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi = 'rilevazione_allestimento_spazi_ricerche_materiali_servizi_educativi',
  RilevazioneDocumentazioneProgettualeServiziEducativi = 'rilevazione_documentazione_progettuale_servizi_educativi',
  RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne = 'rilevazione_allestimento_degli_spazi_ricerche_materiali_aree_esterne',
  RilevazionePartecipazioneFamiglie = 'rilevazione_partecipazione_famiglie',
  ChecklistMonitoraggioStandardStrutturaliOrganizzativi = 'checklist_monitoraggio_standard_strutturali_organizzativi',
  ChecklistMonitoraggioErogazioneServizio = 'checklist_monitoraggio_erogazione_servizio',
  ChecklistMonitoraggioFamilyEngagement = 'checklist_monitoraggio_family_engagement',
  ChecklistMonitoraggioImplementazioneCurricolo = 'checklist_monitoraggio_implementazione_curricolo',
  ChecklistMonitoraggioValutazioneEfficaciaOutcomesServizio = 'checklist_monitoraggio_valutazione_efficacia_outcomes_servizio',
  RelazioneSintesi = 'relazione_di_sintesi',
  ChecklistMonitoraggioImplementazioneCurricoloNidoInfanzia = 'checklist_monitoraggio_implementazione_curricolo_NidoInfanzia',
  ChecklistMonitoraggioImplementazioneCurricoloScuolaInfanzia = 'checklist_monitoraggio_implementazione_curricolo_ScuolaInfanzia'
}

export interface IStrumentoDiRegistrazioneTemplateBody extends IBaseBusinessObject {
  matchRulefasciaEtaDiRiferimentoSezione?: EnumFasciaEta;
  matchRuleTipologiaOffertaServizioEducativo?: EnumTipologiaOffertaServizioEducativo;
  templateBodyCode: EnumStrumentoDiRegistrazioneTemplateBodyCode;
  templateBodyViewMode: EnumStrumentoDiRegistrazioneTemplateBodyViewMode;

  /**
   * Relazioni
   */
  strumentoDiRegistrazioneTemplate: IStrumentoDiRegistrazioneTemplate;
}

export class StrumentoDiRegistrazioneTemplateBody extends BaseBusinessObject
  implements IStrumentoDiRegistrazioneTemplateBody {
  matchRulefasciaEtaDiRiferimentoSezione?: EnumFasciaEta;
  matchRuleTipologiaOffertaServizioEducativo?: EnumTipologiaOffertaServizioEducativo;
  templateBodyCode: EnumStrumentoDiRegistrazioneTemplateBodyCode;
  templateBodyViewMode: EnumStrumentoDiRegistrazioneTemplateBodyViewMode;

  /**
   * Relazioni
   */
  strumentoDiRegistrazioneTemplate: StrumentoDiRegistrazioneTemplate;

  constructor(params?: Partial<IStrumentoDiRegistrazioneTemplateBody>) {
    super(params);

    /**
     * Mapping
     */
    if (params.strumentoDiRegistrazioneTemplate) {
      this.strumentoDiRegistrazioneTemplate = new StrumentoDiRegistrazioneTemplate(
        params.strumentoDiRegistrazioneTemplate,
      );
    }
  }

  deconstructor(): IStrumentoDiRegistrazioneTemplateBody {
    return Object.assign({}, { ...this });
  }
}
