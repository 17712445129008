<mat-card class="no-header">
  <mat-card-header class="background-primary">
    <span class="mat-subtitle">{{item?.titolo}}</span>
    <span class="mat-caption">{{item?.dtInsert | translocoDate: 'shortDate' }}</span>
  </mat-card-header>
  <mat-card-content>
    <pdf-viewer [src]="item?.fileAllegato?.link" [render-text]="false" [original-size]="true" [fit-to-page]="true">
    </pdf-viewer>
  </mat-card-content>
</mat-card>
