import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Params, Router } from '@angular/router';
import { AnniScolasticiService } from '@gpi/lbl/app/data-access/anni-scolastici';
import { AuthService } from '@gpi/lbl/app/data-access/auth';
import { StrumentiRegistrazioneArchivioService } from '@gpi/lbl/app/data-access/strumenti-registrazione-archivio';
import {
  AnnoScolasticoPeriodoFormativo,
  EnumFrequenzaCompilazione,
  EnumStatoAnnoScolastico,
  EnumStatoStrumentoRegistrazione,
  EnumStrumentoDiRegistrazioneTemplateBodyCode,
  EnumStrumentoDiRegistrazioneTemplateBodyViewMode,
  EnumTipologiaRuoloApplicativo,
  FunzionalitaLBL,
  PersonaFisica,
  RuoloApplicativo,
  ServizioEducativo,
  SezioneAnnoScolasticoPeriodoFormativo,
  StrumentoDiRegistrazioneArchivio,
  StrumentoDiRegistrazioneTemplate,
  StrumentoDiRegistrazioneTemplateBody,
  StrutturaEducativa,
  Utente
} from '@gpi/lbl/shared/entity';
import { TranslocoService } from '@ngneat/transloco';
// tslint:disable-next-line: nx-enforce-module-boundaries
import * as moment from 'moment';
import { forkJoin, of, Observable, Subject } from 'rxjs';
import { catchError, map, takeUntil, tap } from 'rxjs/operators';
import { ErrorService, FrontendError } from '@gpi/lbl/app/util/error';
import { FeatureService } from 'libs/lbl/app/feature/backoffice/src/lib/state/feature.service';


@Component({
  selector: 'lbl-app-ui-strumento-registrazione',
  templateUrl: './strumento-registrazione.component.html',
  styleUrls: ['./strumento-registrazione.component.scss'],
})
export class StrumentoRegistrazioneComponent implements OnInit, OnDestroy {
  @Input() funzionalitaLblSelezionata: FunzionalitaLBL;
  @Input() strutturaEducativaSelected: StrutturaEducativa;
  @Input() utenteCorrenteParamInput: Utente;
  @Input() ruoloApplicativoAttualmenteUtilizzatoParamInput: RuoloApplicativo;
  @Input() strumentoArchivioInstanceParamInput: StrumentoDiRegistrazioneArchivio;

  archivioTostrumento: string;

  isError = false;
  errorText: string;
  isArchivio = false;
  disableTemplateBodyEditing: boolean;
  disableTemplateHeaderEditing: boolean;

  compilabileDal: Date;
  compilabileAl: Date;

  strumentoDiRegistrazioneTemplateSelected: StrumentoDiRegistrazioneTemplate;
  strumentoDiRegistrazioneTemplateBodySelected: StrumentoDiRegistrazioneTemplateBody;

  anniScolasticoPeriodoFormativiPerStruttura: Array<AnnoScolasticoPeriodoFormativo>;
  annoScolasticoPeriodoFormativiSelected: AnnoScolasticoPeriodoFormativo;

  labelValueTitolo: string;
  labelValueStrutturaEducativaTitle: string;
  labelValueAnnoScolastico: string;
  labelValuePeriodoRiferimentoCompilazione: string;
  serviziEducativiStrutturaSelezionata: Array<ServizioEducativo>;
  servizioEducativoSelected: ServizioEducativo;
  sezioniAnnoScolasticoPeriodoFormativoPerServizio: Array<SezioneAnnoScolasticoPeriodoFormativo>;
  sezioneAnnoScolasticoPeriodoFormativoSelected: SezioneAnnoScolasticoPeriodoFormativo;

  personeCoinvolte: Array<PersonaFisica>;
  personaSelected: PersonaFisica;

  labelValueDataCreazione: Date;
  labelValueDataUtlimaModifica: Date;
  labelValueCreatoDa: String;
  labelValueUtlimaModificaEffettuataDa: String;
  renderTemplateBodyHint: String;

  istanzeStrumentoDiRegistrazioneArchivio: Observable<StrumentoDiRegistrazioneArchivio[]>;
  strumentoDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[];

  sezioneId: number;
  bambinoSelectId: number;
  servizioEducativoSelectedId: number;
  currentStatusStrumento: EnumStatoStrumentoRegistrazione;

  enumTemplateBodyCode = EnumStrumentoDiRegistrazioneTemplateBodyCode;
  enumTemplateBodyViewMode = EnumStrumentoDiRegistrazioneTemplateBodyViewMode;

  isInsegnante: boolean = false;
  isLoading = false;

  contatoreWrapper: number;
  sezioneCorrente :SezioneAnnoScolasticoPeriodoFormativo;
  /**
   *
   */

  unsubscribe$: Subject<void> = new Subject();

  constructor(
    private authService: AuthService,
    private anniScolasticiService: AnniScolasticiService,
    private strumentiRegistrazioneArchivioService: StrumentiRegistrazioneArchivioService,
    private router: Router,
    private translocoService: TranslocoService,
    private errorService: ErrorService,
    private cdRef: ChangeDetectorRef,
    private featureService: FeatureService,
    
  ) { }


  ngOnInit(): void {
    // serve per gestire il cambio sezione lato layout chiosco il cambiamento del contesto
    this.authService.query.selectSezioneAnnoScolasticoPeriodoFormativoCorrente().subscribe(
      (sezioneCorrente) =>{
        if(sezioneCorrente!=null){ 
          //NB: quando si cambia la sezione da layout backoffice non arriva il valore della sezione
          this.sezioneCorrente = sezioneCorrente;
          //console.log('SEZIONE CORRENTE', this.sezioneCorrente);
          //console.log('cambio di sezione effettuato', this.sezioneCorrente);
          this.clean();
        }
        this.init();
      }
    );
  }

  clean(){
    this.archivioTostrumento = undefined;
    this.isError = false;
    this.errorText= undefined;
    this.isArchivio = false;
    this.disableTemplateBodyEditing= undefined;
    this.disableTemplateHeaderEditing= undefined;
    this.compilabileDal= undefined;
    this.compilabileAl= undefined;
    this.strumentoDiRegistrazioneTemplateSelected = undefined;
    this.strumentoDiRegistrazioneTemplateBodySelected= undefined;
    this.anniScolasticoPeriodoFormativiPerStruttura= undefined;
    this.annoScolasticoPeriodoFormativiSelected= undefined
    this.labelValueTitolo= undefined;
    this.labelValueStrutturaEducativaTitle= undefined;
    this.labelValueAnnoScolastico= undefined;
    this.labelValuePeriodoRiferimentoCompilazione= undefined;
    this.serviziEducativiStrutturaSelezionata= undefined;
    this.servizioEducativoSelected= undefined;
    this.sezioniAnnoScolasticoPeriodoFormativoPerServizio= undefined;
    this.sezioneAnnoScolasticoPeriodoFormativoSelected= undefined;
    this.personeCoinvolte= undefined;
    this.personaSelected= undefined;
    this.labelValueDataCreazione= undefined;
    this.labelValueDataUtlimaModifica= undefined;
    this.labelValueCreatoDa= undefined;
    this.labelValueUtlimaModificaEffettuataDa= undefined;
    this.renderTemplateBodyHint= undefined;
    this.istanzeStrumentoDiRegistrazioneArchivio= undefined;
    this.strumentoDiRegistrazioneArchivio= undefined;
    this.sezioneId= undefined;
    this.bambinoSelectId= undefined;
    this.servizioEducativoSelectedId= undefined;
    this.currentStatusStrumento= undefined;
    this.enumTemplateBodyCode = EnumStrumentoDiRegistrazioneTemplateBodyCode;
    this.enumTemplateBodyViewMode = EnumStrumentoDiRegistrazioneTemplateBodyViewMode;
    this.isInsegnante= false;
    this.isLoading = false;
    this.contatoreWrapper= undefined;
  }

  init(){
    // controllo per la reindirizzazione all'archivio
    // console.log('onInit');
    // azzerare contatore wrapper
    this.contatoreWrapper = this.featureService.resetContatoreWrapper();

    this.isArchivio = false;
    if (localStorage.getItem('ArchivioToStrumento')) {
      this.archivioTostrumento = localStorage.getItem('ArchivioToStrumento');
      localStorage.removeItem('ArchivioToStrumento');
    } else {
      this.archivioTostrumento = 'false';
    }

    forkJoin([
      this.strumentiRegistrazioneArchivioService.readAll(),
      this.anniScolasticiService.readMany({ lessthan: moment().unix().toString(), greaterthan: moment().unix().toString() }),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      ([x, annoScolastico]) => {
        this.anniScolasticoPeriodoFormativiPerStruttura = annoScolastico.data.filter((anno) => anno.stato === EnumStatoAnnoScolastico.Attivo);
        // modifico un istanza quando provengo dal figlio
        if (localStorage.getItem('strumentoArchivioInstanceParamInput')) {
          this.strumentoArchivioInstanceParamInput = new StrumentoDiRegistrazioneArchivio(JSON.parse(
            localStorage.getItem('strumentoArchivioInstanceParamInput'),
          ));
          localStorage.removeItem('strumentoArchivioInstanceParamInput');
        } else {
          this.strumentoArchivioInstanceParamInput = null;
        }

        this.InitStrumentoRegistrazioneSelectorComponentHeader();
        this.InitStrumentoRegistrazioneSelectorComponentBody();
      });
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private FilterAnnoScolasticoInCorsoPerServizio(
    anniValidiPerStruttura: Array<AnnoScolasticoPeriodoFormativo>,
    strutturaEducativaSelected: StrutturaEducativa,
    servizioEducativoSelected: ServizioEducativo,
  ): AnnoScolasticoPeriodoFormativo {
    //console.log('anni validi', anniValidiPerStruttura);
    //console.log('struttura educativa selected',strutturaEducativaSelected);
    //console.log('servizio educativo selected',servizioEducativoSelected);
    return anniValidiPerStruttura.find(
      (a) =>
        a.servizioEducativo.id === servizioEducativoSelected?.id &&
        a.strutturaEducativa.id === strutturaEducativaSelected?.id,
    );
  }

  /// Costrusico il parametro da passare al metodo per recuperare l'istanza dello strumento di registrazione.
  /// </summary>
  /// <param name="anniValidiPerStruttura"></param>
  /// <param name="annoSelected"></param>
  /// <returns></returns>
  private getAnniScolasticiParam(
    anniValidiPerStruttura: AnnoScolasticoPeriodoFormativo[],
    annoSelected: AnnoScolasticoPeriodoFormativo,
  ): AnnoScolasticoPeriodoFormativo[] {
    //se è stato selezionato un anno scolastico significa che lo strumento si riferisce ad un preciso servizioEducativo
    //altrimenti lo strumento si può potenzialmente riferire a tutti i serviziEducativi della struttura ovvero è come
    //se si riferisse alla struttura educativa (sempre in relazione agli anni scolastici)

    //resituisce una llista con un item
    if (annoSelected != null) {
      return anniValidiPerStruttura.filter((a) => a.id === annoSelected.id);
    } else {
      return anniValidiPerStruttura;
    }
  }

  private InitStrumentoRegistrazioneSelectorComponentHeader(): void {
    if (this.strumentoArchivioInstanceParamInput != null) {
      this.disableTemplateHeaderEditing = true;
      this.strumentoDiRegistrazioneTemplateSelected = new StrumentoDiRegistrazioneTemplate(this.strumentoArchivioInstanceParamInput.strumentoRegistrazioneTemaplateBody.strumentoDiRegistrazioneTemplate);

      //forzo il template a quello attualmente in esame per l'istanza passata
      this.strumentoDiRegistrazioneTemplateSelected.templateBodies = [new StrumentoDiRegistrazioneTemplateBody(this.strumentoArchivioInstanceParamInput.strumentoRegistrazioneTemaplateBody)];

      //anno scolastico
      this.anniScolasticoPeriodoFormativiPerStruttura = this.strumentoArchivioInstanceParamInput.anniScolastici;
      if (this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Annuale) {
        this.setDateCompilabiliAnnoScolastico();
      } else {
        this.compilabileDal = this.getDataCompilabileDal(
          this.strumentoArchivioInstanceParamInput.dtInsert,
          this.strutturaEducativaSelected,
        );
        this.compilabileAl = this.getDataCompilabileAl(
          this.strumentoArchivioInstanceParamInput.dtInsert,
          this.strutturaEducativaSelected,
        );
      }
    } else {
      let today = moment(new Date()).startOf('day').toDate();
      if (this.anniScolasticoPeriodoFormativiPerStruttura.length === 0) {
        this.errorService.store.setError(
          new FrontendError('', 'NEED_CONTEXT'),
        );
        this.router.navigate(['/private/backoffice']);
      }
      this.disableTemplateHeaderEditing = false;

      this.strumentoDiRegistrazioneTemplateSelected = new StrumentoDiRegistrazioneTemplate(this.funzionalitaLblSelezionata.strumentoDiRegistrazioneTemplate);


      this.compilabileDal = this.getDataCompilabileDal(today, this.strutturaEducativaSelected);
      this.compilabileAl = this.getDataCompilabileAl(today, this.strutturaEducativaSelected);

    }
    if (
      this.strumentoDiRegistrazioneTemplateSelected.accessibileDaRuoli.find(
        (i) => i.ruoloApplicativo.id === this.ruoloApplicativoAttualmenteUtilizzatoParamInput?.id && (i.update || i.create),
      )
    ) {
      this.disableTemplateBodyEditing = false; // ABILITO
    } else {
      this.disableTemplateBodyEditing = true; //DISISABILITO TUTTI I CAMPI DEL BODY
    }

    //formattazione delle date in base a dove richiamate
    const compilabileAlFormat = moment(this.compilabileAl).format('DD/MM/yyyy');
    const compilabileDalFormat = moment(this.compilabileDal).format('DD/MM/yyyy');

    //--------------------CALLOUT 1 (Titolo)
    this.labelValueTitolo = this.funzionalitaLblSelezionata.translateKeyPlaceHolder;

    //--------------------CALLOUT 2 (Struttura educativa) ????
    //mostro struttura educativa se il template dello strumetno di regeistrazione lo prevede
    if (this.strumentoDiRegistrazioneTemplateSelected.isRelativoAServizioEducativo === true) {
      this.labelValueStrutturaEducativaTitle = this.strutturaEducativaSelected.ragioneSociale;
    }

    //--------------------CALLOUT 3 (Anno scolastico)
    //a livello di label poco importa quale anno seleziono in quanto mi basta avere il valore in fomrato yyyy
    this.labelValueAnnoScolastico =
      moment(this.anniScolasticoPeriodoFormativiPerStruttura[0].dataInizio).year().toString() +
      '/' +
      moment(this.anniScolasticoPeriodoFormativiPerStruttura[0].dataFine).year().toString();

    //--------------------CALLOUT 4 (Periodo riferimento compilazione)
    if (this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Giornaliera) {
      //da riportare la seguente dicitura:
      //giornaliera - compila entro <giorno corrente>/<mese corrente>/<anno corrente>

      this.labelValuePeriodoRiferimentoCompilazione =
        EnumFrequenzaCompilazione.Giornaliera.toString() + ' compila entro ' + compilabileDalFormat;
      //toString('dd/MM/yyyy');
    } else if (
      this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Settimanale
    ) {
      //da riportare la seguente dicitura:
      //settimanale - compilabile dal dal <numero primo giorno settimana configurata per struttura educatiova in base a data corrente>/<mese corrente>/<anno corrente> al
      //<numero utlimo giorno settimana configurata per struttura educatiova in base a data corrente>/<mese corrente>/<anno corrente>

      this.labelValuePeriodoRiferimentoCompilazione =
        EnumFrequenzaCompilazione.Settimanale.toString() +
        ' - compilabile dal ' +
        compilabileDalFormat +
        ' al ' +
        compilabileAlFormat;
    } else if (
      this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Trimestrale
    ) {
      //da riportare la seguente dicitura:
      //trimestrale - compilabile dal <nome mese inzio trimestre in base a data corrente> al <nome mese fine trimestre in base a data corrente>

      this.labelValuePeriodoRiferimentoCompilazione =
        EnumFrequenzaCompilazione.Trimestrale.toString() +
        '- compilabile dal ' +
        moment(this.compilabileDal).locale('it').format('MMMM').toLocaleUpperCase() +
        ' al ' +
        moment(this.compilabileAl).locale('it').format('MMMM').toLocaleUpperCase();
    } else if (
      this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Annuale
    ) {
      //da riportare la seguente dicitura:
      //annuale - compilabile dal 01/01/<anno corrente> al 31/12/<anno corrente>

      this.labelValuePeriodoRiferimentoCompilazione =
        EnumFrequenzaCompilazione.Annuale.toString() +
        ' compilabile dal ' +
        compilabileDalFormat +
        ' al ' +
        compilabileAlFormat;
    }

    //--------------------CALLOUT 5 (Servizio)

    if (
      this.strumentoDiRegistrazioneTemplateSelected.isRelativoAServizioEducativo === true ||
      this.strumentoDiRegistrazioneTemplateSelected.isRelativoAPersona === true ||
      this.strumentoDiRegistrazioneTemplateSelected.isRelativoASezione === true ||
      this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante === true
    ) {
      //mostro dropdowlist con servizi educativi previsti per la struttura selezionata in header

      //se sto accedendo con ruolo parente bambino recuerp sezioni in cui sono coinvolto e filtro
      if (this.ruoloApplicativoAttualmenteUtilizzatoParamInput?.tipo === EnumTipologiaRuoloApplicativo.ParentiBambino) {
        //recupero sezioni in cui il parente è coinvolto (le sezioni restiuite in base ai bambini associati all'utente)
        const sezioniBambiniParente = this.ruoloApplicativoAttualmenteUtilizzatoParamInput.coinvoltoInSezioniPerAnnoScolasticoInCorso;

        //costruisco lista this.serviziEducativiStrutturaSelezionata in base a sezioni associati all'utente
        //mi appgossio sulla proprietà annoScolasticoPeriodoFormativo a livello di sezione che porta con se
        //il riferimento al servizio educativo

        //NB dobbiamo essere in grado di selezionare il servizio educativo corretto, ovvero quello della struttura cehe c'è selezionata nel contesto
        //Es 1 parente con 2 bambini con 2 servizi diversi su stessa struttura altrimenti ha dei problemi
        this.serviziEducativiStrutturaSelezionata = new Array<ServizioEducativo>();
        sezioniBambiniParente.forEach((sezioneBambino) => {
          //recupero il servizio
          const servizioEducativoSezione = this.strutturaEducativaSelected.serviziEducativi.find(
            (i) => i.id === sezioneBambino.annoScolasticoPeriodoFormativo.servizioEducativo.id,
          );
          //console.log('sezioneBambino -> servizioEducativoSezione', sezioneBambino, servizioEducativoSezione);
          if(this.sezioneCorrente.annoScolasticoPeriodoFormativo.id === sezioneBambino.annoScolasticoPeriodoFormativo.id){
            //questa è quella che voglio in base al contesto
            //aggiungo se ho trovato il servizo e non è già stato aggiunto alla lista
            if (servizioEducativoSezione != null &&  !this.serviziEducativiStrutturaSelezionata.find((i) => i.id === servizioEducativoSezione.id)) {
              this.serviziEducativiStrutturaSelezionata.push(servizioEducativoSezione);
            }  
          }
          // this.serviziEducativiStrutturaSelezionata = new Array<ServizioEducativo>(); PERCHè OGNI VOLTA? in questo modo saranno sempre zero
        });
      } else {
        //recupero serivi educativi da anni scolatisti
        this.serviziEducativiStrutturaSelezionata = new Array<ServizioEducativo>();
        //console.log('anni scoltastici della struttura', this.anniScolasticoPeriodoFormativiPerStruttura);
        this.anniScolasticoPeriodoFormativiPerStruttura.forEach((anno) => {
          this.serviziEducativiStrutturaSelezionata.push(anno.servizioEducativo);
        });
      }
      //console.log('lista di servizi educativi', this.serviziEducativiStrutturaSelezionata);
      if (this.strumentoDiRegistrazioneTemplateSelected.enableIsRelativoAServizioEducativoSelector === true ||
        this.strumentoDiRegistrazioneTemplateSelected.enableIsRelativoASezioneSelector === true) {
        if (this.strumentoArchivioInstanceParamInput != null) {
          //significa che ho richiesto la creazione o modifica di una istanza di strumento
          //posso prevalorizzare le opzioni dei vari selettori

          this.servizioEducativoSelected = this.strumentoArchivioInstanceParamInput.anniScolastici[0].servizioEducativo;
        } else if (this.servizioEducativoSelected == null) {
          //console.log('setto il servizio educativo [0]', this.serviziEducativiStrutturaSelezionata);
          this.servizioEducativoSelected = this.serviziEducativiStrutturaSelezionata[0];
        }

        //uan volta identificato il servzio posso anche selezionare l'istanza corretta di anno scolastico
        this.annoScolasticoPeriodoFormativiSelected = this.FilterAnnoScolasticoInCorsoPerServizio(
          this.anniScolasticoPeriodoFormativiPerStruttura,
          this.strutturaEducativaSelected,
          this.servizioEducativoSelected,
        );
      }
    }

    //--------------------CALLOUT 6 (Sezione )
    if (
      this.strumentoDiRegistrazioneTemplateSelected.isRelativoASezione === true ||
      this.strumentoDiRegistrazioneTemplateSelected.isRelativoAPersona === true ||
      this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante === true
    ) {
      //recupero sezioni relative a servizio valorizzata nella drop down list sezioni (di default la prima)

      //se sto accedendo con ruolo parente bambino recuerp sezioni in cui sono coinvolto e filtro
      if (this.ruoloApplicativoAttualmenteUtilizzatoParamInput?.tipo === EnumTipologiaRuoloApplicativo.ParentiBambino) {
        //recupero sezioni in cui il parente è coinvolto (le sezioni restiuite in base ai bambini associati all'utente)
        const sezioniBambiniParente = this.ruoloApplicativoAttualmenteUtilizzatoParamInput.coinvoltoInSezioniPerAnnoScolasticoInCorso;

        //filtro sezioni in base all'anno scolatisco attualmente valorizzato
        this.sezioniAnnoScolasticoPeriodoFormativoPerServizio = sezioniBambiniParente.filter(
          (i) => i.annoScolasticoPeriodoFormativo.id === this.annoScolasticoPeriodoFormativiSelected.id,
        );
      } else {
        //console.log('sezioni', this.annoScolasticoPeriodoFormativiSelected.sezioni);
        this.sezioniAnnoScolasticoPeriodoFormativoPerServizio = this.annoScolasticoPeriodoFormativiSelected.sezioni;
      }

      if (this.strumentoDiRegistrazioneTemplateSelected.enableIsRelativoASezioneSelector === true ||
        //inserito per sipi sezione 5
        this.strumentoDiRegistrazioneTemplateSelected.enableIsRelativoAPersonaSelector === true) {
        if (this.strumentoArchivioInstanceParamInput != null) {
          //significa che ho richiesto la creazione o modifica di una istanza di strumento
          //posso prevalorizzare le opzioni dei vari selettori
          this.sezioniAnnoScolasticoPeriodoFormativoPerServizio = this.strumentoArchivioInstanceParamInput.sezioni;
          this.sezioneAnnoScolasticoPeriodoFormativoSelected = this.strumentoArchivioInstanceParamInput.sezioni[0];
          //console.log(this.sezioneAnnoScolasticoPeriodoFormativoSelected);
        } else if (this.sezioneAnnoScolasticoPeriodoFormativoSelected == null) {
          this.sezioneAnnoScolasticoPeriodoFormativoSelected = this.sezioniAnnoScolasticoPeriodoFormativoPerServizio[0];
        }
      }
    }
    //--------------------CALLOUT 7 (Bambini)
    if (this.strumentoDiRegistrazioneTemplateSelected.isRelativoAPersona === true ||
      this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante === true) {
      //recupero bambini relativi alla sezione selezionata di default la prima
      this.personeCoinvolte = new Array<PersonaFisica>();

      //se sto accedendo con ruolo parente bambino recupero solo i bambini in relazione con la persona fisica a livello di utente
      if (this.ruoloApplicativoAttualmenteUtilizzatoParamInput?.tipo === EnumTipologiaRuoloApplicativo.ParentiBambino) {
        //navigo sui bambin in relazione con il parente (utente corrente) e valorizzo la vista
        // TICKET H00569448
        // NB se la sezione è selezionata mostro solo i bambini appartenenti a quella sezione
        this.utenteCorrenteParamInput.personaFisica.dettaglioRelazioni.forEach((bambinoInRelazione) => {
          if(this.sezioneCorrente!=undefined){          
            // se ho la struttura filtro  
            if(this.sezioneCorrente.bambini.find((x)=>x.id == bambinoInRelazione.inRelazioneCon.id) != undefined){
              this.personeCoinvolte.push(bambinoInRelazione.inRelazioneCon);
            }
          }else{
            //altrimenti no
            this.personeCoinvolte.push(bambinoInRelazione.inRelazioneCon);
          }
        });
      } else {
        console.log(`strumento registrazione, relativo a persona proprietaria ${this.strumentoDiRegistrazioneTemplateSelected.isRelativoAPersonaProprietaria}, relativo a insegnante ${this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante}`);
        if (this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante) {
          //recupero le persone fisiche dalla lista insegnanti che è un lista di utenti
          // ndr potrebbe avere più ruoli check su insegnante ed eventualmente coordinatore
          
          //check su ruolo effettivo e non su lista ruoli utente
          let isInsegnante: boolean = this.ruoloApplicativoAttualmenteUtilizzatoParamInput.tipo == "RuoloEducatore_Insegnante";
          
          if(isInsegnante){
            // deve vedere solo le sue cose, lo aggiungo solo se tra gli insegnanti
            this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti.forEach((insegnante) => {
                if(this.strumentoDiRegistrazioneTemplateSelected.isRelativoAPersonaProprietaria){
                  //controllo se la persona attuale è quella loggata
                  if(insegnante.id == this.utenteCorrenteParamInput.id){
                    // aggiungo solo l'utente corrente
                    console.log('Aggiungo solo utente');
                    this.personeCoinvolte.push(insegnante.personaFisica);  
                  }
                }else{
                  //aggiungo tutti perchè non è relativo a persona proprietaria
                  console.log('Aggiungo tutti gli insegnanti');
                  this.personeCoinvolte.push(insegnante.personaFisica);
                }

            });
          }else{
            // è coordinatore
            console.log('coord');
            this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti.forEach((insegnante) => {
              console.log('Aggiungo tutti gli insegnanti');
                  this.personeCoinvolte.push(insegnante.personaFisica);
            });
          }

        /* if(isInsegnante && this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti.map(i => i.personaFisica.id).includes(this.utenteCorrenteParamInput.personaFisica.id)){
            // se loggato come insegnante di quell'anno scolastico patcho solo quello, altrimenti ogni insegnante che vede il coordinatore
            // NB il caso in cui isInsegnante ma.. non è parte di quelle coinvolte allora la includes non darà risultati e in questo if non ci entra..
            console.log('aggiungo solo insegnante');
            this.personeCoinvolte.push(this.utenteCorrenteParamInput.personaFisica);  
          }else{
            console.log('aggiungo tutti per anno scolastico');
            this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti.forEach((insegnante) => {
              if(isInsegnante){
                if(this.strumentoDiRegistrazioneTemplateSelected.isRelativoAPersonaProprietaria){
                  //controllo se la persona attuale è quella loggata
                  if(insegnante.id == this.utenteCorrenteParamInput.id){
                    // aggiungo solo l'utente corrente
                    //console.log('AGGIUNGO SOLO UTENTE CORRENTE!!');
                    this.personeCoinvolte.push(insegnante.personaFisica);  
                  }
                }else{
                  //aggiungo tutti perchè non è relativo a persona proprietaria
                  //console.log('AGGIUNGO TUTTI!!');
                  this.personeCoinvolte.push(insegnante.personaFisica);
                }
              }else{
                // è coordinatore
                this.personeCoinvolte.push(insegnante.personaFisica);
              }
            });
          }*/
          

          //console.log('insegnanti', this.personeCoinvolte);
          //console.log(this.personaSelected);
        }
        else {
          this.personeCoinvolte = this.sezioneAnnoScolasticoPeriodoFormativoSelected.bambini;
          //console.log('bambini', this.personeCoinvolte);
        }
      }

      // console.log('enableIsRelativoAPersonaSelector', this.strumentoDiRegistrazioneTemplateSelected.enableIsRelativoAPersonaSelector);
      // console.log('ParamInput', this.strumentoArchivioInstanceParamInput);
      // console.log('persona?', this.personaSelected);
      // console.log('persone', this.personeCoinvolte);

      // if (this.strumentoDiRegistrazioneTemplateSelected.enableIsRelativoAPersonaSelector === true || this.strumentoArchivioInstanceParamInput != null) {
      //   if (this.strumentoArchivioInstanceParamInput != null) {
      //     //significa che ho richiesto la creazione o modifica di una istanza di strumento
      //     //posso prevalorizzare le opzioni dei vari selettori
      //     this.personeCoinvolte = this.strumentoArchivioInstanceParamInput.personeCoinvolte;
      //     this.personaSelected = this.strumentoArchivioInstanceParamInput.personeCoinvolte[0];

      //     console.log('persone', this.personeCoinvolte);
      //     console.log('personaSelected', this.personaSelected);

      //   } else {
      //     // if (this.personaSelected == null || this.personaSelected == undefined) {
      //     //   console.log('insegnante?', this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante);

      //     if (this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante) {
      //       //recupero le persone fisiche dalla lista insegnanti che è un lista di utenti e selzioni di defatul il primo
      //       this.personaSelected = this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti[0].personaFisica;
      //       console.log('personaSelectedInsegnante', this.personaSelected);
      //     }
      //     else {
      //       this.personaSelected = this.sezioneAnnoScolasticoPeriodoFormativoSelected.bambini[0];
      //       console.log('personaSelectedBambino', this.personaSelected);
      //     }
      //     // }
      //   }
      // }


      if (this.strumentoDiRegistrazioneTemplateSelected.enableIsRelativoAPersonaSelector === true || this.strumentoArchivioInstanceParamInput != null) {
        if (this.strumentoArchivioInstanceParamInput != null) {
          //significa che ho richiesto la creazione o modifica di una istanza di strumento
          //posso prevalorizzare le opzioni dei vari selettori
          this.personeCoinvolte = this.strumentoArchivioInstanceParamInput.personeCoinvolte;
          this.personaSelected = this.strumentoArchivioInstanceParamInput.personeCoinvolte[0];
          //console.log('persone', this.personeCoinvolte);
          //console.log('personaSelected', this.personaSelected);

        } else if (this.personaSelected == null || this.personaSelected == undefined) {
          //console.log('insegnante?', this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante);

          if (this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante) {
            if(this.strumentoDiRegistrazioneTemplateSelected.isRelativoAPersonaProprietaria){
              let ruoliInsegnante = this.utenteCorrenteParamInput.ruoli.filter((ruolo)=>ruolo.tipo == "RuoloEducatore_Insegnante");
              let isInsegnante = false;
              if(ruoliInsegnante!=undefined && ruoliInsegnante.length>0){
                isInsegnante = true;
              }
              if(!isInsegnante){
                // fai come prima
                //recupero le persone fisiche dalla lista insegnanti che è un lista di utenti e selzioni di defatul il primo
                this.personaSelected = this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti[0].personaFisica;
              }else{
                //cerca l'insergnante corrente
                this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti.forEach((insegnante) => {
                    //controllo se la persona attuale è quella loggata
                    if(insegnante.id == this.utenteCorrenteParamInput.id){
                      // aggiungo solo l'utente corrente
                      this.personaSelected = insegnante.personaFisica;  
                      //console.log('HO SELEZIONATO ', this.personaSelected);
                    }
                });
              }
              
            }else{
              //recupero le persone fisiche dalla lista insegnanti che è un lista di utenti e selzioni di defatul il primo
              this.personaSelected = this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti[0].personaFisica;
            }

            //console.log('personaSelectedInsegnante', this.personaSelected);
          }
          else {
            this.personaSelected = this.sezioneAnnoScolasticoPeriodoFormativoSelected.bambini[0];
            //console.log('personaSelectedBambino', this.personaSelected);
          }
        }
      }

    }
    //modifico data compilabile dal al nel caso cui la frequenza di compilazione di annuale
    //eseguoin questo momento la modifica in quanto ho le varibili configurate correttamente
    if (this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Annuale) {
      this.setDateCompilabiliAnnoScolastico();
    }
    //console.log(this.personaSelected);
    //console.log(this.personeCoinvolte);
    //console.log(this.strumentoDiRegistrazioneTemplateSelected);
  }

  public InitStrumentoRegistrazioneSelectorComponentBody(): void {
    //--------------------CALLOUT 8 (istanza strumento di registrazione)

    //vi è l apossibilità di avere diversi tamplte body?
    if (this.strumentoDiRegistrazioneTemplateSelected.templateBodies.length > 1) {
      //vi sono template bosy che prevedono delle regole di match li filtro l'ordine va ad individuare le regole più importanti
      let filteredTemplateBodies: Array<StrumentoDiRegistrazioneTemplateBody> = [...this.strumentoDiRegistrazioneTemplateSelected.templateBodies];
      // console.log(filteredTemplateBodies);
      //filtro per regola su servizio this.servizioEducativoSelected.tipologiaServizioOfferto
      if (
        this.strumentoDiRegistrazioneTemplateSelected.templateBodies.filter(
          (tb) => tb.matchRuleTipologiaOffertaServizioEducativo != null,
        ).length > 0
      ) {
        filteredTemplateBodies = filteredTemplateBodies.filter(
          (tb) =>
            tb.matchRuleTipologiaOffertaServizioEducativo === this.servizioEducativoSelected.tipologiaServizioOfferto,
        );
      }

      //filtro per regola su riferimetno a fascia di età
      if (
        this.strumentoDiRegistrazioneTemplateSelected.templateBodies.filter(
          (tb) => tb.matchRulefasciaEtaDiRiferimentoSezione != null,
        ).length > 0
      ) {
        filteredTemplateBodies = filteredTemplateBodies.filter(
          (tb) =>
            tb.matchRulefasciaEtaDiRiferimentoSezione ===
            this.sezioneAnnoScolasticoPeriodoFormativoSelected.fasciaEtaDiRiferimento,
        );
      }

      //a questo punto dovrei aver trovato il template body
      if (filteredTemplateBodies.length === 1) {
        this.strumentoDiRegistrazioneTemplateBodySelected = filteredTemplateBodies[0];
        // this.strumentoDiRegistrazioneTemplateBodySelected.strumentoDiRegistrazioneTemplate = this.strumentoDiRegistrazioneTemplateSelected;
      } else {
        throw new Error("Impossbile trovare il modello di template da visualizzare. Contattare l'helpdesk di Lbl");
      }
    } else if (this.strumentoDiRegistrazioneTemplateSelected.templateBodies.length === 1) {
      this.strumentoDiRegistrazioneTemplateBodySelected = this.strumentoDiRegistrazioneTemplateSelected.templateBodies[0];
      // this.strumentoDiRegistrazioneTemplateBodySelected.strumentoDiRegistrazioneTemplate = this.strumentoDiRegistrazioneTemplateSelected;
    } else {
      throw new Error("Impossbile trovare il modello di template da visualizzare. Contattare l'helpdesk di Lbl");
    }

    //in base al body selezionato recupero il codice identificativo del template body in modo da sapere
    //quale viewmodel utilizzare nella mia applicazione

    // this.istanzaStrumentoDiRegistrazioneArchivio = new Observable<StrumentoDiRegistrazioneArchivio[]>();
    const arrayObs: Array<Observable<StrumentoDiRegistrazioneArchivio[]>> = [];

    if (this.strumentoArchivioInstanceParamInput != null) {
      //forzo visualizzazione a full e passo istanza

      this.strumentoDiRegistrazioneTemplateBodySelected.templateBodyViewMode =
        EnumStrumentoDiRegistrazioneTemplateBodyViewMode.Full;
      this.istanzeStrumentoDiRegistrazioneArchivio =
        new Observable<StrumentoDiRegistrazioneArchivio[]>((el) => {
          el.next([this.strumentoArchivioInstanceParamInput]);
        });

    } else {
      if (
        this.strumentoDiRegistrazioneTemplateBodySelected.templateBodyViewMode ===
        EnumStrumentoDiRegistrazioneTemplateBodyViewMode.Full
      ) {
        //sono nel caso in cui il corpo del template è recuperabile in toto dall'archivio
        if (this.strumentoDiRegistrazioneTemplateSelected.isRelativoAPersona || this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante) {
          //NB: segnalazione H00771011: i selettori non sono ottimizzati come da sviluppo iniziale
          // se si vogliono ottimizzare M.Giordani dice che deve essere una MEV per il cliente
          // il problema ricade sulla personaSelected che non rispetta a pieno quello che si vede a video
          // se infatti la combobox delle personecoinvolte è vuota, non ci deve essere la persona selected di default
          // quindi se le persone coinvolte sono 0 allora non viene caricato lo strumento
          if((this.personeCoinvolte || []).length>0) {
            arrayObs.push(
              this.strumentiRegistrazioneArchivioService.getStrumentoDiRegistrazioneArchivio(
                this.compilabileDal,
                this.compilabileAl,
                this.strumentoDiRegistrazioneTemplateBodySelected,
                this.getAnniScolasticiParam(
                  this.anniScolasticoPeriodoFormativiPerStruttura,
                  this.annoScolasticoPeriodoFormativiSelected,
                ),
                [this.sezioneAnnoScolasticoPeriodoFormativoSelected],
                [this.personaSelected],
              ),
            );
          }
          //console.log('personaSelected', this.personaSelected);
        } else if (this.strumentoDiRegistrazioneTemplateSelected.isRelativoASezione) {
          arrayObs.push(
            this.strumentiRegistrazioneArchivioService.getStrumentoDiRegistrazioneArchivio(
              this.compilabileDal,
              this.compilabileAl,
              this.strumentoDiRegistrazioneTemplateBodySelected,
              this.getAnniScolasticiParam(
                this.anniScolasticoPeriodoFormativiPerStruttura,
                this.annoScolasticoPeriodoFormativiSelected,
              ),
              [this.sezioneAnnoScolasticoPeriodoFormativoSelected],
              null,
            ),
          );

        } else {
          arrayObs.push(
            this.strumentiRegistrazioneArchivioService.getStrumentoDiRegistrazioneArchivio(
              this.compilabileDal,
              this.compilabileAl,
              this.strumentoDiRegistrazioneTemplateBodySelected,
              this.getAnniScolasticiParam(
                this.anniScolasticoPeriodoFormativiPerStruttura,
                this.annoScolasticoPeriodoFormativiSelected,
              ),
              [null],
              null,
              true
            ),
          );

        }

      } else if (
        this.strumentoDiRegistrazioneTemplateBodySelected.templateBodyViewMode ===
        EnumStrumentoDiRegistrazioneTemplateBodyViewMode.ListItemStandard &&
        this.strumentoDiRegistrazioneTemplateSelected.isRelativoAPersona ||
        this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante === true      
        ) {
        //significa che siamo in presenza di un body costruito a partire da una lista dei bambini in base alla sezione selezionata
        //per ogni bambino avro la possibilità si aprire in modale lo strumento di strumento di registrazione in viewmode full

        arrayObs.push(
          this.strumentiRegistrazioneArchivioService.getStrumentoDiRegistrazioneArchivio(
            this.compilabileDal,
            this.compilabileAl,
            this.strumentoDiRegistrazioneTemplateBodySelected,
            this.getAnniScolasticiParam(
              this.anniScolasticoPeriodoFormativiPerStruttura,
              this.annoScolasticoPeriodoFormativiSelected,
            ),
            [this.sezioneAnnoScolasticoPeriodoFormativoSelected],
            this.personeCoinvolte,
          ),
        );
        //console.log('personaSelected', this.personaSelected);
      } else if (
        this.strumentoDiRegistrazioneTemplateBodySelected.templateBodyViewMode ===
        EnumStrumentoDiRegistrazioneTemplateBodyViewMode.ListItemStandard &&
        this.strumentoDiRegistrazioneTemplateSelected.isRelativoASezione
      ) {
        //significa che siamo in presenza di un body costruito a partire da una lista di sezioni  in base al servizo educativo selezionato
        //per ogni sezione avro la possibilità si aprire in modale lo strumento di strumento di registrazione in viewmode full
        arrayObs.push(
          this.strumentiRegistrazioneArchivioService.getStrumentoDiRegistrazioneArchivio(
            this.compilabileDal,
            this.compilabileAl,
            this.strumentoDiRegistrazioneTemplateBodySelected,
            this.getAnniScolasticiParam(
              this.anniScolasticoPeriodoFormativiPerStruttura,
              this.annoScolasticoPeriodoFormativiSelected,
            ),
            this.sezioniAnnoScolasticoPeriodoFormativoPerServizio,
            null,
          ),
        );
        //console.log('personaSelected', this.personaSelected);
      } else if (this.strumentoDiRegistrazioneTemplateBodySelected.templateBodyViewMode === EnumStrumentoDiRegistrazioneTemplateBodyViewMode.ListItemCustom &&
        this.strumentoDiRegistrazioneTemplateSelected.isRelativoAPersona
      ) {
        //significa che siamo in presenza di un body costruito a partire da una lista dei bambini in base alla sezione selezionata
        //per ogni bambino utilizzerò una versione di template custom (vedi situazioni giornaliere)
        this.renderTemplateBodyHint = 'listItemCustomBambino';

        arrayObs.push(
          this.strumentiRegistrazioneArchivioService.getStrumentoDiRegistrazioneArchivio(
            this.compilabileDal,
            this.compilabileAl,
            this.strumentoDiRegistrazioneTemplateBodySelected,
            this.getAnniScolasticiParam(
              this.anniScolasticoPeriodoFormativiPerStruttura,
              this.annoScolasticoPeriodoFormativiSelected,
            ),
            [this.sezioneAnnoScolasticoPeriodoFormativoSelected],
            this.personeCoinvolte,
          ),
        );
        //console.log('personaSelected', this.personaSelected);
      } else if (this.strumentoDiRegistrazioneTemplateBodySelected.templateBodyViewMode === EnumStrumentoDiRegistrazioneTemplateBodyViewMode.ListItemCustom &&
        this.strumentoDiRegistrazioneTemplateSelected.isRelativoASezione
      ) {
        //significa che siamo in presenza di un body costruito a partire da una lista dei bambini in base alla sezione selezionata
        //per ogni bambino utilizzerò una versione di template custom (vedi situazioni giornaliere)
        this.renderTemplateBodyHint = 'listItemCustomSezione';
        arrayObs.push(
          this.strumentiRegistrazioneArchivioService.getStrumentoDiRegistrazioneArchivio(
            this.compilabileDal,
            this.compilabileAl,
            this.strumentoDiRegistrazioneTemplateBodySelected,
            this.getAnniScolasticiParam(
              this.anniScolasticoPeriodoFormativiPerStruttura,
              this.annoScolasticoPeriodoFormativiSelected,
            ),
            this.sezioniAnnoScolasticoPeriodoFormativoPerServizio,
            null,
          ),
        );
        //console.log('personaSelected', this.personaSelected);
      }
      this.istanzeStrumentoDiRegistrazioneArchivio = forkJoin(arrayObs).pipe(
        //tap(val => console.log('istanza ', val)),
        map((el) => el.reduce((acc, val) => acc.concat(val), [])),
        catchError((err) => {
          if (err?.error?.codiceErrore === 'SUPERATO_N_COMPILAZIONI_STRUMENTI') {
            this.isArchivio = true;
            this.errorText = 'nCompilazioniSuperate';
            return of([]);
          } else
            return of(err);
        }),
        takeUntil(this.unsubscribe$),
      );

    }
    //valorizzo lo stato attuale dello strumento
    //se stiamo trattando istanze di strumento archivio in modalità listItemCustom singnifica che stiamo visualizzando più
    //strumenti in un 'unica intrefaccia . prendo lo status della instanza in posizione 0 visto che sarà condiviso dalle altre
    // in ogni caso sia che stiamo analizzando un'istanza o n lato component mi viene fornita sempre una lista vedi

    //una volta che tutto il mio array devo fare una valutazione se numeroCompilazioneNecessaria > 1 allora se
    //è >1 e prevede numero compliazioneFiniit = true allora recupro l i+ultima instanza della lista restituita dal BE
    //oltre a recuperare l ultimo inoltro un msg

  }

  redirectToArchivio() {
    const params: Params = {
      relation: null,
      startwith: null,
      relations: null,
      contains: null,
      equals: null,
      lessthan: null,
      greaterthan: null,
    };

    this.istanzeStrumentoDiRegistrazioneArchivio.subscribe((strumenti) => {
      // if (strumenti.length === 0) {

      //   params.relation = `${params.relation},compilatoDa:${strumenti[0].updatedBy.id}`;
      //   params.equals = `stato:${strumenti[0].status}`;
      // }

      params.greaterthan = `dataInizio:${this.compilabileDal.getTime()}`;
      this.strumentoDiRegistrazioneTemplateBodySelected.strumentoDiRegistrazioneTemplate.frequenzaCompilazione === 'giornaliera' ?
        params.lessthan = `dataFine:${this.compilabileAl.getTime() + 86400000}` : params.lessthan = `dataFine:${this.compilabileAl.getTime()}`

      if (this.strumentoDiRegistrazioneTemplateSelected.enableIsRelativoAPersonaSelector) {
        params.relations = `${params.relations},bambino:${this.personaSelected?.id}`;
      }

      if (this.sezioneAnnoScolasticoPeriodoFormativoSelected) {
        params.relations = `${params.relations},sezione:${this.sezioneAnnoScolasticoPeriodoFormativoSelected.id}`;
      }

      if(this.annoScolasticoPeriodoFormativiSelected){
        params.relations = `${params.relations},annoScolastico:${this.annoScolasticoPeriodoFormativiSelected.id}`;
      }


      params.relation = `${params.relation},nomeStrumentoDiRegistrazione:${this.strumentoDiRegistrazioneTemplateBodySelected.templateBodyCode}`;

      this.router.navigate(['/private/backoffice/archivio-strumenti-di-registrazione'], { queryParams: params });
      // this.router.navigate([`/private/backoffice/archivio-strumenti-di-registrazione?relation=nomeStrumentoDiRegistrazione:${this.strumentoDiRegistrazioneTemplateBodySelected.templateBodyCode}`]);
    });
  }
  public RenderHeader(): void {
    this.InitStrumentoRegistrazioneSelectorComponentHeader();
  }

  private getDataCompilabileDal(refDate: Date, strutturaEducativa: StrutturaEducativa) {
    let dataDal = refDate;

    if (this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Giornaliera) {
      //da riportare la seguente dicitura:
      //giornaliera - compila entro <giorno corrente>/<mese corrente>/<anno corrente>

      dataDal = refDate;
    } else if (
      this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Settimanale
    ) {
      //da riportare la seguente dicitura:
      //settimanale - compilabile dal dal <numero primo giorno settimana configurata per struttura educatiova in base a data corrente>/<mese corrente>/<anno corrente> al
      //<numero utlimo giorno settimana configurata per struttura educatiova in base a data corrente>/<mese corrente>/<anno corrente>

      dataDal = this.getInzioSettimanaDate(refDate);
    } else if (
      this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Trimestrale
    ) {
      //da riportare la seguente dicitura:
      //trimestrale - compilabile dal <nome mese inzio trimestre in base a data corrente> al <nome mese fine trimestre in base a data corrente>

      dataDal = this.getDataInizioTrimestre(refDate);
    } else if (
      this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Annuale
    ) {
      //da riportare la seguente dicitura:
      //annuale - compilabile dal 01/01/<anno corrente> al 31/12/<anno corrente>
      dataDal = refDate;

      //???da valutare anche le prorpietà numeroAnniDiValidita
    }

    return moment(dataDal).startOf('day').toDate();
  }

  private getDataCompilabileAl(refDate: Date, strutturaEducativa: StrutturaEducativa) {
    let dataAl = refDate;

    if (this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Giornaliera) {
      //da riportare la seguente dicitura:
      //giornaliera - compila entro <giorno corrente>/<mese corrente>/<anno corrente>

      // dataAl = moment(Date.parse('01/01/' + dataAl.getFullYear().toString())).toDate();
      dataAl = moment().toDate();

    } else if (
      this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Settimanale
    ) {
      //da riportare la seguente dicitura:
      //settimanale - compilabile dal dal <numero primo giorno settimana configurata per struttura educatiova in base a data corrente>/<mese corrente>/<anno corrente> al
      //<numero utlimo giorno settimana configurata per struttura educatiova in base a data corrente>/<mese corrente>/<anno corrente>

      dataAl = this.getFineSettimanaDate(refDate, strutturaEducativa);
    } else if (
      this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Trimestrale
    ) {
      //da riportare la seguente dicitura:
      //trimestrale - compilabile dal <nome mese inzio trimestre in base a data corrente> al <nome mese fine trimestre in base a data corrente>

      dataAl = this.getDataFineTrimestre(refDate);
    } else if (
      this.strumentoDiRegistrazioneTemplateSelected.frequenzaCompilazione === EnumFrequenzaCompilazione.Annuale
    ) {
      //???da valutare anche le prorpietà numeroAnniDiValidita

      //da riportare la seguente dicitura:
      //annuale - compilabile dal 01/01/<anno corrente> al 31/12/<anno corrente>
      dataAl = refDate;
    }
    return moment(dataAl).startOf('day').toDate();
  }

  changeSelectServiziEducativi(select?) {
    //console.log(this.personeCoinvolte);
    if (select) {
      //seleziono il servizio educativo in base alla select
      // this.personeCoinvolte.forEach(ins => {
      //   if (ins.id === this.personaSelected.id && !this.isInsegnante) {
      //     //console.log("trovato insegnante");
      //     this.isInsegnante = true;
      //   }
      // })

      //aggiunto controllo per capire se è insegnante in strumento ricaduta attività formativa e selezionare la persone corretta
      // if (this.funzionalitaLblSelezionata.strumentoDiRegistrazioneTemplate.templateBodies[0].templateBodyCode === 'ricaduta_attivita_formativa') {
      //   this.personeCoinvolte.forEach(ins => {
      //     if (ins.id === this.personaSelected.id && !this.isInsegnante) {
      //       console.log("trovato insegnante");
      //       this.isInsegnante = true;
      //     }
      //   })
      // }

      //aggiunto controllo per capire se lo strumento è relativo a insegnante
      if (this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante) {
        if (!this.isInsegnante) {
          this.isInsegnante = true;
        }
      }

      this.servizioEducativoSelected = this.serviziEducativiStrutturaSelezionata.find(
        (el) => el.id === +select.target.value,
      );

      localStorage.setItem('servizioEducativoSelectedId', `${this.servizioEducativoSelected.id}`);
      this.servizioEducativoSelectedId = +localStorage.getItem('servizioEducativoSelectedId');

      this.changeSelectSezioni();
    } else {
      //primo avvio
      this.servizioEducativoSelected = this.serviziEducativiStrutturaSelezionata[0];
      localStorage.setItem('servizioEducativoSelectedId', `${this.servizioEducativoSelected.id}`);
      this.servizioEducativoSelectedId = +localStorage.getItem('servizioEducativoSelectedId');
    }
  }

  changeSelectSezioni(select?) {
    //console.log(this.personeCoinvolte);
    if (select) {
      //aggiunto controllo per capire se è insegnante in strumento ricaduta attività formativa e selezionare la persone corretta
      // if (this.funzionalitaLblSelezionata.strumentoDiRegistrazioneTemplate.templateBodies[0].templateBodyCode === 'ricaduta_attivita_formativa') {
      //   this.personeCoinvolte.forEach(ins => {
      //     if (ins.id === this.personaSelected.id && !this.isInsegnante) {
      //       console.log("trovato insegnante");
      //       this.isInsegnante = true;
      //     }
      //   })
      // }

      //aggiunto controllo per capire se lo strumento è relativo a insegnante
      if (this.strumentoDiRegistrazioneTemplateSelected.isRelativoAInsegnante) {
        if (!this.isInsegnante) {
          this.isInsegnante = true;
        }
      }

      this.sezioneAnnoScolasticoPeriodoFormativoSelected = this.sezioniAnnoScolasticoPeriodoFormativoPerServizio.find(
        (el) => el.id === +select.target.value,
      );
      localStorage.setItem('sezioneId', `${this.sezioneAnnoScolasticoPeriodoFormativoSelected.id}`);
      this.sezioneId = +localStorage.getItem('sezioneId');
      this.changeSelectBambini();
      //console.log(this.sezioneId);
      // this.ngOnInit();
    } else if (this.servizioEducativoSelectedId) {
      this.annoScolasticoPeriodoFormativiSelected = this.FilterAnnoScolasticoInCorsoPerServizio(
        this.anniScolasticoPeriodoFormativiPerStruttura,
        this.strutturaEducativaSelected,
        this.servizioEducativoSelected,
      );
      this.sezioniAnnoScolasticoPeriodoFormativoPerServizio = this.annoScolasticoPeriodoFormativiSelected.sezioni;
      this.sezioneAnnoScolasticoPeriodoFormativoSelected = this.sezioniAnnoScolasticoPeriodoFormativoPerServizio[0];
      localStorage.setItem('sezioneId', `${this.sezioneAnnoScolasticoPeriodoFormativoSelected.id}`);
      this.sezioneId = +localStorage.getItem('sezioneId');

      this.changeSelectBambini();
      //console.log(this.sezioneId);
    } else {
      this.sezioneAnnoScolasticoPeriodoFormativoSelected = this.sezioniAnnoScolasticoPeriodoFormativoPerServizio[0];
      localStorage.setItem('sezioneId', `${this.sezioneAnnoScolasticoPeriodoFormativoSelected.id}`);
      this.sezioneId = +localStorage.getItem('sezioneId');
      //console.log(this.sezioneId);
    }
  }

  changeSelectBambini(select?) {
    //console.log('select', select);

    if (select) {
      this.personaSelected = this.sezioneAnnoScolasticoPeriodoFormativoSelected.bambini.find(
        (el) => el.id === +select.target.value,
      );
      // eseguo un controllo perchè nel caso in cui la select non fosse destinata ai bambini la prima find darebbe esito negativo
      // all'interno dell'if mi occupo della find per gli insegnanti
      if (!this.personaSelected) {
        let persona = this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti.find(
          (el) => el.personaFisica.id === +select.target.value,
        );
        this.personaSelected = persona.personaFisica;
        //console.log('personaSelected', this.personaSelected);

      }

      // la variabile del local storage dovrebbe chiamarsi personaSelectId ma per questione di patching rimarrà bambinoSelectId
      localStorage.setItem('bambinoSelectId', `${this.personaSelected.id}`);
      this.bambinoSelectId = +localStorage.getItem('bambinoSelectId');
      this.ngOnInit();

    } else if (this.sezioneId || this.servizioEducativoSelectedId) {

      //console.log(this.sezioneId, this.servizioEducativoSelectedId);

      //nuovo controllo di prova per capire se la lista è di insegnanti o bambini

      if (this.isInsegnante) {
        //console.log("is insegnante true");

        if (this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti.length > 0) {

          //H00945960 se sono loggato come insegnante la lista contiene comunque tutti quelli della sezione selezionata. Per cui aggiungo controllo su id persona
          if(this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti.some(insegnante => insegnante.id == this.utenteCorrenteParamInput.id)){
            this.personaSelected = this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti.find(insegnante => insegnante.id == this.utenteCorrenteParamInput.id).personaFisica;
          }else{
            this.personaSelected = this.sezioneAnnoScolasticoPeriodoFormativoSelected.insegnanti[0].personaFisica;
          }

          localStorage.setItem('bambinoSelectId', `${this.personaSelected.id}`);
          this.bambinoSelectId = +localStorage.getItem('bambinoSelectId');
          this.isError = false;
        } else {
          try {
            throw new Error('Bambini non trovati, verificare se configurati per la corrente sezione');
          }
          catch (error) {
            this.isError = true;
            this.errorText = 'bambiniNonTrovati'
            console.error('Here is the error message', error);
          }
        }
      } else {
        //console.log('else');

        if (this.sezioneAnnoScolasticoPeriodoFormativoSelected.bambini.length > 0) {
          this.personaSelected = this.sezioneAnnoScolasticoPeriodoFormativoSelected.bambini[0];
          localStorage.setItem('bambinoSelectId', `${this.personaSelected.id}`);
          this.bambinoSelectId = +localStorage.getItem('bambinoSelectId');
          this.isError = false;
        } else {
          try {
            throw new Error('Bambini non trovati, verificare se configurati per la corrente sezione');
          }
          catch (error) {
            this.isError = true;
            this.errorText = 'bambiniNonTrovati'
            console.error('Here is the error message', error);
          }
        }
      }
      this.ngOnInit();

    } else {
      //console.log('else');

      this.personaSelected = this.sezioneAnnoScolasticoPeriodoFormativoSelected.bambini[0];
      localStorage.setItem('bambinoSelectId', `${this.personaSelected.id}`);
      this.bambinoSelectId = +localStorage.getItem('bambinoSelectId');
    }
  }

  getStrumentiRegistrazione(strumentoDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[]) {
    //console.log('getting strumento di registrazione archivio');
    this.strumentoDiRegistrazioneArchivio = [...strumentoDiRegistrazioneArchivio];
  }

  action(status: string) {

    //console.log(this.isLoading);

    localStorage.removeItem('strumentoArchivioInstanceParamInput');
    let formInvalido = false;
    if (!this.strumentoDiRegistrazioneArchivio) {
      let string = this.translocoService.translate('messages.form');
      //console.log('strumento non valido');
      alert(string);
      formInvalido = true;
    } else {

      //sezione modificata
      this.isLoading = true;
      //console.log(this.isLoading);

      //numero "bambini" tramite numero di strumento registrazione archivi
      let bambini = 0;
      this.strumentoDiRegistrazioneArchivio.forEach((el) => {
        bambini = bambini + 1;
      });
      //console.log('bambini', bambini);

      let countUpdate = 0;
      let countCreate = 0;
      let countError = 0;

      this.strumentoDiRegistrazioneArchivio.forEach((strumento) => {
        strumento.dtUpdate = moment(Date.now()).toDate();
        strumento.status = EnumStatoStrumentoRegistrazione[status];

        //console.log(strumento);

        // controllo per gestione degli strumenti delle aree esterne
        if (strumento.strumentoRegistrazioneTemaplateBody.templateBodyCode === EnumStrumentoDiRegistrazioneTemplateBodyCode.RilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne) {
          strumento.sezioni = strumento.anniScolastici[0].sezioni
        }

        if (strumento.id) {
          this.strumentiRegistrazioneArchivioService.updateById(strumento.id, strumento).subscribe(error => {
            countError++;
            //console.log("c'è stato un errore nell'aggiornamento dello strumento: ", error)
          });
          //console.log('chiamataUpdate');
          countUpdate = countUpdate + 1;
        } else {
          this.strumentiRegistrazioneArchivioService.createOne(strumento).subscribe(error => {
            countError++;
            //console.log("c'è stato un errore nella creazione: ", error)
          });
          //console.log('chiamataCreate');
          countCreate = countCreate + 1;
        }
      });
      // console.log('countUpdate', countUpdate);
      // console.log('countCreate', countCreate);
      // console.log('countError', countError);

      if ((bambini === countUpdate || bambini === countCreate) && countError === 0) {
        setTimeout(() => {
          this.isLoading = false;
          //console.log('ok');
        }, 2000);
      } else {
        //console.log('errore');
        throw new Error('Errore inatteso');
      }
    }

    if (!formInvalido) {
      if (this.archivioTostrumento === 'true') {
        this.redirectToArchivio();
      } else {
        this.ngOnInit();
      }
    }
    //console.log(this.isLoading);
  }

  onClickCompilaStrumentoListItem(strumentoInput) {
    if (strumentoInput) {
      localStorage.setItem('strumentoArchivioInstanceParamInput', JSON.stringify(strumentoInput));
    }

    this.ngOnInit();
  }

  private getInzioSettimanaDate(refDate: Date): Date {

    const startWeek = moment(refDate).toDate();

    if (startWeek.toString() !== 'Invalid Date') {

      return moment(startWeek).startOf('isoWeek').toDate();
    } else {
      return moment(refDate).startOf('isoWeek').toDate();
    }
  }

  private getFineSettimanaDate(refDate: Date, strutturaEducativa: StrutturaEducativa): Date {
    const startWeek = moment(refDate).toDate();

    const lastDays = strutturaEducativa.orarioApertura.length;

    if (startWeek.toString() !== 'Invalid Date') {

      return moment(startWeek).endOf('isoWeek').toDate();
    } else {

      return moment(refDate).endOf('isoWeek').toDate();
    }
  }

  private getDataInizioTrimestre(refDate: Date): Date {
    //MOMENT GESTISCE I MEDI DA 0-GENNAIO 11-DICEMBRE
    //SET-NOV
    //DIC-FEB
    //MAR-MAG
    //GIU-AGO
    const refMonth = moment(refDate).month() + 1;
    refDate = moment().startOf('month').toDate();

    let setInizioTrimestre: number;

    if (refMonth >= 9 && refMonth <= 11) {
      setInizioTrimestre = 8; // settembre
    } else if (refMonth >= 12 || refMonth <= 2) {
      setInizioTrimestre = 11; //dicembre
    } else if (refMonth >= 3 && refMonth <= 5) {
      setInizioTrimestre = 2; //marzo
    } else if (refMonth >= 6 && refMonth <= 8) {
      setInizioTrimestre = 5; //giugno
    }

    // const start = moment(refDate).quarter(moment(refDate).quarter()).startOf('quarter').toDate();

    const start = moment(refDate).set('month', setInizioTrimestre).toDate();

    return start;
  }

  private getDataFineTrimestre(refDate: Date): Date {
    const refMonth = moment(refDate).month() + 1;

    let setFineTrimestre: number;

    if (refMonth >= 9 && refMonth <= 11) {
      setFineTrimestre = 10; //novembre
    } else if (refMonth >= 12 || refMonth <= 2) {
      setFineTrimestre = 1; //febbraio
    } else if (refMonth >= 3 && refMonth <= 5) {
      setFineTrimestre = 4; //maggio
    } else if (refMonth >= 6 && refMonth <= 8) {
      setFineTrimestre = 7; //agosto
    }
    const monthQuarter = moment(refDate).set('month', setFineTrimestre).toDate();
    const refLastDayMonth = moment(monthQuarter).daysInMonth();

    const end = moment(monthQuarter).set('date', refLastDayMonth).toDate();

    return end;
  }

  private setDateCompilabiliAnnoScolastico() {
    //verifico se ho un anno selezionato

    if (this.annoScolasticoPeriodoFormativiSelected != null) {
      this.compilabileDal = this.getDataCompilabileDal(
        this.annoScolasticoPeriodoFormativiSelected.dataInizio,
        this.strutturaEducativaSelected,
      );
      this.compilabileAl = this.getDataCompilabileAl(
        this.annoScolasticoPeriodoFormativiSelected.dataFine,
        this.strutturaEducativaSelected,
      );
    } else {
      //significa che lo strumento di registrazione è a livello di struttura
      //quindi recupero la data di inizione minore e la data di fine maggiore tra gli anni scolastici previsti

      const dataList: Date[] = [];
      this.anniScolasticoPeriodoFormativiPerStruttura.forEach((anno) => {
        dataList.push(anno.dataInizio, anno.dataFine);
      });
      dataList.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
      this.compilabileDal = this.getDataCompilabileDal(dataList[0], this.strutturaEducativaSelected);
      this.compilabileAl = this.getDataCompilabileAl(dataList.slice(-1)[0], this.strutturaEducativaSelected);
    }
    this.labelValuePeriodoRiferimentoCompilazione =
      EnumFrequenzaCompilazione.Annuale.toString() +
      ' compilabile dal ' +
      moment(this.compilabileDal).format('DD/MM/yyyy') +
      ' al ' +
      moment(this.compilabileAl).format('DD/MM/yyyy');
  }

}
