export interface IRelazioneSintesi {
    testo: string;
}

export class RelazioneSintesi {
    testo: string;

    constructor(params: Partial<IRelazioneSintesi> = {}) {
        Object.assign(this, params);

        if (params.testo) {
            this.testo = params.testo;
        }
    }

    static InitContent(): RelazioneSintesi {
        const content = new RelazioneSintesi();

        return content;
    }

    deconstructor(): IRelazioneSintesi {
        return Object.assign({}, this);
    }
}