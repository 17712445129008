import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  Dimensione,
  EnumConformitaCriterioDimensione,
  EnumGradoValutazioneRequisito,
  EnumStrumentoDiRegistrazioneTemplateBodyCode,
  ItemMonitoraggioValutazione,
  StandardOProceduraDiRiferimento,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'lbl-app-ui-erogazione-servizio',
  templateUrl: './erogazione-servizio.component.html',
  styleUrls: ['./erogazione-servizio.component.scss']
})
export class ErogazioneServizioComponent implements OnInit {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button')
  buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): StandardOProceduraDiRiferimento {
    let content = this.strumentoDiRegistrazioneArchivio.content as StandardOProceduraDiRiferimento;

    if (!content) {
      content = StandardOProceduraDiRiferimento.InitContent(EnumStrumentoDiRegistrazioneTemplateBodyCode.ChecklistMonitoraggioErogazioneServizio);
    }

    return content;
  }

  form: FormGroup;

  get dimensioni(): FormArray {
    return this.form.get('dimensioni') as FormArray;
  }

  columns: string[] = ['dimensione', 'puntiDimensione'];

  votiValutazione: number[] = Object.keys(EnumGradoValutazioneRequisito).map(key => EnumGradoValutazioneRequisito[key]).filter(el => !isNaN(el));
  conformita: string[] = Object.keys(EnumConformitaCriterioDimensione).map(key => EnumConformitaCriterioDimensione[key]).filter(el => isNaN(Number(el)));;

  constructor(private notificationService: MatSnackBar,
  ) {

  }

  ngOnInit() {
    this.form = this.buildForm();
    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      dimensioni: this.content && this.content.dimensioni ? this.buildFormDimensioni(this.content.dimensioni) : new FormArray([]),
      noteESuggerimenti: new FormControl(this.content && this.content.noteESuggerimenti ? this.content.noteESuggerimenti : null),
    });

    return form;
  }

  buildFormDimensioni(dimensioni: Dimensione[]): FormArray {
    const form = new FormArray(dimensioni.map((value) => this.buildDimensione(value)));

    return form;
  }

  buildDimensione(dimensione: Dimensione): FormGroup {
    const form = new FormGroup({
      titoloDimensione: new FormControl(dimensione.titoloDimensione ? dimensione.titoloDimensione : null),
      puntiDimensione: new FormControl(dimensione.puntiDimensione || !isNaN(dimensione.puntiDimensione) ? dimensione.puntiDimensione : null),
      itemsMonitoraggioValutazione: dimensione.itemsMonitoraggioValutazione && dimensione.itemsMonitoraggioValutazione.length > 0 ? this.buildItemsMonitoraggioValutazione(dimensione.itemsMonitoraggioValutazione) : new FormArray([])
    });

    return form;
  }

  buildItemsMonitoraggioValutazione(items: ItemMonitoraggioValutazione[]): FormArray {
    const form = new FormArray(items.map((value) => this.buildItemMonitoraggioValutazione(value)));

    return form;
  }

  buildItemMonitoraggioValutazione(item: ItemMonitoraggioValutazione): FormGroup {
    const form = new FormGroup({
      titoloItemMonitoraggioValutazione: new FormControl(item.titoloItemMonitoraggioValutazione ? item.titoloItemMonitoraggioValutazione : null),
      pesoItemMonitoraggioValutazionePercentuale: new FormControl(item.pesoItemMonitoraggioValutazionePercentuale || !isNaN(item.pesoItemMonitoraggioValutazionePercentuale) ? item.pesoItemMonitoraggioValutazionePercentuale : null),
      conformita: new FormControl(item.conformita ? item.conformita : null),
      gradoSoddisfazioneRequisiti: new FormControl(item.gradoSoddisfazioneRequisiti || !isNaN(item.gradoSoddisfazioneRequisiti) ? item.gradoSoddisfazioneRequisiti : null),
    });

    return form;
  }

  getFormItemsDimensione(index: number): FormArray {
    return this.dimensioni.at(index).get('itemsMonitoraggioValutazione') as FormArray;
  }

  updateForm(entity: StandardOProceduraDiRiferimento): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const standardOProceduraDiRiferimento = new StandardOProceduraDiRiferimento(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: standardOProceduraDiRiferimento,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }

}
