<ng-container *transloco="let t; read: 'gestionePortfolioCompetenzeBambini.strumentoDiRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>
      <span>
        {{t('subtitle.inizio')}}
        <button mat-flat-button (click)="openDialog()">{{t('subtitle.link')}}</button>
        {{t('subtitle.fine')}}
      </span>

      <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start center">
        <div [matTooltip]="t('button.uploadFileDisabled')" matTooltipPosition="right" [matTooltipDisabled]="!(content?.portfolio || tempFile)">
          <button mat-flat-button color="primary" type="button" (click)="inputContenuto.click()" [disabled]="content?.portfolio || tempFile">
            {{ t('button.uploadFile') }}
          </button>
        </div>
        <input hidden type="file" accept=".pdf" #inputContenuto (change)="upload($event.target.files)" />
      </div>
      <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="start center" *ngIf="content?.portfolio || tempFile">
        <span *ngIf="!tempAllegato?.link && !content?.portfolio?.link">
          {{ tempFile ? tempFile?.name : content?.portfolio?.originalName }}
        </span>
        <span *ngIf="tempAllegato?.link || content?.portfolio?.link">
          <a *ngIf="tempAllegato?.link"
            [href]="tempAllegato.link">{{ tempFile ? tempFile?.name : content?.portfolio?.originalName }}
          </a>
          <a *ngIf="content?.portfolio?.link"
            [href]="content?.portfolio?.link">{{ tempFile ? tempFile?.name : content?.portfolio?.originalName }}
          </a>
        </span>
        <button mat-icon-button color="primary" type="button"
          (click)="deleteFile(tempAllegato ? tempAllegato?.id : content?.portfolio?.id )"
          matTooltipPosition="right" [matTooltip]="t('button.deleteFile')"
          *ngIf="content?.portfolio || tempFile">
          <mat-icon svgIcon="delete"></mat-icon>
        </button>
      </div>

    </div>
    <button #button type="submit" class="d-none"></button>
  </form>
</ng-container>