import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';

@Component({
  selector: 'lbl-app-feature-strumento-registrazione-portfolio-competenze-bambini-dialog-linee-guida-portfolio-competenze-bambini',
  templateUrl: 'dialog-linee-guida-portfolio-competenze-bambini.component.html',
  styleUrls: ['dialog-linee-guida-portfolio-competenze-bambini.component.scss'],
})
export class DialogLineeGuidaPortfolioCompetenzeBambiniComponent {

  submit$: Subject<void> = new Subject();

  constructor(
    private matDialogRef: MatDialogRef<DialogLineeGuidaPortfolioCompetenzeBambiniComponent>
  ) { }

  cancel() {
    this.matDialogRef.close();
  }
}
