<ng-container *transloco="let t; read: 'sipiSezioneAnagrafica.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <div fxLayout="row" fxLayoutGap="1em">
        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{t('auslRiferimento')}}</mat-label>
          <input matInput formControlName="auslDiRiferimento" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{t('altroReferente')}}</mat-label>
          <input matInput formControlName="altroReferente" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="1em">
        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{t('neuropsichiatra')}}</mat-label>
          <input matInput formControlName="neuropsichiatraDiRiferimento" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{t('telefono')}}</mat-label>
          <input matInput formControlName="numeroTelefono" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="1em">
        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{t('altriContatti')}}</mat-label>
          <input matInput formControlName="altriContattiAusl" />
        </mat-form-field>
        <mat-form-field appearance="outline" fxFlex="40">
          <mat-label>{{t('email')}}</mat-label>
          <input matInput formControlName="email" />
        </mat-form-field>
      </div>

      <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center">
        <h3>{{t('table.title')}}</h3>
        <button mat-raised-button color="primary" type="button" (click)="addFiguraProfessionale()">
          <mat-icon svgIcon="add"></mat-icon>
          {{t('addFiguraProfessionale')}}
        </button>
      </div>

      <div class="mat-elevation-z1" *ngIf="formFigureProfessionali?.value?.length > 0">
        <table mat-table [dataSource]="formFigureProfessionali.value" formArrayName="figureProfessionaliDiRiferimento">
          <!-- Nome -->
          <ng-container matColumnDef="nome">
            <th mat-header-cell *matHeaderCellDef>{{t('table.column.nome')}}</th>
            <td mat-cell *matCellDef="let element;">
              {{ element?.nome }}
            </td>
          </ng-container>

          <!-- Cognome -->
          <ng-container matColumnDef="cognome">
            <th mat-header-cell *matHeaderCellDef>{{t('table.column.cognome')}}</th>
            <td mat-cell *matCellDef="let element;">
              {{ element?.cognome }}
            </td>
          </ng-container>

          <!-- Funzione -->
          <ng-container matColumnDef="funzione">
            <th mat-header-cell *matHeaderCellDef>{{t('table.column.funzione')}}</th>
            <td mat-cell *matCellDef="let element;">
              {{ element?.funzione }}
            </td>
          </ng-container>

          <!-- Telefono-->
          <ng-container matColumnDef="telefono">
            <th mat-header-cell *matHeaderCellDef>{{t('table.column.telefono')}}</th>
            <td mat-cell *matCellDef="let element;">
              {{ element?.telefono }}
            </td>
          </ng-container>

          <!-- Altri recapiti -->
          <ng-container matColumnDef="altriRecapiti">
            <th mat-header-cell *matHeaderCellDef>{{t('table.column.altriRecapiti')}}</th>
            <td mat-cell *matCellDef="let element;">
              {{ element?.altriRecapiti }}
            </td>
          </ng-container>

          <!-- Azioni Column -->
          <ng-container matColumnDef="azioni">
            <th mat-header-cell *matHeaderCellDef>
              {{ t('table.column.azioni.title') }}
            </th>
            <td mat-cell *matCellDef="let row; let i = index;">
              <button mat-icon-button color="primary" type="button" [matMenuTriggerFor]="rowActions">
                <mat-icon svgIcon="more_vert"></mat-icon>
              </button>

              <mat-menu #rowActions="matMenu" xPosition="before" yPosition="below">
                <button mat-menu-item type="button" (click)="editFiguraProfessionale(i)">
                  {{ t('table.column.azioni.button.edit') }}
                </button>
                <button mat-menu-item type="button" (click)="deleteFiguraProfessionale(i)">
                  {{ t('table.column.azioni.button.delete') }}
                </button>
              </mat-menu>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="figureProfessionaliDisplayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: figureProfessionaliDisplayedColumns"></tr>
        </table>
      </div>

      <mat-form-field appearance="outline" fxFill>
        <mat-label>{{t('altreInformazioni')}}</mat-label>
        <textarea matInput formControlName="altreInformazioni"></textarea>
      </mat-form-field>
    </div>

    <button #button type="submit" class="d-none"></button>

  </form>
</ng-container>
