<ng-container *transloco="let t; read: 'familyEngagement.strumentoDiRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">

    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>
      <h3>{{t('title')}}</h3>
      <div fxLayout="row" fxLayoutAlign="end end">
        <h4>{{t('gradoSoddisfazione')}}</h4>
      </div>
      <div formArrayName="dimensioni">
        <div fxLayout="column" fxLayoutAlign="start" class="mt-3"
          *ngFor="let dimensione of dimensioni.controls; let indexDimensioni = index;">
          <div [formGroupName]="indexDimensioni">
            <span>
              <strong>{{t('dimensione')}} - {{t('content.dimensioni.dimensione_' + indexDimensioni +
                '.title')}}</strong>
            </span>
            <div formArrayName="itemsMonitoraggioValutazione">
              <div *ngFor="let item of getFormItemsDimensione(indexDimensioni).controls; let indexItems = index">
                <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="space-between center" [formGroupName]="indexItems">
                  <span fxFlex="30">{{t('content.dimensioni.dimensione_' + indexDimensioni + '.items.item_' +
                    indexItems)}}</span>
                  <mat-form-field appearance="outline" required>
                    <mat-label>{{t('conformita')}}</mat-label>
                    <mat-select formControlName="conformita" required>
                      <mat-option *ngFor="let item of conformita" [value]="item">{{'type.' + item | transloco}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <mat-radio-group fxLayoutGap="2em" formControlName="gradoSoddisfazioneRequisiti" required>
                    <mat-radio-button *ngFor="let value of votiValutazione" [value]="value">{{value}}</mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <table mat-table formArrayName="dimensioni" [dataSource]="dimensioni.value">

        <!-- dimensione -->
        <ng-container matColumnDef="dimensione">
          <th mat-header-cell *matHeaderCellDef>{{t('table.column.dimensione')}}</th>
          <td mat-cell *matCellDef="let element; let i = index;">
            {{t('content.dimensioni.dimensione_' + i + '.title')}}
          </td>
        </ng-container>

        <!-- puntiDimensione -->
        <ng-container matColumnDef="puntiDimensione">
          <th mat-header-cell *matHeaderCellDef>{{t('table.column.dimensione')}}</th>
          <td mat-cell *matCellDef="let element">
            {{element.puntiDimensione}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
      </table>

      <mat-form-field fxFill appearance="outline">
        <mat-label>{{t('note')}}</mat-label>
        <textarea matInput formControlName="noteESuggerimenti"></textarea>
      </mat-form-field>
    </div>
  </form>
</ng-container>
