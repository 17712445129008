import { Domanda, IDomanda } from './Domanda';

export interface IGestioneScrittureProgettuali {
  noteApprovazione: string;

  /**
   * Relazioni
   */
  domande: IDomanda[];
}

export class GestioneScrittureProgettuali implements IGestioneScrittureProgettuali {

  constructor(params: Partial<IGestioneScrittureProgettuali> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params?.domande) {
      this.domande = params.domande.map((value) => new Domanda(value));
    }
  }
  noteApprovazione: string;

  /**
   * Relazioni
   */
  domande: Domanda[] = [];

  static InitContent(): GestioneScrittureProgettuali {
    const content = new GestioneScrittureProgettuali();

    const testiDomande = [
      'Analisi del contesto - Bambini, insegnanti, famiglie, spazi e materiali',
      'Zone esplorabili e campi di esperienza - Tesi iniziali, motivazioni delle scelte, domande generative',
      'Contesti, proposte e linguaggi',
      'Valutazioni, verifica e concetti emergenti',
      'Bibliografia',
    ];

    content.domande = testiDomande.map((value, index) => {
      return new Domanda({
        codiceDomanda: `GestioneScrittureProgettuali_${index}`,
        rispostaDomanda: '',
        testoDomanda: value,
      });
    });

    return content;
  }

  deconstructor(): IGestioneScrittureProgettuali {
    return Object.assign({}, this);
  }
}
