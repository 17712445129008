export class Tree<T> {
  value: T;
  children: Tree<T>[];

  constructor(nodes: T[], idKey: string, parentIdKey: string, root?: T) {
    this.value =
      root ||
      nodes.find(
        (node) => node[parentIdKey] == null || node[parentIdKey] === 0,
      );
    this.children = nodes
      .filter((node) => node[parentIdKey] === this.value[idKey])
      .map((child) => new Tree<T>(nodes, idKey, parentIdKey, child));
  }

  sort(key: string, order: 'asc' | 'desc' = 'asc'): Tree<T> {
    const sortedTree: Tree<T> = this;

    sortedTree.children
      .sort((a, b) =>
        order === 'asc'
          ? a.value[key] - b.value[key]
          : b.value[key] - a.value[key],
      )
      .forEach((child) => child.sort(key, order));

    return sortedTree;
  }

  find(key: string, value: any): Tree<T> {
    const findedTree: Tree<T> = this.children.find((child) =>
      child.value[key] === value ? child : child.find(key, value),
    );

    if (findedTree && findedTree.value[key] !== value) {
      return findedTree.find(key, value);
    }

    return findedTree;
  }

  remove(key: string, value: any, root: Tree<T> = this): Tree<T> {
    const object = this.children.find((child) => child.value[key] === value);
    const childIndex = this.children.indexOf(object);

    if (childIndex !== -1) {
      this.children.splice(childIndex, 1);
    } else {
      this.children.forEach((child) => {
        child.remove(key, value, root);
      });
    }

    return root;
  }
}
