import { Domanda, IDomanda } from './Domanda';

export interface IPensieriRiflessioniPrimoPeriodoServizioEducativo {
  /**
   * Relazioni
   */
  domande: IDomanda[];
}

export class PensieriRiflessioniPrimoPeriodoServizioEducativo
  implements IPensieriRiflessioniPrimoPeriodoServizioEducativo {

  constructor(params: Partial<IPensieriRiflessioniPrimoPeriodoServizioEducativo> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params?.domande) {
      this.domande = params.domande.map((value) => new Domanda(value));
    }
  }
  /**
   * Relazioni
   */
  domande: Domanda[] = [];

  static InitContent(): PensieriRiflessioniPrimoPeriodoServizioEducativo {
    const content = new PensieriRiflessioniPrimoPeriodoServizioEducativo();

    const testiDomande = [
      'Come valutate le prime settimane di esperienza?',
      'Le vostre aspettative iniziali sono state confermate o ci sono stati cambiamenti significativi?',
      'Ci sono dei cambiamenti nel comportamento e nelle abitudini del/la vostro/a bambino/a che avete notato e volete condividere?',
      'Si è modificata la relazione con il/la vostro/a bambino/a in questo periodo?',
      'Altre riflessioni',
    ];

    content.domande = testiDomande.map((value, index) => {
      return new Domanda({
        codiceDomanda: `PensieriRiflessioniPrimoPeriodoServizioEducativo_${index}`,
        rispostaDomanda: '',
        testoDomanda: value,
      });
    });

    return content;
  }

  deconstructor(): IPensieriRiflessioniPrimoPeriodoServizioEducativo {
    return Object.assign({}, this);
  }
}
