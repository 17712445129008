import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';

export enum EnumTipoIndirizzo {
  Residenza = 'Residenza',
  Domicilio = 'Domicilio',
}

export interface IIndirizzo extends IBaseBusinessObject {
  cap: string;
  comune: string;
  numeroCivico: string;
  provincia: string;
  tipo: EnumTipoIndirizzo;
  via: string;
}

export class Indirizzo extends BaseBusinessObject implements IIndirizzo {
  cap: string;
  comune: string;
  numeroCivico: string;
  provincia: string;
  tipo: EnumTipoIndirizzo;
  via: string;

  constructor(params?: Partial<IIndirizzo>) {
    super(params);
  }

  deconstructor(): IIndirizzo {
    return Object.assign({ }, this);
  }
}
