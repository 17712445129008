import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  IStrumentoDiRegistrazioneArchivio,
  ReadManyResponse,
  StrumentoDiRegistrazioneArchivio,
} from '@gpi/lbl/shared/entity';
import { combineLatest, Observable } from 'rxjs';
import { map, switchMap, zipAll } from 'rxjs/operators';

import {
  StrumentiRegistrazioneArchivioState,
  StrumentiRegistrazioneArchivioStore,
} from './strumenti-registrazione-archivio.store';

@Injectable({ providedIn: 'root' })
export class StrumentiRegistrazioneArchivioQuery extends QueryEntity<StrumentiRegistrazioneArchivioState> {
  constructor(protected store: StrumentiRegistrazioneArchivioStore) {
    super(store);
  }

  readAll(): Observable<StrumentoDiRegistrazioneArchivio[]> {
    return this.selectAll().pipe(
      map((entities: IStrumentoDiRegistrazioneArchivio[]) =>
        entities.map((entity) => new StrumentoDiRegistrazioneArchivio(entity)),
      ),
    );
  }

  readById(id: number): Observable<StrumentoDiRegistrazioneArchivio> {
    return this.selectEntity(id).pipe(map((entity) => (entity ? new StrumentoDiRegistrazioneArchivio(entity) : null)));
  }

  readMany(): Observable<ReadManyResponse<StrumentoDiRegistrazioneArchivio>> {
    const data$: Observable<StrumentoDiRegistrazioneArchivio[]> = this.select(
      (state) => <StrumentoDiRegistrazioneArchivio[]> (<unknown> state.entities.data),
    ).pipe(
      switchMap((entities) => {
        const ids = (entities || []).map((entity) => entity.id);

        return this.selectMany(ids);
      }),
      map((entities) => entities.map((entity) => new StrumentoDiRegistrazioneArchivio(entity))),
    );
    const perPage$: Observable<number> = this.select((state) => state.entities.perPage as any);
    const currentPage$: Observable<number> = this.select((state) => state.entities.currentPage as any);
    const lastPage$: Observable<number> = this.select((state) => state.entities.lastPage as any);
    const total$: Observable<number> = this.select((state) => state.entities.total as any);

    const readManyResponse$ = combineLatest([data$, perPage$, currentPage$, lastPage$, total$]).pipe(
      map(([data, perPage, currentPage, lastPage, total]) => {
        return { data, perPage, currentPage, lastPage, total };
      }),
    );

    return readManyResponse$;
  }
}
