import { Component, OnDestroy, Input, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utente, FiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow } from '@gpi/lbl/shared/entity';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lbl-app-feature-sipi-sezione-sintesi-progetto-dialog-linee-guida-sintesi-proggetto',
  templateUrl: 'dialog-linee-guida-sintesi-progetto.component.html',
  styleUrls: ['dialog-linee-guida-sintesi-progetto.component.scss'],
})
export class DialogLineeGuidaSintesiProgettoComponent {

  submit$: Subject<void> = new Subject();

  constructor(
    private matDialogRef: MatDialogRef<DialogLineeGuidaSintesiProgettoComponent>
  ) { }

  cancel() {
    this.matDialogRef.close();
  }
}
