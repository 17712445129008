import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';
import { IAllegato } from '@gpi/lbl/shared/entity';

export interface AllegatiState extends EntityState<IAllegato, number> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lbl-app-data-access-allegati' })
export class AllegatiStore extends EntityStore<AllegatiState> {
  constructor() {
    super();
  }
}
