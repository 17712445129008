<ng-container *transloco="let t; read: 'gestioneMatriceIndicatori.header'">

  <div fxLayout="column" fxLayoutGap="1em">
    <!-- Header -->
    <div class="align-self-start w-100">
      <!-- Title -->
      <h1 class="title">
        <span class="logo" [class]="funzionalitaLblSelezionata?.routePath"></span>
        {{ 'functionality.' + funzionalitaLblSelezionata?.translateKeyPlaceHolder | transloco }}
      </h1>

      <div fxLayout="column" fxLayoutGap="1em">
        <div class="row" style="    justify-content: center;">
          <mat-form-field appearance="outline" style="width: 400px;">
            <mat-label>Anno scolastico</mat-label>
            <mat-select multiple [compareWith]="compareFnAnniScolastici"
              (selectionChange)="selectAnnoScolastico($event.value)" [(value)]="anniScolastivi">
              <mat-option *ngFor="let annoScolastico of anniScolasticoPeriodoFormativiPerStruttura"
                [value]="annoScolastico" [disabled]="isDisable && anniScolastivi[0]?.nome != annoScolastico.nome">
                {{ annoScolastico.dataInizio | translocoDate: 'shortDate' }} -
                {{ annoScolastico.dataFine | translocoDate: 'shortDate' }} -
                {{ 'type.' + annoScolastico.servizioEducativo.tipologiaServizioOfferto | transloco}}
              </mat-option>
            </mat-select>
            <!-- max 2 dello stesso anno -->
          </mat-form-field>
          <div class="btn-field">
            <button mat-button mat-flat-button color="primary" type="submit" (click)="submit()">{{text}}</button>
          </div>
        </div>
      </div>
      <router-outlet *ngIf="true">
      </router-outlet>

      <!-- a livello di struttura -->
      <ng-container *ngIf="!open">
        <ng-container *ngIf="anniScolastivi.length > 1">
          STRUTTURA
        </ng-container>

        <!-- a livello di servizio -->
        <ng-container *ngIf=" anniScolastivi.length === 1">

          <div class="card w-100 mat-elevation-z1 p-3" style="margin-bottom: 15px;">
            <!-- {{t(item.titoloCluster)}} -->
            standard-strutturali-organizzativi

            <button style="margin-left: auto;" (click)="openComponent('standard-strutturali-e-organizzativi')">
              modifica

            </button>

            <!-- <ng-container *ngFor="let sintesi of item.sintesiStrumentoDiRegistrazione">
              <h4>{{ t(sintesi.templateBodyCode) }}</h4>
              <div fxLayout="row" class="container-card">

                <ng-container *ngIf="sintesi.sintesiPerSezione">
                  <ng-container *ngFor="let sezione of sintesi.sintesiPerSezione; let i = index">

                    <div fxLayout="column" class="mat-elevation-z1 section"
                      style="margin-right: 10px; margin-bottom: 10px;">
                      <div fxLayout="row" style="margin-bottom: 20px;">
                        <span> <strong>{{sezione.sezioneNome}} </strong></span>
                        <button [disabled]="sezione.strumentiDiRegistrazioneArchivio.length < 1"
                          (click)="redirectTOArchivio(sezione.sezioneId, sintesi.templateBodyCode,i, sezione.strumentiDiRegistrazioneArchivio)"
                          style="margin-left: auto;">
                          {{t('dettaglio')}}
                        </button>

                      </div>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container> -->


          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>
