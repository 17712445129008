import { Allegato, IAllegato } from '../Allegato';

export interface IRicadutaAttivitaFormativaRow {
  attivitaDaValutare: string;
  inizioAnno: boolean;
  fineAnno: boolean;
  allegatiCompilati: IAllegato;
}

export interface IRicadutaAttivitaFormativa {
  dataValutazione: Date;
  percorsoFormativo: string;
  osservazioniList: IRicadutaAttivitaFormativaRow[];
}

export class RicadutaAttivitaFormativaRow implements IRicadutaAttivitaFormativaRow {
  attivitaDaValutare: string;
  inizioAnno: boolean;
  fineAnno: boolean;
  allegatiCompilati: Allegato;

  constructor(params?: Partial<IRicadutaAttivitaFormativaRow>) {
    if (!params) {
      return;
    }

    Object.assign(this, params);
  }

  deconstructor(): IRicadutaAttivitaFormativaRow {
    return Object.assign({}, this);
  }
}

export class RicadutaAttivitaFormativa implements IRicadutaAttivitaFormativa {
  dataValutazione: Date;
  percorsoFormativo: string;
  osservazioniList: RicadutaAttivitaFormativaRow[];

  constructor(params: Partial<IRicadutaAttivitaFormativa> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.dataValutazione) {
      this.dataValutazione = new Date(params.dataValutazione);
    }

    if (params.osservazioniList) {
      this.osservazioniList = params.osservazioniList.map((value) => new RicadutaAttivitaFormativaRow(value));
    }
  }

  static InitContent(): RicadutaAttivitaFormativa {
    const content = new RicadutaAttivitaFormativa();

    const attivitaDaValutareList = [
      'È stata eseguita una progettazione coerente con la formazione',
      'Emergono, nelle scritture progettuali, progetti inerenti al tema di formazione',
      'Sono state generate documentazioni coerenti con la formazione effettuata',
      'Sono stati generati allestimenti e/o spazi coerenti con la formazione effettuata',
      'Sono stati predisposti materiali coerenti con la formazione effettuata',
      'Sono stati realizzate attività che fanno riferimento a più ambiti di sviluppo al nido o a campi esperienziali nelle scuole',
      'Il partecipante ha approfondito gli argomenti in autoformazione',
      'Sono state realizzate attività o progetti inerenti alla formazione che hanno visto la partecipazione e/o la condivisione delle famiglie',
      'La formazione ha generato progetti che sono stati inseriti o presentati in occasione \rdi pubblicazione o presenza in occasione di almeno un evento',
    ];

    content.osservazioniList = attivitaDaValutareList.map((value) => {
      return new RicadutaAttivitaFormativaRow({
        attivitaDaValutare: value,
      });
    });

    return content;
  }

  deconstructor(): IRicadutaAttivitaFormativa {
    return Object.assign({}, this);
  }
}
