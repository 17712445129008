import { AutovalutazioneQualitaSintesiStrutturaRow, DescrittoreAutovalutazioneQualitaSintesiStrutturaRow, IAutovalutazioneQualitaSintesiStrutturaRow } from './AutovalutazioneQualitaSintesiStrutturaRow';

export interface IAutovalutazioneQualitaSintesiStrutturaNidoInfanzia {
  spaziAccessbilita: IAutovalutazioneQualitaSintesiStrutturaRow[];
  leggibilitaRiconoscibilita: IAutovalutazioneQualitaSintesiStrutturaRow[];
  differenziazioneFunzionale: IAutovalutazioneQualitaSintesiStrutturaRow[];
  spaziPersonalizzazione: IAutovalutazioneQualitaSintesiStrutturaRow[];
  tempiPrevedibilitaRiconoscibilita: IAutovalutazioneQualitaSintesiStrutturaRow[];
  tempiPersonalizzazione: IAutovalutazioneQualitaSintesiStrutturaRow[];
  tempiContinuitaCambiamenti: IAutovalutazioneQualitaSintesiStrutturaRow[];
  relazioniBenessere: IAutovalutazioneQualitaSintesiStrutturaRow[];
  relazioniPersonalizzazione: IAutovalutazioneQualitaSintesiStrutturaRow[];
  relazioniSocialitaProcessi: IAutovalutazioneQualitaSintesiStrutturaRow[];
  proposteEducativeIntenzionalita: IAutovalutazioneQualitaSintesiStrutturaRow[];
  proposteEducativeSignificativitacontinuita: IAutovalutazioneQualitaSintesiStrutturaRow[];
  proposteEducativeVarietaCoerenza: IAutovalutazioneQualitaSintesiStrutturaRow[];
  relazioniPartecipazioneFamiglie: IAutovalutazioneQualitaSintesiStrutturaRow[];
  culturaGenitorialita: IAutovalutazioneQualitaSintesiStrutturaRow[];
  rapportoTerritorioAzioniRaccordo: IAutovalutazioneQualitaSintesiStrutturaRow[];
  rapportoTerritorioCulturaComunitaEducante: IAutovalutazioneQualitaSintesiStrutturaRow[];
  coordinamentoGruppoLavoroCollegialita: IAutovalutazioneQualitaSintesiStrutturaRow[];
  coordinamentoGruppoLavoroFormazioneContestualizzata: IAutovalutazioneQualitaSintesiStrutturaRow[];
  coordinamentoGruppoLavoroCoordinamentoPedagogico: IAutovalutazioneQualitaSintesiStrutturaRow[];
  coordinamentoGruppoLavoroProcessiRiflessione: IAutovalutazioneQualitaSintesiStrutturaRow[];
  progettazioneIntenzionalitaCoerenza: IAutovalutazioneQualitaSintesiStrutturaRow[];
  progettazioneContestualizzazioneFlessibilita: IAutovalutazioneQualitaSintesiStrutturaRow[];
  documentazioneSistematicitaCoerenza: IAutovalutazioneQualitaSintesiStrutturaRow[];
  documentazioneLeggibilita: IAutovalutazioneQualitaSintesiStrutturaRow[];
  sistematicita: IAutovalutazioneQualitaSintesiStrutturaRow[];
  funzioneFormativa: IAutovalutazioneQualitaSintesiStrutturaRow[];
}

export class AutovalutazioneQualitaSintesiStrutturaNidoInfanzia implements IAutovalutazioneQualitaSintesiStrutturaNidoInfanzia {
  spaziAccessbilita: AutovalutazioneQualitaSintesiStrutturaRow[];
  leggibilitaRiconoscibilita: AutovalutazioneQualitaSintesiStrutturaRow[];
  differenziazioneFunzionale: AutovalutazioneQualitaSintesiStrutturaRow[];
  spaziPersonalizzazione: AutovalutazioneQualitaSintesiStrutturaRow[];
  tempiPrevedibilitaRiconoscibilita: AutovalutazioneQualitaSintesiStrutturaRow[];
  tempiPersonalizzazione: AutovalutazioneQualitaSintesiStrutturaRow[];
  tempiContinuitaCambiamenti: AutovalutazioneQualitaSintesiStrutturaRow[];
  relazioniBenessere: AutovalutazioneQualitaSintesiStrutturaRow[];
  relazioniPersonalizzazione: AutovalutazioneQualitaSintesiStrutturaRow[];
  relazioniSocialitaProcessi: AutovalutazioneQualitaSintesiStrutturaRow[];
  proposteEducativeIntenzionalita: AutovalutazioneQualitaSintesiStrutturaRow[];
  proposteEducativeSignificativitacontinuita: AutovalutazioneQualitaSintesiStrutturaRow[];
  proposteEducativeVarietaCoerenza: AutovalutazioneQualitaSintesiStrutturaRow[];
  relazioniPartecipazioneFamiglie: AutovalutazioneQualitaSintesiStrutturaRow[];
  culturaGenitorialita: AutovalutazioneQualitaSintesiStrutturaRow[];
  rapportoTerritorioAzioniRaccordo: AutovalutazioneQualitaSintesiStrutturaRow[];
  rapportoTerritorioCulturaComunitaEducante: AutovalutazioneQualitaSintesiStrutturaRow[];
  coordinamentoGruppoLavoroCollegialita: AutovalutazioneQualitaSintesiStrutturaRow[];
  coordinamentoGruppoLavoroFormazioneContestualizzata: AutovalutazioneQualitaSintesiStrutturaRow[];
  coordinamentoGruppoLavoroCoordinamentoPedagogico: AutovalutazioneQualitaSintesiStrutturaRow[];
  coordinamentoGruppoLavoroProcessiRiflessione: AutovalutazioneQualitaSintesiStrutturaRow[];
  progettazioneIntenzionalitaCoerenza: AutovalutazioneQualitaSintesiStrutturaRow[];
  progettazioneContestualizzazioneFlessibilita: AutovalutazioneQualitaSintesiStrutturaRow[];
  documentazioneSistematicitaCoerenza: AutovalutazioneQualitaSintesiStrutturaRow[];
  documentazioneLeggibilita: AutovalutazioneQualitaSintesiStrutturaRow[];
  sistematicita: AutovalutazioneQualitaSintesiStrutturaRow[];
  funzioneFormativa: AutovalutazioneQualitaSintesiStrutturaRow[];

  constructor(params: Partial<IAutovalutazioneQualitaSintesiStrutturaNidoInfanzia> = {}) {
    Object.assign(this, params);

    if (params.spaziAccessbilita) {
      this.spaziAccessbilita = params.spaziAccessbilita.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.leggibilitaRiconoscibilita) {
      this.leggibilitaRiconoscibilita = params.leggibilitaRiconoscibilita.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.differenziazioneFunzionale) {
      this.differenziazioneFunzionale = params.differenziazioneFunzionale.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.spaziPersonalizzazione) {
      this.spaziPersonalizzazione = params.spaziPersonalizzazione.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.tempiPrevedibilitaRiconoscibilita) {
      this.tempiPrevedibilitaRiconoscibilita = params.tempiPrevedibilitaRiconoscibilita.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.tempiPersonalizzazione) {
      this.tempiPersonalizzazione = params.tempiPersonalizzazione.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.tempiContinuitaCambiamenti) {
      this.tempiContinuitaCambiamenti = params.tempiContinuitaCambiamenti.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.relazioniBenessere) {
      this.relazioniBenessere = params.relazioniBenessere.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.relazioniPersonalizzazione) {
      this.relazioniPersonalizzazione = params.relazioniPersonalizzazione.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.relazioniSocialitaProcessi) {
      this.relazioniSocialitaProcessi = params.relazioniSocialitaProcessi.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.proposteEducativeIntenzionalita) {
      this.proposteEducativeIntenzionalita = params.proposteEducativeIntenzionalita.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.proposteEducativeSignificativitacontinuita) {
      this.proposteEducativeSignificativitacontinuita = params.proposteEducativeSignificativitacontinuita.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.proposteEducativeVarietaCoerenza) {
      this.proposteEducativeVarietaCoerenza = params.proposteEducativeVarietaCoerenza.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.relazioniPartecipazioneFamiglie) {
      this.relazioniPartecipazioneFamiglie = params.relazioniPartecipazioneFamiglie.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.culturaGenitorialita) {
      this.culturaGenitorialita = params.culturaGenitorialita.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.rapportoTerritorioAzioniRaccordo) {
      this.rapportoTerritorioAzioniRaccordo = params.rapportoTerritorioAzioniRaccordo.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.rapportoTerritorioCulturaComunitaEducante) {
      this.rapportoTerritorioCulturaComunitaEducante = params.rapportoTerritorioCulturaComunitaEducante.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.coordinamentoGruppoLavoroCollegialita) {
      this.coordinamentoGruppoLavoroCollegialita = params.coordinamentoGruppoLavoroCollegialita.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.coordinamentoGruppoLavoroProcessiRiflessione) {
      this.coordinamentoGruppoLavoroProcessiRiflessione = params.coordinamentoGruppoLavoroProcessiRiflessione.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.progettazioneIntenzionalitaCoerenza) {
      this.progettazioneIntenzionalitaCoerenza = params.progettazioneIntenzionalitaCoerenza.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.progettazioneContestualizzazioneFlessibilita) {
      this.progettazioneContestualizzazioneFlessibilita = params.progettazioneContestualizzazioneFlessibilita.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.documentazioneSistematicitaCoerenza) {
      this.documentazioneSistematicitaCoerenza = params.documentazioneSistematicitaCoerenza.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.documentazioneLeggibilita) {
      this.documentazioneLeggibilita = params.documentazioneLeggibilita.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.sistematicita) {
      this.sistematicita = params.sistematicita.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
    if (params.funzioneFormativa) {
      this.funzioneFormativa = params.funzioneFormativa.map((value) => new AutovalutazioneQualitaSintesiStrutturaRow(value));
    }
  }

  static InitContent() {
    const content = new AutovalutazioneQualitaSintesiStrutturaNidoInfanzia();
    const descrittoriSpaziAccessibilita: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoreSpaziAccessibilita_0', descrittore: 'Tutti i bambini, soprattutto quelli con diritti speciali, possono accedere senza difficolta ai vari spazi' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoreSpaziAccessibilita_1', descrittore: 'E\' garantito il diritto di accesso autonomo dei bambini ai materiali' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoreSpaziAccessibilita_2', descrittore: 'Lo spazio delle sezioni è organizzato in modo coerente alle premesse del progetto e all\' età dei bambini, rispetto alle strutture e alle tipologie dei materiali' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow
    ];
    const descrittoriSpaziLeggibilitaRiconoscibilita: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoreSpaziLeggibilitaRiconoscibilita_0', descrittore: 'Gli spazi sono definiti chiaramente per prevalenza d\'uso  (ad es. negli angoli sono presenti materiali pertinenti con l\' attività prevista)' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoreSpaziLeggibilitaRiconoscibilita_1', descrittore: 'L\' organizzazione degli spazi deve essere in dialogo/relazione  con le proposte educative in evoluzione' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoreSpaziLeggibilitaRiconoscibilita_2', descrittore: 'La documentazione descrive e accompagna l\' esplorazione e l\' utilizzo dello spazio da parte di bambini e adulti' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow
    ];
    const descrittoriSpaziDifferenziazioneFunzionale: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriSpaziDifferenziazioneFunzionale_0', descrittore: 'Sia nella sezione che negli spazi comuni è presente una pluralità di zone ben definite che sostengono lo sviluppo delle diverse competenze (zona per il gioco di movimento, zona per il gioco del “far finta”, ecc..)' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriSpaziDifferenziazioneFunzionale_1', descrittore: 'Lo spazio esterno è differenziato in zone che danno opportunità di gioco e apprendimenti differenti.' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriSpaziDifferenziazioneFunzionale_2', descrittore: 'In sezione è presente una varietà di materiali formali e informali (es. materiali di recupero e naturali) la cui qualità e quantità è in relazione al progetto di utilizzo dei vari spazi' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriSpaziPersonalizzazione: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriSpaziPersonalizzazione_0', descrittore: 'Sono presenti spazi e oggetti personali del bambino' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriSpaziPersonalizzazione_1', descrittore: 'Sono presenti spazi che facilitano l’aggregazione di piccoli gruppi di bambini' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriSpaziPersonalizzazione_2', descrittore: 'Durante l’anno si modificano spazi e materiali in funzione dei progressi e degli interessi dei bambini e dell\'evoluzione dei progetti' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriSpaziPersonalizzazione_3', descrittore: 'Lo spazio consente la ricerca individuale' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriTempiPrevedibilitaRiconoscibilita: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriTempiPrevedibilitaRiconoscibilita_0', descrittore: 'La scansione temporale della giornata educativa presenta una struttura ricorsiva' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriTempiPrevedibilitaRiconoscibilita_1', descrittore: 'Durante la giornata sono presenti rituali che aiutano il bambino a orientarsi nel tempo quotidiano e dove si condivide quello che si farà o che si è fatto?' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow
    ];
    const descrittoriTempiPersonalizzazione: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriTempiPersonalizzazione_0', descrittore: 'I tempi di svolgimento delle diverse situazioni quotidiane e i tempi di cura sono tali da favorire la qualità delle relazioni tra bambini e tra adulto e bambino' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriTempiPersonalizzazione_1', descrittore: 'I tempi individuali e di esplorazione del bambino sono rispettati anche in relazione alle esigenze organizzative del servizio' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriTempiPersonalizzazione_2', descrittore: 'Viene dedicato un tempo personalizzato alla coppia genitore/bambino' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriTempiContinuitaCambiamenti: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriTempiContinuitaCambiamenti_0', descrittore: 'Durante le transizioni l’educatrice comunica e spiega quello che si sta per fare' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriTempiContinuitaCambiamenti_1', descrittore: 'Sono previsti momenti di riordino dei materiali al termine delle situazioni di gioco e attività' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriRelazioniBenessere: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriRelazioniBenessere_0', descrittore: 'Lo stile di comunicazione degli adulti è orientato a creare un clima di ascolto ed empatia' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRelazioniBenessere_1', descrittore: 'Si presta attenzione alla cura e all’igiene personale dei bambini' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRelazioniBenessere_2', descrittore: 'L’adulto sostiene e gestisce i bambini nei processi di autonomia e gestione dei conflitti' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRelazioniBenessere_3', descrittore: 'Lo stile di relazione tra gli adulti è orientato alla collaborazione e condivisione nel dialogo tra i diversi punti di vista' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriRelazioniPersonalizzazione: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriRelazioniPersonalizzazione_0', descrittore: 'Sono previsti tempi graduali e soggettivi per favorire l’ambientamento dei bambini al nido' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRelazioniPersonalizzazione_1', descrittore: 'L’educatore, in coerenza con il progetto educativo, è attento al comportamento di ogni singolo bambino e alla costruzione di un’identità di gruppo/sezione' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRelazioniPersonalizzazione_2', descrittore: 'L’educatore legittima il gruppo dei bambini come risorsa fondamentale per l’ambientamento' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriRelazioniSocialitaProcessi: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriRelazioniSocialitaProcessi_0', descrittore: 'Vengono privilegiati momenti di piccolo gruppo anche auto-organizzati' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRelazioniSocialitaProcessi_1', descrittore: 'Nei diversi momenti della giornata l’adulto coglie, e rilancia al gruppo gli spunti e le idee dei bambini' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriProposteEducativeIntenzionalita: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriProposteEducativeIntenzionalita_0', descrittore: 'Le proposte educative, compresi i momenti di cura, si riferiscono ad una progettazione disposta in forma scritta dal gruppo di lavoro insieme al coordinatore pedagogico' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriProposteEducativeIntenzionalita_1', descrittore: 'Gli educatori sanno motivare le ragioni delle scelte delle proposte educative anche attraverso l\'utilizzo di strumenti progettuali che le sostengano' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriProposteEducativeIntenzionalita_2', descrittore: 'In riferimento alle diverse attività, l’educatore interviene in modo ragionato per sostenere i processi di apprendimento dei bambini' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriProposteEducativeSignificativitacontinuita: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriProposteEducativeSignificativitacontinuita_0', descrittore: 'Nell’evoluzione del progetto educativo, in ogni giornata, sono garantiti contesti di esperienza organizzati' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriProposteEducativeSignificativitacontinuita_1', descrittore: 'Il gruppo adotta strumenti di osservazione per cogliere i contributi dei bambini e sostenere lo sviluppo dei loro apprendimenti' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriProposteEducativeSignificativitacontinuita_2', descrittore: 'Sono pianificati percorsi progettuali che prevedono l’articolazione e l’arricchimento progressivo delle attività' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriProposteEducativeVarietaCoerenza: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriProposteEducativeVarietaCoerenza_0', descrittore: 'Sono presenti una pluralità di proposte educative, anche all’aperto, riferibili alle diverse aree di sviluppo del bambino' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriProposteEducativeVarietaCoerenza_1', descrittore: 'Nella giornata i bambini hanno la possibilità di scegliere tra differenti possibilità di gioco' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriRelazioniPartecipazioneFamiglie: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriRelazioniPartecipazioneFamiglie_0', descrittore: 'I genitori vengono informati e coinvolti sui contenuti del progetto pedagogico' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRelazioniPartecipazioneFamiglie_1', descrittore: 'Il progetto di ambientamento del bambino è condiviso con i genitori (ad es. le modalità e i tempi sono negoziati con i genitori, sono esplicitate le regole e i ruoli, ecc…)' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRelazioniPartecipazioneFamiglie_2', descrittore: 'I momenti di partecipazione sono organizzati secondo spazi, tempi e modalità che ne favoriscono l’accesso' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRelazioniPartecipazioneFamiglie_3', descrittore: 'Esistono forme di monitoraggio della partecipazione' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriCulturaGenitorialita: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriCulturaGenitorialita_0', descrittore: 'Sono previsti periodicamente momenti con le famiglie dedicati all’ascolto, allo scambio e al confronto sulle tematiche educative anche con interlocutori esterni' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,

    ];
    const descrittoriRapportoTerritorioAzioniRaccordo: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriRapportoterritorioAzioniRaccordo_0', descrittore: 'Il servizio facilita la circolazione delle informazioni ai genitori sulla rete dei servizi presenti sul territorio' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRapportoTerritorioAzioniRaccordo_1', descrittore: 'Il gruppo di lavoro si confronta sul tema della continuità del progetto educativo con le famiglie e con le altre istituzioni educative' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRapportoTerritorioAzioniRaccordo_2', descrittore: 'Viene curato il rapporto sistematico con i servizi sanitari di base, con particolare attenzione al tema della disabilità' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriRapportoTerritorioCulturaComunitaEducante: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriRapportoTerritorioCulturaComunitaEducante_0', descrittore: 'Il servizio facilita la circolazione delle informazioni ai genitori sulla rete dei servizi presenti sul territorio' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriRapportoTerritorioCulturaComunitaEducante_1', descrittore: 'Il gruppo di lavoro si confronta sul tema della continuità del progetto educativo con le famiglie e con le altre istituzioni educative' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriCoordinamentoGruppoLavoroCollegialita: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoreCoordinamentoGruppoLavoroCollegialita_0', descrittore: 'Il ruolo e la funzione di ogni figura che opera all’interno del servizio sono chiaramente definiti e recepiti all’interno del gruppo di lavoro, in un’ottica di interdipendenza e corresponsabilità rispetto al progetto educativo' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoreCoordinamentoGruppoLavoroCollegialita_1', descrittore: 'Gli incontri del gruppo di lavoro sono caratterizzati da un clima costruttivo, dialogico e partecipato' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoreCoordinamentoGruppoLavoroCollegialita_2', descrittore: 'Tutti gli incontri sono organizzati secondo un ordine del giorno e regolarmente verbalizzati' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriCoordinamentoGruppoLavoroFormazioneContestualizzata: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriCoordinamentoGruppoLavoroFormazioneContestualizzata_0', descrittore: 'Sono previste annualmente occasioni di formazione in servizio' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriCoordinamentoGruppoLavoroFormazioneContestualizzata_1', descrittore: 'I percorsi di formazione sono pianificati in modo coerente al progetto educativo del servizio' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriCoordinamentoGruppoLavoroFormazioneContestualizzata_2', descrittore: 'Sono previsti anche progetti formativi specifici per i diversi profili professionali' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriCoordinamentoGruppoLavoroCoordinamentoPedagogico: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriCoordinamentoGruppoLavoroCoordinamentoPedagogico_0', descrittore: 'Sono programmati regolarmente incontri con il coordinatore pedagogico nei servizi e nelle sezioni' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriCoordinamentoGruppoLavoroCoordinamentoPedagogico_1', descrittore: 'Il coordinatore pedagogico sostiene il gruppo di lavoro anche attraverso la presenza e l’osservazione nei servizi' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriCoordinamentoGruppoLavoroCoordinamentoPedagogico_2', descrittore: 'Sono previste annualmente occasioni di formazione in servizio' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriCoordinamentoGruppoLavoroCoordinamentoPedagogico_3', descrittore: 'Il coordinatore pedagogico sostiene sistematicamente il progetto partecipativo e la collegialità del gruppo di lavoro' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriCoordinamentoGruppoLavoroProcessiRiflessione: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriCoordinamentoGruppoLavoroProcessiRiflessione_0', descrittore: 'Il gruppo di lavoro si incontra regolarmente per analizzare e riflettere sia sui processi e le pratiche educativi, sia sugli aspetti organizzativi' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriCoordinamentoGruppoLavoroProcessiRiflessione_1', descrittore: 'Il confronto tra adulti sulle pratiche è una prassi consolidata che può realizzarsi anche nella quotidianità' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriProgettazioneIntenzionalitaCoerenza: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriProgettazioneIntenzionalitaCoerenza_0', descrittore: 'Il progetto didattico è conosciuto, condiviso e periodicamente rivisto all’interno del gruppo di lavoro' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriProgettazioneIntenzionalitaCoerenza_1', descrittore: 'Nell\'ambito delle varie proposte educative, le strategie dei diversi educatori sono coerenti tra di loro' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriProgettazioneIntenzionalitaCoerenza_2', descrittore: 'I criteri e gli strumenti per la progettazione educativa di sezione sono condivisi all’interno del gruppo di lavoro' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriProgettazioneContestualizzazioneFlessibilita: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriProgettazioneContestualizzazioneFlessibilita_0', descrittore: 'Vengono elaborati specifici progetti di sezione in base al gruppo' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriProgettazioneContestualizzazioneFlessibilita_1', descrittore: 'Modalità e strumenti di osservazione sistematica, e conseguente utilizzo, sono concordati all’interno del gruppo di lavoro' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriDocumentazioneSistematicitaCoerenza: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriDocumentazioneSistematicitaCoerenza_0', descrittore: 'Si documentano regolarmente le esperienze realizzate' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriDocumentazioneSistematicitaCoerenza_1', descrittore: 'È presente un archivio organizzato di materiali documentativi di produzione interna ed esterna al servizio' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriDocumentazioneSistematicitaCoerenza_2', descrittore: 'Criteri e modalità di documentazione sono condivisi all’interno del gruppo di lavoro' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriDocumentazioneSistematicitaCoerenza_3', descrittore: 'La documentazione è utilizzata come strumento per riflettere e per rilanciare i percorsi educativi' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriDocumentazioneLeggibilita: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriDocumentazioneLeggibilita_0', descrittore: 'La documentazione è pensata e calibrata in funzione dei suoi diversi destinatari' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriDocumentazioneLeggibilita_1', descrittore: 'Nel servizio e in sezione sono presenti materiali di documentazione che i bambini possono riconoscere' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriDocumentazioneLeggibilita_2', descrittore: 'La documentazione delle esperienze realizzate diventa materiale per momenti pubblici di scambio e comunicazione' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriSistematicita: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoreSistematicita_0', descrittore: 'Sono definiti e periodicamente utilizzati strumenti e procedure di valutazione della qualità educativa del servizio' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoreSistematicita_1', descrittore: 'È prevista regolarmente la valutazione della qualità percepita da parte delle famiglie utenti del servizio' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];
    const descrittoriFunzioneFormativa: DescrittoreAutovalutazioneQualitaSintesiStrutturaRow[] = [
      { id: 'descrittoriFunzioneFormativa_0', descrittore: 'Si documentano i percorsi e le azioni di valutazione' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
      { id: 'descrittoriFunzioneFormativa_1', descrittore: 'Gli esiti della valutazione sono occasione per progettare interventi di miglioramento e percorsi formativi' } as DescrittoreAutovalutazioneQualitaSintesiStrutturaRow,
    ];

    content.spaziAccessbilita = descrittoriSpaziAccessibilita.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.leggibilitaRiconoscibilita = descrittoriSpaziLeggibilitaRiconoscibilita.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.differenziazioneFunzionale = descrittoriSpaziDifferenziazioneFunzionale.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.spaziPersonalizzazione = descrittoriSpaziPersonalizzazione.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.tempiPrevedibilitaRiconoscibilita = descrittoriTempiPrevedibilitaRiconoscibilita.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.tempiPersonalizzazione = descrittoriTempiPersonalizzazione.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.tempiContinuitaCambiamenti = descrittoriTempiContinuitaCambiamenti.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.relazioniBenessere = descrittoriRelazioniBenessere.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.relazioniPersonalizzazione = descrittoriRelazioniPersonalizzazione.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.relazioniSocialitaProcessi = descrittoriRelazioniSocialitaProcessi.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.proposteEducativeIntenzionalita = descrittoriProposteEducativeIntenzionalita.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.proposteEducativeSignificativitacontinuita = descrittoriProposteEducativeSignificativitacontinuita.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.proposteEducativeVarietaCoerenza = descrittoriProposteEducativeVarietaCoerenza.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.relazioniPartecipazioneFamiglie = descrittoriRelazioniPartecipazioneFamiglie.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.culturaGenitorialita = descrittoriCulturaGenitorialita.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.rapportoTerritorioAzioniRaccordo = descrittoriRapportoTerritorioAzioniRaccordo.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.rapportoTerritorioCulturaComunitaEducante = descrittoriRapportoTerritorioCulturaComunitaEducante.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.coordinamentoGruppoLavoroCollegialita = descrittoriCoordinamentoGruppoLavoroCollegialita.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.coordinamentoGruppoLavoroFormazioneContestualizzata = descrittoriCoordinamentoGruppoLavoroFormazioneContestualizzata.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.coordinamentoGruppoLavoroCoordinamentoPedagogico = descrittoriCoordinamentoGruppoLavoroCoordinamentoPedagogico.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.coordinamentoGruppoLavoroProcessiRiflessione = descrittoriCoordinamentoGruppoLavoroProcessiRiflessione.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.progettazioneIntenzionalitaCoerenza = descrittoriProgettazioneIntenzionalitaCoerenza.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.progettazioneContestualizzazioneFlessibilita = descrittoriProgettazioneContestualizzazioneFlessibilita.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.documentazioneSistematicitaCoerenza = descrittoriDocumentazioneSistematicitaCoerenza.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.documentazioneLeggibilita = descrittoriDocumentazioneLeggibilita.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.sistematicita = descrittoriSistematicita.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });
    content.funzioneFormativa = descrittoriFunzioneFormativa.map((value) => {
      return new AutovalutazioneQualitaSintesiStrutturaRow({
        descrittore: value,
        osservazioniEmpiriche: '',
        puntiDiForza: '',
        azioniDiMiglioramento: '',
        approfindimentiProcedure: '',
      });
    });


    return content;
  }
  deconstructor(): IAutovalutazioneQualitaSintesiStrutturaNidoInfanzia {
    return Object.assign({}, this);
  }
}
