import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';

export enum EnumTipologiaAllegato {
  CertificatoMedicoBambino = 'CertificatoMedicoBambino',
  MenuAslBambino = 'MenuAslBambino',
  DelegaTutoreLegaleBambino = 'DelegaTutoreLegaleBambino',
  TrattamentoDatiSensibili = 'TrattamentoDatiSensibili',
  ImmagineUtente = 'ImmagineUtente',
  ImmaginePersonaFisica = 'ImmaginePersonaFisica',
  ImmagineStrutturaEducativa = 'ImmagineStrutturaEducativa',
  ContenutoChiosco = 'AllegatoContenutoChiosco',
  ImmagineContenutoChiosco = 'ImmaginePreviewContenutoChiosco',
  GestioniPortfolioCompetenzeBambini = 'GestioniPortfolioCompetenzeBambini',
  ValutazioneRicadutaFormativa = 'ValutazioneRicadutaFormativa',
  TutorialModuloLbl = 'TutorialModuloLbl',
  DocumentoHelpdesk = 'DocumentoHelpdesk'
}

export enum EnumAllegatiTabellaDiProvenienza {
  Utenti = 't_utenti',
  PersoneFisiche = 't_persone_fisiche',
  StruttureEducative = 't_strutture_educative',
  ContenutiChioscoArchivio = 't_contenuti_chiosco_archivio',
  Segnalazioni = 't_segnalazioni',
  StrumentiRegistrazioneArchivio = 't_strumenti_di_registrazione_archivio',
  ModuliLBLAttivatiPerStruttura = 't_moduli_lbl_attivati_per_struttura'
}

export interface IAllegato extends IBaseBusinessObject {
  extension: string;
  link: string;
  mimeType: string;
  originalName: string;
  pathOnServer: string;
  tabellaDiProvenienza: EnumAllegatiTabellaDiProvenienza;
  tableRowId: string;
  tipologia: EnumTipologiaAllegato;

  titolo: string;
  descrizione: string;
}

export class Allegato extends BaseBusinessObject implements IAllegato {
  extension: string;
  link: string;
  mimeType: string;
  originalName: string;
  pathOnServer: string;
  tabellaDiProvenienza: EnumAllegatiTabellaDiProvenienza;
  tableRowId: string;
  tipologia: EnumTipologiaAllegato;
  
  metadata: any;
  titolo: string;
  descrizione: string;

  constructor(params?: Partial<IAllegato>) {
    super(params);
  }

  deconstructor(): IAllegato {
    return Object.assign({ }, this);
  }

  get backgroundImageUrl(): string {
    return `url(${this.link})`;
  }
}
