<ng-container
  *transloco="let t; read: 'rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterne.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">

    <div formGroupName="AreeEsterne">
      <ng-container *ngFor="let areaEsterna of areeEsterne.controls; let indexArea = index">

        <div class="card w-100 mat-elevation-z1 p-3 mb-3" [formGroupName]="indexArea">

          <div class="card-body">
            <div class="d-flex">
              <h2>{{t('Area')}} {{indexArea+1}}</h2>

              <button mat-icon-button color="primary" style="margin-top: 9px" (click)="removeAreaEsterna(indexArea)">
                <mat-icon svgIcon="delete"></mat-icon>
              </button>
            </div>

            <p>{{t('totAllestimentiRicerche')}} {{form.get(['AreeEsterne', indexArea, 'totAllestimentiRicerche']).value
              || 0}}</p>
            <p>{{t('totMaterialiSupporto')}} {{form.get(['AreeEsterne', indexArea, 'totMaterialiSupporto']).value || 0}}
            </p>

            <button mat-stroked-button
              (click)="addrow(indexArea,'rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList')">+</button>
            <div class="d-flex" style="margin-bottom: 23px;">

              <div class="container" formArrayName="rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList">
                <div
                  *ngFor="let item of getDocForm(indexArea, 'rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList').controls; let i = index"
                  [formGroupName]="i">
                  <div fxLayout="row" fxLayoutAlign="space-between center">
                    <div>
                      <p>{{t('table.column.centroInteresse')}}</p>
                      <mat-form-field>
                        <mat-label>{{t('table.selectCentroInteresse')}}</mat-label>
                        <mat-select formControlName="centroInteresse"s>
                          <mat-option *ngFor="let centro of centriInteresseList" [value]="centro">
                            {{'type.' + centro | transloco}}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>

                    <div>
                      <p>{{t('table.column.approfondimenti')}}</p>
                      <mat-form-field appearance="outline" class="format-data">
                        <input type="number"
                          (focusout)="calcoloTot(indexArea,'rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList')"
                          matInput formControlName="numApprofondimenti">
                      </mat-form-field>
                      <mat-form-field>
                        <textarea matInput formControlName="approfondimenti"> </textarea>
                      </mat-form-field>
                    </div>

                    <div>
                      <p>{{t('table.column.materiali')}}</p>
                      <mat-form-field appearance="outline" class="format-data">
                        <input type="number"
                          (focusout)="calcoloTot(indexArea,'rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList')"
                          matInput formControlName="numMateriali">
                      </mat-form-field>
                      <mat-form-field>
                        <textarea matInput formControlName="materiali"> </textarea>
                      </mat-form-field>
                    </div>

                    <div>
                      <button mat-icon-button color="primary" style="margin-top: 9px"
                        (click)="removeRow(indexArea,'rilevazioneAllestimentoSpaziRicercheMaterialiAreeEsterneList',i)"
                        matTooltip="{{t('table.delete')}}">
                        <mat-icon svgIcon="delete"></mat-icon>
                      </button>
                    </div>

                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>

      </ng-container>
      <button mat-stroked-button (click)="addAreaEsterna()">Aggiungi Area esterna</button>


    </div>
  </form>
</ng-container>
