import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';

export enum EnumGiorniSettimana {
  Lunedi = 'lunedi',
  Martedi = 'martedi',
  Mercoledi = 'mercoledi',
  Giovedi = 'giovedi',
  Venerdi = 'venerdi',
  Sabato = 'sabato',
  Domenica = 'domenica',
}

export enum EnumFasciaOraria {
  Mattina = 'mattina',
  Pomeriggio = 'pomeriggio',
}

export interface IGiornoSettimanaStruttura extends IBaseBusinessObject {
  aperturaAl: string;
  aperturaDal: string;
  nomeGiorno: EnumGiorniSettimana;
}

export class GiornoSettimanaStruttura extends BaseBusinessObject implements IGiornoSettimanaStruttura {
  aperturaAl: string;
  aperturaDal: string;
  nomeGiorno: EnumGiorniSettimana;

  constructor(params?: Partial<IGiornoSettimanaStruttura>) {
    super(params);
  }

  deconstructor(): IGiornoSettimanaStruttura {
    return Object.assign({}, this);
  }
}
