import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { Language } from './language.model';

export interface LanguagesState
  extends EntityState<Language, string>,
    ActiveState<string> { }

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'lbl-app-util-languages' })
export class LanguagesStore extends EntityStore<LanguagesState> {
  constructor() {
    super();
  }
}
