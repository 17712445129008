import { BaseBusinessObject, IBaseBusinessObject } from "./BaseBusinessObject";
import { IRuoloApplicativo, RuoloApplicativo } from "./RuoloApplicativo";

export interface IStrumentoDiRegistrazioneTemplateAccessibileDaRuoli extends IBaseBusinessObject {
    ruoloApplicativo: IRuoloApplicativo;
    create: boolean;
    update: boolean;
    read: boolean;
    delete: boolean;
}

export class StrumentoDiRegistrazioneTemplateAccessibleDaRuoli extends BaseBusinessObject {

    ruoloApplicativo: RuoloApplicativo;
    create: boolean;
    update: boolean;
    read: boolean;
    delete: boolean;

    constructor(params?: Partial<IStrumentoDiRegistrazioneTemplateAccessibileDaRuoli>) {
        super(params);

        /**
         * Mapping
         */
        if (params.ruoloApplicativo) {
            this.ruoloApplicativo = new RuoloApplicativo(params.ruoloApplicativo)
        }

    }

    deconstructor(): IStrumentoDiRegistrazioneTemplateAccessibileDaRuoli {
        return Object.assign({}, { ...this });
    }

}

