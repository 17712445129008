import { Injectable } from '@angular/core';
import { filterNil } from '@datorama/akita';
import { AuthService } from '@gpi/lbl/app/data-access/auth';
import { IUserInfoResponse } from '@gpi/lbl/shared/entity';
import { forkJoin, of, EMPTY, Observable } from 'rxjs';
import { catchError, finalize, first, switchMapTo, tap } from 'rxjs/operators';

@Injectable()
export class UtentiProvider {
  constructor(private authService: AuthService) { }

  load(): Promise<IUserInfoResponse[]> {
    const authState = this.authService.query.getValue();
    const utenteToken = authState.utenteToken;
    const utenteImpersonificatoToken = authState.utenteImpersonificatoToken;
    const utenteParenteToken = authState.utenteParenteToken;

    const sources: Observable<IUserInfoResponse>[] = [];

    if (utenteToken) {
      const source = this.authService.userInfo({ token: utenteToken }).pipe(
        tap((utente) => this.authService.store.setUtente(utente)),
        switchMapTo(this.authService.query.selectUtente().pipe(filterNil, first())),
        catchError(() => EMPTY),
      );

      sources.push(source);
    }

    if (utenteImpersonificatoToken) {
      const source = this.authService.userInfo({ token: utenteImpersonificatoToken }).pipe(
        tap((utente) => this.authService.store.setUtenteImpersonificato(utente)),
        switchMapTo(this.authService.query.selectUtenteImpersonificato().pipe(filterNil, first())),
        catchError(() => EMPTY),
      );

      sources.push(source);
    }

    if (utenteParenteToken) {
      const source = this.authService.userInfo({ token: utenteParenteToken }).pipe(
        tap((utente) => this.authService.store.setUtenteParente(utente)),
        switchMapTo(this.authService.query.selectUtenteParente().pipe(filterNil, first())),
        catchError(() => EMPTY),
      );

      sources.push(source);
    }

    if (!utenteToken && !utenteImpersonificatoToken && !utenteParenteToken) {
      sources.push(of(null));
    }

    return forkJoin(sources).pipe(finalize(() => console.log('END'))).toPromise();
  }
}
