<ng-container *transloco="let t; read: 'ricadutaAttivitaFormativa.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div fxLayout="row" fxLayoutGap="1em">
      <mat-form-field appearance="outline">
        <mat-label>{{t('dataValutazione')}}</mat-label>
        <input matInput [matDatepicker]="dataInizioOsservazionePicker" formControlName="dataValutazione">
        <mat-datepicker-toggle matSuffix [for]="dataInizioOsservazionePicker"></mat-datepicker-toggle>
        <mat-datepicker #dataInizioOsservazionePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{t('percorsoFormativo')}}</mat-label>
        <input matInput type="text" formControlName="percorsoFormativo">
      </mat-form-field>
    </div>

    <div class="mat-elevation-z1">

      <table mat-table [dataSource]="osservazioniList.controls" formArrayName="osservazioniList">

        <ng-container matColumnDef="attivitaDaValutare">
          <th mat-header-cell *matHeaderCellDef>
            {{t('tabella.column.testo')}}
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ t('domande.attivitaDaValutare_'+i) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="inizioAnno">
          <th mat-header-cell *matHeaderCellDef>
            {{t('tabella.column.inizioAnno')}}
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-checkbox formControlName="inizioAnno" [checked]="element?.content?.inizioAnno">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="fineAnno">
          <th mat-header-cell *matHeaderCellDef>
            {{t('tabella.column.fineAnno')}}
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-checkbox formControlName="fineAnno" [checked]="element?.content?.fineAnno">
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="allegatiCompilati">
          <th mat-header-cell *matHeaderCellDef>
            {{t('tabella.column.allegato')}}
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">

            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="end center">
              <div [matTooltip]="t('button.uploadFileDisabled')" matTooltipPosition="right"
                [matTooltipDisabled]="!(content?.osservazioniList[i]?.allegatiCompilati || tempFile[i])">
                <button mat-flat-button color="primary" type="button" (click)="inputContenuto.click()"
                  *ngIf="!(content?.osservazioniList[i]?.allegatiCompilati || tempFile[i])">
                  {{ t('button.uploadFile') }}
                </button>
              </div>
              <input hidden type="file" accept=".pdf" #inputContenuto (change)="upload($event.target.files, i)" />
            </div>
            <div fxLayout="row" fxLayoutGap="1em" fxLayoutAlign="end center"
              *ngIf="content?.osservazioniList[i]?.allegatiCompilati || tempFile">
              <span *ngIf="!tempAllegato[i]?.link && !content?.osservazioniList[i]?.allegatiCompilati?.link">
                {{ tempFile ? tempFile[i]?.name : content?.osservazioniList[i]?.allegatiCompilati?.originalName }}
              </span>
              <span *ngIf="tempAllegato[i]?.link || content?.osservazioniList[i]?.allegatiCompilati?.link">
                <a *ngIf="tempAllegato[i]?.link" [href]="tempAllegato[i].link">{{tempFile[i]?.name  }}
                </a>
                <a *ngIf="content?.osservazioniList[i]?.allegatiCompilati?.link"
                  [href]="content?.osservazioniList[i]?.allegatiCompilati?.link">{{ content?.osservazioniList[i]?.allegatiCompilati?.originalName }}
                </a>
              </span>
              <button mat-icon-button color="primary" type="button"
                (click)="deleteFile( tempAllegato[i]?.id || content?.osservazioniList[i]?.allegatiCompilati?.id, i )"
                matTooltipPosition="right" [matTooltip]="t('button.deleteFile')"
                *ngIf="content?.osservazioniList[i]?.allegatiCompilati?.id || tempFile[i]">
                <mat-icon svgIcon="delete"></mat-icon>
              </button>
            </div>


          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

  </form>
</ng-container>
