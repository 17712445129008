import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Form } from '@angular/forms';
import {
  Domanda,
  SituazioneInizialeSipi,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
  RaccoltaInformazioniSipi,
  OrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow,
  OrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow,
  EnumFasciaOraria,
  EnumGiorniSettimana,
  GiornoSettimanaStruttura,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormatWidth } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DialogFormProfessionistaComponent } from './dialog/dialog-form-professionista.component';
import { MatTable } from '@angular/material/table';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-sipi-sezione-raccolta-informazioni',
  templateUrl: './sipi-sezione-raccolta-informazioni.component.html',
  styleUrls: ['./sipi-sezione-raccolta-informazioni.component.scss'],
})
export class SipiSezioneRaccoltaInformazioniComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  @ViewChild('button')
  buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  orarioFrequenzaRows = [EnumFasciaOraria.Mattina, EnumFasciaOraria.Pomeriggio];
  orarioFrequenzaBaminoDisplayedColumns: string[] = ['fasciaOraria'];
  orarioOrganizzazioneOrariaAdultiDisplayedColumns: string[] = ['nome', 'ruolo'];
  orarioOrganizzazioneLavoroAdultiDisplayedColumns: string[] = ['nome', 'azione', 'momentoAzione'];

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): RaccoltaInformazioniSipi {
    let content = this.strumentoDiRegistrazioneArchivio.content as RaccoltaInformazioniSipi;

    if (!content) {
      content = RaccoltaInformazioniSipi.InitContent(this.strutturaEducativa);
    }

    return content;
  }

  form: FormGroup;

  get formOrarioFrequenzaBambino(): FormArray {
    return this.form.get('orarioFrequenzaBambino') as FormArray;
  }

  get formOrganizzazioneOrariaAdulti(): FormArray {
    return this.form.get('organizzazioneOrariaAdulti') as FormArray;
  }

  get formDomande(): FormArray {
    return this.form.get('domande') as FormArray;
  }

  constructor(private matDialog: MatDialog) { }

  ngOnInit() {
    this.strutturaEducativa.orarioApertura.map(orarioApertura => {
      this.orarioFrequenzaBaminoDisplayedColumns.push(orarioApertura.nomeGiorno);
      this.orarioOrganizzazioneOrariaAdultiDisplayedColumns.push(orarioApertura.nomeGiorno);
    });
    this.orarioOrganizzazioneOrariaAdultiDisplayedColumns.push('azioni');
    this.form = this.buildForm();
    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      orarioFrequenzaBambino: this.buildFormOrariFrequenzaBambini(this.content.orarioFrequenzaBambino),
      noteOrarioFrequenzaBambino: new FormControl(this.content && this.content.noteOrarioFrequenzaBambino ? this.content.noteOrarioFrequenzaBambino : null),
      organizzazioneOrariaAdulti: this.buildFormOrganizzazioneOrariaAdulti(this.content.organizzazioneOrariaAdulti),
      noteOrarioFrequenzaAdulto: new FormControl(this.content && this.content.noteOrarioFrequenzaAdulto ? this.content.noteOrarioFrequenzaAdulto : null),
      domande: this.buildFormDomande(this.content.domande),
    });

    return form;
  }

  buildFormOrariFrequenzaBambini(orari: OrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow[]): FormArray {
    const form = new FormArray(orari.map((value) => this.buildFormOrarioFrequenzaBambini(value)));

    return form;
  }

  buildFormOrarioFrequenzaBambini(orario: OrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow): FormGroup {
    const form = new FormGroup({
      nomeGiorno: new FormControl(orario.nomeGiorno ? orario.nomeGiorno : null),
      orarioMattinoDal: new FormControl(orario.orarioMattinoDal ? orario.orarioMattinoDal : null),
      orarioMattinoAl: new FormControl(orario.orarioMattinoAl ? orario.orarioMattinoAl : null),
      orarioPomeriggioDal: new FormControl(orario.orarioPomeriggioDal ? orario.orarioPomeriggioDal : null),
      orarioPomeriggioAl: new FormControl(orario.orarioPomeriggioAl ? orario.orarioPomeriggioAl : null),
    });

    return form;
  }

  buildFormOrganizzazioneOrariaAdulti(organizzazioneOrari: OrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow[]): FormArray {
    const form = organizzazioneOrari && organizzazioneOrari.length > 0 ?
      new FormArray(organizzazioneOrari.map((value) => this.buildFormOrganizzazioneOrariaAdulto(value))) :
      new FormArray([]);

    return form;
  }

  buildFormOrganizzazioneOrariaAdulto(organizzazioneOrario: OrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow): FormGroup {
    const form = new FormGroup({
      nome: new FormControl(organizzazioneOrario.nome ? organizzazioneOrario.nome : null),
      cognome: new FormControl(organizzazioneOrario.cognome ? organizzazioneOrario.cognome : null),
      funzione: new FormControl(organizzazioneOrario.funzione ? organizzazioneOrario.funzione : null),
      orarioFrequenza: this.buildGiorniSettimanaStruttura(organizzazioneOrario.orarioFrequenza),
      ruoloOAzione: new FormControl(organizzazioneOrario.ruoloOAzione ? organizzazioneOrario.ruoloOAzione : null),
      momentoRuoloOAzione: new FormControl(organizzazioneOrario.momentoRuoloOAzione ? organizzazioneOrario.momentoRuoloOAzione : null)
    });

    return form;
  }

  buildGiorniSettimanaStruttura(giorni: GiornoSettimanaStruttura[]): FormArray {
    const form = giorni && giorni.length ?
      new FormArray(giorni.map((value) => this.buildGiornoSettimanaStruttura(value))) :
      new FormArray([]);

    return form;
  }

  buildGiornoSettimanaStruttura(giorno: GiornoSettimanaStruttura): FormGroup {
    const form = new FormGroup({
      nomeGiorno: new FormControl(giorno.nomeGiorno ? giorno.nomeGiorno : null),
      aperturaAl: new FormControl(giorno.aperturaAl ? giorno.aperturaAl : null),
      aperturaDal: new FormControl(giorno.aperturaDal ? giorno.aperturaDal : null),
    });

    return form;
  }

  buildFormDomande(domande: Domanda[]): FormArray {
    const form = new FormArray(domande.map((value) => this.buildFormDomanda(value)));

    return form;
  }

  buildFormDomanda(domanda: Domanda): FormGroup {
    const form = new FormGroup({
      codiceDomanda: new FormControl(domanda.codiceDomanda),
      testoDomanda: new FormControl(domanda.testoDomanda),
      rispostaDomanda: new FormControl(domanda.rispostaDomanda),
    });

    return form;
  }

  getOrarioFrequenzaBambinoFormGroupName(giorno: EnumGiorniSettimana): number {
    const formControl = this.formOrarioFrequenzaBambino.controls.find(
      (control) => control.value.nomeGiorno === giorno,
    );
    const formGroupName = this.formOrarioFrequenzaBambino.controls.indexOf(formControl);
    return formGroupName;
  }

  getOrarioFrequenzaBambinoFormControlName(fascia: EnumFasciaOraria, isDal: boolean): string {
    let formControlName = '';
    if (fascia === EnumFasciaOraria.Mattina) {
      formControlName = isDal ? 'orarioMattinoDal' : 'orarioMattinoAl';
    } else if (fascia === EnumFasciaOraria.Pomeriggio) {
      formControlName = isDal ? 'orarioPomeriggioDal' : 'orarioPomeriggioAl';
    }

    return formControlName;
  }

  getOrganizzazioneOrariaGiorniFormGroupName(giorno: EnumGiorniSettimana, index: number): number {
    const formControl = (this.formOrganizzazioneOrariaAdulti.at(index).get('orarioFrequenza') as FormArray).controls.find(
      (control) => control.value.nomeGiorno === giorno,
    );
    const formGroupName = (this.formOrganizzazioneOrariaAdulti.at(index).get('orarioFrequenza') as FormArray).controls.indexOf(formControl);
    return formGroupName;
  }

  addProfessionista() {
    const orarioFrequenzaEntity: GiornoSettimanaStruttura[] = this.strutturaEducativa.orarioApertura;
    orarioFrequenzaEntity.map(orario => {
      orario.aperturaAl = null;
      orario.aperturaDal = null;
    });
    const dialogRef = this.matDialog.open(DialogFormProfessionistaComponent, { data: this.buildFormOrganizzazioneOrariaAdulto(new OrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow({ orarioFrequenza: orarioFrequenzaEntity })) });
    dialogRef.afterClosed().subscribe((entity: FormGroup) => {
      if (entity) {
        this.formOrganizzazioneOrariaAdulti.push(entity);
        this.tables.map(table => table.renderRows());
      }
    });
  }

  editProfessionista(index: number) {
    const dialogRef = this.matDialog.open(DialogFormProfessionistaComponent, { data: this.buildFormOrganizzazioneOrariaAdulto(this.formOrganizzazioneOrariaAdulti.at(index).value) });
    dialogRef.afterClosed().subscribe((entity: FormGroup) => {
      if (entity) {
        this.formOrganizzazioneOrariaAdulti.at(index).patchValue(entity.value);
        this.tables.map(table => table.renderRows());
      }
    });
  }

  deleteProfessionista(index: number) {
    this.formOrganizzazioneOrariaAdulti.removeAt(index);
    this.tables.map(table => table.renderRows());
  }

  updateForm(entity: RaccoltaInformazioniSipi): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const raccoltaInformazioniSipi = new RaccoltaInformazioniSipi(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: raccoltaInformazioniSipi,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
