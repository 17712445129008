import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  Domanda,
  EnumFasciaOraria,
  EnumGiorniSettimana,
  GestioneColloquiInizialiNidoInfanzia,
  GiornoSettimanaStruttura,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-gestione-colloqui-iniziali-nido-infanzia',
  templateUrl: './strumento-registrazione-gestione-colloqui-iniziali-nido-infanzia.component.html',
  styleUrls: ['./strumento-registrazione-gestione-colloqui-iniziali-nido-infanzia.component.scss'],
})
export class StrumentoRegistrazioneGestioneColloquiInizialiNidoInfanziaComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();


  @ViewChild('button')
  buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): GestioneColloquiInizialiNidoInfanzia {
    let content = this.strumentoDiRegistrazioneArchivio.content as GestioneColloquiInizialiNidoInfanzia;

    if (!content) {
      content = GestioneColloquiInizialiNidoInfanzia.InitContent(this.strutturaEducativa);
    }

    return content;
  }

  form: FormGroup;

  get formPartecipantiColloquio(): FormControl {
    return this.form.get('partecipantiColloquio') as FormControl;
  }
  partecipantiColloquioSeparatorKeysCodes: number[] = [ENTER, COMMA];

  get formDomande(): FormArray {
    return this.form.get('domande') as FormArray;
  }
  domandeDatiAnagraficiMin = 0;
  domandeDatiAnagraficiMax = 1;

  domandeAllaFamigliaMin = 1;

  get formOrarioFrequenzaRichiesto(): FormArray {
    return this.form.get('orarioFrequenzaRichiesto') as FormArray;
  }
  orarioFrequenzaRows = [EnumFasciaOraria.Mattina, EnumFasciaOraria.Pomeriggio];
  orarioFrequenzaColumns: string[] = [
    EnumGiorniSettimana.Lunedi,
    EnumGiorniSettimana.Martedi,
    EnumGiorniSettimana.Mercoledi,
    EnumGiorniSettimana.Giovedi,
    EnumGiorniSettimana.Venerdi,
  ];
  orarioFrequenzaDisplayedColumns: string[] = [
    'fascia',
    EnumGiorniSettimana.Lunedi,
    EnumGiorniSettimana.Martedi,
    EnumGiorniSettimana.Mercoledi,
    EnumGiorniSettimana.Giovedi,
    EnumGiorniSettimana.Venerdi,
  ];

  ngOnInit() {
    this.form = this.buildForm();

    if (this.strumentoDiRegistrazioneArchivio.status === 'Bozza') {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    }

    this.form.valueChanges.subscribe((el) => {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      inizioAmbientamento: new FormControl(this.content.inizioAmbientamento, Validators.required),
      noteAnagrafica: new FormControl(this.content.noteAnagrafica, Validators.required),
      notePostColloquio: new FormControl(this.content.notePostColloquio, Validators.required),
      noteRiflessioni: new FormControl(this.content.noteRiflessioni, Validators.required),
      partecipantiColloquio: new FormControl(this.content.partecipantiColloquio || [], Validators.required),

      domande: this.buildFormDomande(this.content.domande),
      orarioFrequenzaRichiesto: this.buildFormOrarioFrequenzaRichiesto(
        this.content.orarioFrequenzaRichiesto,
      ),
    });
    return form;
  }

  buildFormDomande(domande: Domanda[]): FormArray {
    const form = new FormArray(domande.map((value) => this.buildFormDomanda(value)));
    return form;
  }

  buildFormDomanda(domanda: Domanda): FormGroup {
    const form = new FormGroup({
      codiceDomanda: new FormControl(domanda.codiceDomanda),
      testoDomanda: new FormControl(domanda.testoDomanda),
      rispostaDomanda: new FormControl(domanda.rispostaDomanda),
    });
    return form;
  }

  buildFormOrarioFrequenzaRichiesto(orarioApertura: GiornoSettimanaStruttura[]): FormArray {
    const form = new FormArray(orarioApertura.map((value) => this.buildFormGiornoSettimanaStruttura(value)));

    return form;
  }

  buildFormGiornoSettimanaStruttura(giornoSettimanaStruttura: GiornoSettimanaStruttura): FormGroup {
    const form = new FormGroup({
      aperturaDal: new FormControl(giornoSettimanaStruttura.aperturaDal),
      aperturaAl: new FormControl(giornoSettimanaStruttura.aperturaAl),
      nomeGiorno: new FormControl(giornoSettimanaStruttura.nomeGiorno),
    });

    return form;
  }

  updateForm(entity: GestioneColloquiInizialiNidoInfanzia): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  addPartecipanteColloquio(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;

    if ((value || '').trim()) {

      const values: string[] = Object.values(this.formPartecipantiColloquio.value);
      values.push(value.trim());

      this.formPartecipantiColloquio.setValue(values);
    }

    if (input) {
      input.value = '';
    }

    this.form.updateValueAndValidity();
  }

  removePartecipanteColloquio(index: number): void {
    const values: string[] = Object.values(this.formPartecipantiColloquio.value);
    values.splice(index, 1);
    this.formPartecipantiColloquio.setValue(values);
    this.form.updateValueAndValidity();
  }
  getOrarioFrequenzaRichiestoFormGroupName(giorno: EnumGiorniSettimana): number {
    const formControl = this.formOrarioFrequenzaRichiesto.controls.find(
      (control) => control.value.nomeGiorno === giorno,
    );
    const formGroupName = this.formOrarioFrequenzaRichiesto.controls.indexOf(formControl);

    return formGroupName;
  }

  getOrarioFrequenzaRichiestoFormControlName(fascia: EnumFasciaOraria) {
    let formControlName = '';

    if (fascia === EnumFasciaOraria.Mattina) {
      formControlName = 'aperturaDal';
    } else if (fascia === EnumFasciaOraria.Pomeriggio) {
      formControlName = 'aperturaAl';
    }

    return formControlName;
  }

  buildStrumentoForm() {

    const form = this.form.getRawValue();

    this.strumentiDiRegistrazioneArchivio[0].content = form;

    return this.strumentiDiRegistrazioneArchivio;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const gestioneColloquiIniziali = new GestioneColloquiInizialiNidoInfanzia(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: gestioneColloquiIniziali,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
