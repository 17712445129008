import { StrutturaEducativa } from '../StrutturaEducativa';

import { GiornoSettimanaStruttura, IGiornoSettimanaStruttura } from './../GiornoSettimanaStruttura';
import { Domanda, IDomanda } from './Domanda';

export interface IGestioneColloquiInizialiNidoInfanzia {
  inizioAmbientamento: Date;
  noteAnagrafica: string;
  notePostColloquio: string;
  noteRiflessioni: string;
  partecipantiColloquio: string[];

  /**
   * Relazioni
   */
  domande: IDomanda[];
  orarioFrequenzaRichiesto: IGiornoSettimanaStruttura[];
}

export class GestioneColloquiInizialiNidoInfanzia implements IGestioneColloquiInizialiNidoInfanzia {
  inizioAmbientamento: Date;
  noteAnagrafica: string;
  notePostColloquio: string;
  noteRiflessioni: string;
  partecipantiColloquio: string[];

  /**
   * Relazioni
   */
  domande: Domanda[];
  orarioFrequenzaRichiesto: GiornoSettimanaStruttura[];

  constructor(params: Partial<IGestioneColloquiInizialiNidoInfanzia> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.inizioAmbientamento) {
      this.inizioAmbientamento = new Date(params.inizioAmbientamento);
    }

    if (params.domande) {
      this.domande = params.domande.map((value) => new Domanda(value));
    }
    if (params.orarioFrequenzaRichiesto) {
      this.orarioFrequenzaRichiesto = params.orarioFrequenzaRichiesto.map(
        (value) => new GiornoSettimanaStruttura(value),
      );
    }
  }

  static InitContent(strutturaEducativaSelezionataInContesto: StrutturaEducativa): GestioneColloquiInizialiNidoInfanzia {
    const content = new GestioneColloquiInizialiNidoInfanzia();

    const testiDomande = [
      // Sezione "Dati anagrafici"
      'Per favorire la partecipazione delle famiglie agli incontri/iniziative che il servizio educativo propone, quale fascia oraria preferite (prima/dopo cena)?',

      // Sezione "Domande famiglia"
      'Volete raccontarci del / la vostro / a bambino / a ?',
      'Come si relaziona con le persone che frequenta abitualmente ? (fratelli, nonni, ecc.)',
      'Come si relaziona con le persone che non conosce ?',
      'Quali modalità possono favorire il coinvolgimento e la relazione con il/ la vostro / a bambino / a ?',
      'Ci sono dei gesti abituali che usate al momento del saluto? Quali sono le strategie che utilizzate per consolarlo / a ?',
      'Come comunica verbalmente? Dice già le prime parole? quali? Di solito usa le parole da sole o insieme ai gesti?Usa parole-frasi o costruisce  discorsi che contengono prime parole ?',
      'Quali spazi privilegia per giocare e quali sono i suoi giochi preferiti ?',
      'È interessato a qualcosa in particolare ? (ad es.oggetti insoliti, musica, ecc.)',
      'Come gioca di solito: in modo autonomo o preferisce la presenza di adulti o bambini?',
      'Quali sono le sue capacità di movimento? lattanti-piccoli: gattona, cerca la posizione eretta, sta seduto / a, fa i primi passi, ecc…medi - grandi: corre, salta, sale e scende le scale,ecc.',
      'Come esplora l’ambiente ? (con curiosità, entusiasmo, timore, ecc)',
      'Che cosa mangia abitualmente il bambino/ a ? Ci sono cibi che ama particolarmente o che rifiuta? Ha intolleranze alimentari o allergie?',
      'Quali autonomie possiede ? (ad es.prende il cibo da solo/ a, beve dal bicchiere, usa le posate, ecc.)',
      'Quali sono le vostre abitudini familiari durante colazione/ pranzo / cena ?',
      'Dove dorme di solito il / la bambino / a e che abitudini / rituali ha al momento del sonno?',
      'Quali sono le sue abitudini e rituali?(ciuccio, si addormenta in braccio, oggetto preferito, racconto di storie, ecc.)',
      'Ci raccontate come avviene e come vive il / la bambino / a il momento del cambio?',
      'Ha allergie a creme o pannolini?',
      'Quali autonomie possiede ? (ad es.si lava le mani da solo / a, usa il vasino, ecc.)',
      //'Riflessioni sul colloquio e prime impressioni rispetto la famiglia',
      //'Informazioni significative emerse dopo il colloquio',
    ];

    content.domande = testiDomande.map((value, index) => {
      return new Domanda({
        codiceDomanda: `GestioneColloquiInizialiNidoInfanzia_${index}`,
        rispostaDomanda: '',
        testoDomanda: value,
      });
    });
    content.orarioFrequenzaRichiesto = strutturaEducativaSelezionataInContesto.orarioApertura.map((giornoConfiguratoPerStruttura) => {
      return new GiornoSettimanaStruttura(giornoConfiguratoPerStruttura);
    });

    return content;
  }

  deconstructor(): IGestioneColloquiInizialiNidoInfanzia {
    return Object.assign({}, this);
  }
}
