export interface IRilevazioneGenericRow {
  numeroAttivitaEffettaute: number;
  descrizioneAttivita: string;
}

export class RilevazioneGenericRow implements IRilevazioneGenericRow {
  numeroAttivitaEffettaute: number;
  descrizioneAttivita: string;
  constructor(params: Partial<IRilevazioneGenericRow> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
  }

  deconstructor(): IRilevazioneGenericRow {
    return Object.assign({}, this);
  }
}

export interface IRilevazioneDocumentazioneProgettualeServiziEducativi {
  NumTotDocumentazioniProgettuali: number;
  NumTotDocumentazioneQuotidiana: number;
  NumTotIncontriDiSezione: number;
  NumTotDocumantazioneFamiglie: number;

  DocumentazioniProgettuali: RilevazioneGenericRow[];
  DocumentazioneQuotidiana: RilevazioneGenericRow[];
  IncontriDiSezione: RilevazioneGenericRow[];
  DocumantazioneFamiglie: RilevazioneGenericRow[];
}

export class RilevazioneDocumentazioneProgettualeServiziEducativi implements IRilevazioneDocumentazioneProgettualeServiziEducativi {
  NumTotDocumentazioniProgettuali: number;
  NumTotDocumentazioneQuotidiana: number;
  NumTotIncontriDiSezione: number;
  NumTotDocumantazioneFamiglie: number;

  DocumentazioniProgettuali: RilevazioneGenericRow[];
  DocumentazioneQuotidiana: RilevazioneGenericRow[];
  IncontriDiSezione: RilevazioneGenericRow[];
  DocumantazioneFamiglie: RilevazioneGenericRow[];

  constructor(params: Partial<IRilevazioneDocumentazioneProgettualeServiziEducativi> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params?.DocumentazioniProgettuali) {
      this.DocumentazioniProgettuali = params.DocumentazioniProgettuali.map((value) => new RilevazioneGenericRow(value));
    }

    if (params?.DocumentazioneQuotidiana) {
      this.DocumentazioneQuotidiana = params.DocumentazioneQuotidiana.map((value) => new RilevazioneGenericRow(value));

    }
    if (params?.IncontriDiSezione) {
      this.IncontriDiSezione = params.IncontriDiSezione.map((value) => new RilevazioneGenericRow(value));
    }

    if (params?.DocumantazioneFamiglie) {
      this.DocumantazioneFamiglie = params.DocumantazioneFamiglie.map((value) => new RilevazioneGenericRow(value));
    }

  }
  static InitContent(): RilevazioneDocumentazioneProgettualeServiziEducativi {
    const content = new RilevazioneDocumentazioneProgettualeServiziEducativi();

    content.DocumentazioniProgettuali = new Array<RilevazioneGenericRow>();
    content.DocumentazioneQuotidiana = new Array<RilevazioneGenericRow>();
    content.IncontriDiSezione = new Array<RilevazioneGenericRow>();
    content.DocumantazioneFamiglie = new Array<RilevazioneGenericRow>();

    return content;
  }

  deconstructor(): IRilevazioneDocumentazioneProgettualeServiziEducativi {
    return Object.assign({}, this);
  }
}
