import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';

export enum EnumTipologiaPastoMenu {
  Colazione = 'Colazione',
  Spuntino = 'Spuntino',
  Pranzo = 'Pranzo',
  Merenda = 'Merenda',
  Cena = 'Cena',
}

export interface IPastoMenuStruttura extends IBaseBusinessObject {
  tipo: EnumTipologiaPastoMenu;
}

export class PastoMenuStruttura extends BaseBusinessObject
  implements IPastoMenuStruttura {
  tipo: EnumTipologiaPastoMenu;

  constructor(params?: Partial<IPastoMenuStruttura>) {
    super(params);
  }

  deconstructor(): IPastoMenuStruttura {
    return Object.assign({ }, this);
  }
}
