import {
  AnnoScolasticoPeriodoFormativo,
  IAnnoScolasticoPeriodoFormativo,
} from './AnnoScolasticoPeriodoFormativo';
import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { IMenuPasto, MenuPasto } from './MenuPasto';
import { IPersonaFisica, PersonaFisica } from './PersonaFisica';

export enum EnumTipologiaDietaMenu {
  Normale_12_36_Mesi = 'normale_12_36_mesi',
  Normale_3_5_Anni = 'normale_3_5_anni',
  Lattanti_0_12 = 'lattanti_0_12',
  Vegetariana = 'vegetariana',
  Vegana = 'vegana',
  NoCarne = 'no_carne',
  NoUovo = 'no_uovo',
  NoPomodoro = 'no_pomodoro',
  NoPesce = 'no_pesce',
  MotiviReligiosiNoMaiale = 'motivi_religiosi_no_maiale',
  MotiviReligiosiNoVacca = 'motivi_religiosi_no_vacca',
  Celiachia = 'celiachia',
  ModeratoApportoCalorico = 'moderato_apporto_calorico',
  Diabete = 'diabete',
  Altro = 'altro',
}

export enum EnumStagioneMenu {
  Invernale = 'Invernale',
  Estiva = 'Estiva',
}

export interface IMenu extends IBaseBusinessObject {
  relativoADieta: EnumTipologiaDietaMenu;
  stagioneDiRiferimento: EnumStagioneMenu;

  /**
   * Relazioni
   */
  pastiCensiti: IMenuPasto[];
  personalizzatoPer: IPersonaFisica;
  relativoAAnnoScolasticoPeriodoFormativo: IAnnoScolasticoPeriodoFormativo;
}

export class Menu extends BaseBusinessObject implements IMenu {
  relativoADieta: EnumTipologiaDietaMenu;
  stagioneDiRiferimento: EnumStagioneMenu;

  /**
   * Relazioni
   */
  pastiCensiti: MenuPasto[];
  personalizzatoPer: PersonaFisica;
  relativoAAnnoScolasticoPeriodoFormativo: AnnoScolasticoPeriodoFormativo;

  constructor(params?: Partial<IMenu>) {
    super(params);

    /**
     * Mapping
     */
    if (params.pastiCensiti) {
      this.pastiCensiti = params.pastiCensiti.map(
        (value) => new MenuPasto(value),
      );
    }
    if (params.personalizzatoPer) {
      this.personalizzatoPer = new PersonaFisica(params.personalizzatoPer);
    }
    if (params.relativoAAnnoScolasticoPeriodoFormativo) {
      this.relativoAAnnoScolasticoPeriodoFormativo = new AnnoScolasticoPeriodoFormativo(
        params.relativoAAnnoScolasticoPeriodoFormativo,
      );
    }
  }

  deconstructor(): IMenu {
    return Object.assign({ }, this);
  }
}
