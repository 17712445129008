import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { Domanda, RegistrazioniCaduteIncidentiBambini, RegistrazioniSmarrimentiOggettiBambini, StrumentoDiRegistrazioneArchivio } from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-smarrimenti-oggetti-bambini',
  templateUrl: './strumento-registrazione-smarrimenti-oggetti-bambini.component.html',
  styleUrls: ['./strumento-registrazione-smarrimenti-oggetti-bambini.component.scss'],
})
export class StrumentoRegistrazioneSmarrimentiOggettiBambiniComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();
  @Input()
  submit$: Observable<void>;

  @ViewChild('button')
  buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): RegistrazioniSmarrimentiOggettiBambini {
    let content = this.strumentoDiRegistrazioneArchivio.content as RegistrazioniSmarrimentiOggettiBambini;

    return content;
  }

  form: FormGroup;

  ngOnInit() {
    this.form = this.buildForm();
    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      data: new FormControl(this.content && this.content.data ? this.content.data : null),
      oggetto: new FormControl(this.content && this.content.oggetto ? this.content.oggetto : null),
      valore: new FormControl(this.content && this.content.valore ? this.content.valore : null),
      provvedimenti: new FormControl(this.content && this.content.provvedimenti ? this.content.provvedimenti : null),
    });

    return form;
  }


  updateForm(entity: RegistrazioniSmarrimentiOggettiBambini): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const registrazioniSmarrimentiOggettiBambini = new RegistrazioniSmarrimentiOggettiBambini(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: registrazioniSmarrimentiOggettiBambini,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
