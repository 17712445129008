
export interface ICompetenzeInsegnanti {
    competenze: ICompetenzaInsegnantiRow[];
}

export class CompetenzeInsegnanti implements ICompetenzeInsegnanti {

    competenze: CompetenzaInsegnantiRow[];

    constructor(params: Partial<ICompetenzeInsegnanti> = {}) {
        Object.assign(this, params);

        if (params.competenze) {
            this.competenze = params.competenze.map((value) => new CompetenzaInsegnantiRow(value));
        }
    }

    static InitContent() {
        const content = new CompetenzeInsegnanti();

        const tasks: TaskEducatore[] = [
            { id: 'taskCompetenzaInsegnanti_0', task: 'Condivide le regole del nido e della SCI con i bambini' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_1', task: 'Applica e promuove il regolamento e il codice etico comportamentale del servizio' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_2', task: 'Applica la metodologia e le procedure previste dal Learning By Languages Method' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_3', task: 'Sostiene e coinvolge le famiglie nella partecipazione al progetto Nido e Scuola' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_4', task: 'Concorre alla realizzazione delle attività promozionali e di sviluppo dell\' identità del Centro' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_5', task: 'Osserva e valuta le competenze, gli ambiti di sviluppo e i processi di apprendimento del bambino' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_6', task: 'Supporta lo sviluppo delle autonomie del bambini' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_7', task: 'Promuove le competenze cognitive, sociali, fisiche e relazionali del bambino attraverso esperienze educative e didattiche, principalmente utilizzando la modalità del gioco' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_8', task: 'Supporta il bambino nelle attività quotidiane di cura (routine) durante i momenti del pranzo, dell’igiene, del sonno e in tutte le azioni preparatorie di queste routine affiancata dalla assistente educativa (teacher assistant) ' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_9', task: 'Registra sul diario quotidiano le attività fisiologiche del bambino ' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_10', task: 'Osserva e monitora lo sviluppo evolutivo del bambino e se nota bisogni specifici a livello emotivo, psicologico o fisico, riferisce al proprio coordinatore' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_11', task: 'Concorre ad individuare il materiale didattico (strutturato e destrutturato) idoneo, che possa sostenere il bambino nello sviluppo dei “cento linguaggi” e nelle pluralità delle intelligenze, in relazione alle diverse fasce di età' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_12', task: 'Predispone le azioni e le strategie per accogliere la famiglia sia all’inizio dell’esperienza nel servizio (ambientamento, riambientamento del bambino e della famiglia) e sia in entrata che in uscita quotidiana dal servizio' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_13', task: 'Prepara i contesti educativi (spazi comuni e di sezione) in riferimento all’attività che andranno a svolgere e in riferimento all’età del bambino, con il supporto della assistente educativa' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_14', task: 'Riordina i materiali negli spazi della sezione coinvolgendo i bambini, con il supporto della assistente educativa' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_15', task: 'Progetta attività in riferimento agli interessi mostrati dai bambini' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_16', task: 'Incontra i colleghi e il pedagogista per condividere le tappe del progetto che si vuole sviluppare e gli aspetti organizzativi del servizio' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_17', task: 'Rendiconta giornalmente le attività educative e didattiche svolte' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_18', task: 'Documenta lo stato avanzamento del progetto per promuoverlo ai bambini e per condividerlo con le famiglie' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_19', task: 'Incontra le famiglie per discutere e condividere i traguardi di competenza raggiunti dalla sezione e dal bambino' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_20', task: 'Realizza documentazioni (cartacee, digitali, video) in riferimento alle attività svolte' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_21', task: 'Incontra periodicamente i colleghi per discutere e monitorare lo sviluppo e i bisogni dei singoli bambini e del gruppo sezione' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_22', task: 'Partecipa agli aggiornamenti professionali per mantenere e migliorare le proprie competenze e si impegna ad auto formarsi' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_23', task: 'Supporta il buon inserimento di nuovo staff educativo o di tirocinanti' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_24', task: 'Attua processi di autovalutazione delle proprie performance e della qualità del servizio erogato' } as TaskEducatore,
            { id: 'taskCompetenzaInsegnanti_25', task: 'Raccoglie gli indicatori necessari alla elaborazione del processo di valutazione e monitoraggio della qualità del servizio erogato' } as TaskEducatore,
        ];
        const skills: TaskEducatore[] = [
            { id: 'skillCompetenzaInsegnanti_0', task: 'Dialogo: saper dialogare in modo efficace con i differenti interlocutori' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_1', task: 'Strategie di apprendimento: saper utilizzare strategie e metodi differenti in riferimento a situazioni educative e di esperienza differenti' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_2', task: 'Ascolto attivo: dare attenzione a ciò che viene detto, assicurarsi di aver compreso ciò che è stato detto, porre domande quando appropriato e non interrompere in momenti inopportuni' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_3', task: 'Intelligenza Emotiva: sapersi comportare in modo appropriato in riferimento allo stato dell\' altro' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_4', task: 'Pensiero critico e metacognitivo: usare la logica e il ragionamento per individuare strategie alternative ottimali e per riflettere sui meccanismi di produzione dell\' apprendimento' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_5', task: 'Autovalutazione e Monitoraggio: sapersi autovalutare e saper monitorare le proprie performance e quelle del gruppo' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_6', task: 'Comprensione del testo: saper comprendere ciò che viene letto (testi, documenti, scritture)' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_7', task: 'Orientamento al servizio: sapersi comportare in maniera ottimale in riferimento alle procedure e ai bisogni delle persone del servizio' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_8', task: 'Decision making: valutare i pro e i contro di determinate azioni per individuare la più appropriata nella situazione contingente' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_9', task: 'Gestione del tempo: saper gestire il proprio tempo e il tempo del servizio in modo efficiente ed efficace' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_10', task: 'Scrittura: saper comunicare attraverso la scrittura in modo efficace in riferimento ai bisogni del servizio e del cliente e utente (in lingua madre e in lingua inglese)' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_11', task: 'Problem solving: saper analizzare problematiche, anche tramite documentazione specifica, al fine di progettare e implementare possibili soluzioni efficaci' } as TaskEducatore,
            { id: 'skillCompetenzaInsegnanti_12', task: 'Competenze tecnologiche: saper utilizzare le tecnologie e i media informatici in riferimento alle esigenze del servizio.' } as TaskEducatore,
        ];

        content.competenze = [...tasks, ...skills].map((value) => new CompetenzaInsegnantiRow({competenza: value}));

        return content;
    }

    deconstructor(): ICompetenzeInsegnanti {
        return Object.assign({}, this);
    }
}

export interface ICompetenzaInsegnantiRow {
    competenza: ITaskEducatore;
    autovalutazione: number;
    valutazione: number;
}

export class CompetenzaInsegnantiRow implements ICompetenzaInsegnantiRow {
    competenza: TaskEducatore;
    autovalutazione: number;
    valutazione: number;

    constructor(params: Partial<ICompetenzaInsegnantiRow> = {}) {
        Object.assign(this, params);

        if (params.competenza) {
            this.competenza = new TaskEducatore(params.competenza);
        }

    }

    deconstructor(): ICompetenzaInsegnantiRow {
        return Object.assign({}, this);
    }
}

export interface ITaskEducatore {
    id: string;
    task: string;
}

export class TaskEducatore implements ITaskEducatore {
    id: string;
    task: string;

    constructor(params: Partial<ITaskEducatore> = {}) {
        Object.assign(this, params);

    }

    deconstructor(): ITaskEducatore {
        return Object.assign({}, this);
    }
}

