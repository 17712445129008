import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { IPersonaGiuridica, PersonaGiuridica } from './PersonaGiuridica';

export enum EnumTipologiaAccessoServizioEducativo {
  Pubblico = 'Pubblico',
  Privato = 'Privato',
  Aziendale = 'Aziendale',
}

export enum EnumTipologiaOffertaServizioEducativo {
  NidoInfanzia = 'NidoInfanzia',
  ScuolaInfanzia = 'ScuolaInfanzia',
  ScuolaPrimaria = 'ScuolaPrimaria',
}

export interface IServizioEducativo extends IBaseBusinessObject {
  tipologiaAccesso: EnumTipologiaAccessoServizioEducativo;
  tipologiaServizioOfferto: EnumTipologiaOffertaServizioEducativo;

  /**
   * Relazioni
   */
  enteCommissionante: IPersonaGiuridica;
}

export class ServizioEducativo extends BaseBusinessObject
  implements IServizioEducativo {
  tipologiaAccesso: EnumTipologiaAccessoServizioEducativo;
  tipologiaServizioOfferto: EnumTipologiaOffertaServizioEducativo;

  /**
   * Relazioni
   */
  enteCommissionante: PersonaGiuridica;

  constructor(params?: Partial<IServizioEducativo>) {
    super(params);

    /**
     * Mapping
     */
    if (params.enteCommissionante) {
      this.enteCommissionante = new PersonaGiuridica(params.enteCommissionante);
    }
  }

  deconstructor(): IServizioEducativo {
    return Object.assign({ }, this);
  }
}
