<ng-container *transloco="let t; read: 'gestioneOsservazioniLinguaggiEspressiviCreativita.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="row" fxLayoutGap="1em">
      <mat-form-field appearance="outline">
        <mat-label>{{t('data')}}</mat-label>
        <input matInput [matDatepicker]="dataInizioOsservazionePicker" formControlName="dataOsservazione">
        <mat-datepicker-toggle matSuffix [for]="dataInizioOsservazionePicker"></mat-datepicker-toggle>
        <mat-datepicker #dataInizioOsservazionePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{t('valutazione')}}</mat-label>
        <!-- <input matInput type="number" formControlName="valutazioneComplessiva" min="1" max="5"> -->
        <mat-select formControlName="valutazioneComplessiva">
          <mat-option value=1>1</mat-option>
          <mat-option value=2>2</mat-option>
          <mat-option value=3>3</mat-option>
          <mat-option value=4>4</mat-option>
          <mat-option value=5>5</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutGap="1em">
      <mat-form-field appearance="outline">
        <mat-label>{{t('luogo')}}</mat-label>
        <input matInput type="text" formControlName="luogo">
      </mat-form-field>

      <mat-form-field fxFlex appearance="outline">
        <mat-chip-list #chipList formControlName="gruppoBambini">
          <mat-chip color="primary" *ngFor="let presente of formGruppoBambini.value; let i = index" [selectable]="true"
            [removable]="true" (removed)="removeBambini(i)">
            {{ presente }}
            <mat-icon svgIcon="delete" matChipRemove></mat-icon>
          </mat-chip>

          <input placeholder="{{t('bambini')}}" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="gruppoBambiniSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addBambini($event)" />
        </mat-chip-list>
      </mat-form-field>
    </div>

    <div fxLayout="column" fxLayoutGap="1em">
      <mat-form-field appearance="outline">
        <mat-label>{{t('strumentiESupporti')}}</mat-label>
        <textarea matInput type="text" formControlName="strumentiESupporti"></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{t('focusOsservativi')}}</mat-label>
        <textarea matInput type="text" formControlName="focusOsservativi"></textarea>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{t('domandeAdulto')}}</mat-label>
        <textarea matInput type="text" formControlName="domandeAdulto"></textarea>
      </mat-form-field>
    </div>

    <div class="mat-elevation-z1">
      <button mat-raised-button (click)="addrow()">{{t('aggiungiRiga')}}</button>

      <table mat-table [dataSource]="formTabella.controls" formArrayName="osservazioniList">
        <ng-container matColumnDef="approcci">
          <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tabella.column.approcci')"></th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="approcciESperimentazioni"> </textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="alfabeti">
          <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tabella.column.alfabeti')"></th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="alfabeti"> </textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="relazioni">
          <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tabella.column.relazioni.title')"></th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="relazioneConGruppoText"> </textarea>
            </mat-form-field>
            <!-- <mat-radio-group formControlName="relazioneConGruppo" aria-label="Select an option">
              <mat-radio-button [value]="true" class="mr-4">{{t('tabella.column.relazioni.si')}}</mat-radio-button>
              <mat-radio-button [value]="false">{{t('tabella.column.relazioni.no')}}</mat-radio-button>
            </mat-radio-group> -->
          </td>
        </ng-container>

        <ng-container matColumnDef="linguaggi">
          <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tabella.column.linguaggio')"></th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="linguaggi"> </textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="note">
          <th mat-header-cell *matHeaderCellDef [innerHTML]="t('tabella.column.note')"></th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="note"> </textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="TabellaDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: TabellaDisplayedColumns"></tr>
      </table>
    </div>
  </form>
</ng-container>
