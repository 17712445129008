import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { AuthService } from '@gpi/lbl/app/data-access/auth';
import { ENVIRONMENT, IEnvironment } from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';

@Injectable()
export class RuoloApplicativoHeaderInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    @Inject(ENVIRONMENT)
    private environment: IEnvironment,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const apiUrl = this.environment.urls.api;
    const ruoloApplicativo = this.authService.query.getValue().ruoloApplicativo;
    const ruoloApplicativoImpersonificato = this.authService.query.getValue()
      .ruoloApplicativoImpersonificato;

    const ruoloApplicativoId = ruoloApplicativoImpersonificato
      ? ruoloApplicativoImpersonificato.id.toString()
      : ruoloApplicativo
      ? ruoloApplicativo.id.toString()
      : null;

    if (req.url.startsWith(apiUrl) && ruoloApplicativoId) {
      req = req.clone({
        setHeaders: { ruoloId: ruoloApplicativoId },
      });
    }

    return next.handle(req);
  }
}
