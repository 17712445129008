import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { FeatureState, FeatureStore } from './feature.store';

@Injectable({ providedIn: 'root' })
export class FeatureQuery extends Query<FeatureState> {
  constructor(protected store: FeatureStore) {
    super(store);
  }

  isNavVisible() {
    return this.select((state) => state.ui.nav.visible);
  }
}
