import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { Allegato } from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AllegatiState, AllegatiStore } from './allegati.store';

@Injectable({ providedIn: 'root' })
export class AllegatiQuery extends QueryEntity<AllegatiState> {
  constructor(protected store: AllegatiStore) {
    super(store);
  }

  readById(id: number): Observable<Allegato> {
    return this.selectEntity(id).pipe(
      map((entity) => (entity ? new Allegato(entity) : null)),
    );
  }
}
