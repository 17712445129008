<ng-container *transloco="let t; read: 'sipiSezioneVerbali.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <div fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>{{ t('numeroVerbale') }}</mat-label>
          <input matInput formControlName="numeroVerbale" />
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>{{ t('dataIncontro') }}</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="dataIncontro" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="30">
          <mat-label>{{ t('luogoIncontro') }}</mat-label>
          <input matInput formControlName="luogoIncontro" />
        </mat-form-field>
      </div>

      <mat-form-field fxFill appearance="outline">
        <mat-chip-list #chipList formControlName="partecipanti">
          <mat-chip color="primary" *ngFor="let partecipante of formPartecipanti.value; let i = index"
            [selectable]="true" [removable]="true" (removed)="removePartecipante(i)">
            {{ partecipante }}
            <mat-icon svgIcon="delete" matChipRemove></mat-icon>
          </mat-chip>

          <input placeholder="{{t('partecipanti')}}" [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="partecipantiSeparatorKeysCodes" [matChipInputAddOnBlur]="true"
            (matChipInputTokenEnd)="addPartecipante($event)" />
        </mat-chip-list>
      </mat-form-field>

      <div formArrayName="domande">
        <ng-container *ngFor="let domanda of formDomande.controls; let i = index">
          <mat-form-field fxFill appearance="outline" [formGroupName]="i">
            <mat-label>{{ t('domande.' + domanda.value.codiceDomanda) }}</mat-label>
            <textarea matInput formControlName="rispostaDomanda"></textarea>
          </mat-form-field>
        </ng-container>
      </div>
    </div>
    <button #button type="submit" class="d-none"></button>
  </form>
</ng-container>
