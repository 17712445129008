import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';

export interface INumeroSettimanaMenuStruttura extends IBaseBusinessObject {
  nome: string;
}

export class NumeroSettimanaMenuStruttura extends BaseBusinessObject
  implements INumeroSettimanaMenuStruttura {
  nome: string;

  constructor(params?: Partial<INumeroSettimanaMenuStruttura>) {
    super(params);
  }

  deconstructor(): INumeroSettimanaMenuStruttura {
    return Object.assign({ }, this);
  }
}
