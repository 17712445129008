import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  filterStore,
  NgEntityService,
  NgEntityServiceConfig,
  NgEntityServiceLoader,
  NgEntityServiceNotifier
} from '@datorama/akita-ng-entity-service';
import {
  AnnoScolasticoPeriodoFormativo,

  IStrumentoDiRegistrazioneArchivio,
  PersonaFisica,
  ReadManyParams,
  ReadManyResponse,







  SezioneAnnoScolasticoPeriodoFormativo,
  StrumentoDiRegistrazioneArchivio,
  StrumentoDiRegistrazioneTemplateBody
} from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { StrumentiDiRegistrazioneArchivioBackend } from './strumenti-registrazione-archivio.backend';
import { StrumentiRegistrazioneArchivioQuery } from './strumenti-registrazione-archivio.query';
import {
  StrumentiRegistrazioneArchivioState,
  StrumentiRegistrazioneArchivioStore
} from './strumenti-registrazione-archivio.store';


@NgEntityServiceConfig({
  resourceName: 'strumenti-di-registrazione', //per BE reale
})
@Injectable({ providedIn: 'root' })
export class StrumentiRegistrazioneArchivioService extends NgEntityService<StrumentiRegistrazioneArchivioState> {
  loaders = this.loaderService.loadersFor('lbl-app-data-access-strumenti-registrazione-archivio');
  notifiers$ = this.notifierService.action$.pipe(filterStore('lbl-app-data-access-strumenti-registrazione-archivio'));

  constructor(
    public query: StrumentiRegistrazioneArchivioQuery,
    public store: StrumentiRegistrazioneArchivioStore,
    protected loaderService: NgEntityServiceLoader,
    protected notifierService: NgEntityServiceNotifier,
    private backend: StrumentiDiRegistrazioneArchivioBackend,
  ) {
    super(store);
  }

  readAll(): Observable<StrumentoDiRegistrazioneArchivio[]> {
    return this.get<IStrumentoDiRegistrazioneArchivio[]>({
      mapResponseFn: (response) => response.data,
    }).pipe(map((entities) => entities.map((entity) => new StrumentoDiRegistrazioneArchivio(entity))));
  }

  readMany(params: ReadManyParams): Observable<ReadManyResponse<StrumentoDiRegistrazioneArchivio>> {
    const httpParams = new HttpParams({ fromObject: params as any });

    return this.get<ReadManyResponse<IStrumentoDiRegistrazioneArchivio>>({ params: httpParams }).pipe(
      map((response) => {
        const mappedResponse: ReadManyResponse<StrumentoDiRegistrazioneArchivio> = {
          ...response,
          data: (response && response.data) ? response.data.map((entity) => new StrumentoDiRegistrazioneArchivio(entity)) : []
        };

        return mappedResponse;
      }),
    );
  }

  readById(id: number): Observable<StrumentoDiRegistrazioneArchivio> {
    return this.get<IStrumentoDiRegistrazioneArchivio>(id).pipe(
      map((entity) => new StrumentoDiRegistrazioneArchivio(entity)),
    );
  }

  createOne(body: StrumentoDiRegistrazioneArchivio): Observable<StrumentoDiRegistrazioneArchivio> {
    return this.add<IStrumentoDiRegistrazioneArchivio>(body.deconstructor()).pipe(
      map((entity) => new StrumentoDiRegistrazioneArchivio(entity)),
    );
  }

  updateById(id: number, body: StrumentoDiRegistrazioneArchivio): Observable<StrumentoDiRegistrazioneArchivio> {
    return this.update<IStrumentoDiRegistrazioneArchivio>(id, body.deconstructor()).pipe(
      map((entity) => new StrumentoDiRegistrazioneArchivio(entity)),
    );
  }

  deleteById(id: number): Observable<void> {
    return this.delete<void>(id);
  }

  getStrumentoDiRegistrazioneArchivio(
    dal: Date,
    al: Date,
    strumentoRegistrazioneTemaplateBody: StrumentoDiRegistrazioneTemplateBody,
    annoScolasticoPeriodoFormativiSelected: AnnoScolasticoPeriodoFormativo[],
    // strutturaEducativaSelected: StrutturaEducativa,
    // servizioEducativoSelected: ServizioEducativo,
    sezioneAnnoScolasticoPeriodoFormativo: SezioneAnnoScolasticoPeriodoFormativo[],
    personeCoinvolte: PersonaFisica[],
    isSameUserUpdatedBy ? : boolean
  ) {
    const body = {
      compilabileDal: dal,
      compilabileAl: al,
      strumentoRegistrazioneTemplateBody: strumentoRegistrazioneTemaplateBody,
      anniScolasticiPeriodoFormativi: [],
      sezioni: [],
      personeCoinvolte: [],
      sameUserUpdatedBy: null
    }; //dettagli per i parametri da passare al BE

    if (personeCoinvolte) {
      for (let index = 0; index < personeCoinvolte.length; index++) {
        const element = personeCoinvolte[index];
        body.personeCoinvolte.push({ id: element.id });
      }
    }

    if (annoScolasticoPeriodoFormativiSelected) {
      for (let index = 0; index < annoScolasticoPeriodoFormativiSelected.length; index++) {
        const element = annoScolasticoPeriodoFormativiSelected[index];
        body.anniScolasticiPeriodoFormativi.push({ id: element.id });
      }
    }

    if (sezioneAnnoScolasticoPeriodoFormativo) {
      for (let index = 0; index < sezioneAnnoScolasticoPeriodoFormativo.length; index++) {
        const element = sezioneAnnoScolasticoPeriodoFormativo[index];
        if (element) {
          body.sezioni.push({ id: element.id });
        }
      }
    }
    if(isSameUserUpdatedBy && isSameUserUpdatedBy == true){
      body.sameUserUpdatedBy = isSameUserUpdatedBy;
    }
    //console.log('body', body);
    return this.backend.postStrumentoDiRegistrazioneArchivio(body).pipe(
      take(1),
      tap((entities) => this.store.add(entities)),
      map((entities) => {

        const strumentoDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];

        entities['data'].map((entity) => strumentoDiRegistrazioneArchivio.push(new StrumentoDiRegistrazioneArchivio(entity)));

        return strumentoDiRegistrazioneArchivio.reduce((acc, val) => acc.concat(val), []);
      }),
    );

  }

}

