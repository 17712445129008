<ng-container *transloco="let t; read: 'sipiSezioneSintesiProgetto.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit(form)">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>
      <h3>{{t('title')}}</h3>
      <span>
        {{t('subtitle.inizio')}}
        <button mat-flat-button (click)="openDialog()">{{t('subtitle.link')}}</button>
        {{t('subtitle.fine')}}
      </span>
      <ckeditor formControlName="sintesi" [editor]="editor"
      [config]="{ toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'indent', 'outdent', '|', 'blockQuote', 'insertTable', 'undo', 'redo' ] }"
      >
      </ckeditor>

    </div>
    <button #button type="submit" class="d-none"></button>
  </form>
</ng-container>