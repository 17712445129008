import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  Domanda,
  GiornoSettimanaStruttura,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
  VerbaliSipi,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-sipi-sezione-verbali',
  templateUrl: './sipi-sezione-verbali.component.html',
  styleUrls: ['./sipi-sezione-verbali.component.scss'],
})
export class SipiSezioneVerbaliComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button')
  buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): VerbaliSipi {
    let content = this.strumentoDiRegistrazioneArchivio.content as VerbaliSipi;

    if (!content) {
      content = VerbaliSipi.InitContent();
    }

    return content;
  }

  form: FormGroup;

  get formPartecipanti(): FormControl {
    return this.form.get('partecipanti') as FormControl;
  }
  partecipantiSeparatorKeysCodes: number[] = [ENTER, COMMA];

  get formDomande(): FormArray {
    return this.form.get('domande') as FormArray;
  }

  ngOnInit() {
    this.form = this.buildForm();
    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      numeroVerbale: new FormControl(this.content && this.content.numeroVerbale ? this.content.numeroVerbale : null),
      dataIncontro: new FormControl(this.content && this.content.dataIncontro ? this.content.dataIncontro : null),
      luogoIncontro: new FormControl(this.content && this.content.luogoIncontro ? this.content.luogoIncontro : null),
      partecipanti: new FormControl(this.content && this.content.partecipanti ? this.content.partecipanti : []),

      domande: this.buildFormDomande(this.content.domande),
    });

    return form;
  }

  buildFormDomande(domande: Domanda[]): FormArray {
    const form = new FormArray(domande.map((value) => this.buildFormDomanda(value)));

    return form;
  }

  buildFormDomanda(domanda: Domanda): FormGroup {
    const form = new FormGroup({
      codiceDomanda: new FormControl(domanda.codiceDomanda),
      testoDomanda: new FormControl(domanda.testoDomanda),
      rispostaDomanda: new FormControl(domanda.rispostaDomanda),
    });

    return form;
  }

  buildFormOrarioFrequenzaRichiesto(orarioApertura: GiornoSettimanaStruttura[]): FormArray {
    const form = new FormArray(orarioApertura.map((value) => this.buildFormGiornoSettimanaStruttura(value)));

    return form;
  }

  buildFormGiornoSettimanaStruttura(giornoSettimanaStruttura: GiornoSettimanaStruttura): FormGroup {
    const form = new FormGroup({
      aperturaDal: new FormControl(giornoSettimanaStruttura.aperturaDal),
      aperturaAl: new FormControl(giornoSettimanaStruttura.aperturaAl),
      nomeGiorno: new FormControl(giornoSettimanaStruttura.nomeGiorno),
    });

    return form;
  }

  updateForm(entity: VerbaliSipi): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  addPartecipante(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || '').trim()) {
      const values: string[] = Object.values(this.formPartecipanti.value);
      values.push(value.trim());
      this.formPartecipanti.setValue(values);
    }
    if (input) {
      input.value = '';
    }
    this.form.updateValueAndValidity();
  }

  removePartecipante(index: number): void {
    const values: string[] = Object.values(this.formPartecipanti.value);
    values.splice(index, 1);
    this.formPartecipanti.setValue(values);
    this.form.updateValueAndValidity();
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const verbaliSipi = new VerbaliSipi(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: verbaliSipi,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
