import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import {
  ProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow,
  RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-rilevazione-progettualita-sviluppo-linguaggi-espressivi-creativita',
  templateUrl: './rilevazione-progettualita-sviluppo-linguaggi-espressivi-creativita.component.html',
  styleUrls: ['./rilevazione-progettualita-sviluppo-linguaggi-espressivi-creativita.component.scss'],
})
export class RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativitaComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button')
  buttonElementRef: ElementRef;

  @ViewChild(MatTable, {static: false}) table: MatTable<any>;

  unsubscribe$: Subject<void> = new Subject();

  progettiRealizzatiDisplayedColumns = ['progettazione', 'proposte', 'documentazione', 'azioni'];

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita {
    let content = this.strumentoDiRegistrazioneArchivio.content as RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita;

    return content;
  }

  form: FormGroup;

  get formProgettiRealizzati(): FormArray {
    return this.form.get('progettiRealizzati') as FormArray;
  }

  ngOnInit() {
    this.form = this.buildForm();
    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      numeroProgettiRealizzati: new FormControl(this.content && this.content.numeroProgettiRealizzati ? this.content.numeroProgettiRealizzati : 0),

      progettiRealizzati: this.content && this.content.progettiRealizzati ? this.buildFormProgettiRealizzati(this.content.progettiRealizzati) : new FormArray([]),
    });

    return form;
  }

  buildFormProgettiRealizzati(progettiRealizzati: ProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow[]): FormArray {
    const form = new FormArray(progettiRealizzati.map((value) => this.buildFormProgettoRealizzato(value)));

    return form;
  }

  buildFormProgettoRealizzato(progettoRealizzato: ProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow): FormGroup {
    const form = new FormGroup({

      progettazione: new FormControl(progettoRealizzato.progettazione ? progettoRealizzato.progettazione : null),
      proposte: new FormControl(progettoRealizzato.proposte ? progettoRealizzato.proposte : null),
      documentazione: new FormControl(progettoRealizzato.documentazione ? progettoRealizzato.documentazione : null),

    });

    return form;
  }

  updateForm(entity: RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  addProgettoRealizzato() {
    this.formProgettiRealizzati.push(this.buildFormProgettoRealizzato(new ProgettiRealizzatiAttivitaSviluppoLinguaggiEspressiviCreativitaRow()));
    this.form.get('numeroProgettiRealizzati').setValue(this.formProgettiRealizzati.value.length);
    this.table.renderRows();
  }
  
  deleteProgettoRealizzato(index: number) {
    this.formProgettiRealizzati.removeAt(index);
    this.form.get('numeroProgettiRealizzati').setValue(this.formProgettiRealizzati.value.length);
    this.table.renderRows();
  }

  submit(form: FormGroup) {
    if (form.valid) {

      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const rilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita = new RilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: rilevazioneProgettualitaSviluppoLinguaggiEspressiviCreativita,
      });
      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
