
export interface IRegistrazioniSmarrimentiOggettiBambini {
  data: Date;
  oggetto: string;
  valore: string;
  provvedimenti: string;

}

export class RegistrazioniSmarrimentiOggettiBambini implements IRegistrazioniSmarrimentiOggettiBambini {
  data: Date;
  oggetto: string;
  valore: string;
  provvedimenti: string;


  constructor(params: Partial<IRegistrazioniSmarrimentiOggettiBambini> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.data) {
      this.data = new Date(params.data);
    }

  }

  deconstructor(): IRegistrazioniSmarrimentiOggettiBambini {
    return Object.assign({}, this);
  }
}
