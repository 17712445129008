import { Allegato, IAllegato } from './Allegato';
import {
  AnnoScolasticoPeriodoFormativo,
  IAnnoScolasticoPeriodoFormativo,
} from './AnnoScolasticoPeriodoFormativo';
import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { IPersonaFisica, PersonaFisica } from './PersonaFisica';
import { IUtente, Utente } from './Utente';

export enum EnumTipoSegnalazione {
  DietaBambino = 'dietaBambino',
  PrenotazionePasto = 'prenotazionePasto',
}

export enum EnumStatoSegnalazione {
  Nuova = 'nuova',
  Completata = 'completata',
  PresaInCarico = 'presaincarico',
  Chiusa = 'chiusa',
}

export interface ISegnalazione extends IBaseBusinessObject {
  descrizioneSegnalazione: string;
  noteGestioneSegnalazione: string;
  stato: EnumStatoSegnalazione;
  tipo: EnumTipoSegnalazione;

  /**
   * Relazioni
   */
  allegati: IAllegato[];
  effettuataDa: IUtente;
  presaInCaricoDa: IUtente;
  riferitaAAnnoScolastico: IAnnoScolasticoPeriodoFormativo;
  riferitaAPersona: IPersonaFisica;
}

export class Segnalazione extends BaseBusinessObject implements ISegnalazione {
  descrizioneSegnalazione: string;
  noteGestioneSegnalazione: string;
  stato: EnumStatoSegnalazione;
  tipo: EnumTipoSegnalazione;

  /**
   * Relazioni
   */
  allegati: Allegato[];
  effettuataDa: Utente;
  presaInCaricoDa: Utente;
  riferitaAAnnoScolastico: AnnoScolasticoPeriodoFormativo;
  riferitaAPersona: PersonaFisica;

  constructor(params?: Partial<ISegnalazione>) {
    super(params);

    /**
     * Mapping
     */
    if (params.allegati) {
      this.allegati = params.allegati.map((value) => new Allegato(value));
    }
    if (params.effettuataDa) {
      this.effettuataDa = new Utente(params.effettuataDa);
    }
    if (params.presaInCaricoDa) {
      this.presaInCaricoDa = new Utente(params.presaInCaricoDa);
    }
    if (params.riferitaAAnnoScolastico) {
      this.riferitaAAnnoScolastico = new AnnoScolasticoPeriodoFormativo(
        params.riferitaAAnnoScolastico,
      );
    }
    if (params.riferitaAPersona) {
      this.riferitaAPersona = new PersonaFisica(params.riferitaAPersona);
    }
  }

  deconstructor(): ISegnalazione {
    return Object.assign({ }, this);
  }
}
