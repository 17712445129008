<ng-container *transloco="let t; read: 'gestioneProgettazioniSettimanali.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div class="mat-elevation-z1">
      <table mat-table [dataSource]="formProgettazioniPerGiorno.controls" formArrayName="progettazioniPerGiorno">
        <!-- Giorni -->
        <ng-container matColumnDef="giorni">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.giorni') }}
          </th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ 'type.' + element?.value?.nomeGiorno | transloco}}
          </td>
        </ng-container>

        <!-- Giorni -->
        <ng-container matColumnDef="zoneInteresse">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.zoneInteresse')}}
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="zone_di_interesse_osservazine_e_rapprendimento"> </textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Esperienza grande gruppo -->
        <ng-container matColumnDef="esperienzaGrandeGruppo">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.esperienzaGrandeGruppo') }}
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="esperienza_a_grande_gruppo"> </textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Esperienza piccoli gruppi -->
        <ng-container matColumnDef="esperienzaPiccoliGruppi">
          <th mat-header-cell *matHeaderCellDef>
            {{t('table.columns.esperienzaPiccoliGruppi')}}
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="esprerienza_nei_piccoli_gruppi"> </textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Incontri famiglie -->
        <ng-container matColumnDef="incontriFamiglie">
          <th mat-header-cell *matHeaderCellDef>
            {{t('table.columns.incontriFamiglie')}}
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="incontri_con_le_famiglie_ed_il_territorio"> </textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <!-- Formazione e aggiornamento -->
        <ng-container matColumnDef="formazioneEAggiornamento">
          <th mat-header-cell *matHeaderCellDef>
            {{ t('table.columns.formazioneEAggiornamento') }}
          </th>
          <td mat-cell *matCellDef="let element; let i = index" [formGroupName]="i">
            <mat-form-field>
              <textarea matInput formControlName="formazione_e_aggiornamento"> </textarea>
            </mat-form-field>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="progettazioniPerGiornoDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: progettazioniPerGiornoDisplayedColumns"></tr>
      </table>
    </div>
  </form>
</ng-container>
