<ng-container *transloco="let t; read: 'registrazioniSmarrimentoOggettiBambino.strumentoRegistrazione'">
  <form [formGroup]="form" (ngSubmit)="submit()">
    <div fxLayout="column" fxLayoutGap="1em" fxLayoutAlign="start" fxFill>

      <div fxLayout="row" fxLayoutGap="1em">
        <mat-form-field appearance="outline">
          <mat-label>{{t('data')}}</mat-label>
          <input matInput [matDatepicker]="data" formControlName="data">
          <mat-datepicker-toggle matSuffix [for]="data"></mat-datepicker-toggle>
          <mat-datepicker #data></mat-datepicker>
        </mat-form-field>
      </div>

      <mat-form-field fxFill appearance="outline">
        <mat-label>{{ t('oggetto') }}</mat-label>
        <textarea matInput formControlName="oggetto"></textarea>
      </mat-form-field>

      <mat-form-field fxFill appearance="outline">
        <mat-label>{{ t('valore') }}</mat-label>
        <textarea matInput formControlName="valore"></textarea>
      </mat-form-field>

      <mat-form-field fxFill appearance="outline">
        <mat-label>{{ t('provvedimenti') }}</mat-label>
        <textarea matInput formControlName="provvedimenti"></textarea>
      </mat-form-field>

    </div>
    <button #button type="submit" class="d-none"></button>
  </form>
</ng-container>