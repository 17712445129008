import { Injectable } from '@angular/core';
import {
  ActiveState,
  EntityState,
  EntityStore,
  StoreConfig,
} from '@datorama/akita';

import { SharedUiScreen } from '../models/shared-ui-screen.model';

export interface SharedUiScreensState
  extends EntityState<SharedUiScreen>,
    ActiveState { }

export function createInitialState(): SharedUiScreensState {
  return {
    active: null,
  };
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'shared-ui-screens' })
export class SharedUiScreensStore extends EntityStore<SharedUiScreensState> {
  constructor() {
    super(createInitialState());
  }
}
