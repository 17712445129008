import { Domanda, IDomanda } from '../Domanda';
import { StrutturaEducativa } from '@gpi/lbl/shared/entity';
import { GiornoSettimanaStruttura, EnumGiorniSettimana } from '../../GiornoSettimanaStruttura';


export interface IRaccoltaInformazioniSipi {
    noteOrarioFrequenzaBambino: string;
    noteOrarioFrequenzaAdulto: string;

    /**
     * Relazioni
     */
    orarioFrequenzaBambino: IOrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow[];
    organizzazioneOrariaAdulti: IOrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow[];
    domande: IDomanda[];

}

export class RaccoltaInformazioniSipi implements IRaccoltaInformazioniSipi {
    noteOrarioFrequenzaBambino: string;
    noteOrarioFrequenzaAdulto: string;

    /**
     * Relazioni
     */
    orarioFrequenzaBambino: OrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow[];
    organizzazioneOrariaAdulti: OrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow[];
    domande: Domanda[];

    constructor(params: Partial<IRaccoltaInformazioniSipi> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */

        if (params.orarioFrequenzaBambino) {
            this.orarioFrequenzaBambino = params.orarioFrequenzaBambino.map(
                (value) => new OrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow(value),
            );
        }

        if (params.organizzazioneOrariaAdulti) {
            this.organizzazioneOrariaAdulti = params.organizzazioneOrariaAdulti.map(
                (value) => new OrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow(value),
            );
        }

        if (params.domande) {
            this.domande = params.domande.map((value) => new Domanda(value));
        }
    }

    static InitContent(strutturaEducativaSelezionataInContesto: StrutturaEducativa): RaccoltaInformazioniSipi {
        const content = new RaccoltaInformazioniSipi();

        const testiDomande = [
            'Routine definite per lo scambio quotidiano e/o abituale delle informazioni (da compilare alla luce dei verbali)',
            'L\'organizzazione e le caratteristiche principali dello spazio interno dell\'aula e dello spazio esterno',
            'Il contesto scolastico (principali caratteristiche, vincoli e relazioni che hanno un impatto sulla situazione di cui tenere conto)',
            'Cambiamenti avvenuti durante l\'anno scolastico',
            'Risorse e/o servizi sul territorio'
        ];

        content.domande = testiDomande.map((value, index) => {
            return new Domanda({
                codiceDomanda: `RaccoltaInformazioniSipi_${index}`,
                rispostaDomanda: '',
                testoDomanda: value,
            });
        });
        content.orarioFrequenzaBambino = strutturaEducativaSelezionataInContesto.orarioApertura.map((giornoConfiguratoPerStruttura) => {
            return new OrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow({ nomeGiorno: giornoConfiguratoPerStruttura.nomeGiorno });
        });

        if (content.organizzazioneOrariaAdulti) {
            content.organizzazioneOrariaAdulti.map((organizzazioneOrario) =>
                organizzazioneOrario.orarioFrequenza = strutturaEducativaSelezionataInContesto.orarioApertura
            );
        }
        
        return content;
    }

    deconstructor(): IRaccoltaInformazioniSipi {
        return Object.assign({}, this);
    }
}

export interface IOrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow {
    nomeGiorno: EnumGiorniSettimana;
    orarioMattinoDal: string;
    orarioMattinoAl: string;
    orarioPomeriggioDal: string;
    orarioPomeriggioAl: string;
}

export class OrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow implements IOrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow {
    nomeGiorno: EnumGiorniSettimana;
    orarioMattinoDal: string;
    orarioMattinoAl: string;
    orarioPomeriggioDal: string;
    orarioPomeriggioAl: string;

    constructor(params: Partial<IOrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
    }

    deconstructor(): IOrarioFrequenzaServizioBambinoRaccoltaInformazioniSipiRow {
        return Object.assign({}, this);
    }
}

export interface IOrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow {
    nome: string;
    cognome: string;
    funzione: string;
    orarioFrequenza: GiornoSettimanaStruttura[];
    ruoloOAzione: string;
    momentoRuoloOAzione: string;
}

export class OrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow implements IOrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow {
    nome: string;
    cognome: string;
    funzione: string;
    orarioFrequenza: GiornoSettimanaStruttura[];
    ruoloOAzione: string;
    momentoRuoloOAzione: string;

    constructor(params: Partial<IOrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
        if (params.orarioFrequenza) {
            this.orarioFrequenza = params.orarioFrequenza.map(
                (value) => new GiornoSettimanaStruttura(value),
            );
        }
    }

    deconstructor(): IOrganizzazioneOrariaServizioAdultiRaccoltaInformazioniSipiRow {
        return Object.assign({}, this);
    }
}
