export interface IGestioneSchedaControlloPulizie {
  data: Date;
  ora: string;

  /**
   * Relazioni
   */
  listaControlli: IGestioneSchedaControlloPulizieRow[];
}

export class GestioneSchedaControlloPulizie {
  data: Date;
  ora: string;

  /**
   * Relazioni
   */
  listaControlli: GestioneSchedaControlloPulizieRow[];

  constructor(params: Partial<IGestioneSchedaControlloPulizie> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.data) {
      this.data = new Date(params.data);
    }

    if (params.listaControlli) {
      this.listaControlli = params.listaControlli.map((value) => new GestioneSchedaControlloPulizieRow(value));
    }
  }

  static InitContent(): GestioneSchedaControlloPulizie {
    const content = new GestioneSchedaControlloPulizie();

    // i testi sono qui solo a scopo informativo e corrispondo alle stringhe identificative
    // di testiControlli utili per le traduzioni
    // test -> [
    //   'Angoli e bordi: assenza di accumuli di sporco',
    //   'Superficie calpestata: assenza di macchie o sporcizia libera; assenza di scivolosità',
    //   'Trattamento: assenza di segni di usura; assenza di scivolosità',
    //   'Vetri, infissi e davanzali: assenza di polvere, macchie e incrostazioni',
    //   'Tapparelle e cassonetti: assenza di polvere e macchie',
    //   'Veneziane: assenza di \rpolvere e macchie',
    //   'Porte e rivestimenti assenza di polvere dagli stipiti e di macchie o incrostazioni',
    //   'Controsoffittature e soffitti: assenza di macchie o accumuli di sporco o ragnatele',
    //   'Canalette o tubi impiantistica: assenza di polvere e macchie',
    // ];

    const testiControlli = [
      'testiControlli_0',
      'testiControlli_1',
      'testiControlli_2',
      'testiControlli_3',
      'testiControlli_4',
      'testiControlli_5',
      'testiControlli_6',
      'testiControlli_7',
      'testiControlli_8',
    ];

    content.listaControlli = testiControlli.map((value) => {
      return new GestioneSchedaControlloPulizieRow({
        descrizioneControllo: value,
      });
    });

    return content;
  }

  deconstructor(): IGestioneSchedaControlloPulizie {
    return Object.assign({}, this);
  }
}

export interface IGestioneSchedaControlloPulizieRow {
  conforme: boolean;
  dataProvvedimento: Date;
  descrizioneControllo: string;
  osservazioni: string;
  provvedimento: string;
}

export class GestioneSchedaControlloPulizieRow implements IGestioneSchedaControlloPulizieRow {
  conforme: boolean;
  dataProvvedimento: Date;
  descrizioneControllo: string;
  osservazioni: string;
  provvedimento: string;

  constructor(params: Partial<IGestioneSchedaControlloPulizieRow> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.dataProvvedimento) {
      this.dataProvvedimento = new Date(params.dataProvvedimento);
    }
  }

  deconstructor(): IGestioneSchedaControlloPulizieRow {
    return Object.assign({}, this);
  }
}
