import { AfterViewChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AllegatiService } from '@gpi/lbl/app/data-access/allegati';
import { ContenutoChioscoLBLArchivio } from '@gpi/lbl/shared/entity';
import { SharedUiScreensService } from '@gpi/shared/ui/screens';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'lbl-app-ui-contenuti-chiosco-viewer',
  templateUrl: 'contenuti-chiosco-viewer.component.html',
  styleUrls: ['contenuti-chiosco-viewer.component.scss'],
})
export class ContenutiChioscoViewerComponent implements OnInit, AfterViewChecked {

  @Input()
  list: ContenutoChioscoLBLArchivio[] = [];
  @Input()
  showType = false;

  itemSelected: ContenutoChioscoLBLArchivio;

  isMobile: boolean;

  constructor(private screensService: SharedUiScreensService, private allegatiService: AllegatiService, private cdRef: ChangeDetectorRef) { }

  ngOnInit() {
    this.screensService.query.selectActive().pipe(
      tap((screen) =>
        this.isMobile = screen.cssClass === 'xs' || screen.cssClass === 'sm',
      ),
    ).subscribe();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  selectedItem(item: ContenutoChioscoLBLArchivio) {
    if (this.isMobile) {
      if (item && item.fileAllegato) {
        this.allegatiService.download(item.fileAllegato);
      }
    } else {
      this.itemSelected = new ContenutoChioscoLBLArchivio(item);
    }
  }
}
