import { Allegato, IAllegato } from './Allegato';
import {
  GiornoSettimanaStruttura,
  IGiornoSettimanaStruttura,
} from './GiornoSettimanaStruttura';
import {
  IModuloLBLAttivatoPerStruttura,
  ModuloLBLAttivatoPerStruttura,
} from './ModuloLBLAttivatoPerStruttura';
import { INumeroSettimanaMenuStruttura, NumeroSettimanaMenuStruttura } from './NumeroSettimanaMenuStruttura';
import { IPastoMenuStruttura, PastoMenuStruttura } from './PastoMenuStruttura';
import { IPersonaGiuridica, PersonaGiuridica } from './PersonaGiuridica';
import { IServizioEducativo, ServizioEducativo } from './ServizioEducativo';

export interface IStrutturaEducativa extends IPersonaGiuridica {
  numeroSettimaneMenu: number;

  /**
   * Relazioni
   */
  immagine: IAllegato;
  moduliAttivatiStruttura: IModuloLBLAttivatoPerStruttura[];
  orarioApertura: IGiornoSettimanaStruttura[];
  pastiPrevisti: IPastoMenuStruttura[];
  serviziEducativi: IServizioEducativo[];
  settimaneMenu: INumeroSettimanaMenuStruttura[];

  documentiHelpdesk: IAllegato[];
}

export class StrutturaEducativa extends PersonaGiuridica
  implements IStrutturaEducativa {
  numeroSettimaneMenu: number;

  /**
   * Relazioni
   */
  immagine: Allegato;
  moduliAttivatiStruttura: ModuloLBLAttivatoPerStruttura[];
  orarioApertura: GiornoSettimanaStruttura[];
  pastiPrevisti: PastoMenuStruttura[];
  serviziEducativi: ServizioEducativo[];
  settimaneMenu: NumeroSettimanaMenuStruttura[];

  documentiHelpdesk: Allegato[];

  constructor(params?: Partial<IStrutturaEducativa>) {
    super(params);

    /**
     * Mapping
     */
    if (params.immagine) {
      this.immagine = new Allegato(params.immagine);
    }
    if (params.moduliAttivatiStruttura) {
      this.moduliAttivatiStruttura = params.moduliAttivatiStruttura.map(
        (value) => new ModuloLBLAttivatoPerStruttura(value),
      );
    }
    if (params.orarioApertura) {
      this.orarioApertura = params.orarioApertura.map(
        (value) => new GiornoSettimanaStruttura(value),
      );
    }
    if (params.pastiPrevisti) {
      this.pastiPrevisti = params.pastiPrevisti.map(
        (value) => new PastoMenuStruttura(value),
      );
    }
    if (params.serviziEducativi) {
      this.serviziEducativi = params.serviziEducativi.map(
        (value) => new ServizioEducativo(value),
      );
    }
    if (params.settimaneMenu) {
      this.settimaneMenu = params.settimaneMenu.map(
        (value) => new NumeroSettimanaMenuStruttura(value),
      );
    }
    if(params.documentiHelpdesk){
      this.documentiHelpdesk = params.documentiHelpdesk.map(value => new Allegato(value));
    }
  }

  deconstructor(): IStrutturaEducativa {
    return Object.assign({ }, this);
  }
}
