import { Allegato, IAllegato } from './Allegato';
import {
  AnnoScolasticoPeriodoFormativo,
  IAnnoScolasticoPeriodoFormativo,
} from './AnnoScolasticoPeriodoFormativo';
import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { IPersonaFisica, PersonaFisica } from './PersonaFisica';
import {
  ISezioneAnnoScolasticoPeriodoFormativo,
  SezioneAnnoScolasticoPeriodoFormativo,
} from './SezioneAnnoScolasticoPeriodoFormativo';

export enum EnumTipoContenutoChiosco {
  Pubblicazione = 'Pubblicazione',
  Verbale = 'Verbale',
  Presentazione = 'Presentazione',
  Newsletter = 'Newsletter',
  Evento = 'Evento',
  DiarioQuotidiano = 'DiarioQuotidiano',
  IdentitaStruttura = 'IdentitaStruttura',
}

export interface IContenutoChioscoLBLArchivio extends IBaseBusinessObject {
  dataFinePubblicazione: Date;
  dataInizioPubblicazione: Date;
  tipo: EnumTipoContenutoChiosco;
  titolo: string;
  descrizione: string;

  /**
   * Relazioni
   */
  anniScolastici: IAnnoScolasticoPeriodoFormativo[];
  bambiniCoinvolti: IPersonaFisica[];
  fileAllegato: IAllegato;
  immaginePreview: IAllegato;
  sezioni: ISezioneAnnoScolasticoPeriodoFormativo[];
}

export class ContenutoChioscoLBLArchivio extends BaseBusinessObject
  implements IContenutoChioscoLBLArchivio {
  dataFinePubblicazione: Date;
  dataInizioPubblicazione: Date;
  descrizione: string;
  tipo: EnumTipoContenutoChiosco;
  titolo: string;

  /**
   * Relazioni
   */
  anniScolastici: AnnoScolasticoPeriodoFormativo[];
  bambiniCoinvolti: PersonaFisica[];
  fileAllegato: Allegato;
  immaginePreview: Allegato;
  sezioni: SezioneAnnoScolasticoPeriodoFormativo[];

  constructor(params?: Partial<IContenutoChioscoLBLArchivio>) {
    super(params);

    /**
     * Mapping
     */
    if (params.dataFinePubblicazione) {
      this.dataFinePubblicazione = new Date(params.dataFinePubblicazione);
    }
    if (params.dataInizioPubblicazione) {
      this.dataInizioPubblicazione = new Date(params.dataInizioPubblicazione);
    }

    if (params.anniScolastici) {
      this.anniScolastici = params.anniScolastici.map(
        (value) => new AnnoScolasticoPeriodoFormativo(value),
      );
    }
    if (params.bambiniCoinvolti) {
      this.bambiniCoinvolti = params.bambiniCoinvolti.map(
        (value) => new PersonaFisica(value),
      );
    }
    if (params.fileAllegato) {
      this.fileAllegato = new Allegato(params.fileAllegato);
    }
    if (params.immaginePreview) {
      this.immaginePreview = new Allegato(params.immaginePreview);
    }
    if (params.sezioni) {
      this.sezioni = params.sezioni.map(
        (value) => new SezioneAnnoScolasticoPeriodoFormativo(value),
      );
    }
  }

  deconstructor(): IContenutoChioscoLBLArchivio {
    return Object.assign({ }, this);
  }
}
