import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ServiceWorkerModule } from '@angular/service-worker';
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { LblAppUtilAuthModule } from '@gpi/lbl/app/util/auth';
import { LblAppUtilErrorModule } from '@gpi/lbl/app/util/error';
import { LblAppUtilLanguagesModule } from '@gpi/lbl/app/util/languages';
import { LblAppUtilSharedModule } from '@gpi/lbl/app/util/shared';
import { LblAppUtilThemesModule } from '@gpi/lbl/app/util/themes';

import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),

    environment.production ? [] : AkitaNgDevtools.forRoot({ sortAlphabetically: true }),
    AkitaNgRouterStoreModule.forRoot(),

    LblAppUtilAuthModule.forRoot(),
    LblAppUtilSharedModule.forRoot({ environment: environment }),
    LblAppUtilErrorModule.forRoot({ enabled: environment.production || environment.staging }),
    LblAppUtilLanguagesModule.forRoot({
      prodMode: environment.production,
    }),
    LblAppUtilThemesModule.forRoot(),
  ],
  providers: [
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: environment.urls.api },
    },
  ],
})
export class LblAppCoreModule { }
