import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { SharedUiScreensState, SharedUiScreensStore } from './shared-ui-screens.store';

@Injectable({ providedIn: 'root' })
export class SharedUiScreensQuery extends QueryEntity<SharedUiScreensState> {
  constructor(protected store: SharedUiScreensStore) {
    super(store);
  }
}
