import { Allegato, EnumTipologiaAllegato, IAllegato } from './Allegato';
import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { Indirizzo, IIndirizzo } from './Indirizzo';
import { EnumTipologiaDietaMenu } from './Menu';
import { IPersonaFisicaRelazioni, PersonaFisicaRelazioni } from './PersonaFisicaRelazioni';
import { IStrutturaEducativa, StrutturaEducativa } from './StrutturaEducativa';

export enum EnumTipologiaPersonaFisica {
  AmministratoreLBL = 'AmministratoreLBL',
  Atelierista = 'Atelierista',
  Auditor = 'Auditor',
  CoordinatorePedagogico = 'CoordinatorePedagogico',
  Cuoco = 'Cuoco',
  EducatoreInsegnante = 'Educatore_Insegnante',
  ParentiBambino = 'ParentiBambino',
  Bambino = 'Bambino',
  Psicologo = 'Psicologo',
  Logopedista = 'Logopedista',
  Altro = 'Altro',
}

export enum EnumPersonaFisicaGender {
  Maschio = 'M',
  Femmina = 'F',
  NonDisponibile = 'ND',
}

export interface IPersonaFisica extends IBaseBusinessObject {
  codiceFiscale: string;
  cognome: string;
  comuneDiNascita: string;
  dataDiNascita: Date;
  email: string;
  genere: EnumPersonaFisicaGender;
  nome: string;
  provinciaDiNascita: string;
  statoDiNascita: string;
  telefonoCellulare: string;
  telefonoFisso: string;
  telefonoUfficio: string;
  tipologie: EnumTipologiaPersonaFisica[];

  /**
   * DatiPerTipologiaAttoreBambino"
   */
  allergieEIntolleranze: string;
  altriServiziEducativiFrequentati: string;
  dietaSeguita: EnumTipologiaDietaMenu;
  linguaOriginaria: string;
  linguaParlataInFamiglia: string;

  /**
   * DatiPerTipologiaParenteBambino"
   */
  luogoDiLavoro: string;
  orarioDiLavoro: string;
  professione: string;
  titoloDiStudio: string;

  /**
   * Relazioni
   */
  allegati: IAllegato[];
  dettaglioRelazioni: IPersonaFisicaRelazioni[];
  indirizzi: IIndirizzo[];
  coinvoltaInStrutture: IStrutturaEducativa[];
}

export class PersonaFisica extends BaseBusinessObject implements IPersonaFisica {
  codiceFiscale: string;
  cognome: string;
  comuneDiNascita: string;
  dataDiNascita: Date;
  email: string;
  genere: EnumPersonaFisicaGender;
  nome: string;
  provinciaDiNascita: string;
  statoDiNascita: string;
  telefonoCellulare: string;
  telefonoFisso: string;
  telefonoUfficio: string;
  tipologie: EnumTipologiaPersonaFisica[];

  /**
   * DatiPerTipologiaAttoreBambino"
   */
  allergieEIntolleranze: string;
  altriServiziEducativiFrequentati: string;
  dietaSeguita: EnumTipologiaDietaMenu;
  linguaOriginaria: string;
  linguaParlataInFamiglia: string;

  /**
   * DatiPerTipologiaParenteBambino"
   */
  luogoDiLavoro: string;
  orarioDiLavoro: string;
  professione: string;
  titoloDiStudio: string;

  /**
   * Relazioni
   */
  allegati: Allegato[];
  dettaglioRelazioni: PersonaFisicaRelazioni[];
  indirizzi: Indirizzo[];
  coinvoltaInStrutture: StrutturaEducativa[];

  constructor(params?: Partial<IPersonaFisica>) {
    super(params);

    /**
     * Mapping
     */
    if (params.dataDiNascita) {
      this.dataDiNascita = new Date(params.dataDiNascita);
    }

    if (params.allegati) {
      this.allegati = params.allegati.map((value) => new Allegato(value));
    }
    if (params.dettaglioRelazioni) {
      this.dettaglioRelazioni = params.dettaglioRelazioni.map((value) => new PersonaFisicaRelazioni(value));
    }
    if (params.indirizzi) {
      this.indirizzi = params.indirizzi.map((value) => new Indirizzo(value));
    }
    if (params.coinvoltaInStrutture) {
      this.coinvoltaInStrutture = params.coinvoltaInStrutture.map((value) => new StrutturaEducativa(value));
    }
  }

  deconstructor(): IPersonaFisica {
    return Object.assign({ }, this);
  }

  get fullName(): string {
    return `${this.nome} ${this.cognome}`;
  }
  get initials(): string {
    return `${this.nome[0]}${this.cognome[0]}`;
  }
  get avatar(): Allegato {
    return this.allegati?.find((allegato) => allegato.tipologia === EnumTipologiaAllegato.ImmaginePersonaFisica);
  }
  get ageInMonths(): string {
    const from = this.dataDiNascita;
    const to = new Date();

    let months = 0;
    months = (to.getFullYear() - from.getFullYear()) * 12;
    months -= from.getMonth();
    months += to.getMonth();
    months = months <= 0 ? 0 : months;

    return months.toString();
  }
}
