
export interface IDomanda {
  codiceDomanda: string;
  rispostaDomanda: string;
  testoDomanda: string;
}

export class Domanda implements IDomanda {
  public codiceDomanda: string;
  public rispostaDomanda: string;
  public testoDomanda: string;

  constructor(params?: Partial<IDomanda>) {
    if (!params) {
      return;
    }

    Object.assign(this, params);
  }

  deconstructor(): IDomanda {
    return Object.assign({}, this);
  }
}
