import { Inject, Injectable } from '@angular/core';
import { filterStore, NgEntityService, NgEntityServiceLoader, NgEntityServiceNotifier } from '@datorama/akita-ng-entity-service';
import {
  Allegato,
  EnumAllegatiTabellaDiProvenienza,
  EnumTipologiaAllegato,
  ENVIRONMENT,
  IAllegato,
  IEnvironment,
} from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AllegatiQuery } from './allegati.query';
import { AllegatiState, AllegatiStore } from './allegati.store';

@Injectable({ providedIn: 'root' })
export class AllegatiService extends NgEntityService<AllegatiState> {
  loaders = this.loaderService.loadersFor('lbl-app-data-access-allegati');
  notifiers$ = this.notifierService.action$.pipe(
    filterStore('lbl-app-data-access-allegati'),
  );

  constructor(
    @Inject(ENVIRONMENT)
    private environment: IEnvironment,
    public store: AllegatiStore,
    public query: AllegatiQuery,
    protected loaderService: NgEntityServiceLoader,
    protected notifierService: NgEntityServiceNotifier,
  ) {
    super(store);
  }

  download(allegato: Allegato) {
    const url = allegato.link;

    window.open(url);
  }

  upload(allegato: Allegato, file: File): Observable<Allegato> {
    const body = new FormData();
    body.append('rowId', allegato.tableRowId);
    body.append('tabellaDiProvenienza', allegato.tabellaDiProvenienza);
    body.append('tipologiaAllegato', allegato.tipologia);
    body.append('uploadfile', file);
    
    if(allegato.titolo) body.append('titolo', allegato.titolo);
    if(allegato.descrizione) body.append('descrizione', allegato.descrizione);

    return this.add<IAllegato>(body as any, {
      url: `${this.environment.urls.api}/allegati/upload`,
    }).pipe(map((entity) => new Allegato(entity)));
  }

  deleteById(id: number): Observable<void> {
    return this.delete<void>(id, {
      url: `${this.environment.urls.api}/allegati/${id}`,
    });
  }
}
