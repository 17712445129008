import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { EnumModuliLBL, FunzionalitaLBL, StrumentoDiRegistrazioneArchivio } from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  FunzionalitaLblState,
  FunzionalitaLblStore,
} from './funzionalita-lbl.store';

@Injectable({ providedIn: 'root' })
export class FunzionalitaLblQuery extends QueryEntity<FunzionalitaLblState> {
  constructor(protected store: FunzionalitaLblStore) {
    super(store);
  }

  readAll(): Observable<FunzionalitaLBL[]> {
    return this.selectAll().pipe(
      map((entities) => entities.map((entity) => new FunzionalitaLBL(entity))),
    );
  }

  readMany(tipologia: EnumModuliLBL): Observable<FunzionalitaLBL[]> {
    return this.readAll().pipe(
      map((entities) =>
        entities.filter((entity) => entity.moduloDiRiferimento === tipologia),
      ),
    );
  }

  readById(id: number): Observable<FunzionalitaLBL> {
    return this.selectEntity(id).pipe(map((entity) => entity ? new FunzionalitaLBL(entity) : null));
  }

}
