import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { AnnoScolasticoPeriodoFormativo, IAnnoScolasticoPeriodoFormativo } from '@gpi/lbl/shared/entity';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  AnniScolasticiState,
  AnniScolasticiStore,
} from './anni-scolastici.store';

@Injectable({ providedIn: 'root' })
export class AnniScolasticiQuery extends QueryEntity<AnniScolasticiState> {
  constructor(protected store: AnniScolasticiStore) {
    super(store);
  }

  readAll(): Observable<AnnoScolasticoPeriodoFormativo[]> {
    return this.selectAll().pipe(
      map((entities: AnnoScolasticoPeriodoFormativo[]) =>
        entities.map((entity) => new AnnoScolasticoPeriodoFormativo(entity)),
      ),
    );
  }

  readById(id: number): Observable<AnnoScolasticoPeriodoFormativo> {
    return this.selectEntity(id).pipe(
      map((entity) => new AnnoScolasticoPeriodoFormativo(entity)),
    );
  }

}
