import { Injectable, OnDestroy } from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SharedUiScreenEnum } from '../models/shared-ui-screen.enum';

import { SharedUiScreensQuery } from './shared-ui-screens.query';
import { SharedUiScreensStore } from './shared-ui-screens.store';

@Injectable({ providedIn: 'root' })
export class SharedUiScreensService implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject();

  constructor(
    public query: SharedUiScreensQuery,
    public store: SharedUiScreensStore,
    private mediaObserver: MediaObserver,
  ) {
    this.init();
  }

  private init() {
    this.setDefaults();

    this.listeners();
  }

  private setDefaults() {
    this.store.set([
      {
        id: 0,

        name: 'Extra Small',
        description: 'Default extra small screen',

        cssClass: SharedUiScreenEnum.XS,
      },
      {
        id: 1,

        name: 'Small',
        description: 'Default small screen',

        cssClass: SharedUiScreenEnum.SM,
      },
      {
        id: 2,

        name: 'Medium',
        description: 'Default medium screen',

        cssClass: SharedUiScreenEnum.MD,
      },
      {
        id: 3,

        name: 'Large',
        description: 'Default large screen',

        cssClass: SharedUiScreenEnum.LG,
      },
      {
        id: 4,

        name: 'Extra large',
        description: 'Default extra large screen',

        cssClass: SharedUiScreenEnum.XL,
      },
    ]);

    this.store.setActive(0);
  }

  private listeners() {
    this.mediaObserver
      .asObservable()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((mediaChanges) => {
        const screens = this.query.getAll();
        let active = this.query.getActive();
        mediaChanges.forEach((mediaChange) => {
          const matchingScreen = screens.find((screen) => screen.cssClass === mediaChange.mqAlias);
          if (matchingScreen) {
            active = matchingScreen;
          }
        });

        this.store.setActive(active.id);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
