import { NgModule } from '@angular/core';
import {
  ExtraOptions,
  PreloadAllModules,
  RouterModule,
  Routes,
} from '@angular/router';
import { LblAppUtilSharedModule } from '@gpi/lbl/app/util/shared';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@gpi/lbl/app/feature/shell').then(
        (m) => m.LblAppFeatureShellModule,
      ),
  },
];
const config: ExtraOptions = {
  initialNavigation: true,
  preloadingStrategy: PreloadAllModules,
  urlUpdateStrategy: 'eager',
  onSameUrlNavigation: 'reload',
};

@NgModule({
  imports: [LblAppUtilSharedModule, RouterModule.forRoot(routes, config)],
})
export class LblAppRoutingModule { }
