import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { EnumModuliLBL } from './ModuloLBLAttivatoPerStruttura';
import { IRuoloApplicativo, RuoloApplicativo } from './RuoloApplicativo';
import { IStrumentoDiRegistrazioneTemplateAccessibileDaRuoli, StrumentoDiRegistrazioneTemplateAccessibleDaRuoli } from './StrumentoDiRegistrazioneTemplateAccessibileDaRuoli';
import {
  IStrumentoDiRegistrazioneTemplateBody,
  StrumentoDiRegistrazioneTemplateBody,
} from './StrumentoDiRegistrazioneTemplateBody';

export enum EnumFrequenzaCompilazione {
  Giornaliera = 'giornaliera',
  Settimanale = 'settimanale',
  Trimestrale = 'trimestrale',
  Annuale = 'annuale',
}

export interface IStrumentoDiRegistrazioneTemplate extends IBaseBusinessObject {
  enableIsRelativoAPersonaSelector: boolean;
  enableIsRelativoAServizioEducativoSelector: boolean;
  enableIsRelativoASezioneSelector: boolean;
  frequenzaCompilazione: EnumFrequenzaCompilazione;
  moduloDiRiferimento: EnumModuliLBL;
  notificaNumeroGiorniPrimaDellaScadenzaValidita: number;
  numeroAnniDiValidita: number;
  numeroCompilazioniNecessarie: number;
  isRelativoAPersona: boolean;
  isRelativoAInsegnante: boolean;
  isRelativoAServizioEducativo: boolean;
  isRelativoASezione: boolean;
  prevedeApprovazione: boolean;
  prevedeNumeroCompilazioniFinito: boolean;

  /**
   * Relazioni
   */
  accessibileDaRuoli: IStrumentoDiRegistrazioneTemplateAccessibileDaRuoli[];
  templateBodies: IStrumentoDiRegistrazioneTemplateBody[];
}

export class StrumentoDiRegistrazioneTemplate extends BaseBusinessObject
  implements IStrumentoDiRegistrazioneTemplate {
  enableIsRelativoAPersonaSelector: boolean;
  enableIsRelativoAServizioEducativoSelector: boolean;
  enableIsRelativoASezioneSelector: boolean;
  frequenzaCompilazione: EnumFrequenzaCompilazione;
  moduloDiRiferimento: EnumModuliLBL;
  notificaNumeroGiorniPrimaDellaScadenzaValidita: number;
  numeroAnniDiValidita: number;
  numeroCompilazioniNecessarie: number;
  isRelativoAPersona: boolean;
  isRelativoAInsegnante: boolean;
  isRelativoAServizioEducativo: boolean;
  isRelativoASezione: boolean;
  prevedeApprovazione: boolean;
  prevedeNumeroCompilazioniFinito: boolean;
  isRelativoAPersonaProprietaria: boolean;
  isAnonymous:boolean;

  /**
   * Relazioni
   */
  accessibileDaRuoli: StrumentoDiRegistrazioneTemplateAccessibleDaRuoli[];
  templateBodies: StrumentoDiRegistrazioneTemplateBody[];

  constructor(params?: Partial<IStrumentoDiRegistrazioneTemplate>) {
    super(params);

    /**
     * Mapping
     */
    if (params.accessibileDaRuoli) {
      this.accessibileDaRuoli = params.accessibileDaRuoli.map(
        (value) => new StrumentoDiRegistrazioneTemplateAccessibleDaRuoli(value),
      );
    }
    if (params.templateBodies) {
      this.templateBodies = params.templateBodies.map(
        (value) => new StrumentoDiRegistrazioneTemplateBody(value),
      );
    }
  }

  deconstructor(): IStrumentoDiRegistrazioneTemplate {
    return Object.assign({}, { ...this });
  }
}
