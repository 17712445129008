import { ErrorHandler, Injectable } from '@angular/core';

import { FrontendError } from '../models/frontend-error.model';
import { ErrorService } from '../state/error.service';

@Injectable()
export class FrontendErrorHandler implements ErrorHandler {
  constructor(private errorService: ErrorService) { }

  handleError(error) {
    if (error instanceof FrontendError) {
      this.errorService.store.setError(error);
    }
  }
}
