import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';

import { ErrorState, ErrorStore } from './error.store';

@Injectable({ providedIn: 'root' })
export class ErrorQuery extends Query<ErrorState> {
  error$ = this.selectError();

  constructor(protected store: ErrorStore) {
    super(store);
  }
}
