import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  Type,
  ViewChild,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import {
  RilevazionePartecipazioneFamiglie,
  RilevazionePartecipazioneFamiglieRow,
  SezioneAnnoScolasticoPeriodoFormativo,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-rilevazione-partecipazione-famiglie',
  templateUrl: './rilevazione-partecipazione-famiglie.component.html',
  styleUrls: ['./rilevazione-partecipazione-famiglie.component.scss'],
})
export class RilevazionePartecipazioneFamiglieComponent implements OnInit, OnChanges, OnDestroy {
  @Input() strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input() strutturaEducativa: StrutturaEducativa;
  @Input() submit$: Observable<void>;
  @Output() updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button') buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  sezioniArray: SezioneAnnoScolasticoPeriodoFormativo[] = [];

  COLUMNS1 = [
    'incontriSezione',
    'laboratori',
    'feste',
  ];
  COLUMNS2 = [
    'incontriConsiglio',
    'serateATema',
    'altro'
  ];
  COLUMNS = [];
  bamniniArray = {}
  form: FormGroup;

  constructor() {
    this.COLUMNS = this.COLUMNS.concat(this.COLUMNS1, this.COLUMNS2)
  }

  getDocForm(sezione: string, titolo: string) {
    return this.form.get([sezione, titolo]) as FormArray;
  }

  getData(sezione, col, i) {
    let type: string;
    this.form.get([sezione, col, i, 'data']).value == null ? type = 'text' : type = 'date'

    return type

  }

  get sezioniForm() {
    return this.form.get('sezioni') as FormArray;
  }

  ngOnInit() {
    this.strumentiDiRegistrazioneArchivio.forEach((el) => el.sezioni.forEach((sezione) => {
      this.sezioniArray.push(sezione);
      this.bamniniArray[sezione.nome] = (sezione.bambini.length);
    }))

    this.form = this.buildForm();

    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      changes.strumentiDiRegistrazioneArchivio.currentValue.forEach((element, i) => {
        if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.getContent(i)) {
          this.form = this.updateForm(this.getContent(i));
        }
      });
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  updateForm(entity: RilevazionePartecipazioneFamiglie): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getContent(index: number): RilevazionePartecipazioneFamiglie {
    let content = this.strumentiDiRegistrazioneArchivio[index].content as RilevazionePartecipazioneFamiglie;
    if (!content) {
      content = RilevazionePartecipazioneFamiglie.InitContent();
    }

    return content;
  }

  buildForm(): FormGroup {
    const formGroup: FormGroup = new FormGroup({});

    this.sezioniArray.forEach((el, i) => {
      const form = new FormGroup({
        incontriSezione: this.buildFormRilevazionePartecipazioneFamiglie(this.getContent(i)?.incontriSezione),
        totRowincontriSezione: new FormControl(this.getContent(i)?.totRowincontriSezione),
        totFamiglieincontriSezione: new FormControl(this.getContent(i)?.totFamiglieincontriSezione),

        laboratori: this.buildFormRilevazionePartecipazioneFamiglie(this.getContent(i)?.laboratori),
        totRowlaboratori: new FormControl(this.getContent(i)?.totRowlaboratori),
        totFamiglielaboratori: new FormControl(this.getContent(i)?.totFamiglielaboratori),

        feste: this.buildFormRilevazionePartecipazioneFamiglie(this.getContent(i)?.feste),
        totRowfeste: new FormControl(this.getContent(i)?.totRowfeste),
        totFamigliefeste: new FormControl(this.getContent(i)?.totFamigliefeste),

        incontriConsiglio: this.buildFormRilevazionePartecipazioneFamiglie(this.getContent(i)?.incontriConsiglio),
        totRowincontriConsiglio: new FormControl(this.getContent(i)?.totRowincontriConsiglio),
        totFamiglieincontriConsiglio: new FormControl(this.getContent(i)?.totFamiglieincontriConsiglio),

        serateATema: this.buildFormRilevazionePartecipazioneFamiglie(this.getContent(i)?.serateATema),
        totRowserateATema: new FormControl(this.getContent(i)?.totRowserateATema),
        totFamiglieserateATema: new FormControl(this.getContent(i)?.totFamiglieserateATema),

        altro: this.buildFormRilevazionePartecipazioneFamiglie(this.getContent(i)?.altro),
        totRowaltro: new FormControl(this.getContent(i)?.totRowaltro),
        totFamigliealtro: new FormControl(this.getContent(i)?.totFamigliealtro),


        totFamigliePresentiIncontriSezione: new FormControl(this.getContent(i)?.totFamigliePresentiIncontriSezione),
        partecipazioneMediaIncontriSezione: new FormControl(this.getContent(i)?.partecipazioneMediaIncontriSezione),

        totIniziativeFamigliePresenti: new FormControl(this.getContent(i)?.totIniziativeFamigliePresenti),
        // partecipazioneMediaIniziativeFamiglie: new FormControl(this.getContent(i)?.partecipazioneMediaIniziativeFamiglie),

      }, { updateOn: 'blur' });

      const formControlFields = [];
      formControlFields.push({ name: el.nome })

      formControlFields.forEach(f => formGroup.addControl(f.name, form));
      this.form = new FormGroup({ sezioni: formGroup });
    });
    return formGroup;
  }

  buildFormRilevazionePartecipazioneFamiglie(rilevazionePartecipazioneFamiglieRow: RilevazionePartecipazioneFamiglieRow[]): FormArray {
    const formArray = new FormArray(rilevazionePartecipazioneFamiglieRow.map((value) => this.buildFormRilevazionePartecipazioneFamiglieRow(value)));

    return formArray;
  }

  buildFormRilevazionePartecipazioneFamiglieRow(rilevazionePartecipazioneFamiglieRow: RilevazionePartecipazioneFamiglieRow): FormGroup {
    const form = new FormGroup({
      data: new FormControl(rilevazionePartecipazioneFamiglieRow.data),
      descrizione: new FormControl(rilevazionePartecipazioneFamiglieRow.descrizione),
      numeroFamiglie: new FormControl(rilevazionePartecipazioneFamiglieRow.numeroFamiglie),

    });

    return form;
  }

  addrow(sezione: string, column: string) {
    let newCell = this.form.get([sezione, column]) as FormArray
    newCell.push(this.buildFormRilevazionePartecipazioneFamiglieRow(new RilevazionePartecipazioneFamiglieRow()))

    this.form.get([sezione, 'totRow' + column]).setValue(newCell.length)

    this.totIncontriIniziativeFamiglie(sezione);
  }

  removeRow(sezione: string, column: string, index: number) {
    let removeCell = this.form.get([sezione, column]) as FormArray
    removeCell.removeAt(index);

    this.form.get([sezione, 'totRow' + column]).setValue(removeCell.length)

    this.calcolatotFamiglie(sezione, column);
    this.totIncontriIniziativeFamiglie(sezione);
    this.calcoloMediaPartecipanti(sezione)

  }

  totIncontriIniziativeFamiglie(sezione: string) {
    let arrayNum = []

    this.COLUMNS.forEach(el => {
      if (el != 'incontriSezione') {
        let count = this.form.get([sezione, el]).value
        arrayNum.push(count.length)
      }
    })
    let sum = arrayNum.reduce((acc, cur) => acc + cur, 0);
    this.form.get([sezione, 'totIniziativeFamigliePresenti']).setValue(sum);


    let sum2 = this.form.get([sezione, 'totRowincontriSezione']).value;
    this.form.get([sezione, 'totFamigliePresentiIncontriSezione']).setValue(sum2);


    this.calcoloMediaPartecipanti(sezione)
  }

  calcolatotFamiglie(sezione: string, column: string) {
    let newCell = this.form.get([sezione, column]).value
    let arrayNum = []

    if (newCell.length) {
      newCell.forEach((el: RilevazionePartecipazioneFamiglieRow) => {
        arrayNum.push(el.numeroFamiglie)
      })
      let sum = arrayNum.reduce((acc, cur) => acc + cur, 0);
      this.form.get([sezione, 'totFamiglie' + column]).setValue(sum)
    } else {
      this.form.get([sezione, 'totFamiglie' + column]).setValue(null)
    }
    this.calcoloMediaPartecipanti(sezione)

  }

  calcoloMediaPartecipanti(sezione: string) {
    let arrayTotFamiglie = []
    let arrayTotRow = []

    this.COLUMNS.forEach(column => {
      if (column != 'incontriSezione') {
        let totFamiglie = this.form.get([sezione, 'totFamiglie' + column]).value
        arrayTotFamiglie.push(totFamiglie)

        let totRow = this.form.get([sezione, 'totRow' + column]).value
        arrayTotRow.push(totRow)

      }
    });
     // Sezione per partecipazioneMediaIniziativeFamiglie
    // let sumTotFamiglie = arrayTotFamiglie.reduce((acc, cur) => acc + cur, 0);
    // let sumTotRow = arrayTotRow.reduce((acc, cur) => acc + cur, 0);
    // let value = ((sumTotFamiglie / sumTotRow) * 100) / +(this.bamniniArray[sezione]) //bambini
    // if (isNaN(value)) {
    //   value = 0
    // }
    // this.form.get([sezione, 'partecipazioneMediaIniziativeFamiglie']).setValue(value.toFixed(2) || 0);

    // Sezione per partecipazioneMediaIncontriSezione
    let totRowincontriSezione = this.form.get([sezione, 'totRowincontriSezione']).value;
    let totFamigliePresentiIncontriSezione = this.form.get([sezione, 'totFamiglieincontriSezione']).value
    let value2 = ((totFamigliePresentiIncontriSezione / totRowincontriSezione) * 100 / +(this.bamniniArray[sezione]))
    if (isNaN(value2)) {
      value2 = 0
    }
    this.form.get([sezione, 'partecipazioneMediaIncontriSezione']).setValue(value2.toFixed(2) || 0);

    // prendo il numero del totFamiglie diviso totRow il risultato lo moltiplico per 100
    //e lo divido per il numero di bambini in sezione
  }

  submit(form: FormGroup) {

    if (form.valid) {

      this.sezioniArray.forEach((sezione, i) => {
        this.strumentiDiRegistrazioneArchivio[i].content = form.controls[sezione.nome].value

      });
      // console.log(this.strumentiDiRegistrazioneArchivio)
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.strumentiDiRegistrazioneArchivio);

    }

  }
}
