import { InjectionToken } from '@angular/core';

export interface IEnvironment {
  readonly title: string;
  readonly version: string;

  readonly develop: boolean;
  readonly staging: boolean;
  readonly production: boolean;

  readonly urls: {
    readonly api: string;
  };
}

export const ENVIRONMENT = new InjectionToken<IEnvironment>('Environment');
