import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  Domanda,
  GestioneColloquiInizialiScuolaInfanzia,
  GestioneOsservazionePrimiGiorniDiAmbientamento,
  StrumentoDiRegistrazioneArchivio,
} from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-gestione-osservazione-primi-giorni-ambientamento',
  templateUrl: './strumento-registrazione-gestione-osservazione-primi-giorni-ambientamento.component.html',
  styleUrls: ['./strumento-registrazione-gestione-osservazione-primi-giorni-ambientamento.component.scss'],
})
export class StrumentoRegistrazioneGestioneOsservazionePrimiGiorniAmbientamentoComponent implements OnInit, OnChanges {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get gestioneOsservazionePrimiGiorniDiAmbientamento(): GestioneOsservazionePrimiGiorniDiAmbientamento {
    let gestioneOsservazionePrimiGiorniDiAmbientamento = this.strumentoDiRegistrazioneArchivio.content as GestioneOsservazionePrimiGiorniDiAmbientamento;

    if (!gestioneOsservazionePrimiGiorniDiAmbientamento) {
      gestioneOsservazionePrimiGiorniDiAmbientamento = GestioneOsservazionePrimiGiorniDiAmbientamento.InitContent();
    }

    return gestioneOsservazionePrimiGiorniDiAmbientamento;
  }

  form: FormGroup;

  get formDomande(): FormArray {
    return this.form.get('domande') as FormArray;
  }
  domandeSalutoAccoglienzaMin = 0;
  domandeSalutoAccoglienzaMax = 3;

  domandeRelazioniMin = 3;

  ngOnInit() {
    this.form = this.buildForm();

    if (this.strumentoDiRegistrazioneArchivio.status === 'Bozza') {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    }

    this.form.valueChanges.subscribe((el) => {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = this.updateForm(this.gestioneOsservazionePrimiGiorniDiAmbientamento);
      }
    }
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      periodoOsservazioneAl: new FormControl(this.gestioneOsservazionePrimiGiorniDiAmbientamento.periodoOsservazioneAl || []),
      periodoOsservazioneDal: new FormControl(this.gestioneOsservazionePrimiGiorniDiAmbientamento.periodoOsservazioneDal || []),

      domande: this.buildFormDomande(this.gestioneOsservazionePrimiGiorniDiAmbientamento.domande),
    });

    return form;
  }

  buildFormDomande(domande: Domanda[]): FormArray {
    const form = new FormArray(domande.map((value) => this.buildFormDomanda(value)));

    return form;
  }

  buildFormDomanda(domanda: Domanda): FormGroup {
    const form = new FormGroup({
      codiceDomanda: new FormControl(domanda.codiceDomanda, Validators.required),
      testoDomanda: new FormControl(domanda.testoDomanda, Validators.required),
      rispostaDomanda: new FormControl(domanda.rispostaDomanda, Validators.required),
    });

    return form;
  }

  updateForm(entity: GestioneOsservazionePrimiGiorniDiAmbientamento): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  buildStrumentoForm() {

    const form = this.form.getRawValue();

    this.strumentiDiRegistrazioneArchivio[0].content = form;

    return this.strumentiDiRegistrazioneArchivio;
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const gestioneColloquiIniziali = new GestioneColloquiInizialiScuolaInfanzia(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: gestioneColloquiIniziali,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;

      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
