import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { Indirizzo, IIndirizzo } from './Indirizzo';

export enum EnumTipologiaPersonaGiuridica {
  EnteCommissionante = 'ViveCon',
  StrutturaEducativa = 'ReferentePrincipale',
}

export interface IPersonaGiuridica extends IBaseBusinessObject {
  codiceFiscale: string;
  email: string;
  numeroTelefono: string;
  partitaIva: string;
  ragioneSociale: string;
  tipo: EnumTipologiaPersonaGiuridica;
  webSiteUrl: string;

  /**
   * Relazioni
   */
  indirizzo: IIndirizzo;
}

export class PersonaGiuridica extends BaseBusinessObject
  implements IPersonaGiuridica {
  codiceFiscale: string;
  email: string;
  numeroTelefono: string;
  partitaIva: string;
  ragioneSociale: string;
  tipo: EnumTipologiaPersonaGiuridica;
  webSiteUrl: string;

  /**
   * Relazioni
   */
  indirizzo: Indirizzo;

  constructor(params: Partial<IPersonaGiuridica>) {
    super(params);

    /**
     * Mapping
     */
    if (params.indirizzo) {
      this.indirizzo = new Indirizzo(params.indirizzo);
    }
  }

  deconstructor(): IPersonaGiuridica {
    return Object.assign({ }, this);
  }
}
