import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import {
  EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi,
  RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi,
  RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-rilevazione-allestimento-spazi-ricerche-materiali-servizi-educativi',
  templateUrl: './rilevazione-allestimento-spazi-ricerche-materiali-servizi-educativi.component.html',
  styleUrls: ['./rilevazione-allestimento-spazi-ricerche-materiali-servizi-educativi.component.scss'],
})
export class RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button')
  buttonElementRef: ElementRef;

  @ViewChildren(MatTable) tables: QueryList<MatTable<any>>;

  unsubscribe$: Subject<void> = new Subject();

  progettiRealizzatiDisplayedColumns = ['centroInteresse', 'approfondimenti', 'materiali', 'azioni'];

  centriInteresseList: EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi[] = Object.values(EnumRilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi);

  form: FormGroup;

  get sezioniForm(): FormArray {
    return this.form.get('sezioniForm') as FormArray;
  }

  ngOnInit() {
    this.form = new FormGroup({
      sezioniForm: this.buildSezioniForm()
    });
    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = new FormGroup({
          sezioniForm: this.buildSezioniForm()
        });
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getContent(index: number): RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi {
    let content = this.strumentiDiRegistrazioneArchivio[index].content as RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi;

    return content;
  }

  buildSezioniForm(): FormArray {
    const formArray = new FormArray([]);
    this.strumentiDiRegistrazioneArchivio.map((s, i) => formArray.push(this.buildForm(i)));
    return formArray;
  }

  buildForm(index: number): FormGroup {
    const form = new FormGroup({
      totAllestimentiRicerche: new FormControl(this.getContent(index)?.totAllestimentiRicerche || 0),
      totMaterialiSupporto: new FormControl(this.getContent(index)?.totMaterialiSupporto || 0),

      rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList: this.getContent(index) && this.getContent(index).rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList ? this.buildFormRilevazioni(this.getContent(index).rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList) : new FormArray([]),
    });

    return form;
  }

  buildFormRilevazioni(list: RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow[]): FormArray {
    const form = new FormArray(list.map((value) => this.buildFormRilevazione(value)));

    return form;
  }

  buildFormRilevazione(row: RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow): FormGroup {
    const form = new FormGroup({
      centroInteresse: new FormControl(row?.centroInteresse || null),
      approfondimenti: new FormControl(row?.approfondimenti || null),
      numApprofondimenti: new FormControl(row?.numApprofondimenti || null),
      materiali: new FormControl(row?.materiali || null),
      numMateriali: new FormControl(row?.numMateriali || null)
    });

    return form;
  }

  calcoloTot(indexSezione: number) {
    let arrayNumApprofondimenti = []
    let arrayNumMateriali = []
    let array: Array<RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow> = this.sezioniForm.at(indexSezione).get('rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList').value
    array.forEach(el => {
      arrayNumApprofondimenti.push(el.numApprofondimenti)
      arrayNumMateriali.push(el.numMateriali)

    })
    let sumNumApprofondimenti = arrayNumApprofondimenti.reduce((acc, cur) => acc + cur, 0);
    this.sezioniForm.at(indexSezione).get('totAllestimentiRicerche').setValue(sumNumApprofondimenti);

    let sumNumMateriali = arrayNumMateriali.reduce((acc, cur) => acc + cur, 0);
    this.sezioniForm.at(indexSezione).get('totMaterialiSupporto').setValue(sumNumMateriali);

  }

  getFormArrayRilevazione(index: number): FormArray {
    return this.sezioniForm.at(index).get('rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList') as FormArray;
  }

  addRilevazione(index: number) {
    (this.sezioniForm.at(index).get('rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList') as FormArray).push(
      this.buildFormRilevazione(new RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiRow()));

    this.tables.map(table => table.renderRows());
  }

  deleteRilevazione(sezioneIndex: number, index: number) {
    (this.sezioniForm.at(sezioneIndex).get('rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativiList') as FormArray).removeAt(index);
    this.calcoloTot(sezioneIndex)
    this.tables.map(table => table.renderRows());
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];
      this.sezioniForm.value.forEach((sezioneForm: RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi, index: number) => {
        const rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi = new RilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi(sezioneForm);
        const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
          ...this.strumentiDiRegistrazioneArchivio[index],
          content: rilevazioneAllestimentoSpaziRicercheMaterialiServiziEducativi,
        });
        strumentiDiRegistrazioneArchivio[index] = strumentoDiRegistrazioneArchivio;
      });
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
