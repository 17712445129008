import {
  EnumAllegatiTabellaDiProvenienza,
  EnumTipologiaAllegato,
} from './Allegato';
import { EnumTipoAnnoScolasticoPeriodoFormativo } from './AnnoScolasticoPeriodoFormativo';
import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { EnumTipoContenutoChiosco } from './ContenutoChioscoLBLArchivio';
import { EnumGiorniSettimana } from './GiornoSettimanaStruttura';
import { EnumTipoIndirizzo } from './Indirizzo';
import { EnumStagioneMenu, EnumTipologiaDietaMenu } from './Menu';
import { EnumTipoMenuPastoPortata } from './MenuPastoPortata';
import { EnumModuliLBL } from './ModuloLBLAttivatoPerStruttura';
import { EnumTipologiaPastoMenu } from './PastoMenuStruttura';
import {
  EnumPersonaFisicaGender,
  EnumTipologiaPersonaFisica,
} from './PersonaFisica';
import {
  EnumPersonaFisicaTipologiaParentela,
  EnumTipologiaDettagliPersonaFisicaRelazioni,
} from './PersonaFisicaRelazioni';
import { EnumTipologiaPersonaGiuridica } from './PersonaGiuridica';
import { EnumTipologiaRuoloApplicativo } from './RuoloApplicativo';
import { EnumStatoSegnalazione, EnumTipoSegnalazione } from './Segnalazione';
import {
  EnumTipologiaAccessoServizioEducativo,
  EnumTipologiaOffertaServizioEducativo,
} from './ServizioEducativo';
import { EnumFasciaEta } from './SezioneAnnoScolasticoPeriodoFormativo';
import { EnumStatoStrumentoRegistrazione, EnumTipologiaConsumoPasto } from './StrumentoDiRegistrazioneArchivio';
import { EnumFrequenzaCompilazione } from './StrumentoDiRegistrazioneTemplate';
import { EnumStrumentoDiRegistrazioneTemplateBodyCode, EnumStrumentoDiRegistrazioneTemplateBodyViewMode } from './StrumentoDiRegistrazioneTemplateBody';
import { EnumStatoAccount, EnumStatoPassword } from './Utente';

export interface ITipologia extends IBaseBusinessObject {
  categoriaTipologia: EnumCategoriaTipologia;
  codice: CodiceTipologia;
}

export class Tipologia extends BaseBusinessObject implements ITipologia {
  categoriaTipologia: EnumCategoriaTipologia;
  codice: CodiceTipologia;

  constructor(params?: Partial<ITipologia>) {
    super(params);
  }

  deconstructor(): ITipologia {
    return Object.assign({ }, this);
  }
}

export enum EnumCategoriaTipologia {
  AllegatoTabellaDiProvenienza = 'EnumAllegatiTabellaDiProvenienza',
  FasciaEta = 'EnumFasciaEta',
  FrequenzaCompilazione = 'EnumFrequenzaCompilazione',
  GiornoSettimana = 'EnumGiorniSettimana',
  ModuloLBL = 'EnumModuliLBL',
  PersonaFisicaGenere = 'EnumPersonaFisicaGender',
  PersonaFisicaParentela = 'EnumPersonaFisicaTipologiaParentela',
  StagioneMenu = 'EnumStagioneMenu',
  StatoAccount = 'EnumStatoAccount',
  StatoPassword = 'EnumStatoPassword',
  StatoSegnalazione = 'EnumStatoSegnalazione',
  StatoStrumentoRegistrazione = 'EnumStatoStrumentoRegistrazione',
  AnnoScolasticoPeriodoFormativo = 'EnumTipoAnnoScolasticoPeriodoFormativo',
  Indirizzo = 'EnumTipoIndrizzo',
  AccessoServizioEducativo = 'EnumTipologiaAccessoServizioEducativo',
  Allegato = 'EnumTipologiaAllegato',
  DettagliPersonaFisicaRelazioni = 'EnumTipologiaDettagliPersonaFisicaRelazioni',
  DietaMenu = 'EnumTipologiaDietaMenu',
  OffertaServizioEducativo = 'EnumTipologiaOffertaServizioEducativo',
  PastoMenu = 'EnumTipologiaPastoMenu',
  PersonaFisica = 'EnumTipologiaPersonaFisica',
  PersonaGiuridica = 'EnumTipologiaPersonaGiuridica',
  RuoloApplicativo = 'EnumTipologiaRuoloApplicativo',
  MenuPastoPortata = 'EnumTipoMenuPastoPortata',
  Segnalazione = 'EnumTipoSegnalazione',
  TipologiaRelazione = 'EnumTipologiaDettagliPersonaFisicaRelazioni',
  ContenutoChiosco = 'EnumTipoContenutoChiosco',
  StrumentoDiRegistrazioneTemplateBodyCode = 'EnumStrumentoDiRegistrazioneTemplateBodyCode',
  TipologiaConsumoPasto = 'EnumTipologiaConsumoPasto',
}

export type CodiceTipologia =
  | EnumAllegatiTabellaDiProvenienza
  | EnumFasciaEta
  | EnumFrequenzaCompilazione
  | EnumGiorniSettimana
  | EnumModuliLBL
  | EnumPersonaFisicaGender
  | EnumPersonaFisicaTipologiaParentela
  | EnumStagioneMenu
  | EnumStatoAccount
  | EnumStatoPassword
  | EnumStatoSegnalazione
  | EnumStatoStrumentoRegistrazione
  | EnumStrumentoDiRegistrazioneTemplateBodyViewMode
  | EnumTipoAnnoScolasticoPeriodoFormativo
  | EnumTipoIndirizzo
  | EnumTipologiaAccessoServizioEducativo
  | EnumTipologiaAllegato
  | EnumTipologiaDettagliPersonaFisicaRelazioni
  | EnumTipologiaDietaMenu
  | EnumTipologiaOffertaServizioEducativo
  | EnumTipologiaPastoMenu
  | EnumTipologiaPersonaFisica
  | EnumTipologiaPersonaGiuridica
  | EnumTipologiaRuoloApplicativo
  | EnumTipoMenuPastoPortata
  | EnumTipoSegnalazione
  | EnumTipologiaDettagliPersonaFisicaRelazioni
  | EnumTipoContenutoChiosco
  | EnumStrumentoDiRegistrazioneTemplateBodyCode
  | EnumTipologiaConsumoPasto;
