

export interface IRilevazioneProgettazioniAttivitaSviluppoPensieroEcologico {
    numeroProgettiRealizzati: number;

    /**
     * Relazioni
     */
    progettiRealizzati: IProgettiRealizzatiAttivitaSviluppoPensieroEcologicoRow[];
}

export class RilevazioneProgettazioniAttivitaSviluppoPensieroEcologico implements IRilevazioneProgettazioniAttivitaSviluppoPensieroEcologico {
    numeroProgettiRealizzati: number;

    /**
     * Relazioni
     */
    progettiRealizzati: ProgettiRealizzatiAttivitaSviluppoPensieroEcologicoRow[];


    constructor(params: Partial<IRilevazioneProgettazioniAttivitaSviluppoPensieroEcologico> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
        if (params.progettiRealizzati) {
            this.progettiRealizzati = params.progettiRealizzati.map((value) => new ProgettiRealizzatiAttivitaSviluppoPensieroEcologicoRow(value));
        }
    }

    deconstructor(): IRilevazioneProgettazioniAttivitaSviluppoPensieroEcologico {
        return Object.assign({}, this);
    }
}

export interface IProgettiRealizzatiAttivitaSviluppoPensieroEcologicoRow {
    progettazione: string;
    proposte: string;
    documentazione: string;
}

export class ProgettiRealizzatiAttivitaSviluppoPensieroEcologicoRow implements IProgettiRealizzatiAttivitaSviluppoPensieroEcologicoRow {
    progettazione: string;
    proposte: string;
    documentazione: string;

    constructor(params: Partial<IProgettiRealizzatiAttivitaSviluppoPensieroEcologicoRow> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
    }

    deconstructor(): IProgettiRealizzatiAttivitaSviluppoPensieroEcologicoRow {
        return Object.assign({}, this);
    }
}
