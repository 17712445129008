import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  Domanda,
  GestioneColloquiInizialiScuolaInfanzia,
  GestioneSchedaControlloPulizie,
  GestioneSchedaControlloPulizieRow, StrumentoDiRegistrazioneArchivio
} from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-gestione-scheda-controllo-pulizie',
  templateUrl: './strumento-registrazione-gestione-scheda-controllo-pulizie.component.html',
  styleUrls: ['./strumento-registrazione-gestione-scheda-controllo-pulizie.component.scss'],
})
export class StrumentoRegistrazioneGestioneSchedaControlloPulizieComponent implements OnInit, OnChanges {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): GestioneSchedaControlloPulizie {
    let content = this.strumentoDiRegistrazioneArchivio.content as GestioneSchedaControlloPulizie;

    if (!content) {
      content = GestioneSchedaControlloPulizie.InitContent();
    }

    return content;
  }

  form: FormGroup;

  get formListaControlli(): FormArray {
    return this.form.get('listaControlli') as FormArray;
  }
  listaControlliDisplayedColumns = ['descrizioneControllo', 'conforme', 'osservazioni', 'provvedimento', 'dataProvvedimento'];

  ngOnInit() {
    this.form = this.buildForm();

    this.form.valueChanges.subscribe(() => {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = this.updateForm(this.content);
      }
    }
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      data: new FormControl(this.content.data),
      ora: new FormControl(this.content.ora),

      listaControlli: this.buildFormListaControlli(this.content.listaControlli),
    });

    return form;
  }

  buildFormListaControlli(listaControlli: GestioneSchedaControlloPulizieRow[]): FormArray {
    const form = new FormArray(listaControlli.map((value) => this.buildFormControllo(value)));

    return form;
  }

  buildFormControllo(controllo: GestioneSchedaControlloPulizieRow): FormGroup {
    const form = new FormGroup({
      conforme: new FormControl(controllo.conforme),
      dataProvvedimento: new FormControl(controllo.dataProvvedimento),
      descrizioneControllo: new FormControl(controllo.descrizioneControllo),
      osservazioni: new FormControl(controllo.osservazioni),
      provvedimento: new FormControl(controllo.provvedimento),
    }, { updateOn: 'blur' });

    return form;
  }

  updateForm(entity: GestioneSchedaControlloPulizie): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  buildStrumentoForm() {
    const form = this.form.getRawValue();
    this.strumentiDiRegistrazioneArchivio[0].content = form;

    return this.strumentiDiRegistrazioneArchivio;
  }

  submit(form: FormGroup) {
    if (form.valid) {
    }
  }
}
