import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@gpi/lbl/app/data-access/auth';
import { ENVIRONMENT, IEnvironment } from '@gpi/lbl/shared/entity';
import { throwError, Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthorizationHeaderInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    @Inject(ENVIRONMENT)
    private environment: IEnvironment,
    private router: Router,
  ) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const apiUrl = this.environment.urls.api;
    const authState = this.authService.query.getValue();
    const utenteToken = authState.utenteToken;
    const utenteImpersonificatoToken = authState.utenteImpersonificatoToken;
    const utenteParenteToken = authState.utenteParenteToken;

    const token = utenteParenteToken || utenteImpersonificatoToken || utenteToken;

    if (req.url.startsWith(apiUrl) && token) {
      req = req.clone({
        setHeaders: { Authorization: `Bearer ${token}` },
      });
    }

    return next.handle(req).pipe(
      catchError((err: any) => {
        if (err instanceof HttpErrorResponse || err.status) {
          if (err.status === 401 && !this.router.url.includes('bambini')) {
            this.authService.logout().subscribe();
            this.router.navigate(['/']);
          }
        }

        return throwError(err);
      }),
    );
  }
}
