import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AllegatiService } from '@gpi/lbl/app/data-access/allegati';
import {
  Allegato,
  EnumAllegatiTabellaDiProvenienza,
  EnumTipologiaAllegato,
  GestionePortfolioCompetenzeBambini,
  StrumentoDiRegistrazioneArchivio,
  StrutturaEducativa,
} from '@gpi/lbl/shared/entity';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogLineeGuidaPortfolioCompetenzeBambiniComponent } from './dialog/dialog-linee-guida-portfolio-competenze-bambini.component';


@Component({
  selector: 'lbl-app-ui-strumento-registrazione-gestione-portfolio-competenze-bambini',
  templateUrl: './strumento-registrazione-gestione-portfolio-competenze-bambini.component.html',
  styleUrls: ['./strumento-registrazione-gestione-portfolio-competenze-bambini.component.scss'],
})
export class StrumentoRegistrazioneGestionePortfolioCompetenzeBambini implements OnInit, OnChanges, OnDestroy {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Input()
  strutturaEducativa: StrutturaEducativa;
  @Input()
  submit$: Observable<void>;
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  @ViewChild('button')
  buttonElementRef: ElementRef;

  unsubscribe$: Subject<void> = new Subject();

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): GestionePortfolioCompetenzeBambini {
    let content = this.strumentoDiRegistrazioneArchivio.content as GestionePortfolioCompetenzeBambini;

    return content;
  }

  form: FormGroup;

  tempAllegato: Allegato;
  tempFile: File;

  constructor(
    private allegatiService: AllegatiService,
    private matDialog: MatDialog) { }

  ngOnInit() {
    this.form = this.buildForm();
    this.form.valueChanges.subscribe(() => this.submit(this.form));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue && this.content) {
        this.form = this.updateForm(this.content);
      }
    }

    // Initialize submit from parent
    if (changes.submit$ && this.submit$) {
      this.submit$.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
        const htmlButtonElement: HTMLButtonElement = this.buttonElementRef
          .nativeElement;

        htmlButtonElement.click();
      });
    }
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      portfolio: new FormControl(this.content && this.content.portfolio ? this.content.portfolio : null)
    });

    return form;
  }

  updateForm(entity: GestionePortfolioCompetenzeBambini): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  openDialog() {
    this.matDialog.open(DialogLineeGuidaPortfolioCompetenzeBambiniComponent);
  }

  upload(fileList: FileList) {
    for (const key in fileList) {
      if (fileList.hasOwnProperty(key)) {
        const file = fileList[key];
        const allegato = new Allegato({
          tableRowId: this.strumentiDiRegistrazioneArchivio[0].rowId,
          tabellaDiProvenienza: EnumAllegatiTabellaDiProvenienza.StrumentiRegistrazioneArchivio,
          tipologia: EnumTipologiaAllegato.GestioniPortfolioCompetenzeBambini,
        });

        this.allegatiService.upload(allegato, file).subscribe(res => {
          this.form.get('portfolio').setValue(res);
          this.tempAllegato = res;
          this.tempFile = file;
        });
      }
    }
  }

  deleteFile(id: number) {
    this.allegatiService.deleteById(id).subscribe(() => {
      this.tempAllegato = null;
      this.tempFile = null;
  
      if (this.content && this.content.portfolio) {
        this.content.portfolio = null;
      }
      this.form.get('portfolio').setValue(null);
    });
  }

  submit(form: FormGroup) {
    if (form.valid) {
      const strumentiDiRegistrazioneArchivio = [...this.strumentiDiRegistrazioneArchivio];

      const gestionePortfolioCompetenzeBambini = new GestionePortfolioCompetenzeBambini(form.value);
      const strumentoDiRegistrazioneArchivio = new StrumentoDiRegistrazioneArchivio({
        ...this.strumentoDiRegistrazioneArchivio,
        content: gestionePortfolioCompetenzeBambini,
      });

      strumentiDiRegistrazioneArchivio[0] = strumentoDiRegistrazioneArchivio;
      this.updatedStrumentiDiRegistrazioneArchivio.emit(strumentiDiRegistrazioneArchivio);
    }
  }
}
