export class BackendError extends Error {
  code: string;
  error: {
    codiceErrore: string,
  };

  constructor(message: string, code?: string) {
    super(message);
    this.name = 'BackendError';
    this.code = `error.backend.${code}`;
    this.error = {
      codiceErrore : code,
    };

    Object.setPrototypeOf(this, BackendError.prototype);
  }
}
