

export interface ISintesiProgettoEducativoSipi {
    sintesi: string;

  /**
   * Relazioni
   */

}

export class SintesiProgettoEducativoSipi implements ISintesiProgettoEducativoSipi {
    sintesi: string;

  /**
   * Relazioni
   */

  constructor(params: Partial<ISintesiProgettoEducativoSipi> = {}) {
    Object.assign(this, params);

    /**
     * Mapping
     */
  }

  deconstructor(): ISintesiProgettoEducativoSipi {
    return Object.assign({}, this);
  }
}
