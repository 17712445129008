

export interface IRilevazioneProgettazioniAttivitaLinguaggiCibo {
    numeroProgettiRealizzati: number;

    /**
     * Relazioni
     */
    progettiRealizzati: IProgettiRealizzatiAttivitaLinguaggioCiboRow[];
}

export class RilevazioneProgettazioniAttivitaLinguaggiCibo implements IRilevazioneProgettazioniAttivitaLinguaggiCibo {
    numeroProgettiRealizzati: number;

    /**
     * Relazioni
     */
    progettiRealizzati: ProgettiRealizzatiAttivitaLinguaggioCiboRow[];


    constructor(params: Partial<IRilevazioneProgettazioniAttivitaLinguaggiCibo> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
        if (params.progettiRealizzati) {
            this.progettiRealizzati = params.progettiRealizzati.map((value) => new ProgettiRealizzatiAttivitaLinguaggioCiboRow(value));
        }
    }

    deconstructor(): IRilevazioneProgettazioniAttivitaLinguaggiCibo {
        return Object.assign({}, this);
    }
}

export interface IProgettiRealizzatiAttivitaLinguaggioCiboRow {
    progettazione: string;
    proposte: string;
    documentazione: string;
}

export class ProgettiRealizzatiAttivitaLinguaggioCiboRow implements IProgettiRealizzatiAttivitaLinguaggioCiboRow {
    progettazione: string;
    proposte: string;
    documentazione: string;

    constructor(params: Partial<IProgettiRealizzatiAttivitaLinguaggioCiboRow> = {}) {
        Object.assign(this, params);

        /**
         * Mapping
         */
    }

    deconstructor(): IProgettiRealizzatiAttivitaLinguaggioCiboRow {
        return Object.assign({}, this);
    }
}
