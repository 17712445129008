<div class="avatar-wrapper mat-elevation-z1" [style]="avatarStyle">
  <span *ngIf="!avatar && !newAvatar" [class]="textClass">{{
    intialsPerson()
  }}</span>

  <button
    *ngIf="editable"
    type="button"
    mat-icon-button
    color="primary"
    (click)="inputFile.click()"
  >
    <mat-icon svgIcon="add_a_photo"></mat-icon>
  </button>

  <input type="file" accept=".jpg, .jpeg, .png" hidden #inputFile (change)="upload($event.target.files)" />
</div>
