import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';

import { Theme } from './state/theme.model';
import { ThemesService } from './state/themes.service';

export function preloadThemes(themesService: ThemesService) {
  return function () {
    const defaultThemes: Theme[] = [
      {
        id: 'light',
        label: 'Light',

        cssClass: 'theme-light',
      },
      {
        id: 'dark',
        label: 'Dark',

        cssClass: 'theme-dark',
      },
    ];

    themesService.store.upsertMany(defaultThemes);

    if (!themesService.query.hasActive()) {
      themesService.store.setActive(defaultThemes[0].id);
    }
  };
}

@NgModule()
export class LblAppUtilThemesModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: LblAppUtilThemesModule,
      providers: [
        {
          provide: APP_INITIALIZER,
          multi: true,
          useFactory: preloadThemes,
          deps: [ThemesService],
        },
      ],
    };
  }
}
