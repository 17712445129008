import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { RouterModule } from '@angular/router';
import { LblAppUiModule } from '@gpi/lbl/app/ui';
import { ENVIRONMENT } from '@gpi/lbl/shared/entity';
import { SharedUiMaterialModule } from '@gpi/shared/ui/material';
import { TranslocoModule } from '@ngneat/transloco';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';

import { Configuration } from './models/configuration.model';

const modules = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  FlexLayoutModule,
  SharedUiMaterialModule,
  LblAppUiModule,
  TranslocoModule,
  TranslocoLocaleModule,
];

@NgModule({
  imports: [...modules],
  exports: [...modules],
})
export class LblAppUtilSharedModule {
  static forRoot(configuration: Configuration): ModuleWithProviders {
    return {
      ngModule: LblAppUtilSharedModule,
      providers: [
        {
          provide: ENVIRONMENT,
          useValue: configuration.environment,
        },

        {
          provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
          useValue: { duration: 3500 },
        },
      ],
    };
  }
}
