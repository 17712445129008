import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';
import { IServizioEducativo, ServizioEducativo } from './ServizioEducativo';
import {
  ISezioneAnnoScolasticoPeriodoFormativo,
  SezioneAnnoScolasticoPeriodoFormativo,
} from './SezioneAnnoScolasticoPeriodoFormativo';
import { IStrutturaEducativa, StrutturaEducativa } from './StrutturaEducativa';
import { IUtente, Utente } from './Utente';

export enum EnumStatoAnnoScolastico {
  Attivo = 'Attivo',
  Chiuso = 'Chiuso',
}

export enum EnumTipoAnnoScolasticoPeriodoFormativo {
  Canonico = 'Canonico',
  Temporaneo = 'Temporaneo',
}

export interface IAnnoScolasticoPeriodoFormativo extends IBaseBusinessObject {
  dataFine: Date;
  dataInizio: Date;
  nome: string;
  stato: EnumStatoAnnoScolastico;
  tipo: EnumTipoAnnoScolasticoPeriodoFormativo;

  /**
   * Relazioni
   */
  strutturaEducativa: IStrutturaEducativa;
  servizioEducativo: IServizioEducativo;
  coordinatori: IUtente[];
  chiosco: IUtente;
  cuochi: IUtente[];
  sezioni: ISezioneAnnoScolasticoPeriodoFormativo[];
}

export class AnnoScolasticoPeriodoFormativo extends BaseBusinessObject
  implements IAnnoScolasticoPeriodoFormativo {
  dataFine: Date;
  dataInizio: Date;
  nome: string;
  stato: EnumStatoAnnoScolastico;
  tipo: EnumTipoAnnoScolasticoPeriodoFormativo;

  /**
   * Relazioni
   */
  chiosco: Utente;
  coordinatori: Utente[];
  cuochi: Utente[];
  servizioEducativo: ServizioEducativo;
  sezioni: SezioneAnnoScolasticoPeriodoFormativo[];
  strutturaEducativa: StrutturaEducativa;

  constructor(params?: Partial<IAnnoScolasticoPeriodoFormativo>) {
    super(params);

    /**
     * Mapping
     */
    if (params.dataFine) {
      this.dataFine = new Date(params.dataFine);
    }
    if (params.dataInizio) {
      this.dataInizio = new Date(params.dataInizio);
    }

    if (params.chiosco) {
      this.chiosco = new Utente(params.chiosco);
    }
    if (params.coordinatori) {
      this.coordinatori = params.coordinatori.map((value) => new Utente(value));
    }
    if (params.cuochi) {
      this.cuochi = params.cuochi.map((value) => new Utente(value));
    }
    if (params.servizioEducativo) {
      this.servizioEducativo = new ServizioEducativo(params.servizioEducativo);
    }
    if (params.sezioni) {
      this.sezioni = params.sezioni.map(
        (value) => new SezioneAnnoScolasticoPeriodoFormativo(value),
      );
    }
    if (params.strutturaEducativa) {
      this.strutturaEducativa = new StrutturaEducativa(
        params.strutturaEducativa,
      );
    }
  }

  deconstructor(): IAnnoScolasticoPeriodoFormativo {
    return Object.assign({ }, this);
  }

  get yearRange(): string {
    return `${this.dataInizio.getFullYear()} - ${this.dataFine.getFullYear()}`;
  }
}
