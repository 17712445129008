import { BaseBusinessObject, IBaseBusinessObject } from './BaseBusinessObject';

export enum EnumTipoMenuPastoPortata {
  Unica = 'unica',
  Primo = 'primo',
  Secondo = 'secondo',
  Contorno = 'contorno',
}

export interface IMenuPastoPortata extends IBaseBusinessObject {
  nomePasto: string;
  noteVariazioniPasto: string;
  tipoPortata: EnumTipoMenuPastoPortata;
}

export class MenuPastoPortata extends BaseBusinessObject
  implements IMenuPastoPortata {
  nomePasto: string;
  noteVariazioniPasto: string;
  tipoPortata: EnumTipoMenuPastoPortata;

  constructor(params?: Partial<IMenuPastoPortata>) {
    super(params);
  }

  deconstructor(): IMenuPastoPortata {
    return Object.assign({ }, this);
  }
}
