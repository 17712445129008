import { IUtente } from './Utente';

export interface IBaseBusinessObject {
  id?: number;
  rowId?: string;

  dtInsert?: Date;
  dtUpdate?: Date;
  deleted?: boolean;

  insertedBy?: IUtente;
  updatedBy?: IUtente;
}

export class BaseBusinessObject implements IBaseBusinessObject {
  id?: number;
  rowId?: string;

  dtInsert?: Date;
  dtUpdate?: Date;
  deleted?: boolean;

  insertedBy?: IUtente;
  updatedBy?: IUtente;

  constructor(params?: Partial<IBaseBusinessObject>) {
    if (!params) {
      return;
    }

    Object.assign(this, params);

    /**
     * Mapping
     */
    if (params.dtInsert) {
      this.dtInsert = new Date(params.dtInsert);
    }
    if (params.dtUpdate) {
      this.dtUpdate = new Date(params.dtUpdate);
    }
  }
}
