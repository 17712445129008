import { Component, OnDestroy, Input, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Utente, FiguraProfessionaleDiRiferimentoInformazioniAnagraficheSipiRow } from '@gpi/lbl/shared/entity';
import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'lbl-app-feature-sipi-sezione-anagrafica-dialog-form-figura-professionale',
  templateUrl: 'dialog-form-figura-professionale.component.html',
  styleUrls: ['dialog-form-figura-professionale.component.scss'],
})
export class DialogFormFiguraProfessionaleComponent {

  submit$: Subject<void> = new Subject();

  constructor(
    private matDialogRef: MatDialogRef<DialogFormFiguraProfessionaleComponent>,
    @Inject(MAT_DIALOG_DATA) public form: FormGroup
  ) { }

  cancel() {
    this.matDialogRef.close();
  }

  continue() {
    this.matDialogRef.close(this.form);
  }
}
