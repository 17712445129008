import { Injectable } from '@angular/core';
import {
  EnumTipologiaRuoloApplicativo,
  IImpersonateBody,
  IImpersonateResponse,
  ILoginBody,
  ILoginResponse,
  IResetPasswordBody,
  ISendMailResetPasswordBody,
  IUpdatePasswordBody,
  IUserInfoBody,
  IUserInfoResponse,
} from '@gpi/lbl/shared/entity';
import { of, throwError, Observable } from 'rxjs';
import { catchError, finalize, first, tap } from 'rxjs/operators';

import { AuthBackend } from './auth.backend';
import { AuthQuery } from './auth.query';
import { AuthStore } from './auth.store';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    public query: AuthQuery,
    public store: AuthStore,
    private backend: AuthBackend,
  ) { }

  login(body: ILoginBody): Observable<ILoginResponse> {
    this.store.setLoading(true);

    return this.backend.login(body).pipe(
      tap((utente) => this.store.setUtente(utente)),
      catchError((error) => {
        this.store.setError(error);

        return throwError(error);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  impersonate(body: IImpersonateBody): Observable<IImpersonateResponse> {
    this.store.setLoading(true);

    return this.backend.impersonate(body).pipe(
      tap((utente) => this.store.setUtenteImpersonificato(utente)),
      catchError((error) => {
        this.store.setError(error);

        return throwError(error);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  loginParente(body: ILoginBody): Observable<ILoginResponse> {
    this.store.setLoading(true);

    return this.backend.login(body).pipe(
      tap((utente) => this.store.setUtenteParente(utente)),
      catchError((error) => {
        this.store.setError(error);

        return throwError(error);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  userInfo(body: IUserInfoBody): Observable<IUserInfoResponse> {
    this.store.setLoading(true);

    return this.backend.userInfo(body).pipe(
      catchError((error) => {
        this.store.setError(error);

        return throwError(error);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  sendMailResetPassword(body: ISendMailResetPasswordBody): Observable<void> {
    this.store.setLoading(true);

    return this.backend.sendMailResetPassword(body).pipe(
      catchError((error) => {
        this.store.setError(error);

        return throwError(error);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  resetPassword(body: IResetPasswordBody): Observable<void> {
    this.store.setLoading(true);

    return this.backend.resetPassword(body).pipe(
      catchError((error) => {
        this.store.setError(error);

        return throwError(error);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  updatePassword(
    utenteId: number,
    body: IUpdatePasswordBody,
  ): Observable<void> {
    this.store.setLoading(true);

    return this.backend.updatePassword(utenteId, body).pipe(
      catchError((error) => {
        this.store.setError(error);

        return throwError(error);
      }),
      finalize(() => this.store.setLoading(false)),
    );
  }

  logout(): Observable<void> {
    return of(null).pipe(
      first(),
      tap(() => this.store.reset()),
    );
  }

  endImpersonation(): Observable<void> {
    return of(null).pipe(
      first(),
      tap(() => {
        this.store.setUtenteImpersonificato(null);
        this.store.setRuoloApplicativoImpersonificato(null);
        this.store.setSezioneAnnoScolasticoPeriodoFormativoImpersonificato(
          null,
        );
        this.store.setStrutturaEducativaImpersonificato(null);
      }),
    );
  }

  logoutParente(): Observable<void> {
    return of(null).pipe(
      first(),
      tap(() => {
        this.store.setUtenteParente(null);
        this.store.setRuoloApplicativoParente(null);
        this.store.setSezioneAnnoScolasticoPeriodoFormativoParente(null);
        this.store.setStrutturaEducativaParente(null);
      }),
    );
  }
}
