import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Domanda, RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate, StrumentoDiRegistrazioneArchivio } from '@gpi/lbl/shared/entity';

@Component({
  selector: 'lbl-app-ui-strumento-registrazione-racconti-riflessioni',
  templateUrl: './strumento-registrazione-racconti-riflessioni.component.html',
  styleUrls: ['./strumento-registrazione-racconti-riflessioni.component.scss'],
})
export class StrumentoRegistrazioneRaccontiRiflessioniComponent implements OnInit, OnChanges {
  @Input()
  strumentiDiRegistrazioneArchivio: StrumentoDiRegistrazioneArchivio[] = [];
  @Output()
  updatedStrumentiDiRegistrazioneArchivio: EventEmitter<StrumentoDiRegistrazioneArchivio[]> = new EventEmitter();

  isMobile: boolean;

  get strumentoDiRegistrazioneArchivio(): StrumentoDiRegistrazioneArchivio {
    return this.strumentiDiRegistrazioneArchivio[0];
  }

  get content(): RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate {
    let content = this.strumentoDiRegistrazioneArchivio.content as RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate;

    if (!content) {
      content = RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate.InitContent();
    }

    return content;
  }

  form: FormGroup;

  get formDomande(): FormArray {
    return this.form.get('domande') as FormArray;
  }

  ngOnInit() {
    if (window.innerWidth < 1280) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.form = this.buildForm();

    this.form.valueChanges.subscribe((el) => {
      this.updatedStrumentiDiRegistrazioneArchivio.emit(this.buildStrumentoForm());
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (changes.strumentiDiRegistrazioneArchivio && changes.strumentiDiRegistrazioneArchivio.currentValue) {
        this.form = this.updateForm(this.content);
      }
    }
  }

  onResize(event) {
    if (window.innerWidth < 1280) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  buildForm(): FormGroup {
    const form = new FormGroup({
      domande: this.buildFormDomande(this.content.domande),
    });

    return form;
  }

  buildFormDomande(domande: Domanda[]): FormArray {
    const form = new FormArray(domande.map((value) => this.buildFormDomanda(value)));

    return form;
  }

  buildFormDomanda(domanda: Domanda): FormGroup {
    const form = new FormGroup({
      codiceDomanda: new FormControl(domanda.codiceDomanda),
      testoDomanda: new FormControl(domanda.testoDomanda),
      rispostaDomanda: new FormControl(domanda.rispostaDomanda),
    });

    return form;
  }

  updateForm(entity: RaccontiRiflessioniAutonomieCompetenzeBambiniDuranteEstate): FormGroup {
    const form = this.buildForm();
    form.patchValue(entity);

    return form;
  }

  buildStrumentoForm() {

    const form = this.form.getRawValue();

    this.strumentiDiRegistrazioneArchivio[0].content = form;

    return this.strumentiDiRegistrazioneArchivio;
  }

  submit(form: FormGroup) {
    if (form.valid) {

    }
  }
}
